import React, { Component } from "react";
import { Point } from "./Point";
import { renderCurves } from "./rendering";

const { round } = Math;

const dpi = window !== undefined ? window.devicePixelRatio : 1;

interface Props {
  width: number;
  height: number;
  className?: string;
  color: string;
  points: Point[];
  pixelsPerSecond: number;
  maskColor?: string;
  maskPercentage?: number;
}

export class StaticVisualizer extends Component<Props> {
  canvas: HTMLCanvasElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;
  canvasWidth: number;
  canvasHeight: number;

  constructor(props: Props) {
    super(props);

    this.canvasWidth = round(this.width * dpi);
    this.canvasHeight = round(this.props.height * dpi);

    this.renderFrame = this.renderFrame.bind(this);
    this.canvasRef = this.canvasRef.bind(this);
  }

  get width() {
    if (this.props.points.length <= 0) {
      return 0;
    }

    return (
      this.props.points[this.props.points.length - 1].x *
      this.props.pixelsPerSecond
    );
  }

  setupCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    canvas.width = this.canvasWidth;
    canvas.height = this.canvasHeight;
    canvas.style.width = `${this.width}px`;
    canvas.style.height = `${this.props.height}px`;

    this.ctx = this.canvas.getContext("2d")!;
  }

  canvasRef(ref: HTMLCanvasElement) {
    if (ref === null) {
      this.canvas = null;
      this.ctx = null;
      return;
    }

    this.setupCanvas(ref);
    this.renderFrame();
  }

  renderFrame() {
    const ctx = this.ctx!;

    ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

    ctx.fillStyle = this.props.color;

    renderCurves(
      ctx,
      dpi,
      this.props.pixelsPerSecond,
      this.props.points,
      this.width,
      this.props.height,
    );

    if (this.props.maskPercentage && this.props.maskPercentage > 0) {
      ctx.fillStyle = this.props.maskColor || "white";

      ctx.save();
      ctx.globalCompositeOperation = "source-atop";

      ctx.fillRect(
        0,
        0,
        this.width * this.props.maskPercentage * dpi,
        this.props.height * dpi,
      );
      ctx.restore();
    }
  }

  // Don't update on points change.
  shouldComponentUpdate(nextProps: Props) {
    if (nextProps.maskPercentage !== this.props.maskPercentage) {
      this.renderFrame();
    }

    return (
      nextProps.height !== this.props.height ||
      nextProps.width !== this.props.width
    );
  }

  render() {
    const scaleX = this.props.width / this.width;
    return (
      <div
        className={this.props.className}
        style={{
          width: this.width + "px",
          height: this.props.height + "px",
          transform: `scale(${scaleX}, 1)`,
          transformOrigin: "0 50%",
        }}
      >
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}
