import { useSharedState } from "./useSharedState";

export function useInputFocusCheck() {
  const sharedState = useSharedState();
  if (!sharedState) {
    return false;
  } else {
    return sharedState.isInputFocused;
  }
}
