import { Tab, TabList, Tabs as ReachTabs } from "@reach/tabs";
import React from "react";
import "./Tabs.css";

interface Props {
  labels: string[];
  index?: number;
  onChange?: (index: number) => void;
}

export function Tabs({ labels, index, onChange }: Props) {
  return (
    <ReachTabs index={index} onChange={onChange}>
      <TabList>
        {labels.map((label, i) => (
          <Tab key={i}>{label}</Tab>
        ))}
      </TabList>
    </ReachTabs>
  );
}
