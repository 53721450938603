import { Action } from "@druyan/druyan";
import { GreetingTypes, Recording } from "../model";

export interface SaveGreeting extends Action<"App/SaveGreeting"> {
  which: GreetingTypes;
  recording: Recording;
}

export function saveGreeting(
  which: GreetingTypes,
  recording: Recording,
): SaveGreeting {
  return {
    type: "App/SaveGreeting",
    which,
    recording,
  };
}
