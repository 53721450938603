import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import {
  DeleteRecording,
  SetChapterTitle,
  StartRecording,
  Submit,
} from "../actions";
import { Shared } from "../types";
import Recording from "./Recording";
import Submitting from "./Submitting";

function Entry(
  action: Enter | SetChapterTitle | StartRecording | DeleteRecording | Submit,
  shared: Shared,
): StateReturn {
  const { canSubmit, chapterTitleRecording, title } = shared;
  switch (action.type) {
    case "Enter":
      shared.canSubmit =
        !!title &&
        title.trim().length > 0 &&
        chapterTitleRecording !== null &&
        chapterTitleRecording.audio !== "" &&
        !shared.isSaving;
      return update(shared);

    case "SetChapterTitle":
      shared.title = action.title;

      return reenter(shared);

    case "StartRecording":
      return Recording(shared);

    case "Submit":
      return canSubmit ? Submitting(shared) : noop();

    case "DeleteRecording":
      shared.chapterTitleRecording = null;
      shared.canSubmit = false;

      return reenter(shared);
  }
}

const EntryState = state("Entry", Entry);
const { reenter, update } = EntryState;
export default EntryState;
