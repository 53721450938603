import { RecordButton as BasicRecordButton } from "@storytime/shared/build/components";
import React from "react";
import { ScalarGenerator } from "../../utils";
import ReactiveCircle from "../ReactiveCircle";
import "./RecordButton.css";

interface Props {
  size: "small" | "large";
  active: boolean;
  onClick?: () => void;
  generator?: ScalarGenerator;
}

export function RecordButton({ size, active, onClick, generator }: Props) {
  return (
    <div className={`RecordButton --${size}`}>
      <BasicRecordButton
        className="RecordButton_button"
        active={active}
        size={size}
        onClick={onClick}
      />

      {active ? (
        <ReactiveCircle
          className="RecordButton_circle"
          color="rgba(248, 101, 106, 0.22)"
          generator={generator!}
          radius={size === "small" ? 30 : 60}
        />
      ) : null}
    </div>
  );
}
