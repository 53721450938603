import { Enter, state, StateReturn, task } from "@druyan/druyan";
import { Recording as RecordingType } from "../../../../state";
import { generatorSingleton, redirectTo, routeTo } from "../../../../utils";
import { Shared } from "../types";
import Recording from "./Recording";

function StartingRecording(
  action: Enter,
  shared: Shared,
  previousRecording: RecordingType | undefined,
  stream: MediaStream,
  audioContext: AudioContext,
): StateReturn {
  switch (action.type) {
    case "Enter":
      return task(async () => {
        try {
          generatorSingleton.start(audioContext, stream);
        } catch (e) {
          // tslint:disable-next-line: no-console
          console.error(e);

          return redirectTo(routeTo.error());
        }

        return Recording(shared, previousRecording);
      });
  }
}

export default state("StartingRecording", StartingRecording);
