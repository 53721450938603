import { Enter, state, StateReturn } from "@druyan/druyan";
import { SetRecording } from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";

function Recording(action: Enter | SetRecording, shared: Shared): StateReturn {
  switch (action.type) {
    case "Enter":
      shared.canSubmit = false;

      return update(shared);

    case "SetRecording":
      shared.story.recordings = { title: action.recording };

      return Entry(shared);
  }
}

const RecordingState = state("Recording", Recording);
const { update } = RecordingState;
export default RecordingState;
