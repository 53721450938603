import { Action } from "@druyan/druyan";

export interface SetTitle extends Action<"SetTitle"> {
  title: string;
}

export function setTitle(title: string): SetTitle {
  return {
    type: "SetTitle",
    title,
  };
}
