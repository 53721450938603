import { BackButton, ExternalLink } from "@storytime/shared/build/components";
import React from "react";
import { useTranslation } from "react-i18next";
import ContentFrame from "../../components/ContentFrame";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { SetupIllustration } from "../../components/Images";
import { history } from "../../utils";
import "./SetupInstructions.css";

export function SetupInstructions() {
  const { t } = useTranslation();

  return (
    <div>
      <Header mode="active" />

      <ContentFrame>
        <div className="SetupInstructions">
          <h1 className="setup__headline">{t("instructions.headline")}</h1>
          <div className="setup__illustration">
            <SetupIllustration />
          </div>
          <ol className="setup__steps">
            <li className="setup__step setup__step-one">
              <span className="setup__step-number" aria-hidden={true}>
                1
              </span>
              <div className="setup__step-copy">
                <p>{t("instructions.step_one_copy")}</p>
                <span className="setup__step-link-container">
                  <a
                    href="https://support.google.com/googlehome/answer/7552680"
                    className="setup__step-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("instructions.step_one_link")}
                    <span className="external-link-icon">
                      <ExternalLink />
                    </span>
                    <span className="hide-text">{t("links.new_window")}</span>
                  </a>
                </span>
              </div>
            </li>
            <li className="setup__step setup__step-two">
              <span className="setup__step-number" aria-hidden={true}>
                2
              </span>

              <p className="setup__step-copy">
                {t("instructions.step_two_copy")}
              </p>
            </li>
          </ol>
        </div>
      </ContentFrame>
      <Footer
        leftButton={
          <BackButton type={"secondary"} onClick={history.goBack}>
            {t("button.back")}
          </BackButton>
        }
      />
    </div>
  );
}
