import { Enter, eventually, state, StateReturn, task } from "@druyan/druyan";
import { pathTo } from "@storytime/shared/build/pathTo";
import {
  convertAudioChapter,
  isStoryEmpty,
  uploadAudio,
} from "../../../../api";
import { t } from "../../../../i18n";
import { SavedChapter, SavedStory } from "../../../../state";
import { Actions as AppState } from "../../../../state";
import {
  bufferToObjectURL,
  fileToArrayBuffer,
  generatorSingleton,
  processAudioBuffer,
  redirectTo,
  routeTo,
} from "../../../../utils";
import {
  acknowledge,
  Acknowledge,
  Cancel,
  CancelRecording,
  cancelRecording,
  convertFile,
  ConvertFile,
  SelectedFile,
  selectedFile,
  SelectFile,
  ShowWarning,
  StartRecording,
  StartSelectFile,
} from "../actions";
import { Shared } from "../types";
import RecordedUnplayed from "./RecordedUnplayed";
import ShowingConfirmCancelEmptyStoryModal from "./ShowingConfirmCancelEmptyStoryModal";
import ShowingUploadModal from "./ShowingUploadModal";
import StartingRecording from "./StartingRecording";

// tslint:disable-next-line: max-func-body-length
function Empty(
  action:
    | Enter
    | StartRecording
    | StartSelectFile
    | SelectFile
    | SelectedFile
    | ConvertFile
    | Acknowledge
    | Cancel
    | ShowWarning
    | CancelRecording,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { story, chapter, hasSeenUploadModal, audioContext } = shared;

  switch (action.type) {
    case "Enter":
      return AppState.pauseAudio();

    case "StartRecording":
      return StartingRecording(
        shared,
        undefined,
        action.stream,
        action.stopAction,
        audioContext,
      );

    case "StartSelectFile":
      // If never seen modal, show modal
      if (!hasSeenUploadModal) {
        return ShowingUploadModal(shared);
      }
      // return onFile
      return AppState.hideModal();

    case "SelectFile":
      if (!action.file.type.includes("audio/")) {
        // not an audio file
        const onAcknowledge = eventually(acknowledge);
        return [
          onAcknowledge,
          AppState.showAcknowledge({
            headline: t("confirm_audio_file_modal.headline"),
            text: t("confirm_audio_file_modal.text"),
            acknowledgeText: t("global.okay"),
            onAcknowledge,
          }),
        ];
      }

      if (!action.file.type.includes("audio/mp3")) {
        // Audio file, but not mp3
        return [
          AppState.showSpinner(t("spinner.processing")),
          convertFile(action.file),
        ];
      }

      return task(async () => {
        const buffer = await fileToArrayBuffer(action.file);

        return [
          AppState.showSpinner(t("spinner.processing")),
          selectedFile(buffer),
        ];
      });

    case "Acknowledge":
      return AppState.hideModal();

    case "SelectedFile":
      return task(async () => {
        const { points, duration } = await processAudioBuffer(
          action.buffer,
          audioContext,
        );
        return [
          AppState.hideModal(),
          RecordedUnplayed(shared, {
            audio: bufferToObjectURL(action.buffer),
            isUpdated: true,
            duration,
            points,
          }),
        ];
      });

    case "ConvertFile":
      return task(async () => {
        const buffer = await convertFileToMp3(action.file, story, chapter);
        return selectedFile(buffer);
      });

    case "Cancel":
      return task(async () => {
        const isEmpty = await isStoryEmpty(story.id);
        return isEmpty
          ? ShowingConfirmCancelEmptyStoryModal(shared)
          : redirectTo(routeTo.storyDetail(story.id));
      });

    case "ShowWarning":
      const onAcknowledgeWarning = eventually(cancelRecording);
      const text =
        action.error && action.error.message === "NotAllowedError"
          ? t("audio_recording_not_allowed.text")
          : t("audio_recording_error.text");
      return [
        onAcknowledgeWarning,
        AppState.showAcknowledge({
          text,
          acknowledgeText: t("global.okay"),
          onAcknowledge: onAcknowledgeWarning,
        }),
      ];

    case "CancelRecording":
      return task(async () => {
        if (generatorSingleton.isRunning) {
          generatorSingleton.stop();
        }
        return [AppState.hideModal()];
      });
  }
}

async function convertFileToMp3(
  file: File,
  story: SavedStory,
  chapter: SavedChapter,
): Promise<ArrayBuffer> {
  // tslint:disable-next-line: no-console
  console.time("Uploading unconverted chapter recording");

  // Upload to storage
  await uploadAudio(
    pathTo.storage.unconvertedChapterRecording(story.id, chapter.id),
    file,
  );

  // tslint:disable-next-line: no-console
  console.timeEnd("Uploading unconverted chapter recording");

  // tslint:disable-next-line: no-console
  console.time("Remotely converting audio to mp3");

  const {
    data: { success, publicUrl },
  } = await convertAudioChapter(story.id, chapter.id);

  // tslint:disable-next-line: no-console
  console.timeEnd("Remotely converting audio to mp3");

  if (!success) {
    throw new Error("convertAudioArbitrary failed");
  }

  // tslint:disable-next-line: no-console
  console.time("Fetching converted file");

  // Then finally download new file and continue.
  const buffer = fetch(publicUrl).then(r => r.arrayBuffer());

  // tslint:disable-next-line: no-console
  console.timeEnd("Fetching converted file");

  return buffer;
}

export default state("Empty", Empty);
