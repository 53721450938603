import { Action } from "@druyan/druyan";

export interface DeleteChapterTitleRecording
  extends Action<"DeleteChapterTitleRecording"> {}

export function deleteChapterTitleRecording(): DeleteChapterTitleRecording {
  return {
    type: "DeleteChapterTitleRecording",
  };
}
