import { Enter, state, StateReturn } from "@druyan/druyan";
import { Recording } from "../../../../state";
import { Shared } from "../types";
import Empty from "./Empty";
import RecordedUnplayed from "./RecordedUnplayed";
import ShowingFirstChapterModal from "./ShowingFirstChapterModal";

function Entry(
  action: Enter,
  shared: Shared,
  recording?: Recording,
): StateReturn {
  const { story, hasSeenFirstChapterModal } = shared;

  switch (action.type) {
    case "Enter":
      if (hasSeenFirstChapterModal || !story.isChapterStory) {
        return recording ? RecordedUnplayed(shared, recording) : Empty(shared);
      }

      return ShowingFirstChapterModal(shared, recording);
  }
}

export default state("Entry", Entry);
