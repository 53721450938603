import { PlayButton } from "@storytime/shared/build/components";
import React, { useEffect, useRef } from "react";
import "./PlayerButton.css";

const { PI } = Math;

const DPI = window !== undefined ? window.devicePixelRatio : 1;
const SIZE = 46;

interface Props {
  playPercentage?: number;
  isPlaying: boolean;
  onPlay?: () => void;
  onPause?: () => void;
  onUnmount?: () => void;
}

export function PlayerButton({
  playPercentage,
  onPlay,
  onPause,
  isPlaying,
  onUnmount,
}: Props) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    if (!canvasRef.current || playPercentage === undefined) {
      return;
    }

    const ctx = canvasRef.current.getContext("2d")!;

    ctx.save();
    ctx.clearRect(0, 0, SIZE * DPI, SIZE * DPI);

    if (playPercentage <= 0) {
      return;
    }

    // Retina
    ctx.scale(DPI, DPI);

    ctx.translate(SIZE / 2, SIZE / 2);
    ctx.rotate(PI * -0.5);

    ctx.strokeStyle = "white";
    ctx.lineCap = "round";
    ctx.lineWidth = 3.5;

    ctx.beginPath();
    ctx.arc(0, 0, (SIZE - 5) / 2, 0, PI * 2 * playPercentage);
    ctx.stroke();
    ctx.restore();
  }, [canvasRef, playPercentage]);

  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  return (
    <div className="PlayerButton">
      {isPlaying ? (
        <PlayButton size="small" onClick={onPause} active={isPlaying} />
      ) : (
        <PlayButton size="small" onClick={onPlay} active={isPlaying} />
      )}

      <canvas
        aria-hidden={true}
        className={`PlayerButton_canvas ${
          isPlaying ? "--playing" : "--paused"
        }`}
        ref={canvasRef}
        width={SIZE * DPI}
        height={SIZE * DPI}
        style={{ width: SIZE, height: SIZE }}
      />
    </div>
  );
}
