import { Enter, state, StateReturn, task } from "@druyan/druyan";
import { loadGreetings, loadStories, loadUserData } from "../../api";
import { Shared } from "../model";
import Ready from "./Ready";

function Loading(action: Enter, shared: Shared): StateReturn | StateReturn[] {
  const { user } = shared;

  if (!user) {
    throw new Error("Must have a user before loading");
  }

  switch (action.type) {
    case "Enter":
      return task(async () => {
        // Needs to happen first because it creates the account on demand.
        const userData = await loadUserData(user);

        // Load in parallel
        const [stories, recordings] = await Promise.all([
          loadStories(user),
          loadGreetings(user),
        ]);

        return Ready({
          ...shared,
          userData,
          stories,
          greetings: { recordings },
        });
      });
  }
}

export default state("Loading", Loading);
