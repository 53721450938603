import { pathTo } from "@storytime/shared/build/pathTo";
import "firebase/functions";
import { app } from "../firebase";
import { Chapter, SavedChapter, SavedStory, UnsavedChapter } from "../state";
import { Ordering, updateChapterOrder } from "./chapterOrdering";
import { saveRecording, saveRecordings } from "./uploadAudio";

export function getChapterRef(
  story: SavedStory,
  chapter: SavedChapter,
): firebase.firestore.DocumentReference {
  return app.firestore().doc(pathTo.db.chapter(story.id, chapter.id));
}

function chapterToData({ title }: Chapter) {
  return { title };
}

export async function createChapter(
  user: firebase.User,
  story: SavedStory,
  chapter: UnsavedChapter,
): Promise<SavedChapter> {
  const order = Object.keys(story.chapters).length;

  const { id } = await app
    .firestore()
    .collection(pathTo.db.chaptersCollection(story.id))
    .add({
      ...chapterToData(chapter),
      order,
      owner: user.uid,
    });

  return {
    ...chapter,
    id,
    order,
    isSaved: true,
    owner: user.uid,
    ownerName: user.displayName ? user.displayName : "",

    recordings:
      chapter.recordings.audio && chapter.recordings.title
        ? await saveRecordings(
            chapter.recordings,
            pathTo.db.chapterRecordingsCollection(story.id, id),
            pathTo.storage.chapterRecordingsCollection(story.id, id),
          )
        : chapter.recordings.title
        ? {
            audio: null,
            title: await saveRecording(
              pathTo.db.chapterRecording(story.id, id, "title"),
              pathTo.storage.chapterRecordingsCollection(
                story.id,
                id + "-title",
              ),
              chapter.recordings.title,
            ),
          }
        : {
            title: null,
            audio: null,
          },
  };
}

export async function updateChapter(
  _user: firebase.User,
  story: SavedStory,
  chapter: SavedChapter,
): Promise<SavedChapter> {
  const ref = getChapterRef(story, chapter);

  await ref.update(chapterToData(chapter));

  chapter.recordings = await saveRecordings(
    chapter.recordings,
    pathTo.db.chapterRecordingsCollection(story.id, ref.id),
    pathTo.storage.chapterRecordingsCollection(story.id, ref.id),
  );

  return chapter;
}

export async function updateChapterTitle(
  story: SavedStory,
  chapter: SavedChapter,
): Promise<void> {
  const ref = getChapterRef(story, chapter);

  await ref.update({ title: chapter.title });
}

export function deleteChapter(
  story: SavedStory,
  chapter: SavedChapter,
): Ordering {
  const ref = getChapterRef(story, chapter);

  ref.delete();

  delete story.chapters[chapter.id];

  const orders = Object.values(story.chapters).sort(
    (a, b) => a.order - b.order,
  );

  return updateChapterOrder(story, orders as SavedChapter[]);
}

const convertAudio = app.functions().httpsCallable("convertAudio");

export function convertAudioChapter(storyId: string, chapterId: string) {
  return convertAudio({
    mode: { type: "chapter", storyId, chapterId },
    trim: false,
  });
}

export function convertAudioArbitrary() {
  return convertAudio({
    mode: { type: "arbitrary" },
    trim: true,
  });
}

export const concatAudio = app.functions().httpsCallable("concatAudio");
