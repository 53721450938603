import React, { useMemo } from "react";
import { useActions, useAuth, useStories, useUserData } from "../../hooks";
import { Invite } from "../../state";
import { AccountSettings } from "./AccountSettings";
import { StateContext, States } from "./machine";

export default () => {
  const user = useAuth();
  const { logout: onLogout } = useActions();
  const { editors, editorNames, editorEmails, invites } = useUserData();
  const { myStories } = useStories();

  const hasStories = Object.keys(myStories).length > 0 ? true : false;

  if (!user) {
    throw new Error("Cannot view account settings unless logged in.");
  }
  const listOfMyStories = useMemo(
    () => Object.values(myStories).sort((a, b) => a.createdAt - b.createdAt),
    [myStories],
  );

  const listOfEditors = editors.map(id => ({
    id,
    name: editorNames[id],
    email: editorEmails[id],
  }));

  const listOfInvites = Object.values(
    invites.reduce(
      (result, invite: Invite) => {
        if (
          (!result[invite.email] ||
            Number(invite.time) > Number(result[invite.email].time)) &&
          !Object.values(editorEmails).includes(invite.email)
        ) {
          listOfMyStories.forEach(story => {
            const inviteFound =
              story.invites.filter(
                (storyInvite: Invite) =>
                  storyInvite.email === invite.email &&
                  storyInvite.time === invite.time,
              ).length > 0;
            if (inviteFound) {
              result[invite.email] = invite;
            }
          });
        }

        return result;
      },
      {} as {
        [key: string]: Invite;
      },
    ),
  );

  return (
    <div>
      <StateContext.Create
        initialState={States.Entry({
          editorNames,
          user,
        })}
      >
        {({ actions }) => {
          return (
            <AccountSettings
              userEmail={user.email}
              editorUsers={listOfEditors}
              invitedUsers={listOfInvites}
              onRemoveEditor={actions.removeEditor}
              onRemoveEditorFromStory={actions.removeEditorFromStory}
              onRemoveEditorsFromStory={actions.removeEditorsFromStory}
              onDeleteAccount={actions.deleteAccount}
              onDownloadAllStories={actions.downloadAllStories}
              onLogout={onLogout}
              hasStories={hasStories}
              myStories={listOfMyStories}
            />
          );
        }}
      </StateContext.Create>
    </div>
  );
};
