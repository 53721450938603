import { Enter, state, StateReturn, task } from "@druyan/druyan";
import { authorize as requestAuthorization } from "../../firebase";
import { Shared } from "../model";
import Loading from "./Loading";
import LoggedOut from "./LoggedOut";

function Authorizing(
  action: Enter,
  shared: Shared,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      return task(async () => {
        const user = await requestAuthorization();

        // Failed Login
        if (!user || !user.email) {
          return LoggedOut(shared);
        }

        return Loading({ ...shared, user });
      });
  }
}

export default state("Authorizing", Authorizing);
