import React from "react";
import "./Body.css";

interface Props {
  children: React.ReactChild;
}

export function Body({ children }: Props) {
  return <div className="Body">{children}</div>;
}
