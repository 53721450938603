import React, { Suspense, useEffect } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { analytics } from "../../firebase";
import AcceptInvite from "../../routes/AcceptInvite";
import AccountSettings from "../../routes/AccountSettings";
import CreateChapter from "../../routes/CreateChapter";
import CreateStory from "../../routes/CreateStory";
import Dashboard from "../../routes/Dashboard";
import { DeviceGate } from "../../routes/DeviceGate";
import EditChapterTitle from "../../routes/EditChapterTitle";
import {
  InvalidInvite,
  NotFound,
  UnrecoverableError,
} from "../../routes/Error";
import Greetings from "../../routes/Greetings";
import Invite from "../../routes/Invite";
import RecordChapter from "../../routes/RecordChapter";
import SetupInstructions from "../../routes/SetupInstructions";
import StoryDetail from "../../routes/StoryDetail";
import { initialShared, StateContext, States } from "../../state";
import { history, routeTo } from "../../utils";
import AudioPlayer from "../AudioPlayer";
import Header from "../Header";
import Modal from "../Modal";
import Spinner from "../Spinner";
import "./App.css";

// Lazy load so firebaseui isn't in default bundle.
const LoginInvited = React.lazy(() => import("../Login/LoginInvited"));
const LoginUser = React.lazy(() => import("../Login/LoginUser"));

export function App() {
  return (
    <div id="app" className="App">
      <StateContext.Create initialState={States.Initializing(initialShared())}>
        {({ currentState, actions }) => (
          <AppForState
            currentState={currentState}
            hideModal={actions.hideModal}
          />
        )}
      </StateContext.Create>
    </div>
  );
}

// tslint:disable-next-line: max-func-body-length
function AppForState({
  currentState,
  hideModal,
}: {
  currentState: ReturnType<typeof States[keyof typeof States]>;
  hideModal: () => void;
}) {
  useEffect(() => {
    return history.listen(() => {
      if (currentState.data[1] && currentState.data[1].type === "account") {
        hideModal();
      }
    });
  }, [currentState, hideModal]);

  switch (currentState.name) {
    case "LoggedOut":
      return (
        <Router history={history}>
          <Suspense fallback={<Spinner />}>
            <Switch>
              <Route
                exact={true}
                path={routeTo.acceptInvite()}
                component={LoginInvited}
              />
              <Route component={LoginUser} />
            </Switch>
          </Suspense>
        </Router>
      );

    case "Authorizing":
    case "Initializing":
    case "Loading":
      return (
        <>
          <Header mode="static" />

          <div
            style={{
              opacity: 0.75,
              position: "absolute",
              top: "40%",
              left: "50%",
              transform: "translateX(-50%) translateY(-50%)",
            }}
          >
            <Spinner />
          </div>
        </>
      );

    case "DeviceGate":
      return <DeviceGate />;

    default:
      return (
        <>
          <Router history={history}>
            <Header mode="router" />
            <Route
              // tslint:disable-next-line: react-this-binding-issue max-func-body-length
              render={({ location }) => {
                analytics.logEvent("Route Change", { path: location.pathname });
                return (
                  <TransitionGroup>
                    <CSSTransition
                      timeout={250}
                      classNames="TransitionWrapper"
                      key={location.key}
                      // tslint:disable-next-line:react-this-binding-issue
                      onExit={node => {
                        if (node) {
                          node.style.top = -1 * window.scrollY + "px";
                          node.style.position = "fixed";
                        }
                      }}
                    >
                      <Switch location={location}>
                        <Route
                          exact={true}
                          path={routeTo.dashboard()}
                          component={Dashboard}
                        />

                        <Route
                          exact={true}
                          path={routeTo.setupInstructions()}
                          component={SetupInstructions}
                        />

                        <Route
                          exact={true}
                          path={routeTo.settings()}
                          component={AccountSettings}
                        />

                        <Route
                          exact={true}
                          path={routeTo.greetings()}
                          component={Greetings}
                        />

                        <Route
                          exact={true}
                          path={routeTo.invite()}
                          component={Invite}
                        />

                        <Route
                          exact={true}
                          path={routeTo.acceptInvite()}
                          component={AcceptInvite}
                        />

                        <Route
                          exact={true}
                          path={routeTo.createStory()}
                          component={CreateStory}
                        />

                        <Route
                          exact={true}
                          path={routeTo.storyDetail(":storyId")}
                          component={StoryDetail}
                        />

                        <Route
                          exact={true}
                          path={routeTo.storyDetailEdit(
                            ":storyId",
                            ":editMode",
                          )}
                          component={StoryDetail}
                        />

                        <Route
                          exact={true}
                          path={routeTo.editChapterTitle(
                            ":storyId",
                            ":chapterId",
                          )}
                          component={EditChapterTitle}
                        />

                        <Route
                          exact={true}
                          path={routeTo.editChapter(":storyId", ":chapterId")}
                          component={RecordChapter}
                        />

                        <Route
                          exact={true}
                          path={routeTo.createChapter(":storyId")}
                          component={CreateChapter}
                        />

                        <Route
                          exact={true}
                          path={routeTo.notFound()}
                          component={NotFound}
                        />

                        <Route
                          exact={true}
                          path={routeTo.invalidInvite()}
                          component={InvalidInvite}
                        />

                        <Route
                          exact={true}
                          path={routeTo.error()}
                          component={UnrecoverableError}
                        />
                        <Route component={NotFound} />
                      </Switch>
                    </CSSTransition>
                  </TransitionGroup>
                );
              }}
            />
            <Modal modal={currentState.data[1] || null} />
          </Router>

          {currentState.name === "Ready" ? (
            <AudioPlayer audioPlayer={currentState.data[0].audioPlayer} />
          ) : (
            undefined
          )}
        </>
      );
  }
}
