import { Action } from "@druyan/druyan";
import { Recording } from "../../../../state";

export interface SetRecording extends Action<"SetRecording"> {
  recording: Recording;
  startAction?: () => Promise<void>;
}

export function setRecording(
  recording: Recording,
  startAction: () => Promise<void>,
): SetRecording {
  return {
    type: "SetRecording",
    recording,
    startAction,
  };
}
