import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { Actions as AppState } from "../../../../state";
import { FinishedRecordingGreetings } from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";

function RecordingGreetings(
  action: Enter | FinishedRecordingGreetings,
  shared: Shared,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      return noop();

    case "FinishedRecordingGreetings":
      return [AppState.completeGreetings(), Entry(shared)];
  }
}

export default state("RecordingGreetings", RecordingGreetings);
