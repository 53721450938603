import React from "react";
import { useActions } from "../../hooks";
import { Recording } from "../../state";
import { StateContext, States } from "./machine";
import { PlayerButton as PurePlayerButton } from "./PlayerButton";

interface Props {
  recording: Recording;
}

export default function PlayerButton({ recording }: Props) {
  const { playAudio, stopAudio } = useActions();

  return (
    <StateContext.Create
      initialState={States.Paused({ playAudio, stopAudio, recording }, 0)}
    >
      {({
        actions,
        currentState: {
          name: currentStateName,
          data: [, playPercentage],
        },
      }) => {
        return (
          <PurePlayerButton
            isPlaying={currentStateName === "Playing"}
            playPercentage={playPercentage}
            onPlay={actions.play}
            onPause={actions.pause}
            onUnmount={stopAudio}
          />
        );
      }}
    </StateContext.Create>
  );
}
