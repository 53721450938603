import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { Shared } from "../model";

function DeviceGate(action: Enter, _shared: Shared): StateReturn {
  switch (action.type) {
    case "Enter":
      return noop();
  }
}

export default state("DeviceGate", DeviceGate);
