import { GearButton } from "@storytime/shared/build/components";
import React, { createRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useLottie } from "../../hooks";
import { routeTo } from "../../utils";
import "./Header.css";
import { headerAnimation } from "./headerAnimation";
import { HeaderLogo } from "./HeaderLogo";

export interface Props {
  onClickGearMenu?: () => void;
}

export function HeaderWithGearMenu({ onClickGearMenu }: Props) {
  const { t } = useTranslation();

  const headerBgRef = useLottie({
    animationData: headerAnimation,
  });

  const gearRef = createRef<HTMLDivElement>();

  useEffect(() => {
    const element = gearRef.current as HTMLDivElement;
    setTimeout(() => {
      element.classList.add("-active");
    }, 250);
  }, [gearRef]);

  return (
    <div className="Header">
      <div className="header-wrapper">
        <div className="header-bg" ref={headerBgRef} />
        <h1 className="header-title">
          <Link className="header-logo" to={routeTo.dashboard()}>
            <HeaderLogo />
            <span className="hide-text">{t(`header.title`)}</span>
          </Link>
        </h1>
      </div>

      <div className="settings-button" ref={gearRef}>
        <GearButton
          // tslint:disable-next-line: react-this-binding-issue
          onClick={() => (onClickGearMenu ? onClickGearMenu() : undefined)}
          size="small"
        />
      </div>
    </div>
  );
}

export function StaticHeader() {
  const { t } = useTranslation();

  const headerBgRef = useLottie({
    animationData: headerAnimation,
  });

  return (
    <div className="Header">
      <div className="header-wrapper">
        <div className="header-bg" ref={headerBgRef} />
        <h1 className="header-title">
          <a className="header-logo" href="/">
            <HeaderLogo />
            <span className="hide-text">{t(`header.title`)}</span>
          </a>
        </h1>
      </div>
    </div>
  );
}

export function NonInteractiveHeader() {
  const { t } = useTranslation();

  const headerBgRef = useLottie({
    animationData: headerAnimation,
  });

  return (
    <div className="Header">
      <div className="header-wrapper">
        <div className="header-bg" ref={headerBgRef} />
        <h1 className="header-title">
          <span className="non-interactive-header-logo">
            <HeaderLogo />
            <span className="hide-text">{t(`header.title`)}</span>
          </span>
        </h1>
      </div>
    </div>
  );
}
