import { state, StateReturn } from "@druyan/druyan";
import { signOut } from "../../firebase";
import { Logout } from "../actions";
import { initialUserData } from "../model";
import States from "./index";
import LoggedOut from "./LoggedOut";

function Global(
  action: Logout,
  currentState: ReturnType<typeof States[keyof typeof States]>,
): StateReturn {
  const [shared] = currentState.data;

  switch (action.type) {
    case "App/Logout":
      signOut();

      return LoggedOut({
        ...shared,
        user: undefined,
        userData: initialUserData(),
      });
  }
}

export default state("Global", Global);
