// tslint:disable: max-func-body-length
import React, { useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useMedia } from "react-use";
import {
  useActions,
  useAudioContext,
  useAwaitingUserStream,
  useIOSCheck,
  useRecordingController,
  useStories,
  useUserData,
} from "../../hooks";
import { SavedChapter } from "../../state";
import {
  generatorSingleton,
  history,
  MEDIA_QUERY_SMALL,
  routeTo,
} from "../../utils";
import { States } from "./machine";
import { StateContext } from "./machine";
import {
  EmptyRecordChapter,
  PausedRecordChapter,
  PlayingRecordChapter,
  RecordingRecordChapter,
} from "./RecordChapter";

type Props = RouteComponentProps<{ storyId: string; chapterId: string }>;

// tslint:disable-next-line:max-func-body-length
export default function RecordChapter({
  match: {
    params: { storyId, chapterId },
  },
}: Props) {
  const { myStories, storiesSharedWithMe } = useStories();
  const { hasSeenFirstChapterModal, hasSeenUploadModal } = useUserData();
  const { awaitingStream, stopAudio, playAudio } = useActions();
  const recordingController = useRecordingController()!;
  const audioContext = useAudioContext()!;
  const isIOS = useIOSCheck();
  const isAwaitingUserStream = useAwaitingUserStream();
  if (recordingController) {
    recordingController.useOpus();
  }

  const story = myStories[storyId] || storiesSharedWithMe[storyId];

  const chapter = story && chapterId ? story.chapters[chapterId] : undefined;

  const isWideScreen = useMedia(MEDIA_QUERY_SMALL);

  const initialState = useMemo(
    () =>
      States.Entry(
        {
          hasSeenFirstChapterModal,
          hasSeenUploadModal,
          story,
          chapter: chapter as SavedChapter,
          audioContext,
          playAudio,
        },
        (chapter as SavedChapter).recordings.audio || undefined,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [story, chapter],
  );

  if (!story) {
    // tslint:disable-next-line:no-console
    console.error(`Invalid story: ${storyId}`);
    if (history.location.pathname !== routeTo.notFound()) {
      history.replace(routeTo.notFound());
    }
    return null;
  }

  if (!chapter || !chapter.isSaved) {
    // tslint:disable-next-line:no-console
    console.error(`Invalid chapter: ${storyId}`);
    if (history.location.pathname !== routeTo.notFound()) {
      history.replace(routeTo.notFound());
    }
    return null;
  }

  return (
    <div>
      <StateContext.Create initialState={initialState}>
        {({ currentState, actions, context }) => {
          const stopAction = async () => {
            try {
              const { audio } = await recordingController!.stop(audioContext);
              actions.stopRecording(audio);
            } catch (error) {
              actions.showWarning(error);
            }
          };
          const delay = (duration: number) =>
            // tslint:disable-next-line: no-string-based-set-timeout
            new Promise(resolve => setTimeout(resolve, duration));
          const stopSafariAction = async () => {
            try {
              // delay stop by 500ms in iOS Safari to fix truncation
              delay(500).then(async () => {
                const { audio } = await recordingController!.stop(audioContext);
                actions.stopRecording(audio);
              });
            } catch (error) {
              actions.showWarning(error);
            }
          };
          const startAction = async () => {
            try {
              awaitingStream(true);
              const stream = await recordingController!.start(audioContext);
              awaitingStream(false);
              actions.startRecording(stream!, stopAction);
            } catch (error) {
              awaitingStream(false);
              actions.showWarning(error);
            }
          };
          const switchState =
            currentState.name.includes("Modal") ||
            currentState.name.includes("StartingRecording")
              ? context.history.previous!
              : currentState;

          switch (switchState.name) {
            case "Entry":
              return null;

            case "ProcessingRecording":
            case "Empty":
              return (
                <EmptyRecordChapter
                  story={story}
                  chapter={chapter}
                  startRecording={startAction}
                  selectFile={actions.selectFile}
                  startSelectFile={
                    hasSeenUploadModal ? undefined : actions.startSelectFile
                  }
                  isWideScreen={isWideScreen}
                  isIOS={isIOS}
                  isAwaitingUserStream={isAwaitingUserStream}
                  cancel={actions.cancel}
                />
              );

            case "Recording":
              const [, , , , recordingProgress] = switchState.data;

              return (
                <RecordingRecordChapter
                  story={story}
                  chapter={chapter}
                  generator={generatorSingleton}
                  stopRecording={isIOS ? stopSafariAction : stopAction}
                  cancel={actions.cancel}
                  duration={recordingProgress || 0}
                  isIOS={isIOS}
                />
              );

            case "RecordedUnplayed":
            case "RecordedPaused":
              const [, recorded, pausedPlayback] = switchState.data;

              if (!recorded) {
                // tslint:disable-next-line:no-console
                console.error("Expected recording to exist");
                history.replace(routeTo.error());
                return null;
              }

              return (
                <PausedRecordChapter
                  story={story}
                  chapter={chapter}
                  startRecording={startAction}
                  points={recorded.points}
                  recording={recorded}
                  duration={recorded.duration}
                  play={actions.play}
                  trash={actions.deleteRecording}
                  cancel={actions.cancel}
                  saveChapter={actions.saveChapter}
                  playPercentage={
                    pausedPlayback ? pausedPlayback / recorded.duration : 0
                  }
                  isIOS={isIOS}
                />
              );

            case "RecordedPlaying":
              const [, playingRecording, recordingPlayback] = switchState.data;

              if (!playingRecording) {
                // tslint:disable-next-line:no-console
                console.error("Expected recording to exist");
                history.replace(routeTo.error());
                return null;
              }

              return (
                <PlayingRecordChapter
                  story={story}
                  chapter={chapter}
                  recording={playingRecording}
                  startRecording={startAction}
                  cancel={actions.cancel}
                  points={playingRecording.points}
                  pause={actions.pause}
                  onUnmount={stopAudio}
                  trash={actions.deleteRecording}
                  saveChapter={actions.saveChapter}
                  duration={playingRecording.duration}
                  playPercentage={
                    recordingPlayback
                      ? recordingPlayback / playingRecording.duration
                      : 0
                  }
                  isIOS={isIOS}
                />
              );

            default:
              // tslint:disable-next-line:no-console
              console.error("Impossible state", currentState);
              history.replace(routeTo.error());
              return null;
          }
        }}
      </StateContext.Create>
    </div>
  );
}
