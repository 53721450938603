import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Recording } from "../../../../state";
import { Actions as AppState } from "../../../../state";
import { routeTo } from "../../../../utils";
import { redirectTo } from "../../../../utils";
import { Confirm, confirm, Reject, reject } from "../actions";
import { Shared } from "../types";
import SavingChapterModal from "./SavingChapterModal";

function ShowingSaveBeforeClosingModal(
  action: Enter | Confirm | Reject | Exit,
  shared: Shared,
  recording: Recording,
): StateReturn | StateReturn[] {
  const { story } = shared;

  switch (action.type) {
    case "Enter":
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          text: t("save_before_closing_modal.text"),
          confirmText: t("global.yes"),
          rejectText: t("global.no"),
          onConfirm,
          onReject,
        }),
      ];

    case "Confirm":
      return [goBack(), SavingChapterModal(shared, recording)];

    case "Reject":
      return [
        // Must manually hide, since redirect doesn't exit the state.
        AppState.hideModal(),

        redirectTo(routeTo.storyDetail(story.id)),
      ];

    case "Exit":
      return AppState.hideModal();
  }
}

export default state(
  "ShowingSaveBeforeClosingModal",
  ShowingSaveBeforeClosingModal,
);
