import { Action } from "@druyan/druyan";

export interface StopRecording extends Action<"StopRecording"> {
  audioUrl: string;
}

export function stopRecording(audioUrl: string): StopRecording {
  return {
    audioUrl,
    type: "StopRecording",
  };
}
