import { Action } from "@druyan/druyan";
import { AcknowledgeModal } from "../model";

export interface ShowAcknowledge extends Action<"App/ShowAcknowledge"> {
  modal: AcknowledgeModal;
}

export function showAcknowledge(
  modal: Omit<AcknowledgeModal, "type">,
): ShowAcknowledge {
  return {
    type: "App/ShowAcknowledge",
    modal: { ...modal, type: "acknowledge" },
  };
}
