import "firebase/storage";
import { app } from "../firebase";
import { Recording, RecordingsMap } from "../state";

// Promise-based version of the helper below.
export async function uploadAudio(
  filePath: string,
  file: File | Blob,
): Promise<string> {
  return new Promise(resolve => {
    uploadAudioWithProgress(
      filePath,
      file,
      () => void 0,
      (u: string) => resolve(u),
    );
  });
}

export function uploadAudioWithProgress(
  filePath: string,
  file: File | Blob,
  onProgress: (progress: number) => void,
  onComplete: (url: string) => void,
): void {
  const task = app
    .storage()
    .ref()
    .child(filePath)
    .put(file, {
      contentType: file.type,
    });

  task.on(
    "state_changed",
    snapshot => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      onProgress(progress);
    },
    e => {
      throw e;
    },
    () => {
      task.snapshot.ref.getDownloadURL().then(url => {
        onComplete(url);
      });
    },
  );
}

export async function deleteAudio(dbPath: string): Promise<boolean> {
  const doc = await app
    .firestore()
    .doc(dbPath)
    .get();

  if (!doc.exists) {
    return false;
  }

  // Delete DB record
  await app
    .firestore()
    .doc(dbPath)
    .delete();

  return true;
}

export async function saveRecording(
  dbPath: string,
  storagePath: string,
  recording: Recording,
): Promise<Recording> {
  const { audio, duration, points } = recording;

  if (!audio.startsWith("blob:")) {
    throw new Error(`Can only upload blob URLs. Got ${audio}`);
  }

  // Convert in-browser blob URL to an actual binary Blob object.
  const blob = await fetch(audio).then(r => r.blob());

  // Upload to storage
  const url = await new Promise<string>(resolve => {
    uploadAudioWithProgress(
      storagePath,
      blob,
      () => void 0,
      (u: string) => resolve(u),
    );
  });

  // Prepare the new document data.
  const result = {
    audio: url,
    duration,
    points,
  };

  // Store uploaded information in storage.
  await app
    .firestore()
    .doc(dbPath)
    .set({ ...result, points: points.map(p => ({ x: p.x, y: p.y })) });

  // Return result.
  return {
    ...result,
    isUpdated: false,
  };
}

const FORMAT = "mp3";

export async function modifyRecording<T extends string>(
  k: T,
  dbPrefix: string,
  storagePrefix: string,
  recording: Recording | null,
): Promise<Recording | null> {
  const dbPath = `${dbPrefix}/${k}`;
  const storagePath = `${storagePrefix}/${k}.${FORMAT}`;

  if (!recording) {
    // Deleted, needs cleanup
    await deleteAudio(dbPath);

    // tslint:disable-next-line:no-console
    console.log(dbPath, "was deleted");

    return null;
  }

  // If it wasn't changed, don't re-upload
  if (!recording.isUpdated) {
    // tslint:disable-next-line:no-console
    console.log(dbPath, "was not updated, skipping");

    return recording;
  }

  // tslint:disable-next-line:no-console
  console.log(dbPath, "will be updated");

  // Will handle create/update.
  return saveRecording(dbPath, storagePath, recording);
}

export async function saveRecordings<T extends string>(
  recordings: RecordingsMap<T>,
  dbPrefix: string,
  storagePrefix: string,
): Promise<RecordingsMap<T>> {
  // Upload in parallel
  const uploads = await Promise.all(
    Object.keys(recordings).map<Promise<[T, Recording | null]>>(async key => {
      const k = key as T;
      const recording = recordings[k] as Recording;
      const v = await modifyRecording(k, dbPrefix, storagePrefix, recording);
      return [k, v];
    }),
  );

  return uploads.reduce(
    (sum, [key, value]) => {
      sum[key] = value;
      return sum;
    },
    {} as RecordingsMap<T>,
  );
}
