import {
  DeleteButton,
  DragHandle,
  EditButton,
} from "@storytime/shared/build/components";
import React from "react";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { SavedChapter, SavedStory } from "../../state";
import { history, renderDuration, routeTo } from "../../utils";
import "../ChapterRow/ChapterRow.css";

export interface Props {
  chapter: SavedChapter;
  story: SavedStory;
  isEditingTitle?: boolean;
  draggableHandleProps?: DraggableProvidedDragHandleProps | null;
  onDelete?: () => void;
}

export function ChapterRowIsEditing({
  onDelete,
  isEditingTitle,
  chapter,
  draggableHandleProps,
  story,
}: Props) {
  const { t } = useTranslation();
  const {
    recordings: { audio },
    title,
  } = chapter;

  return (
    <div className="chapter-row --editing">
      <div
        className="chapter-row-item chapter-delete"
        aria-label={t(`chapter_row.delete_header`)}
      >
        <DeleteButton
          size="small"
          onClick={onDelete}
          disabled={isEditingTitle}
        />
      </div>
      <div
        className="chapter-row-item chapter-title-wrapper"
        aria-label={t(`chapter_row.title_header`)}
      >
        <div
          className="chapter-row-item chapter-duration"
          aria-label={t(`chapter_row.length_header`)}
        >
          {audio ? renderDuration(audio!.duration) : "0:00"}
        </div>

        <div className="chapter-title">
          {title}

          <div
            className="chapter-row-item chapter-edit"
            aria-label={t(`chapter_row.edit_header`)}
          >
            <EditButton
              label={t(`button.edit`)}
              size="small"
              // tslint:disable-next-line: react-this-binding-issue
              onClick={() => {
                history.replace(routeTo.editChapterTitle(story.id, chapter.id));
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="chapter-row-item chapter-order"
        aria-label={t(`chapter_row.order_header`)}
        {...draggableHandleProps}
      >
        <DragHandle />
      </div>
    </div>
  );
}
