import { effect } from "@druyan/druyan";
import { history } from "./history";

export function redirectTo(url: string, delay = 0) {
  return effect("redirectTo", url, () => {
    setTimeout(() => history.push(url), delay);
  });
}

export function redirectBack() {
  return effect("redirectBack", undefined, () => {
    history.goBack();
  });
}
