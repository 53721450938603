import { Action } from "@druyan/druyan";

export interface PlayAudio extends Action<"App/PlayAudio"> {
  audio: string;
  playHead: number;
  onPlayback?: () => void;
}

export function playAudio(
  audio: string,
  playHead: number = 0,
  onPlayback?: () => void,
): PlayAudio {
  return {
    type: "App/PlayAudio",
    audio,
    playHead,
    onPlayback,
  };
}
