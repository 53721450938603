import { pathTo } from "@storytime/shared/build/pathTo";
import firebase from "firebase/app";
import "firebase/functions";
import { app } from "../firebase";
import { Invite, UserData } from "../state";

export function getUserDataRef(
  userId: string,
): firebase.firestore.DocumentReference {
  return app.firestore().doc(pathTo.db.user(userId));
}

export async function createUserData(user: firebase.User): Promise<UserData> {
  const initialUser = {
    // User info
    name: user.displayName,
    email: user.email,

    // Account state booleans
    hasCompletedGreetings: false,
    hasCompletedIntro: false,
    hasSeenFirstChapterModal: false,
    hasSeenUploadModal: false,

    // Sharing
    editors: [],
    editorNames: {},
    editorEmails: {},
    invites: [],
  };

  await app
    .firestore()
    .doc(pathTo.db.user(user.uid))
    .set(initialUser);

  return initialUser;
}

export async function saveCompleteIntro(
  user: firebase.User,
): Promise<firebase.User> {
  const ref = getUserDataRef(user.uid);

  await ref.update({ hasCompletedIntro: true });

  return user;
}

export async function saveConfirmedFirstChapterModal(
  user: firebase.User,
): Promise<firebase.User> {
  const ref = getUserDataRef(user.uid);

  await ref.update({ hasSeenFirstChapterModal: true });

  return user;
}

export async function saveConfirmedUploadModal(
  user: firebase.User,
): Promise<firebase.User> {
  const ref = getUserDataRef(user.uid);

  await ref.update({ hasSeenUploadModal: true });

  return user;
}

export async function saveCompleteGreetings(
  user: firebase.User,
): Promise<firebase.User> {
  const ref = getUserDataRef(user.uid);

  await ref.update({ hasCompletedGreetings: true });

  return user;
}

export async function addEditor(
  user: firebase.User,
  editorId: string,
): Promise<firebase.User> {
  const ref = getUserDataRef(user.uid);

  await ref.update({
    editors: firebase.firestore.FieldValue.arrayUnion(editorId),
  });

  return user;
}

export async function editorHasStories(
  userId: string,
  editorId: string,
): Promise<boolean> {
  const myStoriesRefs = await app
    .firestore()
    .collection(pathTo.db.storiesCollection())
    .where("owner", "==", userId)
    .get();

  const test = myStoriesRefs.docs.map(async doc => {
    const story = await doc.ref.get();
    const data = story.data();

    if (!data) {
      throw new Error(`Got empty snapshot data for document: ${story.id}.`);
    }

    return data.editors && data.editors.includes(editorId);
  });

  const result = await Promise.all(test);

  return result.includes(true);
}

export async function removeEditor(userId: string, editorId: string) {
  const ref = getUserDataRef(userId);

  await ref.update({
    editors: firebase.firestore.FieldValue.arrayRemove(editorId),
  });

  const myStoriesRefs = await app
    .firestore()
    .collection(pathTo.db.storiesCollection())
    .where("owner", "==", userId)
    .get();

  myStoriesRefs.docs.forEach(async doc => {
    const storyRef = await doc.ref.get();
    const storyData = storyRef.data();

    if (!storyData) {
      throw new Error(`Got empty snapshot data for document: ${storyRef.id}.`);
    }

    if (storyData.editors && storyData.editors.includes(editorId)) {
      await doc.ref.update({
        editors: firebase.firestore.FieldValue.arrayRemove(editorId),
      });
    }
    const chapterRefs = await app
      .firestore()
      .collection(pathTo.db.chaptersCollection(storyRef.id))
      .get();

    chapterRefs.docs.forEach(chapterRef => {
      const chapterData = chapterRef.data();
      const chapter = app
        .firestore()
        .doc(pathTo.db.chapter(storyRef.id, chapterRef.id));

      if (chapterData.owner === editorId) {
        chapter.delete();
      }
    });
  });
}

export async function removeInvite(
  user: firebase.User,
  inviteToRemove: Invite,
): Promise<firebase.User> {
  const ref = getUserDataRef(user.uid);
  const userSnap = await ref.get();
  const userData = userSnap.data();

  if (!userData) {
    throw new Error(`Got empty snapshot data for User: ${user.uid}.`);
  }

  await ref.update({
    invites: firebase.firestore.FieldValue.arrayRemove(inviteToRemove),
  });

  const myStoriesRefs = await app
    .firestore()
    .collection(pathTo.db.storiesCollection())
    .where("owner", "==", user.uid)
    .get();

  myStoriesRefs.docs.forEach(async doc => {
    const story = await doc.ref.get();
    const data = story.data();

    if (!data) {
      throw new Error(`Got empty snapshot data for document: ${story.id}.`);
    }

    const inviteFound =
      data.invites.filter(
        (invite: Invite) =>
          invite.email === inviteToRemove.email &&
          invite.time === inviteToRemove.time,
      ).length > 0;

    if (inviteFound) {
      doc.ref.update({
        invites: firebase.firestore.FieldValue.arrayRemove(inviteToRemove),
      });
    }
  });

  return user;
}

export async function deleteUserData(user: firebase.User): Promise<void> {
  const ref = getUserDataRef(user.uid);

  await ref.delete();

  // This ref.delete() triggers cloud function onDeleteUser
}

export const downloadStories = app.functions().httpsCallable("downloadStories");
