import { Action } from "@druyan/druyan";

export interface SetChapterTitle extends Action<"SetChapterTitle"> {
  title: string;
}

export function setChapterTitle(title: string): SetChapterTitle {
  return {
    type: "SetChapterTitle",
    title,
  };
}
