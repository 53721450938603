import React, { useState } from "react";
import ReactModal from "react-modal";
import { Modal } from "../../state";

import {
  Account,
  Acknowledge,
  Confirm,
  ProcessingIndicator,
  Spinner,
} from "./ModalContent";

interface Props {
  modal: Modal;
}

export default ({ modal }: Props) => {
  const [hasFinishedAnimatingClosed, setHasFinishedAnimatingClosed] = useState(
    true,
  );

  // Closing is the same as rejection of a confirm modal.
  const shouldCloseOnOverlayClick = modal ? "account" === modal.type : false;
  const shouldCloseOnEsc = modal
    ? ["confirm", "account"].includes(modal.type)
    : false;
  const sendStateUpdate =
    modal && ["confirm", "account"].includes(modal.type)
      ? modal.type === "confirm"
        ? modal.onReject
        : modal.type === "account"
        ? modal.onClosed
        : undefined
      : undefined;

  let waitForAnimationTimeout: any;

  const onRequestClose = () => {
    setHasFinishedAnimatingClosed(true);

    waitForAnimationTimeout = setTimeout(() => {
      if (sendStateUpdate) {
        sendStateUpdate();
      }
    }, 300); // Animation takes 300ms to complete
  };

  const onAfterClose = () => {
    window.clearTimeout(waitForAnimationTimeout); // Clear animation timeout after closed
    setHasFinishedAnimatingClosed(true);
  };

  const onAfterOpen = () => {
    setHasFinishedAnimatingClosed(false);
  };

  const animateClass = hasFinishedAnimatingClosed
    ? "--animate-out"
    : "--animate-in";

  return (
    <ReactModal
      isOpen={!!modal}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      onAfterClose={onAfterClose}
      overlayClassName="Modal__Overlay"
      className={`Modal__Container ${modal ? `--${modal.type}` : null}`}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      {modal && "confirm" === modal.type ? (
        <Confirm
          {...modal}
          className={animateClass}
          onReject={onRequestClose}
        />
      ) : modal && "acknowledge" === modal.type ? (
        <Acknowledge {...modal} className={animateClass} />
      ) : modal && "account" === modal.type ? (
        <Account {...modal} className={animateClass} onClose={onRequestClose} />
      ) : modal && "spinner" === modal.type ? (
        <Spinner {...modal} className={animateClass} />
      ) : modal && "processing" === modal.type ? (
        <ProcessingIndicator {...modal} className={animateClass} />
      ) : null}
    </ReactModal>
  );
};
