import Entry from "./Entry";
import Instructions from "./Instructions";
import MicDisclaimer from "./MicDisclaimer";
import RecordGreeting from "./RecordGreeting";

export default {
  RecordGreeting,
  Instructions,
  MicDisclaimer,
  Entry,
};
