import { Action } from "@druyan/druyan";

export interface AwaitingStream extends Action<"App/AwaitingStream"> {
  isAwaitingUserStream: boolean;
}

export function awaitingStream(isAwaitingUserStream: boolean): AwaitingStream {
  return {
    type: "App/AwaitingStream",
    isAwaitingUserStream,
  };
}
