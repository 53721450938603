import { Enter, noop, state, StateReturn, task } from "@druyan/druyan";
import { createChapter } from "../../../../api";
import { emptyChapter } from "../../../../state";
import { Actions as AppState } from "../../../../state";
import { redirectTo, routeTo } from "../../../../utils";
import { sendData, SendData, Submit } from "../actions";
import { Shared } from "../types";

function Submitting(
  action: Enter | Submit | SendData,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { user, story, title, chapterTitleRecording } = shared;

  switch (action.type) {
    case "Enter":
      shared.isSubmitting = true;
      return [update(shared), sendData()];

    case "SendData":
      return task(async () => {
        const chapter = emptyChapter();
        chapter.order = Object.keys(story.chapters).length - 1;
        chapter.title = title;
        chapter.recordings.title = chapterTitleRecording;

        const savedChapter = await createChapter(user, story, chapter);

        return [
          // Send the new chapter to the global data store.
          AppState.didCreateChapter(story, savedChapter),

          redirectTo(routeTo.editChapter(story.id, savedChapter.id)),
        ];
      });

    case "Submit":
      return noop();
  }
}

const SubmittingState = state("Submitting", Submitting);
const { update } = SubmittingState;
export default state("Submitting", Submitting);
