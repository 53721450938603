import React from "react";
import { RouteComponentProps } from "react-router";
import { analytics } from "../../firebase";
import { useActions, useAuth, useIOSCheck, useStories } from "../../hooks";
import { history, routeTo } from "../../utils";
import { EditChapterTitle } from "./EditChapterTitle";
import { StateContext, States } from "./machine";

type Props = RouteComponentProps<{ storyId: string; chapterId: string }>;

export default ({
  match: {
    params: { storyId, chapterId },
  },
}: Props) => {
  const { playAudio, pauseAudio } = useActions();
  const { myStories, storiesSharedWithMe } = useStories();
  const isIOS = useIOSCheck();
  analytics.setCurrentScreen("Edit Chapter Title");

  const user = useAuth()!;

  const story = storyId
    ? myStories[storyId] || storiesSharedWithMe[storyId]
    : undefined;

  const chapter = story && chapterId ? story.chapters[chapterId] : undefined;

  if (!story) {
    // tslint:disable-next-line:no-console
    console.error(`Invalid story: ${storyId}`);
    if (history.location.pathname !== routeTo.notFound()) {
      history.replace(routeTo.notFound());
    }
    return null;
  }

  if (!chapter || !chapter.isSaved) {
    // tslint:disable-next-line:no-console
    console.error(`Invalid chapter: ${storyId}`);
    if (history.location.pathname !== routeTo.notFound()) {
      history.replace(routeTo.notFound());
    }
    return null;
  }

  return (
    <div>
      <StateContext.Create
        initialState={States.Entry({
          user,
          story,
          chapter,
          canSubmit: false,
          isSaving: false,
          isSubmitting: false,
          initialChapterTitle: chapter.title,
          initialRecording: chapter.recordings.title,
        })}
      >
        {({
          actions,
          currentState: {
            data: [{ canSubmit, isSubmitting }],
          },
        }) => {
          const startAction = async () => {
            try {
              actions.startRecording();
            } catch (error) {
              actions.showWarning(error);
            }
          };

          const initialRecording = chapter.recordings.title
            ? chapter.recordings.title
            : undefined;

          return (
            <EditChapterTitle
              story={story}
              chapterTitle={chapter.title}
              canSubmit={canSubmit}
              onChangeTitle={actions.setChapterTitle}
              onSubmit={actions.submit}
              onStartRecording={startAction}
              onSetRecording={actions.setRecording}
              onDeleteRecording={actions.deleteRecording}
              playAudio={playAudio}
              pauseAudio={pauseAudio}
              onBack={history.goBack}
              isIOS={isIOS}
              isSubmitting={isSubmitting}
              currentRecording={initialRecording}
            />
          );
        }}
      </StateContext.Create>
    </div>
  );
};
