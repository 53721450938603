import { ChapterMap } from "./chapters";
import { RecordingsMap } from "./recording";
import { Invite } from "./userData";

export type StoryRecordingTypes = "title";

export const All_STORY_TYPES: StoryRecordingTypes[] = ["title"];

export const emptyStoryRecordings = All_STORY_TYPES.reduce((sum, type) => {
  sum[type] = null;
  return sum;
}, {} as RecordingsMap<StoryRecordingTypes>);

export function emptyStory(): UnsavedStory {
  return {
    isSaved: false,
    title: "",
    author: "",
    recordings: emptyStoryRecordings,
    chapters: {},
    isChapterStory: false,
    completed: false,
    editors: [],
    invites: [],
  };
}

interface BaseStory {
  title: string;
  author: string;
  recordings: RecordingsMap<StoryRecordingTypes>;
  chapters: ChapterMap;
  isChapterStory: boolean;
  completed: boolean;
  editors: string[];
  invites: Invite[];
}

export interface UnsavedStory extends BaseStory {
  isSaved: false;
  completed: false;
}

type FirebaseUserId = string;

export interface SavedStory extends BaseStory {
  ownerName: string | null;
  id: string;
  isSaved: true;
  createdAt: number;
  owner: FirebaseUserId;
  completed: boolean;
}

export interface CompletedStory extends SavedStory {
  completed: true;
}

export type Story = UnsavedStory | SavedStory;

export interface Stories {
  myStories: {
    [key: string]: SavedStory;
  };

  storiesSharedWithMe: {
    [key: string]: SavedStory;
  };
}

export function initialStories(): Stories {
  return {
    myStories: {},
    storiesSharedWithMe: {},
  };
}
