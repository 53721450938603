import { Action } from "@druyan/druyan";
import { SavedStory } from "../../../../state";

export interface RemoveEditorFromStory extends Action<"RemoveEditorFromStory"> {
  userId: string;
  story: SavedStory;
}

export function removeEditorFromStory(
  userId: string,
  story: SavedStory,
): RemoveEditorFromStory {
  return {
    type: "RemoveEditorFromStory",
    userId,
    story,
  };
}
