import { Action } from "@druyan/druyan";
import { ProcessingIndicatorModal } from "../model";

export interface ShowProcessingIndicator
  extends Action<"App/ShowProcessingIndicator"> {
  modal: ProcessingIndicatorModal;
}

export function showProcessingIndicator(
  text: string[],
): ShowProcessingIndicator {
  return {
    type: "App/ShowProcessingIndicator",
    modal: { type: "processing", text },
  };
}
