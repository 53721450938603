import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import {
  DeleteRecording,
  SetChapterTitle,
  StartRecording,
  Submit,
} from "../actions";
import { Shared } from "../types";
import Recording from "./Recording";
import ShowingRerecordModal from "./ShowingRerecordModal";
import Submitting from "./Submitting";

function Entry(
  action: Enter | SetChapterTitle | StartRecording | DeleteRecording | Submit,
  shared: Shared,
): StateReturn {
  const { canSubmit, chapter, initialChapterTitle, initialRecording } = shared;

  switch (action.type) {
    case "Enter":
      shared.canSubmit =
        !!chapter.title &&
        chapter.title.trim().length > 0 &&
        chapter.recordings.title !== null &&
        !shared.isSaving;
      return update(shared);

    case "SetChapterTitle":
      shared.chapter.title = action.title;

      return reenter(shared);

    case "StartRecording":
      return Recording(shared);

    case "Submit":
      // if the initial chapter title text has changed,
      // but the initial chapter title recording has remained the same,
      // then show the re-record modal
      if (
        initialChapterTitle !== shared.chapter.title &&
        initialRecording &&
        shared.chapter.recordings.title &&
        initialRecording.duration === shared.chapter.recordings.title.duration
      ) {
        return ShowingRerecordModal(shared);
      } else {
        // otherwise follow normal submit flow
        return canSubmit ? Submitting(shared) : noop();
      }

    case "DeleteRecording":
      shared.chapter.recordings.title = null;
      shared.canSubmit = false;

      return reenter(shared);
  }
}

const EntryState = state("Entry", Entry);
const { reenter, update } = EntryState;
export default EntryState;
