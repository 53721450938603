import { StateReturn } from "@druyan/druyan";
import { pathTo } from "@storytime/shared/build/pathTo";
import {
  deleteAudio,
  deleteChapter,
  updateChapter,
  updateChapterOrder,
} from "../../../api";
import {
  DeleteChapterRecording,
  DeleteStoryChapter,
  DidCreateChapter,
  ReorderChapters,
  SaveChapterRecording,
  UpdateStoryChapterTitle,
} from "../../actions";
import { Modal, Shared } from "../../model";
import Ready from "../Ready";

export type ChapterActions =
  | ReorderChapters
  | UpdateStoryChapterTitle
  | DeleteStoryChapter
  | DeleteChapterRecording
  | DidCreateChapter
  | SaveChapterRecording;

// tslint:disable-next-line: max-func-body-length
export function chapterActions(
  action: ChapterActions,
  shared: Shared,
  modal: Modal,
  update: (shared: Shared, modal: Modal) => ReturnType<typeof Ready>,
): StateReturn | StateReturn[] {
  const { user, stories } = shared;

  if (!user) {
    throw new Error("Must be logged in to handle greetings");
  }

  switch (action.type) {
    case "App/ReorderChapters":
      const newOrdering = updateChapterOrder(
        action.story,
        action.chaptersInOrder,
      );

      const reorderStory = stories.myStories[action.story.id]
        ? stories.myStories[action.story.id]
        : stories.storiesSharedWithMe[action.story.id];

      Object.keys(newOrdering).forEach(id => {
        reorderStory.chapters[id].order = newOrdering[id];
      });

      return update(shared, modal);

    case "App/UpdateStoryChapterTitle":
      updateChapter(user, action.story, action.chapter);

      stories.myStories[action.story.id].chapters[action.chapter.id] =
        action.chapter;

      return update(shared, modal);

    case "App/DeleteStoryChapter":
      const deletedStory = stories.myStories[action.story.id]
        ? stories.myStories[action.story.id]
        : stories.storiesSharedWithMe[action.story.id];

      const deleteOrdering = deleteChapter(deletedStory, action.chapter);

      delete deletedStory.chapters[action.chapter.id];

      Object.keys(deleteOrdering).forEach(id => {
        deletedStory.chapters[id].order = deleteOrdering[id];
      });

      return update(shared, modal);

    case "App/DeleteChapterRecording":
      deleteAudio(
        pathTo.db.chapterRecording(action.story.id, action.chapter.id, "audio"),
      );

      const updatedChapter = {
        ...action.chapter,
        isRecorded: false,
        recordings: { audio: null, title: null },
      };

      const deletedStoryWithRecording = stories.myStories[action.story.id]
        ? stories.myStories[action.story.id]
        : stories.storiesSharedWithMe[action.story.id];

      deletedStoryWithRecording.chapters[updatedChapter.id] = updatedChapter;

      return update(shared, modal);

    case "App/SaveChapterRecording":
      const savedStoryWithRecording = stories.myStories[action.story.id]
        ? stories.myStories[action.story.id]
        : stories.storiesSharedWithMe[action.story.id];

      savedStoryWithRecording.chapters[action.chapter.id] = {
        ...action.chapter,
        isSaved: true,
        isRecorded: true,
        recordings: {
          audio: action.recording,
          title: action.chapter.recordings.title,
        },
      };

      return update(shared, modal);

    case "App/DidCreateChapter":
      const createdStoryWithRecording = stories.myStories[action.story.id]
        ? stories.myStories[action.story.id]
        : stories.storiesSharedWithMe[action.story.id];

      createdStoryWithRecording.chapters[action.chapter.id] = action.chapter;

      return update(shared, modal);
  }
}
