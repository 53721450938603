type FirebaseUserId = string;
type Email = string;

export interface Invite {
  email: string;
  time: string;
}

export interface UserData {
  hasCompletedIntro: boolean;
  hasSeenFirstChapterModal: boolean;
  hasCompletedGreetings: boolean;
  hasSeenUploadModal: boolean;
  editors: FirebaseUserId[];
  editorNames: { [userId: string]: string };
  editorEmails: { [userId: string]: string };
  invites: Invite[];
  email: Email | null;
}

export function initialUserData(): UserData {
  return {
    hasCompletedIntro: false,
    hasSeenFirstChapterModal: false,
    hasCompletedGreetings: false,
    hasSeenUploadModal: false,
    editors: [],
    editorNames: {},
    editorEmails: {},
    invites: [],
    email: "",
  };
}
