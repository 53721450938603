import {
  BackButton,
  CancelButton,
  PrimaryButton,
  Share,
} from "@storytime/shared/build/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useKeyPressEvent from "react-use/lib/useKeyPressEvent";
import { COLORS } from "../../components/Card";
import ContentFrame from "../../components/ContentFrame";
import Footer from "../../components/Footer";
import TextInput from "../../components/TextInput";
import { SavedStory } from "../../state";
import { history } from "../../utils";
import "./Invite.css";

const COLORS_ARRAY: string[] = Object.values(COLORS);
function colorName(hex: string) {
  return Object.keys(COLORS).find(k => (COLORS as any)[k] === hex);
}
interface InitialProps {
  canShare: boolean;
  myStories: SavedStory[];
  storiesToShare: string[];
  editorUsers: Array<{ id: string; name: string }>;
  onNext: (stories: string[]) => void;
  onSelectStories: (stories: string[]) => void;
}

interface Props {
  email: string;
  canSubmit: boolean;
  onChangeEmail?: (email: string) => void;
  onSubmit?: () => void;
}

export function SelectStories({
  myStories,
  onNext,
  onSelectStories,
  editorUsers,
  canShare,
}: InitialProps) {
  const { t } = useTranslation();
  const [storyIds, setStoryIds] = useState<string[]>([]);

  return (
    <div>
      <ContentFrame>
        <div>
          <ul className="select-story-list">
            {myStories.map((story, storyIndex) => {
              const color = COLORS_ARRAY[
                storyIndex % COLORS_ARRAY.length
              ] as COLORS;

              return (
                <li key={story.id}>
                  <div
                    className={`select-story-item --${colorName(color)} ${
                      storyIds.includes(story.id) ? "--selected" : ""
                    }`}
                    // tslint:disable-next-line: react-a11y-props react-a11y-role-has-required-aria-props
                    // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                    role="checkbox"
                    // tslint:disable-next-line: react-this-binding-issue
                    onClick={() => {
                      if (!storyIds.includes(story.id)) {
                        setStoryIds([...storyIds, story.id]);
                        onSelectStories([...storyIds, story.id]);
                      } else {
                        const storyIdsCopy = [...storyIds];
                        const index = storyIdsCopy.indexOf(story.id);
                        storyIdsCopy.splice(index, 1);

                        setStoryIds(storyIdsCopy);
                        onSelectStories(storyIdsCopy);
                      }
                    }}
                  >
                    <div className="story-item-copy">
                      <span className="story-item-title">{story.title}</span>
                      {story.editors && story.editors.length > 0 ? (
                        <span className="story-item-share-label">
                          <Share /> {t("label.shared_with")}
                          <ul>
                            {editorUsers.map(({ id, name }, index) => {
                              /* tslint:disable:max-func-body-length */

                              const comma =
                                editorUsers.length > 2 &&
                                index < editorUsers.length - 1
                                  ? ","
                                  : "";
                              const and =
                                editorUsers.length > 1 &&
                                index === editorUsers.length - 2
                                  ? " and"
                                  : "";
                              /* tslint:enable:max-func-body-length */

                              return story.editors &&
                                story.editors.includes(id) ? (
                                <li>
                                  {name}
                                  {comma}
                                  {and}
                                </li>
                              ) : (
                                <></>
                              );
                            })}
                          </ul>
                        </span>
                      ) : (
                        <></>
                      )}

                      <span className="story-item-checkbox">&nbsp;</span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </ContentFrame>

      <Footer
        leftButton={
          <CancelButton type={"secondary"} onClick={history.goBack}>
            {t("button.cancel")}
          </CancelButton>
        }
        rightButton={
          <PrimaryButton
            // tslint:disable-next-line: react-this-binding-issue
            onClick={() => {
              onNext(storyIds);
            }}
            icon={<Share />}
            disabled={!canShare}
          >
            {t("button.share")}
          </PrimaryButton>
        }
      />
    </div>
  );
}

export function Invite({ email, onChangeEmail, canSubmit, onSubmit }: Props) {
  const { t } = useTranslation();
  useKeyPressEvent("Enter", onSubmit);

  return (
    <div className="invitation">
      <ContentFrame center={true}>
        <div className="invite">
          <h1 className="invite__headline">{t("invite.headline")}</h1>
          <p>{t("invite.copy")}</p>
          <label id="invite-input-label" className="invite__input">
            <span className="hide-text">{t("invite.input.label")}</span>
            <TextInput
              type="email"
              labelledBy="invite-input-label"
              placeholder={t("invite.input.placeholder")}
              value={email}
              onChange={onChangeEmail}
            />
          </label>
          <PrimaryButton disabled={!canSubmit} onClick={onSubmit}>
            {t("invite.input.submit_text")}
          </PrimaryButton>
        </div>
      </ContentFrame>

      <Footer
        leftButton={
          <BackButton type={"secondary"} onClick={history.goBack}>
            {t("button.back")}
          </BackButton>
        }
      />
    </div>
  );
}
