import {
  Enter,
  Exit,
  OnFrame,
  onFrame,
  state,
  StateReturn,
  task,
} from "@druyan/druyan";
import {
  Actions as AppState,
  Recording as RecordingType,
} from "../../../../state";
import { redirectTo, routeTo } from "../../../../utils";
import {
  Cancel,
  DeleteRecording,
  FinishedPlaying,
  Pause,
  SaveChapter,
  StartRecording,
} from "../actions";
import { Shared } from "../types";
import RecordedPaused from "./RecordedPaused";
import RecordedUnplayed from "./RecordedUnplayed";
import SavingChapterModal from "./SavingChapterModal";
import ShowingConfirmStartOverModal from "./ShowingConfirmStartOverModal";
import ShowingSaveBeforeClosingModal from "./ShowingSaveBeforeClosingModal";
import StartingRecording from "./StartingRecording";

function RecordedPlaying(
  action:
    | Enter
    | Pause
    | DeleteRecording
    | OnFrame
    | FinishedPlaying
    | SaveChapter
    | Cancel
    | StartRecording
    | Exit,
  shared: Shared,
  recording: RecordingType,
  inProgressPlayback: number,
  startTimestamp: number,
): StateReturn | StateReturn[] {
  const { story, audioContext, playAudio } = shared;

  switch (action.type) {
    case "Enter":
      return task(async () => {
        const playPromise = (): Promise<AppState.PlayAudio> =>
          new Promise(resolve =>
            playAudio(recording.audio, inProgressPlayback, () => resolve()),
          );

        await playPromise();

        return onFrame();
      });

    case "OnFrame":
      const updatedPlayback = (performance.now() - startTimestamp) / 1000;

      if (updatedPlayback > recording.duration) {
        return RecordedUnplayed(shared, recording);
      }

      return [
        update(shared, recording, updatedPlayback, startTimestamp),

        onFrame(),
      ];

    case "Pause":
      return RecordedPaused(shared, recording, inProgressPlayback);

    case "DeleteRecording":
      return [
        RecordedPaused(shared, recording, inProgressPlayback),
        ShowingConfirmStartOverModal(shared),
      ];

    case "FinishedPlaying":
      return RecordedUnplayed(shared, recording);

    case "SaveChapter":
      return SavingChapterModal(shared, recording);

    case "Cancel":
      return recording.isUpdated
        ? ShowingSaveBeforeClosingModal(shared, recording)
        : redirectTo(routeTo.storyDetail(story.id));

    case "StartRecording":
      return StartingRecording(
        shared,
        recording,
        action.stream,
        action.stopAction,
        audioContext,
      );

    case "Exit":
      return AppState.stopAudio();
  }
}

const RecordedPlayingState = state("RecordedPlaying", RecordedPlaying);
const { update } = RecordedPlayingState;
export default RecordedPlayingState;
