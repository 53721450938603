import { Action } from "@druyan/druyan";
import { GreetingTypes } from "../model";

export interface DeleteGreeting extends Action<"App/DeleteGreeting"> {
  which: GreetingTypes;
}

export function deleteGreeting(which: GreetingTypes): DeleteGreeting {
  return {
    type: "App/DeleteGreeting",
    which,
  };
}
