import React, { useMemo } from "react";
import { useActions, useStories, useUserData } from "../../hooks";
import { GreetingsWelcome } from "../Greetings/Greetings";
import {
  DashboardWithBothStoryTypes,
  DashboardWithOnlyMyStories,
  DashboardWithOnlyStoriesSharedWithMe,
  EmptyDashboard,
} from "./Dashboard";

export default () => {
  const { myStories, storiesSharedWithMe } = useStories();
  const { hasCompletedIntro } = useUserData();
  const { completeIntro } = useActions();

  const listOfMyStories = useMemo(
    () => Object.values(myStories).sort((a, b) => a.createdAt - b.createdAt),
    [myStories],
  );

  const listOfStoriesSharedWithMe = useMemo(
    () =>
      Object.values(storiesSharedWithMe).sort(
        (a, b) => a.createdAt - b.createdAt,
      ),
    [storiesSharedWithMe],
  );

  if (!hasCompletedIntro) {
    return (
      // tslint:disable-next-line:react-this-binding-issue
      <GreetingsWelcome onCompleteWelcomeFlow={() => completeIntro()} />
    );
  }

  if (listOfMyStories.length && listOfStoriesSharedWithMe.length) {
    return (
      <DashboardWithBothStoryTypes
        myStories={listOfMyStories}
        storiesSharedWithMe={listOfStoriesSharedWithMe}
      />
    );
  }

  if (listOfMyStories.length && !listOfStoriesSharedWithMe.length) {
    return <DashboardWithOnlyMyStories myStories={listOfMyStories} />;
  }

  if (!listOfMyStories.length && listOfStoriesSharedWithMe.length) {
    return (
      <DashboardWithOnlyStoriesSharedWithMe
        storiesSharedWithMe={listOfStoriesSharedWithMe}
      />
    );
  }

  return <EmptyDashboard />;
};
