import { StateReturn } from "@druyan/druyan";
import {
  HideModal,
  ShowAcknowledge,
  ShowConfirm,
  ShowGearMenu,
  ShowProcessingIndicator,
  ShowRerecord,
  ShowSpinner,
} from "../../actions";
import { Modal, Shared } from "../../model";
import Ready from "./index";

export type ModalActions =
  | ShowGearMenu
  | ShowAcknowledge
  | ShowConfirm
  | ShowProcessingIndicator
  | ShowRerecord
  | ShowSpinner
  | HideModal;

export function modalActions(
  action: ModalActions,
  shared: Shared,
  update: (shared: Shared, modal: Modal) => ReturnType<typeof Ready>,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "App/ShowGearMenu":
    case "App/ShowAcknowledge":
    case "App/ShowConfirm":
    case "App/ShowProcessingIndicator":
    case "App/ShowSpinner":
    case "App/ShowRerecord":
      return update(shared, action.modal);

    case "App/HideModal":
      return update(shared, null);
  }
}
