import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { OnNext, SelectStories } from "../actions";
import { Shared } from "../types";
import SelectedStories from "./SelectedStories";

function Entry(
  action: Enter | OnNext | SelectStories,
  shared: Shared,
): StateReturn {
  switch (action.type) {
    case "Enter":
      return noop();

    case "SelectStories":
      shared.canShare = action.stories.length > 0;
      return update(shared);

    case "OnNext":
      shared.storiesToShare = action.stories;
      return SelectedStories(shared);
  }
}

const EntryState = state("Entry", Entry);
const { update } = EntryState;
export default EntryState;
