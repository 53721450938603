import { Action } from "@druyan/druyan";
import { SavedChapter, SavedStory } from "../model";

export interface DidCreateChapter extends Action<"App/DidCreateChapter"> {
  story: SavedStory;
  chapter: SavedChapter;
}

export function didCreateChapter(
  story: SavedStory,
  chapter: SavedChapter,
): DidCreateChapter {
  return {
    type: "App/DidCreateChapter",
    story,
    chapter,
  };
}
