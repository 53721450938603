import { Checkmark } from "@storytime/shared/build/components";
import React, { useCallback } from "react";
import "./RadioGroup.css";

interface RadioOption {
  value: string;
  label: string;
}

interface Props {
  name: string;
  value?: string;
  onChange?: (value: string) => void;
  options: RadioOption[];
  labelledBy?: string;
  disabled?: boolean;
}

export function RadioGroup({
  name,
  value,
  options,
  onChange,
  labelledBy,
  disabled,
}: Props) {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        onChange(e.target.value);
      }
    },
    [onChange],
  );

  return (
    <div className="RadioGroup" role="radiogroup" aria-labelledby={labelledBy}>
      {options.map(o => (
        <React.Fragment key={`radio_${o.value}`}>
          {/* Disabled because react-a11y can't tell the below is an input, not a div  */}
          {/* tslint:disable-next-line:react-a11y-role-has-required-aria-props */}
          <label className="radio-label" htmlFor={`radio_${o.value}`}>
            {/* tslint:disable-next-line: react-a11y-role-has-required-aria-props */}
            <input
              disabled={disabled}
              className="radio-input"
              id={`radio_${o.value}`}
              name={name}
              type="radio"
              value={o.value}
              checked={value === o.value}
              onChange={handleChange}
            />
            <div className="radio-visual">
              <span className="radio-check">
                <Checkmark />
              </span>
            </div>
            {o.label}
          </label>
        </React.Fragment>
      ))}
    </div>
  );
}
