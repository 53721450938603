import { Action } from "@druyan/druyan";

export interface RemoveEditorFromUser
  extends Action<"App/RemoveEditorFromUser"> {
  userId: string;
}

export function removeEditorFromUser(userId: string): RemoveEditorFromUser {
  return {
    type: "App/RemoveEditorFromUser",
    userId,
  };
}
