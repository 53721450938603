import { Enter, state, StateReturn } from "@druyan/druyan";
import { Actions as AppState } from "../../../../state";
import { redirectTo, routeTo } from "../../../../utils";
import {
  DeleteStory,
  DownloadStory,
  RemoveSharedStory,
  ReorderChapters,
  StopEditing,
} from "../actions";
import { Shared } from "../types";
import GeneratingZipFile from "./GeneratingZipFile";
import ShowConfirmDeleteModal from "./ShowConfirmDeleteModal";
import ShowConfirmRemoveModal from "./ShowConfirmRemoveModal";

function Editing(
  action:
    | Enter
    | StopEditing
    | DeleteStory
    | DownloadStory
    | ReorderChapters
    | RemoveSharedStory,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { story } = shared;
  switch (action.type) {
    case "Enter":
      shared.isEditing = true;
      return update(shared);

    case "StopEditing":
      shared.isEditing = false;
      return redirectTo(routeTo.storyDetail(story.id));

    case "DeleteStory":
      return ShowConfirmDeleteModal(shared);

    case "RemoveSharedStory":
      return ShowConfirmRemoveModal(shared);

    case "DownloadStory":
      return GeneratingZipFile(shared);

    case "ReorderChapters":
      return AppState.reorderChapters(shared.story, action.chaptersInOrder);
  }
}

const EditingState = state("Editing", Editing);
const { update } = EditingState;
export default EditingState;
