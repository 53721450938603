import { Action } from "@druyan/druyan";

export interface FinishedRecordingGreetings
  extends Action<"FinishedRecordingGreetings"> {}

export function finishedRecordingGreetings(): FinishedRecordingGreetings {
  return {
    type: "FinishedRecordingGreetings",
  };
}
