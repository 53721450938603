import { Action } from "@druyan/druyan";
import { SavedChapter, SavedStory } from "../model";

export interface DeleteChapterRecording
  extends Action<"App/DeleteChapterRecording"> {
  story: SavedStory;
  chapter: SavedChapter;
}

export function deleteChapterRecording(
  story: SavedStory,
  chapter: SavedChapter,
): DeleteChapterRecording {
  return {
    type: "App/DeleteChapterRecording",
    story,
    chapter,
  };
}
