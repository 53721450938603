import { Enter, state, StateReturn } from "@druyan/druyan";
import { Actions as AppState } from "../../../../state";
import {
  DeleteRecording,
  Reject,
  SaveTitle,
  SetChapterTitle,
  StartRecording,
} from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";
import Recording from "./Recording";
import Submitting from "./Submitting";

function EditingTitle(
  action:
    | Enter
    | SaveTitle
    | SetChapterTitle
    | DeleteRecording
    | StartRecording
    | Reject,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { chapter } = shared;

  switch (action.type) {
    case "Enter":
      shared.canSubmit =
        !!chapter.title &&
        chapter.title.trim().length > 0 &&
        chapter.recordings.title !== null;

      return update(shared);

    case "Reject":
      return [AppState.hideModal(), Entry(shared)];

    case "SetChapterTitle":
      shared.chapter.title = action.title;

      return reenter(shared);

    case "DeleteRecording":
      shared.chapter.recordings.title = null;
      shared.canSubmit = false;

      return reenter(shared);

    case "StartRecording":
      return Recording(shared);

    case "SaveTitle":
      return Submitting(shared);
  }
}

const EditingTitleState = state("EditingTitle", EditingTitle);
const { reenter, update } = EditingTitleState;
export default EditingTitleState;
