import {
  CloseButton,
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from "@storytime/shared/build/components";
import { config } from "@storytime/shared/build/config";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Recording, Story } from "../../state";
import { routeTo } from "../../utils";
import SpinnerAnimation from "../Spinner";
import "./Modal.css";

export interface ConfirmProps {
  text: string;
  confirmText: string;
  rejectText: string;
  className?: string;
  onConfirm?: () => any;
  onReject?: () => any;
  illustration?: () => JSX.Element;
}

export function Confirm({
  text,
  confirmText,
  rejectText,
  className,
  onConfirm,
  onReject,
  illustration,
}: ConfirmProps) {
  return (
    <div className={`Modal ${className || ""}`}>
      {illustration ? illustration() : undefined}
      <h2 className="Modal_text">{text}</h2>

      <SecondaryButton filled={false} onClick={onReject}>
        {rejectText}
      </SecondaryButton>
      <PrimaryButton
        filled={false}
        onClick={onConfirm}
        needsImmediateFocus={true}
      >
        {confirmText}
      </PrimaryButton>
    </div>
  );
}

export interface AcknowledgeProps {
  headline?: string;
  text: string;
  className?: string;
  acknowledgeText: string;
  onAcknowledge?: () => any;
  illustration?: () => JSX.Element;
}

export function Acknowledge({
  headline,
  text,
  className,
  acknowledgeText,
  onAcknowledge,
  illustration,
}: AcknowledgeProps) {
  return (
    <div className={`Modal ${className || ""}`}>
      {illustration ? illustration() : undefined}
      {headline ? <h1 className="Modal_headline">{headline}</h1> : undefined}
      <h2 className="Modal_text">{text}</h2>

      <PrimaryButton onClick={onAcknowledge}>{acknowledgeText}</PrimaryButton>
    </div>
  );
}

export interface AccountProps {
  email: string;
  className?: string;
  onClose: () => any;
  onLogout?: () => any;
}

export function Account({ email, onClose, onLogout, className }: AccountProps) {
  const { t } = useTranslation();

  return (
    <div className={`Modal --account ${className || ""}`}>
      <CloseButton
        className="Modal_close"
        onClick={onClose}
        size="small"
        filled={false}
      />

      <div className="Modal_accountContent">
        <ul className="Modal_accountLinksList">
          <li className="Modal_accountLinks">
            <Link to={routeTo.dashboard()} className="Modal_faqLink">
              {t("account.links.my_stories")}
            </Link>
          </li>
          <li className="Modal_accountLinks">
            <Link to={routeTo.greetings()} className="Modal_faqLink">
              {t("account.links.greetings")}
            </Link>
          </li>
          <li className="Modal_accountLinks">
            <Link to={routeTo.invite()} className="Modal_faqLink">
              {t("account.links.invite")}
            </Link>
          </li>
          <li className="Modal_accountLinks">
            <Link to={routeTo.settings()} className="Modal_faqLink">
              {t("account.links.account")}
            </Link>
          </li>
          <li className="Modal_accountLinks">
            <a
              href={`${config.urls.marketing}/faq`}
              target="_blank"
              rel="noopener noreferrer"
              className="Modal_faqLink"
            >
              {t("account.links.faqs")}
            </a>
          </li>
          <li className="Modal_accountLinks">
            <Link to={routeTo.setupInstructions()} className="Modal_faqLink">
              {t("account.links.setup")}
            </Link>
          </li>
        </ul>
        <p className="Modal_email">
          {t("account.email")}
          <br />
          {email}
        </p>
        <TertiaryButton onClick={onLogout}>
          {t("account.logout")}
        </TertiaryButton>
      </div>
    </div>
  );
}

export interface SpinnerProps {
  text: string;
  className?: string;
}

export function Spinner({ text, className }: SpinnerProps) {
  return (
    <div className={`Modal ${className || ""}`}>
      <SpinnerAnimation />
      <h2 className="Modal_text">{text}</h2>
    </div>
  );
}

export interface ProcessingIndicatorProps {
  text: string[];
  className?: string;
}

export function ProcessingIndicator({
  text,
  className,
}: ProcessingIndicatorProps) {
  const [animationIndex, setAnimationIndex] = useState(0);
  const { t } = useTranslation();
  useEffect(() => {
    const id = setInterval(() => {
      if (animationIndex < text.length - 1) {
        setAnimationIndex(animationIndex + 1);
      } else {
        setAnimationIndex(0);
      }
    }, 2500);

    return () => clearInterval(id);
  });
  return (
    <div className={`Modal --processing-indicator ${className || ""}`}>
      <img
        className="Modal__processing-loader"
        src="/assets/witchLoader.gif"
        alt={t("processing_indicator.witch")}
      />
      <div className="Modal__processing-indicators" aria-live="polite">
        {text.map((line, index) => (
          <p
            aria-hidden={index !== animationIndex}
            key={index}
            className={index === animationIndex ? "--active" : ""}
          >
            {line}
          </p>
        ))}
      </div>
    </div>
  );
}

export interface EditChapterTitleProps {
  story: Story;
  id: string;
  title: string;
  onChange: (name: string) => void;
  onStartRecording: () => void;
  onSetRecording: (recording: Recording) => void;
  onDeleteRecording: () => void;
  canSubmit: boolean;
  onSubmit: () => void;
  playAudio: (
    audio: string,
    playHead?: number,
    onPlayback?: () => void,
  ) => void;
  pauseAudio: () => void;
  onBack: () => void;
  currentRecording: Recording | undefined;
  isIOS: boolean;
}
