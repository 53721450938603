import { Action } from "@druyan/druyan";
import { SavedRecordedChapter, SavedStory } from "../model";

export interface CompleteStoryChapter
  extends Action<"App/CompleteStoryChapter"> {
  story: SavedStory;
  chapter: SavedRecordedChapter;
}

export function completeStoryChapter(
  story: SavedStory,
  chapter: SavedRecordedChapter,
): CompleteStoryChapter {
  return {
    type: "App/CompleteStoryChapter",
    story,
    chapter,
  };
}
