import { Action } from "@druyan/druyan";

export interface StartRecording extends Action<"StartRecording"> {
  stream: MediaStream;
  stopAction: () => Promise<void>;
}

export function startRecording(
  stream: MediaStream,
  stopAction: () => Promise<void>,
): StartRecording {
  return {
    stream,
    stopAction,
    type: "StartRecording",
  };
}
