// tslint:disable:max-classes-per-file
import React, { Component } from "react";
import { ScalarGenerator } from "../../utils";
import { PointInterface } from "../../utils/PointInterface";

const { round, random: randomNumber, cos, sin, PI, sqrt } = Math;

const MAX_GROWTH = 1;
const DPI = window !== undefined ? window.devicePixelRatio : 1;

export function easeOutCirc(pos: number) {
  return -(sqrt(1 - pos * pos) - 1);
}

interface Props {
  radius: number;
  className?: string;
  color: string;
  generator?: ScalarGenerator;
}

class Point implements PointInterface {
  readonly initialRadius: number;
  maxRadius: number;
  targetRadius: number;
  renderedRadius: number;
  time: number = randomNumber() * PI * 2;
  speed = 3;
  dampening = 3;
  maxVelocity = 5;
  maxWobble = 0.05;
  velocity = 0;

  constructor(public radius: number, public radian: number) {
    this.initialRadius = radius;
    this.maxRadius = this.initialRadius * MAX_GROWTH;
    this.renderedRadius = radius;
    this.targetRadius = radius;
  }

  get x() {
    return cos(this.radian) * this.radius;
  }

  get y() {
    return sin(this.radian) * this.radius;
  }

  tick(delta: number) {
    this.time += delta;

    this.velocity -= this.dampening * delta;

    this.targetRadius =
      this.initialRadius +
      this.initialRadius * this.maxVelocity * easeOutCirc(this.velocity);

    if (this.targetRadius >= this.maxRadius) {
      this.targetRadius = this.maxRadius;
    }

    this.renderedRadius +=
      (this.targetRadius - this.renderedRadius) * delta * 0.9;

    this.radius =
      this.renderedRadius +
      this.renderedRadius * this.maxWobble * sin(this.speed * this.time);

    if (this.velocity <= 0) {
      this.velocity = 0;
    }
  }

  impulse(amount: number) {
    this.velocity += amount;

    if (this.velocity >= 1) {
      this.velocity = 1;
    }
  }
}

export class ReactiveCircle extends Component<Props> {
  canvas: HTMLCanvasElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;
  width = this.props.radius * 2 * MAX_GROWTH;
  height = this.props.radius * 2 * MAX_GROWTH;
  canvasWidth: number;
  canvasHeight: number;
  isRendering = false;
  lastTimestamp: number = 0;
  unsubscribeGenerator?: () => void;
  pointsAlongCircle = 8;

  points: Point[];

  constructor(props: Props) {
    super(props);

    this.canvasWidth = round(this.width * DPI);
    this.canvasHeight = round(this.height * DPI);

    this.points = Array(this.pointsAlongCircle)
      .fill(null)
      .map((_, i) => {
        return new Point(
          this.props.radius,
          (i / this.pointsAlongCircle) * PI * 2,
        );
      });

    this.onFrame = this.onFrame.bind(this);
    this.canvasRef = this.canvasRef.bind(this);
  }

  setupCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    canvas.width = this.canvasWidth;
    canvas.height = this.canvasHeight;
    canvas.style.width = `${this.width}px`;
    canvas.style.height = `${this.height}px`;

    this.ctx = this.canvas.getContext("2d")!;
    this.ctx.fillStyle = this.props.color;
  }

  start() {
    if (this.isRendering) {
      return;
    }

    if (this.props.generator) {
      this.connectGenerator(this.props.generator);
    }

    this.isRendering = true;

    this.lastTimestamp = performance.now();
    this.onFrame(this.lastTimestamp);
  }

  connectGenerator(generator: ScalarGenerator) {
    this.unsubscribeGenerator = generator.subscribe(force => {
      for (const p of this.points) {
        p.impulse(force);
      }
    });
  }

  stop() {
    this.isRendering = false;

    if (this.unsubscribeGenerator) {
      this.unsubscribeGenerator();
    }
  }

  canvasRef(ref: HTMLCanvasElement) {
    if (ref === null) {
      this.stop();
      this.canvas = null;
      this.ctx = null;
      return;
    }

    this.setupCanvas(ref);
    this.start();
  }

  onFrame(timestamp: DOMHighResTimeStamp) {
    if (!this.canvas) {
      return this.stop();
    }

    let delta = (timestamp - this.lastTimestamp) / 1000;

    // Throw away long frames (if user tabs away)
    if (delta > 0.05) {
      delta = 0.016;
    }

    const ctx = this.ctx!;

    ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

    ctx.save();

    // Retina
    ctx.scale(DPI, DPI);

    ctx.translate(this.width / 2, this.height / 2);

    for (const p of this.points) {
      p.tick(delta);
    }

    ctx.beginPath();

    const xc1 = (this.points[0].x + this.points[this.points.length - 1].x) / 2;
    const yc1 = (this.points[0].y + this.points[this.points.length - 1].y) / 2;

    ctx.moveTo(xc1, yc1);

    let i;
    for (i = 0; i < this.points.length - 1; i++) {
      const xc = (this.points[i].x + this.points[i + 1].x) / 2;
      const yc = (this.points[i].y + this.points[i + 1].y) / 2;

      ctx.quadraticCurveTo(this.points[i].x, this.points[i].y, xc, yc);
    }

    ctx.quadraticCurveTo(this.points[i].x, this.points[i].y, xc1, yc1);

    ctx.closePath();

    ctx.fill();

    ctx.restore();

    if (this.isRendering) {
      this.lastTimestamp = timestamp;
      requestAnimationFrame(this.onFrame);
    }
  }

  // Don't update on points change.
  shouldComponentUpdate(nextProps: Props) {
    if (
      nextProps.generator &&
      (!this.props.generator ||
        nextProps.generator.id !== this.props.generator.id)
    ) {
      if (this.unsubscribeGenerator) {
        this.unsubscribeGenerator();
      }

      this.connectGenerator(nextProps.generator);
    }

    return nextProps.radius !== this.props.radius;
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{
          width: this.width + "px",
          height: this.height + "px",
        }}
      >
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}
