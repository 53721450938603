import { PrimaryButton, Share } from "@storytime/shared/build/components";
import React from "react";
import Card, { COLORS } from "../../components/Card";
import ContentFrame from "../../components/ContentFrame";
import GoogleHomeTip from "../../components/GoogleHomeTip";
import NewStoryTile from "../../components/NewStoryTile";
import { t } from "../../i18n";
import { SavedStory } from "../../state";
import { routeTo } from "../../utils";

import "./Dashboard.css";

const COLORS_ARRAY: string[] = Object.values(COLORS);

interface DashboardWithOnlyMyStoriesProps {
  myStories: SavedStory[];
}

export function DashboardWithOnlyMyStories({
  myStories,
}: DashboardWithOnlyMyStoriesProps) {
  return (
    <div>
      <ContentFrame>
        <div className="Dashboard">
          <div className="dashboard-heading">
            <h1 className="dashboard-headline">
              {t("dashboard.my_stories_headline")}
            </h1>
            <h2 className="dashboard-subhead">
              {t("dashboard.my_stories_subhead")}
            </h2>
            <PrimaryButton icon={<Share />} linkTo="/invite">
              {t("button.share")}
            </PrimaryButton>
          </div>
          <div className="dashboard-stories">
            <div className="AddStory dashboard-story">
              <NewStoryTile />
            </div>

            {myStories.map((s, i) => {
              return (
                <div
                  className="dashboard-story"
                  key={s.id}
                  style={{ transitionDelay: `${(i + 1) * 0.05}s` }}
                >
                  <Card
                    linkTo={routeTo.storyDetail(s.id)}
                    key={s.id}
                    title={s.title}
                    color={COLORS_ARRAY[i % COLORS_ARRAY.length] as COLORS}
                  />
                </div>
              );
            })}
          </div>
          <hr className="dashboard-hr" />
          <GoogleHomeTip />
        </div>
      </ContentFrame>
    </div>
  );
}

interface DashboardWithBothStoryTypesProps {
  myStories: SavedStory[];
  storiesSharedWithMe: SavedStory[];
}

// tslint:disable-next-line:react-a11y-accessible-headings
export function DashboardWithBothStoryTypes({
  myStories,
  storiesSharedWithMe,
}: DashboardWithBothStoryTypesProps) {
  return (
    <div>
      <ContentFrame>
        <div className="Dashboard">
          <div className="dashboard-heading">
            <h1 className="dashboard-headline">
              {t("dashboard.my_stories_headline")}
            </h1>
            <h2 className="dashboard-subhead">
              {t("dashboard.my_stories_subhead")}
            </h2>
            <PrimaryButton icon={<Share />} linkTo="/invite">
              {t("button.share")}
            </PrimaryButton>
          </div>
          <div className="dashboard-stories">
            <div className="AddStory dashboard-story">
              <NewStoryTile />
            </div>

            {myStories.map((s, i) => {
              return (
                <div
                  className="dashboard-story"
                  key={s.id}
                  style={{ transitionDelay: `${(i + 1) * 0.05}s` }}
                >
                  <Card
                    linkTo={routeTo.storyDetail(s.id)}
                    key={s.id}
                    title={s.title}
                    color={COLORS_ARRAY[i % COLORS_ARRAY.length] as COLORS}
                  />
                </div>
              );
            })}
          </div>

          <hr className="dashboard-hr" />

          <div className="dashboard-heading">
            <h1 className="dashboard-headline">
              {t("dashboard.shared_stories_headline")}
            </h1>
            <h2 className="dashboard-subhead">
              {t("dashboard.shared_stories_subhead")}
            </h2>
          </div>
          <div className="dashboard-stories">
            {storiesSharedWithMe.map((s, i) => {
              const newI = myStories.length + i;
              return (
                <div
                  className="dashboard-story"
                  key={s.id}
                  style={{ transitionDelay: `${(newI + 1) * 0.05}s` }}
                >
                  <Card
                    isShared={true}
                    linkTo={routeTo.storyDetail(s.id)}
                    key={s.id}
                    title={s.title}
                    color={COLORS_ARRAY[newI % COLORS_ARRAY.length] as COLORS}
                  />
                </div>
              );
            })}
          </div>
          <hr className="dashboard-hr" />
          <GoogleHomeTip />
        </div>
      </ContentFrame>
    </div>
  );
}

interface DashboardWithOnlyStoriesSharedWithMeProps {
  storiesSharedWithMe: SavedStory[];
}

// tslint:disable-next-line:react-a11y-accessible-headings
export function DashboardWithOnlyStoriesSharedWithMe({
  storiesSharedWithMe,
}: DashboardWithOnlyStoriesSharedWithMeProps) {
  return (
    <div>
      <ContentFrame>
        <div className="Dashboard">
          <div className="dashboard-heading">
            <h1 className="dashboard-headline">
              {t("dashboard.shared_stories_headline")}
            </h1>
            <h2 className="dashboard-subhead">
              {t("dashboard.shared_stories_subhead")}
            </h2>
          </div>
          <div className="dashboard-stories">
            {storiesSharedWithMe.map((s, i) => {
              return (
                <div
                  className="dashboard-story"
                  key={s.id}
                  style={{ transitionDelay: `${(i + 1) * 0.05}s` }}
                >
                  <Card
                    isShared={true}
                    linkTo={routeTo.storyDetail(s.id)}
                    key={s.id}
                    title={s.title}
                    color={COLORS_ARRAY[i % COLORS_ARRAY.length] as COLORS}
                  />
                </div>
              );
            })}
          </div>
          <hr className="dashboard-hr" />
          <div className="dashboard-empty">
            <h3 className="dashboard-get-started-headline">
              {t("dashboard.get_started")}
            </h3>
            <div className="AddStory">
              <NewStoryTile />
            </div>
          </div>
          <hr className="dashboard-hr" />
          <GoogleHomeTip />
        </div>
      </ContentFrame>
    </div>
  );
}

export function EmptyDashboard() {
  return (
    <div>
      <ContentFrame>
        <div className="Dashboard">
          <div className="dashboard-empty">
            <h4>{t("dashboard.get_started")}</h4>
            <div className="AddStory">
              <NewStoryTile />
            </div>
          </div>
        </div>
      </ContentFrame>
    </div>
  );
}
