type InternalItemList<T> = ItemListHead<T> | ItemListBody<T> | ItemListTail<T>;

export interface ItemList<T> {
  __type: T;
  __branded: "item_list";
}

export function create<T>(items: T[]): ItemList<T> {
  if (items.length <= 0) {
    throw new Error("Cannont create empty ItemLists");
  }

  const [current, ...remaining] = items;

  return brand({
    completed: null,
    current,
    remaining,
  });
}

function brand<T>(value: InternalItemList<T>): ItemList<T> {
  return (value as unknown) as ItemList<T>;
}

function unbrand<T>(value: ItemList<T>): InternalItemList<T> {
  return (value as unknown) as InternalItemList<T>;
}

interface ItemListHead<T> {
  completed: null;
  current: T;
  remaining: T[];
}

interface ItemListBody<T> {
  completed: T[];
  current: T;
  remaining: T[];
}

interface ItemListTail<T> {
  completed: T[];
  current: T;
  remaining: null;
}

export function goToPrevious<T>(itemList: ItemList<T>): ItemList<T> {
  const { completed, current, remaining } = unbrand(itemList);

  if (!completed) {
    throw new Error("Cannot go previous while at the beginning of the list");
  }

  const newCompleted = [...completed];
  const previousCurrent = newCompleted.pop()!;

  const nextState = {
    current: previousCurrent,
    remaining: remaining ? [current, ...remaining] : [current],
  };

  if (newCompleted.length > 0) {
    return brand({
      ...nextState,
      completed: newCompleted,
    });
  }

  return brand({
    ...nextState,
    completed: null,
  });
}

export function goToNext<T>(itemList: ItemList<T>): ItemList<T> {
  const { completed, current, remaining } = unbrand(itemList);

  if (!remaining) {
    throw new Error("Cannot go next while at the end of the list");
  }

  const [nextCurrent, ...newRemaining] = remaining;
  const nextState = {
    completed: completed ? [...completed, current] : [current],
    current: nextCurrent,
  };

  if (newRemaining.length > 0) {
    return brand({
      ...nextState,
      remaining: newRemaining,
    });
  }

  return brand({
    ...nextState,
    remaining: null,
  });
}

export function getCurrent<T>(itemList: ItemList<T>): T {
  return unbrand(itemList).current;
}

export function isFirst<T>(itemList: ItemList<T>): boolean {
  return !unbrand(itemList).completed;
}

export function isLast<T>(itemList: ItemList<T>): boolean {
  return !unbrand(itemList).remaining;
}

export function currentIndex<T>(itemList: ItemList<T>): number {
  const { completed } = unbrand(itemList);

  return completed ? completed.length : 0;
}

export function count<T>(itemList: ItemList<T>): number {
  const { completed, remaining } = unbrand(itemList);
  return (
    (completed ? completed.length : 0) + (remaining ? remaining.length : 0) + 1
  );
}
