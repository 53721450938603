import Empty from "./Empty";
import Entry from "./Entry";
import ProcessingRecording from "./ProcessingRecording";
import RecordedPaused from "./RecordedPaused";
import RecordedPlaying from "./RecordedPlaying";
import RecordedUnplayed from "./RecordedUnplayed";
import Recording from "./Recording";
import SavingChapterModal from "./SavingChapterModal";
import ShowingConfirmCancelEmptyStoryModal from "./ShowingConfirmCancelEmptyStoryModal";
import ShowingConfirmCancelRecordingModal from "./ShowingConfirmCancelRecordingModal";
import ShowingConfirmStartOverModal from "./ShowingConfirmStartOverModal";
import ShowingFirstChapterModal from "./ShowingFirstChapterModal";
import ShowingSaveBeforeClosingModal from "./ShowingSaveBeforeClosingModal";
import StartingRecording from "./StartingRecording";

export default {
  Empty,
  Entry,
  RecordedPaused,
  RecordedPlaying,
  RecordedUnplayed,
  Recording,
  ProcessingRecording,
  ShowingFirstChapterModal,
  ShowingConfirmStartOverModal,
  ShowingConfirmCancelRecordingModal,
  ShowingSaveBeforeClosingModal,
  SavingChapterModal,
  StartingRecording,
  ShowingConfirmCancelEmptyStoryModal,
};
