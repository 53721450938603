import { Action } from "@druyan/druyan";

export interface FinishedProcessing extends Action<"FinishedProcessing"> {
  buffer: ArrayBuffer;
}

export function finishedProcessing(buffer: ArrayBuffer): FinishedProcessing {
  return {
    type: "FinishedProcessing",
    buffer,
  };
}
