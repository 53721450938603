import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useActions, useAuth } from "../../hooks";
import {
  HeaderWithGearMenu,
  NonInteractiveHeader,
  Props,
  StaticHeader,
} from "./Header";

const HeaderWithRouter = withRouter((props: RouteComponentProps & Props) => {
  const { location } = props;

  const needsStaticHeader =
    location.pathname === "/error" ||
    location.pathname === "/not-found" ||
    (!location.key && location.pathname !== "/");

  return needsStaticHeader ? (
    <StaticHeader />
  ) : (
    <HeaderWithGearMenu {...props} />
  );
});

interface HeaderProps {
  mode: "static" | "noninteractive" | "router" | "active";
}

export default function Header({ mode }: HeaderProps) {
  const user = useAuth();
  const { showGearMenu, logout, hideModal } = useActions();

  switch (mode) {
    case "router":
      if (!user) {
        throw new Error("Header with router requires a logged in user");
      }

      return (
        <HeaderWithRouter
          // tslint:disable-next-line: react-this-binding-issue
          onClickGearMenu={() =>
            showGearMenu({
              email: user.email!,
              onClosed: hideModal,
              onLogout: logout,
            })
          }
        />
      );

    case "static":
      return <StaticHeader />;

    case "noninteractive":
      return <NonInteractiveHeader />;

    case "active":
      // App must be logged in to show the active header
      if (!user) {
        return null;
      }

      return (
        <HeaderWithGearMenu
          // tslint:disable-next-line: react-this-binding-issue
          onClickGearMenu={() => {
            showGearMenu({
              email: user.email!,
              onLogout: logout,
              onClosed: hideModal,
            });
          }}
        />
      );
  }
}
