import { Action } from "@druyan/druyan";
import { Recording, SavedChapter, SavedStory } from "../model";

export interface SaveChapterRecording
  extends Action<"App/SaveChapterRecording"> {
  story: SavedStory;
  chapter: SavedChapter;
  recording: Recording;
}

export function saveChapterRecording(
  story: SavedStory,
  chapter: SavedChapter,
  recording: Recording,
): SaveChapterRecording {
  return {
    type: "App/SaveChapterRecording",
    story,
    chapter,
    recording,
  };
}
