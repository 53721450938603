import { Enter, eventually, Exit, state, StateReturn } from "@druyan/druyan";
import { config } from "@storytime/shared/build/config";
import { FolderIllustration } from "../../../../components/Modal/FolderIllustration";
import { t } from "../../../../i18n";
import { Recording } from "../../../../state";
import { Actions as AppState } from "../../../../state";
import { Confirm, confirm, Reject, reject } from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";

function ShowingUploadModal(
  action: Enter | Confirm | Reject | Exit,
  shared: Shared,
  recording?: Recording,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          illustration: FolderIllustration,
          text: t("upload_modal.text"),
          confirmText: t("upload_modal.confirm"),
          rejectText: t("upload_modal.reject"),
          onConfirm,
          onReject,
        }),
      ];

    case "Confirm":
      shared.hasSeenUploadModal = true;
      window.open(`${config.urls.marketing}/faq`);
      return [AppState.confirmedUploadModal(), AppState.hideModal()];

    case "Reject":
      shared.hasSeenUploadModal = true;

      return [
        AppState.confirmedUploadModal(),
        AppState.hideModal(),
        Entry(shared, recording),
      ];

    case "Exit":
      return AppState.hideModal();
  }
}

export default state("ShowingUploadModal", ShowingUploadModal);
