import { createDruyanContext } from "@druyan/druyan-react";
import * as Actions from "./actions";
import States from "./states";
import Global from "./states/Global";

export * from "./model";
export { States, Actions };

export const StateContext = createDruyanContext(States, Actions, {
  fallback: Global,
});
