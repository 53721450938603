import React, { useCallback } from "react";
import { useRecorder } from "../../hooks";
import { Recording } from "../../state";
import RecordingInput from "../RecordingInput";
import "./RecordingRow.css";

export interface Props {
  initialRecording?: Recording;
  playAudio: (
    audio: string,
    playHead?: number,
    onPlayback?: () => void,
  ) => void;
  pauseAudio: () => void;
  onRecordStart: () => void;
  onRecorded: (recording: Recording) => void;
  onDelete: () => void;
  children: JSX.Element | JSX.Element[];
}

export function RecordingRow({
  initialRecording,
  onRecorded,
  children,
  onRecordStart,
  onDelete,
  playAudio,
  pauseAudio,
}: Props) {
  const { recording, startRecording, finishRecording } = useRecorder(
    initialRecording,
    onRecorded,
  );

  const handleOnRecordStart = useCallback(() => {
    onRecordStart();
    startRecording();
  }, [onRecordStart, startRecording]);

  return (
    <>
      <div>{children}</div>

      <RecordingInput
        onRecordStart={handleOnRecordStart}
        onRecorded={finishRecording}
        initialRecording={recording}
        onDelete={onDelete}
        playAudio={playAudio}
        pauseAudio={pauseAudio}
      />
    </>
  );
}
