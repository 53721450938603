import { Enter, eventually, Exit, state, StateReturn } from "@druyan/druyan";
import { SpeechIllustration } from "../../../../components/Modal/SpeechIllustration";
import { t } from "../../../../i18n";
import { Recording } from "../../../../state";
import { Actions as AppState } from "../../../../state";
import { Acknowledge, acknowledge } from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";

function ShowingFirstChapterModal(
  action: Enter | Acknowledge | Exit,
  shared: Shared,
  recording?: Recording,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      const onAcknowledge = eventually(acknowledge);

      return [
        onAcknowledge,
        AppState.showAcknowledge({
          illustration: SpeechIllustration,
          text: t("first_chapter_modal.text"),
          acknowledgeText: t("global.got_it"),
          onAcknowledge,
        }),
      ];

    case "Acknowledge":
      shared.hasSeenFirstChapterModal = true;

      return [AppState.confirmedFirstChapterModal(), Entry(shared, recording)];

    case "Exit":
      return AppState.hideModal();
  }
}

export default state("ShowingFirstChapterModal", ShowingFirstChapterModal);
