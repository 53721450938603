import { app } from "../firebase";
import { SavedChapter, SavedStory } from "../state";
import { getChapterRef } from "./chapter";

export interface Ordering {
  [id: string]: number;
}

export function updateChapterOrder(
  story: SavedStory,
  orders: SavedChapter[],
): Ordering {
  const db = app.firestore();
  const batch = db.batch();

  const ordering = orders.reduce(
    (sum, c, i) => {
      const chapterRef = getChapterRef(story, c as SavedChapter);

      batch.update(chapterRef, { order: i });

      sum[c.id] = i;

      return sum;
    },
    {} as Ordering,
  );

  batch.commit();

  return ordering;
}
