import { Enter, state, StateReturn } from "@druyan/druyan";
import { Shared } from "../types";
import Entry from "./Entry";
import RecordingGreetings from "./RecordingGreetings";

function Initializing(action: Enter, shared: Shared): StateReturn {
  const { hasCompletedGreetings } = shared;

  switch (action.type) {
    case "Enter":
      return hasCompletedGreetings ? Entry(shared) : RecordingGreetings(shared);
  }
}

export default state("Initializing", Initializing);
