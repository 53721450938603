import { StateReturn } from "@druyan/druyan";
import { AwaitingStream } from "../../actions";
import { Shared } from "../../model";
import Ready from "./index";

export type StreamActions = AwaitingStream;

export function streamActions(
  action: StreamActions,
  shared: Shared,
  update: (shared: Shared) => ReturnType<typeof Ready>,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "App/AwaitingStream":
      shared.isAwaitingUserStream = action.isAwaitingUserStream;
      return update(shared);
  }
}
