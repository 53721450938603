import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import {
  DeleteAccount,
  RemoveEditor,
  RemoveEditorFromStory,
  RemoveEditorsFromStory,
} from "../actions";
import { DownloadAllStories } from "../actions/DownloadAllStories";
import { Shared } from "../types";
import ConfirmingAccountDeletion from "./ConfirmingAccountDeletion";
import ConfirmingEditorFromStoryRemoval from "./ConfirmingEditorFromStoryRemoval";
import ConfirmingEditorRemoval from "./ConfirmingEditorRemoval";
import ConfirmingEditorsFromStoryRemoval from "./ConfirmingEditorsFromStoryRemoval";
import GeneratingZipFile from "./GeneratingZipFile";

function Entry(
  action:
    | Enter
    | DeleteAccount
    | RemoveEditor
    | RemoveEditorFromStory
    | RemoveEditorsFromStory
    | DownloadAllStories,
  shared: Shared,
): StateReturn {
  switch (action.type) {
    case "Enter":
      return noop();

    case "DeleteAccount":
      return ConfirmingAccountDeletion(shared);

    case "RemoveEditor":
      return ConfirmingEditorRemoval(shared, action.id);

    case "RemoveEditorFromStory":
      return ConfirmingEditorFromStoryRemoval(
        shared,
        action.userId,
        action.story,
      );

    case "RemoveEditorsFromStory":
      return ConfirmingEditorsFromStoryRemoval(action.story);

    case "DownloadAllStories":
      return GeneratingZipFile(shared);
  }
}

export default state("Entry", Entry);
