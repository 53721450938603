import { Action } from "@druyan/druyan";

export interface SendInvite extends Action<"App/SendInvite"> {
  email: string;
  stories: string[];
}

export function sendInvite(email: string, stories: string[]): SendInvite {
  return {
    type: "App/SendInvite",
    email,
    stories,
  };
}
