import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import {
  Back,
  CreateChapter,
  DeleteChapterTitleRecording,
  DeleteRecording,
  SetAuthor,
  SetChapterTitle,
  SetDoesStoryHaveChapters,
  SetTitle,
  StartRecording,
  StartRecordingChapterTitle,
  Submit,
} from "../actions";
import { Shared } from "../types";
import Recording from "./Recording";
import RecordingChapterTitle from "./RecordingChapterTitle";
import Submitting from "./Submitting";

function Entry(
  action:
    | Enter
    | SetAuthor
    | SetTitle
    | StartRecording
    | SetDoesStoryHaveChapters
    | SetChapterTitle
    | Submit
    | DeleteRecording
    | StartRecordingChapterTitle
    | CreateChapter
    | Back
    | DeleteChapterTitleRecording,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { story } = shared;

  switch (action.type) {
    case "Enter":
      shared.canSubmit =
        !!story.recordings.title &&
        story.title.trim().length > 0 &&
        story.recordings !== undefined &&
        !shared.isSaving;

      return update(shared);

    case "SetTitle":
      shared.story.title = action.title;

      return reenter(shared);

    case "SetAuthor":
      shared.story.author = action.author;

      return reenter(shared);

    case "CreateChapter":
      shared.isCreatingChapter = true;
      shared.canSubmitChapterTitle = false;

      return reenter(shared);

    case "SetChapterTitle":
      shared.chapter.title = action.chapterTitle;

      return reenter(shared);

    case "StartRecording":
      return Recording(shared);

    case "DeleteRecording":
      shared.story.recordings = { title: null };

      return reenter(shared);

    case "SetDoesStoryHaveChapters":
      shared.story.isChapterStory = action.doesStoryHaveChapters === "true";

      return reenter(shared);

    case "StartRecordingChapterTitle":
      return RecordingChapterTitle(shared);

    case "DeleteChapterTitleRecording":
      shared.chapter.recordings.title = null;
      return reenter(shared);

    case "Submit":
      return shared.canSubmit ||
        (shared.story.isChapterStory && shared.canSubmitChapterTitle)
        ? Submitting(shared)
        : noop();

    case "Back":
      shared.isCreatingChapter = false;

      return reenter(shared);
  }
}

const EntryState = state("Entry", Entry);
const { update, reenter } = EntryState;
export default EntryState;
