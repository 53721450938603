import { Action } from "@druyan/druyan";

export interface EditTitle extends Action<"EditTitle"> {
  startAction: () => Promise<void>;
}

export function editTitle(startAction: () => Promise<void>): EditTitle {
  return {
    type: "EditTitle",
    startAction,
  };
}
