import { Enter, state, StateReturn } from "@druyan/druyan";
import { SetRecording, StartRecording } from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";

function Recording(
  action: Enter | SetRecording | StartRecording,
  shared: Shared,
): StateReturn {
  const { chapter } = shared;
  switch (action.type) {
    case "Enter":
      shared.canSubmit =
        !!chapter.title &&
        chapter.title.trim().length > 0 &&
        chapter.recordings.title !== undefined &&
        !shared.isSaving;

      return update(shared);

    case "SetRecording":
      shared.chapter.recordings.title = action.recording;

      return Entry(shared);

    case "StartRecording":
      // If you've gotten here it's because you can click Record faster than a single frame
      return reenter(shared);
  }
}

const RecordingState = state("Recording", Recording);
const { update, reenter } = RecordingState;
export default RecordingState;
