import { Action } from "@druyan/druyan";
import { AccountModal } from "../model";

export interface ShowGearMenu extends Action<"App/ShowGearMenu"> {
  modal: AccountModal;
}

export function showGearMenu(modal: Omit<AccountModal, "type">): ShowGearMenu {
  return {
    type: "App/ShowGearMenu",
    modal: { ...modal, type: "account" },
  };
}
