import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { redirectBack } from "../../../../utils";
import { Next, Previous } from "../actions";
import { Shared } from "../types";
import Instructions from "./Instructions";

function Welcome(action: Enter | Next | Previous, shared: Shared): StateReturn {
  switch (action.type) {
    case "Enter":
      return noop();

    case "Previous":
      return redirectBack();

    case "Next":
      return Instructions(shared);
  }
}

export default state("Welcome", Welcome);
