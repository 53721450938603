import { Action } from "@druyan/druyan";
import { SpinnerModal } from "../model";

export interface ShowSpinner extends Action<"App/ShowSpinner"> {
  modal: SpinnerModal;
}

export function showSpinner(text: string): ShowSpinner {
  return {
    type: "App/ShowSpinner",
    modal: { type: "spinner", text },
  };
}
