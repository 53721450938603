import { Action } from "@druyan/druyan";

export interface SelectStories extends Action<"SelectStories"> {
  stories: string[];
}

export function selectStories(stories: string[]): SelectStories {
  return {
    type: "SelectStories",
    stories,
  };
}
