import { StateReturn } from "@druyan/druyan";
import {
  saveCompleteGreetings,
  saveCompleteIntro,
  saveConfirmedFirstChapterModal,
  saveConfirmedUploadModal,
} from "../../../api";
import { analytics } from "../../../firebase";
import {
  CompleteGreetings,
  CompleteIntro,
  ConfirmedFirstChapterModal,
  ConfirmedUploadModal,
} from "../../actions";
import { Modal, Shared } from "../../model";
import Ready from "../Ready";

export type UserDataActions =
  | CompleteGreetings
  | CompleteIntro
  | ConfirmedFirstChapterModal
  | ConfirmedUploadModal;

export function userDataActions(
  action: UserDataActions,
  shared: Shared,
  modal: Modal,
  update: (shared: Shared, modal: Modal) => ReturnType<typeof Ready>,
): StateReturn | StateReturn[] {
  const { user, userData } = shared;

  if (!user) {
    throw new Error("Must be logged in to handle greetings");
  }

  switch (action.type) {
    case "App/CompleteIntro":
      saveCompleteIntro(user);

      userData.hasCompletedIntro = true;

      return update(shared, modal);

    case "App/ConfirmedFirstChapterModal":
      saveConfirmedFirstChapterModal(user);

      userData.hasSeenFirstChapterModal = true;

      return update(shared, modal);

    case "App/ConfirmedUploadModal":
      saveConfirmedUploadModal(user);

      userData.hasSeenUploadModal = true;

      return update(shared, modal);

    case "App/CompleteGreetings":
      saveCompleteGreetings(user);

      userData.hasCompletedGreetings = true;

      analytics.logEvent("Greetings", {
        complete_greetings: true,
      });

      return update(shared, modal);
  }
}
