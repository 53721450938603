import { Enter, state, StateReturn } from "@druyan/druyan";
import { SetRecording } from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";

function Recording(action: Enter | SetRecording, shared: Shared): StateReturn {
  const { title, chapterTitleRecording } = shared;
  switch (action.type) {
    case "Enter":
      shared.canSubmit =
        !!title &&
        title.trim().length > 0 &&
        chapterTitleRecording !== null &&
        chapterTitleRecording.audio !== "" &&
        !shared.isSaving;

      return update(shared);

    case "SetRecording":
      shared.chapterTitleRecording = action.recording;

      return Entry(shared);
  }
}

const RecordingState = state("Recording", Recording);
const { update } = RecordingState;
export default RecordingState;
