import {
  Enter,
  OnFrame,
  onFrame,
  state,
  StateReturn,
  task,
} from "@druyan/druyan";
import { Recording as RecordingType } from "../../../../state";
import {
  DeleteRecording,
  FinishedPlaying,
  OnPlaying,
  onPlaying,
  Pause,
} from "../actions";
import { Shared } from "../types";
import Empty from "./Empty";
import RecordedPaused from "./RecordedPaused";
import RecordedUnplayed from "./RecordedUnplayed";

function RecordedPlaying(
  action:
    | Enter
    | OnPlaying
    | Pause
    | DeleteRecording
    | OnFrame
    | FinishedPlaying,
  shared: Shared,
  recording: RecordingType,
  startTimestamp: number = performance.now(),
  inProgressPlayback: number = 0,
): StateReturn | StateReturn[] {
  const { onDelete, playAudio } = shared;

  switch (action.type) {
    case "Enter":
      return task(async () => {
        const playPromise = () =>
          new Promise(resolve =>
            playAudio(recording.audio, inProgressPlayback, () => resolve()),
          );

        await playPromise();

        return onPlaying();
      });

    case "OnPlaying":
      return [
        update(
          shared,
          recording,
          performance.now() - (inProgressPlayback || 0) * 1000,
          inProgressPlayback,
        ),
        onFrame(),
      ];

    case "OnFrame":
      const nextProgress = (performance.now() - startTimestamp) / 1000;

      if (nextProgress > recording.duration) {
        return RecordedUnplayed(shared, recording);
      }

      return [
        update(shared, recording, startTimestamp, nextProgress),

        onFrame(),
      ];

    case "Pause":
      return RecordedPaused(shared, recording, inProgressPlayback);

    case "DeleteRecording":
      onDelete();

      return Empty(shared);

    case "FinishedPlaying":
      return RecordedUnplayed(shared, recording);
  }
}

const RecordedPlayingState = state("RecordedPlaying", RecordedPlaying);
const { update } = RecordedPlayingState;
export default RecordedPlayingState;
