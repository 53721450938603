import React from "react";
import { analytics } from "../../firebase";
import {
  useActions,
  useAuth,
  useIOSCheck,
  useRecordingController,
  useUserData,
} from "../../hooks";
import { emptyChapter, emptyStory } from "../../state";
import Greetings from "../Greetings";
import { CreateStory } from "./CreateStory";
import { StateContext, States } from "./machine";

// tslint:disable-next-line: max-func-body-length
export default () => {
  const user = useAuth();
  const { playAudio, pauseAudio } = useActions();
  const { hasCompletedGreetings } = useUserData();
  const recordingController = useRecordingController()!;

  const isIOS = useIOSCheck();
  if (recordingController) {
    recordingController.useOpus();
  }
  analytics.setCurrentScreen("Create Story");

  if (!user) {
    throw new Error("Cannot Create Story without a user");
  }

  return (
    <div>
      <StateContext.Create
        initialState={States.Initializing({
          user,
          story: emptyStory(),
          canSubmit: false,
          canSubmitChapterTitle: false,
          chapter: emptyChapter(),
          hasCompletedGreetings,
          isCreatingChapter: false,
          isSaving: false,
          isSubmitting: false,
        })}
      >
        {({ currentState, actions }) => {
          const [
            {
              story,
              canSubmit,
              chapter,
              canSubmitChapterTitle,
              isCreatingChapter,
              isSubmitting,
            },
          ] = currentState.data;
          const startAction = async (type: string) => {
            try {
              if (type === "story-title") {
                actions.startRecording();
              } else {
                actions.startChapterTitleRecording();
              }
            } catch (error) {
              actions.showWarning(error);
            }
          };

          switch (currentState.name) {
            case "Initializing":
              return null;

            case "RecordingGreetings":
              return (
                <Greetings
                  isFirstRun={true}
                  finishedGreetings={actions.finishedRecordingGreetings}
                />
              );

            default:
              return (
                <CreateStory
                  story={story}
                  chapter={chapter}
                  canSubmit={canSubmit}
                  canSubmitChapterTitle={canSubmitChapterTitle}
                  isIOS={isIOS}
                  isSubmitting={isSubmitting}
                  onSetTitle={actions.setTitle}
                  onSetAuthor={actions.setAuthor}
                  onSetChapterTitle={actions.setChapterTitle}
                  // tslint:disable: react-this-binding-issue
                  onStartRecording={() => {
                    startAction("story-title");
                  }}
                  onStartRecordingChapterTitle={() => {
                    startAction("chapter-title");
                  }}
                  onSetRecording={actions.setRecording}
                  onSetChapterTitleRecording={actions.setChapterTitleRecording}
                  onDeleteRecording={actions.deleteRecording}
                  onDeleteChapterTitleRecording={
                    actions.deleteChapterTitleRecording
                  }
                  onSetDoesStoryHaveChapters={actions.setDoesStoryHaveChapters}
                  onSubmit={actions.submit}
                  playAudio={playAudio}
                  pauseAudio={pauseAudio}
                  onCreateChapter={actions.createChapter}
                  isCreatingChapter={isCreatingChapter}
                  onBack={actions.back}
                />
              );
          }
        }}
      </StateContext.Create>
    </div>
  );
};
