import { Action } from "@druyan/druyan";

export interface StartRecording extends Action<"StartRecording"> {
  stream: MediaStream;
}

export function startRecording(stream: MediaStream): StartRecording {
  return {
    stream,
    type: "StartRecording",
  };
}
