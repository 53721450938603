import React, { useEffect, useRef } from "react";
import { AudioPlayer as AudioPlayerType } from "../../state";

export interface Props {
  audioPlayer: AudioPlayerType;
  stopAudio?: () => void;
}

export function AudioPlayer({ audioPlayer, stopAudio }: Props) {
  const { isPlaying, playHead, onPlayback } = audioPlayer;
  const audio = audioPlayer.isPlaying ? audioPlayer.audio : null;

  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    if (audioPlayer.isPlaying && onPlayback) {
      audioRef.current.load();
      audioRef.current.onplay = () => onPlayback();
    }
  }, [audio, audioPlayer.isPlaying, onPlayback]);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    if (playHead !== 0) {
      audioRef.current.currentTime = playHead || 0;
      audioRef.current.play().catch(e => {
        // tslint:disable-next-line:no-console
        console.error("Audio playback at playHead failed", e);
      });
    }
  }, [playHead]);

  // Pause on unmount (aka leaving the page).
  useEffect(() => {
    const currentAudioRef = audioRef.current;
    return () => {
      if (isPlaying && stopAudio) {
        stopAudio();
        // empty out audio src otherwise Safari somehow caches it,
        // and will play it when the next record button is clicked
        if (currentAudioRef) {
          currentAudioRef.src = "";
        }
      }
    };
  }, [isPlaying, stopAudio]);

  function onEnded() {
    if (isPlaying && stopAudio) {
      stopAudio();
      // empty out audio src otherwise Safari somehow caches it,
      // and will play it when the next record button is clicked
      if (audioRef && audioRef.current) {
        audioRef.current.src = "";
      }
    }
  }

  if (!isPlaying || !audio) {
    return null;
  }

  return (
    <audio
      ref={audioRef}
      src={audio}
      autoPlay={playHead === 0}
      onEnded={onEnded}
      controls={true}
      style={{ display: "none" }}
    />
  );
}
