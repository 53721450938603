import {
  Enter,
  onFrame,
  OnFrame,
  state,
  StateReturn,
  task,
} from "@druyan/druyan";
import { OnPlaying, onPlaying, Pause } from "../actions";
import { Shared } from "../types";
import Paused from "./Paused";

function Playing(
  action: Enter | Pause | OnPlaying | OnFrame,
  shared: Shared,
  playPercentage: number,
  startTime: number,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      return task(async () => {
        const playPromise = () =>
          new Promise(resolve =>
            shared.playAudio(
              shared.recording.audio,
              playPercentage * shared.recording.duration,
              () => resolve(),
            ),
          );

        await playPromise();

        return onPlaying();
      });

    case "OnPlaying":
      return [
        update(
          shared,
          playPercentage,
          performance.now() - playPercentage * shared.recording.duration * 1000,
        ),
        onFrame(),
      ];

    case "OnFrame":
      let elapsed = (performance.now() - startTime) / 1000;

      if (elapsed <= 0) {
        elapsed = 0;
      }

      const percentage = elapsed / shared.recording.duration;

      if (percentage >= 1) {
        return Paused(shared, 0);
      }

      return [update(shared, percentage, startTime), onFrame()];

    case "Pause":
      shared.stopAudio();

      return Paused(shared, playPercentage);
  }
}

const PlayingState = state("Playing", Playing);
const { update } = PlayingState;
export default PlayingState;
