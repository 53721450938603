import {
  Enter,
  eventually,
  OnFrame,
  onFrame,
  state,
  StateReturn,
  task,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import {
  Actions as AppState,
  Recording as RecordingType,
} from "../../../../state";
import { generatorSingleton } from "../../../../utils";
import {
  cancelRecording,
  CancelRecording,
  deleteRecording,
  ShowWarning,
  StopRecording,
} from "../actions";
import { Shared } from "../types";
import Empty from "./Empty";
import ProcessingRecording from "./ProcessingRecording";

function Recording(
  action: Enter | StopRecording | OnFrame | ShowWarning | CancelRecording,
  shared: Shared,
  recording: RecordingType | undefined,
  startTimestamp: number = performance.now(),
  _inProgressDuration: number = 0,
): StateReturn | StateReturn[] {
  const { onRecordStart, onRecorded } = shared;

  switch (action.type) {
    case "Enter":
      onRecordStart();

      return onFrame();

    case "OnFrame":
      return [
        update(
          shared,
          recording,
          startTimestamp,
          (performance.now() - startTimestamp) / 1000,
        ),

        onFrame(),
      ];

    case "StopRecording":
      return task(async () => {
        if (generatorSingleton.isRunning) {
          generatorSingleton.stop();
        }

        // Convert in-browser blob URL to an actual binary Blob object.
        const recordedAudio = await fetch(action.audioUrl).then(r => r.blob());

        return ProcessingRecording(shared, recordedAudio, action.runAsync);
      });

    case "ShowWarning":
      const onAcknowledge = eventually(cancelRecording);
      const text =
        action.error && action.error.message === "NotAllowedError"
          ? t("audio_recording_not_allowed.text")
          : t("audio_recording_error.text");
      return [
        onAcknowledge,
        AppState.showAcknowledge({
          text,
          acknowledgeText: t("global.okay"),
          onAcknowledge,
        }),
      ];

    case "CancelRecording":
      return task(async () => {
        const dummyRecording = {
          audio: "",
          duration: 0,
          points: [],
          isUpdated: false,
        };

        if (generatorSingleton.isRunning) {
          generatorSingleton.stop();
        }

        onRecorded(dummyRecording);
        deleteRecording();
        Empty(shared);

        return [AppState.hideModal(), Empty(shared)];
      });
  }
}

const RecordingState = state("Recording", Recording);
const { update } = RecordingState;
export default RecordingState;
