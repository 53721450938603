import { Action } from "@druyan/druyan";

export interface ShowWarning extends Action<"ShowWarning"> {
  error?: Error;
}

export function showWarning(error: Error): ShowWarning {
  return {
    type: "ShowWarning",
    error,
  };
}
