import React from "react";
import "./ContentFrame.css";

interface Props {
  center?: boolean;
  children: React.ReactChild | React.ReactChild[];
}

export function ContentFrame({ children, center }: Props) {
  return (
    <div className={`ContentFrame ${center ? "ContentFrame__center" : ""}`}>
      {children}
    </div>
  );
}
