import { Action } from "@druyan/druyan";

export interface SelectFile extends Action<"SelectFile"> {
  file: File;
}

export function selectFile(file: File): SelectFile {
  return {
    type: "SelectFile",
    file,
  };
}
