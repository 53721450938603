import { Action } from "@druyan/druyan";

export interface RemoveEditorsFromStory
  extends Action<"App/RemoveEditorsFromStory"> {
  storyId: string;
}

export function removeEditorsFromStory(
  storyId: string,
): RemoveEditorsFromStory {
  return {
    type: "App/RemoveEditorsFromStory",
    storyId,
  };
}
