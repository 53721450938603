import { pathTo } from "@storytime/shared/build/pathTo";
import { Point } from "../components/Visualizer";
import { app } from "../firebase";
import {
  All_GREETING_TYPES,
  All_STORY_TYPES,
  ChapterMap,
  emptyGreetingsRecordings,
  GreetingTypes,
  Invite,
  Recording,
  RecordingsMap,
  SavedChapter,
  SavedStory,
  Stories,
  StoryRecordingTypes,
  UserData,
} from "../state";
import { createUserData, getUserDataRef, removeInvite } from "./userData";

// tslint:disable-next-line:max-func-body-length
async function chapterDocumentToChapter(
  snapshot: firebase.firestore.DocumentSnapshot,
): Promise<SavedChapter> {
  const data = snapshot.data();

  if (!data) {
    throw new Error(`Got empty snapshot data for document: ${snapshot.id}.`);
  }

  const { title, order, owner } = data;

  const displayName = await loadUserName(owner);

  if (!displayName) {
    throw new Error(`Could not find existing user for user id ${owner}.`);
  }

  if (typeof title !== "string") {
    throw new Error(
      `Expected chapter.title to be a string, but '${title}' was a '${typeof title}'.`,
    );
  }

  if (typeof order !== "number") {
    throw new Error(
      `Expected chapter.order to be a number, but '${title}' was a '${typeof title}'.`,
    );
  }

  if (typeof owner !== "string") {
    throw new Error(
      `Expected chapter.owner to be a string, but '${owner}' was a '${typeof owner}'.`,
    );
  }

  const recordingDocument = await snapshot.ref
    .collection("recordings")
    .doc("audio")
    .get();

  const titleRecordingDocument = await snapshot.ref
    .collection("recordings")
    .doc("title")
    .get();

  const recording = recordingDocument.exists
    ? recordingDocumentToRecording(recordingDocument, ["audio"])
    : null;

  const titleRecording = titleRecordingDocument.exists
    ? recordingDocumentToRecording(titleRecordingDocument, ["title"])
    : null;

  if (recording && titleRecording) {
    return {
      id: snapshot.id,
      isRecorded: true,
      isSaved: true,
      owner,
      order,
      title,
      recordings: {
        audio: { ...recording, isUpdated: false },
        title: { ...titleRecording, isUpdated: false },
      },
      ownerName: displayName,
    };
  }

  if (recording) {
    return {
      id: snapshot.id,
      isRecorded: true,
      isSaved: true,
      owner,
      order,
      title,
      recordings: {
        audio: { ...recording, isUpdated: false },
        title: null,
      },
      ownerName: displayName,
    };
  }

  if (titleRecording) {
    return {
      id: snapshot.id,
      isRecorded: true,
      isSaved: true,
      owner,
      order,
      title,
      recordings: {
        audio: null,
        title: { ...titleRecording, isUpdated: false },
      },
      ownerName: displayName,
    };
  }

  return {
    id: snapshot.id,
    isRecorded: false,
    isSaved: true,
    owner,
    order,
    title,
    recordings: {
      audio: null,
      title: null,
    },
    ownerName: displayName,
  };
}

// tslint:disable-next-line: max-func-body-length
async function storyDocumentToStory(
  snapshot: firebase.firestore.DocumentSnapshot,
): Promise<SavedStory> {
  const data = snapshot.data();

  if (!data) {
    throw new Error(`Got empty snapshot data for document: ${snapshot.id}.`);
  }

  const { owner, title, editors, invites } = data;
  let { author, isChapterStory, createdAt, completed } = data;

  if (typeof owner !== "string") {
    throw new Error(
      `Expected story.owner to be a string, but '${owner}' was a '${typeof owner}'.`,
    );
  }

  const displayName = await loadUserName(owner);

  if (!displayName) {
    throw new Error(`Could not find existing user for user id ${owner}.`);
  }

  if (typeof title !== "string") {
    throw new Error(
      `Expected story.title to be a string, but '${title}' was a '${typeof title}'.`,
    );
  }

  if (typeof author !== "string") {
    if (author === undefined) {
      author = "";
    } else {
      throw new Error(
        `Expected story.author to be a string, but '${author}' was a '${typeof author}'.`,
      );
    }
  }

  if (typeof isChapterStory !== "boolean") {
    if (isChapterStory === undefined) {
      isChapterStory = true;
    } else {
      throw new Error(
        `Expected story.isChapterStory to be a boolean, but '${isChapterStory}' was a '${typeof isChapterStory}'.`,
      );
    }
  }

  if (typeof createdAt !== "number") {
    if (createdAt === undefined) {
      createdAt = Date.now();
    } else {
      throw new Error(
        `Expected story.createdAt to be a string, but '${createdAt}' was a '${typeof createdAt}'.`,
      );
    }
  }

  if (typeof completed !== "boolean") {
    if (completed === undefined) {
      completed = false;
    } else {
      throw new Error(
        `Expected story.completed to be a boolean, but '${completed}' was a '${typeof completed}'.`,
      );
    }
  }

  const [recordingDocuments, chapterDocuments] = await Promise.all([
    snapshot.ref.collection("recordings").get(),
    snapshot.ref.collection("chapters").get(),
  ]);

  const recordings = recordingDocuments.docs.reduce((sum, recordingSnap) => {
    const recording = recordingDocumentToRecording(
      recordingSnap,
      All_STORY_TYPES,
    );

    sum.title = recording;

    return sum;
  }, {} as RecordingsMap<StoryRecordingTypes>);

  const savedChapters = await Promise.all(
    chapterDocuments.docs.map(chapterDocumentToChapter),
  );

  const chapters = savedChapters.reduce((sum, chapter) => {
    sum[chapter.id] = chapter;
    return sum;
  }, {} as ChapterMap);

  return {
    id: snapshot.id,
    owner,
    isChapterStory,
    isSaved: true,
    createdAt,
    title,
    author,
    recordings,
    chapters,
    ownerName: displayName,
    completed,
    editors: editors || [],
    invites: invites || [],
  };
}

async function loadUserName(userId: string): Promise<string | undefined> {
  const snapshot = await app
    .firestore()
    .doc(pathTo.db.user(userId))
    .get();

  if (!snapshot || !snapshot.exists) {
    return;
  }

  return snapshot.get("name");
}

async function loadEmail(userId: string): Promise<string | undefined> {
  const snapshot = await app
    .firestore()
    .doc(pathTo.db.user(userId))
    .get();

  if (!snapshot || !snapshot.exists) {
    return;
  }

  return snapshot.get("email");
}

// tslint:disable-next-line: max-func-body-length
export async function loadUserData(user: firebase.User): Promise<UserData> {
  const snapshot = await getUserDataRef(user.uid).get();

  const data = snapshot.data();

  let hasCompletedIntro: boolean;
  let hasSeenFirstChapterModal: boolean;
  let hasCompletedGreetings: boolean;
  let hasSeenUploadModal: boolean;
  let editors: string[];
  let invites: Invite[];
  let email: string;

  if (data) {
    hasCompletedIntro = data.hasCompletedIntro;
    hasSeenFirstChapterModal = data.hasSeenFirstChapterModal;
    hasCompletedGreetings = data.hasCompletedGreetings;
    hasSeenUploadModal =
      data.hasSeenUploadModal === undefined ? false : data.hasSeenUploadModal;
    editors = data.editors;
    invites = data.invites;
    email = data.email;

    if (typeof hasCompletedIntro !== "boolean") {
      throw new Error(
        `Expected data.hasCompletedIntro to be a boolean, but '${hasCompletedIntro}' was a '${typeof hasCompletedIntro}'.`,
      );
    }

    if (typeof hasSeenFirstChapterModal !== "boolean") {
      throw new Error(
        `Expected data.hasSeenFirstChapterModal to be a boolean, but '${hasSeenFirstChapterModal}' was a '${typeof hasSeenFirstChapterModal}'.`,
      );
    }

    if (typeof hasSeenUploadModal !== "boolean") {
      throw new Error(
        `Expected data.hasSeenUploadModal to be a boolean, but '${hasSeenUploadModal}' was a '${typeof hasSeenUploadModal}'.`,
      );
    }

    if (typeof hasCompletedGreetings !== "boolean") {
      throw new Error(
        `Expected data.hasCompletedGreetings to be a boolean, but '${hasCompletedGreetings}' was a '${typeof hasCompletedGreetings}'.`,
      );
    }

    if (!Array.isArray(editors)) {
      throw new Error(
        `Expected data.editors to be an array, but '${editors}' was a '${typeof editors}'.`,
      );
    }

    editors.forEach((editor, i) => {
      if (typeof editor !== "string") {
        throw new Error(
          `Expected data[${i}].editor to be a firebase user id (string), but '${editor}' was a '${typeof editor}'.`,
        );
      }
    });

    if (!Array.isArray(invites)) {
      throw new Error(
        `Expected data.invites to be an array, but '${invites}' was a '${typeof invites}'.`,
      );
    }

    // Remove this for now because previously sent invites will be a string
    // invites.forEach((invite, i) => {
    //   if (typeof invite !== "object") {
    //     throw new Error(
    //       `Expected data[${i}].invite to be an object, but '${invite}' was a '${typeof invite}'.`,
    //     );
    //   }
    // });

    invites = invites.reduce((result: Invite[], invite: Invite) => {
      // check if 48hrs (in milliseconds) has elapsed
      if (Date.now() - Number(invite.time) < 172800000) {
        result.push(invite);
      } else {
        removeInvite(user, invite);
      }
      return result;
    }, []);

    const editorNames = await editors.reduce(async (sumPromise, userId) => {
      const sum = await sumPromise;

      const userName = await loadUserName(userId);

      if (userName) {
        sum[userId] = userName;
      }

      return sum;
    }, Promise.resolve({} as { [userId: string]: string }));

    const editorEmails = await editors.reduce(async (sumPromise, userId) => {
      const sum = await sumPromise;

      const editorEmail = await loadEmail(userId);

      if (editorEmail) {
        sum[userId] = editorEmail;
      }

      return sum;
    }, Promise.resolve({} as { [userId: string]: string }));

    const myStoriesRefs = await app
      .firestore()
      .collection(pathTo.db.storiesCollection())
      .where("owner", "==", user.uid)
      .get();

    const myStories = await Promise.all(
      myStoriesRefs.docs.map(storyDocumentToStory),
    );

    editors = editors.reduce((result: string[], editorId: string) => {
      myStories.forEach(story => {
        if (story.editors && story.editors.includes(editorId) && !result.includes(editorId)) {
          result.push(editorId);
        }
      });

      return result;
    }, []);

    return {
      hasCompletedIntro,
      hasSeenFirstChapterModal,
      hasCompletedGreetings,
      hasSeenUploadModal,
      editors,
      editorNames,
      editorEmails,
      invites,
      email,
    };
  }

  return createUserData(user);
}

export async function loadStories(user: firebase.User): Promise<Stories> {
  const myStoriesRefs = await app
    .firestore()
    .collection(pathTo.db.storiesCollection())
    .where("owner", "==", user.uid)
    .get();

  const myStories = await Promise.all(
    myStoriesRefs.docs.map(storyDocumentToStory),
  );

  const usersThatShareWithMe = await app
    .firestore()
    .collection(pathTo.db.usersCollection())
    .where("editors", "array-contains", user.uid)
    .get();

  const storiesSharedWithMe = await usersThatShareWithMe.docs.reduce(
    async sumPromise => {
      const sum = await sumPromise;

      const storiesForUserRefs = await app
        .firestore()
        .collection(pathTo.db.storiesCollection())
        .where("editors", "array-contains", user.uid)
        .get();

      const storiesForUser = await Promise.all(
        storiesForUserRefs.docs.map(storyDocumentToStory),
      );

      return sum.concat(storiesForUser);
    },
    Promise.resolve([] as SavedStory[]),
  );

  return {
    myStories: myStories.reduce(
      (sum, story) => {
        sum[story.id] = story;
        return sum;
      },
      {} as {
        [key: string]: SavedStory;
      },
    ),

    storiesSharedWithMe: storiesSharedWithMe.reduce(
      (sum, story) => {
        sum[story.id] = story;
        return sum;
      },
      {} as {
        [key: string]: SavedStory;
      },
    ),
  };
}

function recordingDocumentToRecording<T extends string>(
  snapshot:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot,
  validTypes: T[],
): Recording {
  const data = snapshot.data();

  if (!data) {
    throw new Error("recordingDocument did not have data.");
  }

  const id: T | undefined = validTypes.find(t => t === snapshot.id);

  if (!id) {
    throw new Error(
      `${snapshot.id} was not in type list "${validTypes.join(", ")}"`,
    );
  }

  const { audio, duration } = data;

  if (!Array.isArray(data.points)) {
    data.points = [];
    // throw new Error(
    //   `Expected recording.points to be an array, but '${points}' was a '${typeof points}'.`,
    // );
  }

  const rawPoints: Array<{ x: number; y: number }> = data.points.map(
    (p: any, i: number) => {
      if (typeof p.x !== "number") {
        throw new Error(
          `Expected recording.points[${i}].x to be a number, but '${p}' was a '${typeof p}'.`,
        );
      }

      if (typeof p.y !== "number") {
        throw new Error(
          `Expected recording.points[${i}].y to be a number, but '${p}' was a '${typeof p}'.`,
        );
      }

      return { x: p.x, y: p.y };
    },
  );

  if (typeof audio !== "string") {
    throw new Error(
      `Expected recording.audio to be a string, but '${audio}' was a '${typeof audio}'.`,
    );
  }

  if (typeof duration !== "number") {
    throw new Error(
      `Expected recording.duration to be a number, but '${duration}' was a '${typeof duration}'.`,
    );
  }

  const points = rawPoints.map(({ x, y }) => Point.get(x, y).finish());

  return {
    points,
    audio,
    duration,
    isUpdated: false,
  };
}

export async function loadGreetings(
  user: firebase.User,
): Promise<RecordingsMap<GreetingTypes>> {
  const greetings = await app
    .firestore()
    .collection(pathTo.db.greetingsCollection(user.uid))
    .get();

  return greetings.docs.reduce((sum, snapshot) => {
    const id = snapshot.id as GreetingTypes;
    sum[id] = recordingDocumentToRecording(snapshot, All_GREETING_TYPES);
    return sum;
  }, emptyGreetingsRecordings);
}

export async function isStoryEmpty(storyId: string) {
  const chaptersCollection = await app
    .firestore()
    .collection(pathTo.db.chaptersCollection(storyId))
    .get();

  if (chaptersCollection.size === 1) {
    const chapterRecording = await app
      .firestore()
      .collection(
        pathTo.db.chapterRecordingsCollection(
          storyId,
          chaptersCollection.docs[0].id,
        ),
      )
      .doc("audio")
      .get();

    const data = chapterRecording.data();

    if (!data) {
      return true;
    }
  }
  return false;
}
