import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { DeleteChapter, EditTitle } from "../actions";
import { Shared } from "../types";
import EditingTitle from "./EditingTitle";
import ShowConfirmDeleteModal from "./ShowConfirmDeleteModal";

function Entry(
  action: Enter | DeleteChapter | EditTitle,
  shared: Shared,
): StateReturn {
  switch (action.type) {
    case "Enter":
      return noop();

    case "DeleteChapter":
      return ShowConfirmDeleteModal(shared);

    case "EditTitle":
      return EditingTitle(shared);
  }
}

export default state("Entry", Entry);
