import { Enter, eventually, Exit, state, StateReturn } from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import { acknowledge, Acknowledge } from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";

function Submitting(
  action: Enter | Acknowledge | Exit,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { email, storiesToShare } = shared;

  switch (action.type) {
    case "Enter":
      const onAcknowledge = eventually(acknowledge);

      return [
        AppState.sendInvite(email, storiesToShare),
        onAcknowledge,
        AppState.showAcknowledge({
          text: t("invite.acknowledge.text", { email }),
          acknowledgeText: t("button.done"),
          onAcknowledge,
        }),
      ];

    case "Acknowledge":
      shared.email = "";

      return Entry(shared);

    case "Exit":
      return AppState.hideModal();
  }
}

export default state("Submitting", Submitting);
