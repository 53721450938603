import {
  Enter,
  eventually,
  Exit,
  state,
  StateReturn,
  task,
} from "@druyan/druyan";
import { pathTo } from "@storytime/shared/build/pathTo";
import { concatAudio, convertAudioChapter, uploadAudio } from "../../../../api";
import { t } from "../../../../i18n";
import { Recording } from "../../../../state";
import { Actions as AppState } from "../../../../state";
import {
  bufferToBlob,
  generatorSingleton,
  objectURLToBlob,
  processAudioBuffer,
} from "../../../../utils";
import {
  cancelRecording,
  CancelRecording,
  deleteRecording,
  finishedProcessing,
  FinishedProcessing,
  ShowWarning,
  showWarning,
  startedProcessing,
  StartedProcessing,
} from "../actions";
import { Shared } from "../types";
import Empty from "./Empty";
import RecordedUnplayed from "./RecordedUnplayed";

function alreadyHasAudio(recording?: Recording): recording is Recording {
  return !!(recording && recording.audio);
}

function ProcessingRecording(
  action:
    | Enter
    | StartedProcessing
    | FinishedProcessing
    | Exit
    | ShowWarning
    | CancelRecording,
  shared: Shared,
  recording: Recording,
  recordedAudio: Blob,
): StateReturn | StateReturn[] {
  const { story, chapter, audioContext } = shared;

  switch (action.type) {
    case "Enter":
      return [
        AppState.showProcessingIndicator([
          t("processing_indicator.sprinkling"),
          t("processing_indicator.mixing"),
          t("processing_indicator.saving"),
          t("processing_indicator.tucking"),
        ]),
        startedProcessing(),
      ];

    case "StartedProcessing":
      return task(async () => {
        try {
          if (alreadyHasAudio(recording)) {
            const originalAudio = await objectURLToBlob(recording.audio);

            const file = await concatFiles(
              story.id,
              chapter.id,
              originalAudio,
              recordedAudio!,
            );
            return finishedProcessing(file);
          }

          return finishedProcessing(
            await convertFileToMp3(story.id, chapter.id, recordedAudio!),
          );
        } catch (error) {
          return [AppState.hideModal(), showWarning(error)];
        }
      });

    case "FinishedProcessing":
      return task(async () => {
        // Get the waveform and duration from the buffer.
        const { points, duration } = await processAudioBuffer(
          action.buffer,
          audioContext,
        );

        // Update global state.
        return RecordedUnplayed(shared, {
          // URL for the <audio> src.
          audio: URL.createObjectURL(bufferToBlob(action.buffer)),
          isUpdated: true,
          duration,
          points,
        });
      });

    case "ShowWarning":
      const onAcknowledge = eventually(cancelRecording);
      return [
        onAcknowledge,
        AppState.showAcknowledge({
          text: t("audio_recording_error.text"),
          acknowledgeText: t("global.okay"),
          onAcknowledge,
        }),
      ];

    case "CancelRecording":
      return task(async () => {
        if (generatorSingleton.isRunning) {
          generatorSingleton.stop();
        }

        deleteRecording();
        Empty(shared);

        return [AppState.hideModal(), Empty(shared)];
      });

    case "Exit":
      return AppState.hideModal();
  }
}

async function concatFiles(
  storyId: string,
  chapterId: string,
  originalAudio: Blob,
  toBeConcattedAudio: Blob,
): Promise<ArrayBuffer> {
  // tslint:disable-next-line: no-console
  console.time("Uploading copy of original chapter recording");

  await uploadAudio(
    pathTo.storage.copyOfOriginalChapterRecording(storyId, chapterId),
    originalAudio,
  );

  // tslint:disable-next-line: no-console
  console.timeEnd("Uploading copy of original chapter recording");

  // tslint:disable-next-line: no-console
  console.time("Uploading to be concatted chapter recording");

  await uploadAudio(
    pathTo.storage.toBeConcattedChapterRecording(storyId, chapterId),
    toBeConcattedAudio,
  );

  // tslint:disable-next-line: no-console
  console.timeEnd("Uploading to be concatted chapter recording");

  // tslint:disable-next-line: no-console
  console.time("Remotely concatting audio");

  try {
    const {
      data: { publicUrl },
    } = await concatAudio({
      storyId,
      chapterId,
      originalMimeType: originalAudio.type,
      toBeConcattedMimeType: toBeConcattedAudio.type,
    });

    // tslint:disable-next-line: no-console
    console.timeEnd("Remotely concatting audio");

    // tslint:disable-next-line: no-console
    console.time("Fetching converted file");

    const buffer = fetch(publicUrl).then(r => r.arrayBuffer());

    // tslint:disable-next-line: no-console
    console.timeEnd("Fetching converted file");

    return buffer;
  } catch (error) {
    throw new Error();
  }
}

async function convertFileToMp3(
  storyId: string,
  chapterId: string,
  audio: Blob,
): Promise<ArrayBuffer> {
  // tslint:disable-next-line: no-console
  console.time("Uploading unconverted chapter recording");

  // Upload to storage
  await uploadAudio(
    pathTo.storage.unconvertedChapterRecording(storyId, chapterId),
    audio,
  );

  // tslint:disable-next-line: no-console
  console.timeEnd("Uploading unconverted chapter recording");

  // tslint:disable-next-line: no-console
  console.time("Remotely converting audio to mp3");

  const {
    data: { success, publicUrl },
  } = await convertAudioChapter(storyId, chapterId);

  // tslint:disable-next-line: no-console
  console.timeEnd("Remotely converting audio to mp3");

  if (!success) {
    throw new Error("convertAudioChapter failed");
  }

  // tslint:disable-next-line: no-console
  console.time("Fetching converted file");

  // Then finally download new file and continue.
  const buffer = fetch(publicUrl).then(r => r.arrayBuffer());

  // tslint:disable-next-line: no-console
  console.timeEnd("Fetching converted file");

  return buffer;
}
export default state("ProcessingRecording", ProcessingRecording);
