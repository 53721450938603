import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { Next, Previous } from "../actions";
import { Shared } from "../types";
import Instructions from "./Instructions";
import RecordGreeting from "./RecordGreeting";

function MicDisclaimer(
  action: Enter | Next | Previous,
  shared: Shared,
): StateReturn {
  switch (action.type) {
    case "Enter":
      return noop();

    case "Previous":
      return Instructions(shared);

    case "Next":
      return RecordGreeting(shared);
  }
}

export default state("MicDisclaimer", MicDisclaimer);
