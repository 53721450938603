import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { Recording as RecordingType } from "../../../../state";
import { DeleteRecording, Play } from "../actions";
import { Shared } from "../types";
import Empty from "./Empty";
import RecordedPlaying from "./RecordedPlaying";

function RecordedUnplayed(
  action: Enter | Play | DeleteRecording,
  shared: Shared,
  recording: RecordingType,
): StateReturn {
  const { onDelete } = shared;

  switch (action.type) {
    case "Enter":
      return noop();

    case "Play":
      return RecordedPlaying(shared, recording);

    case "DeleteRecording":
      onDelete();

      return Empty(shared);
  }
}

export default state("RecordedUnplayed", RecordedUnplayed);
