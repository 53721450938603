import { Action } from "@druyan/druyan";

export interface SelectedFile extends Action<"SelectedFile"> {
  buffer: ArrayBuffer;
}

export function selectedFile(buffer: ArrayBuffer): SelectedFile {
  return {
    type: "SelectedFile",
    buffer,
  };
}
