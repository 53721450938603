import { Action } from "@druyan/druyan";

export interface StartRecordingChapterTitle
  extends Action<"StartRecordingChapterTitle"> {}

export function startChapterTitleRecording(): StartRecordingChapterTitle {
  return {
    type: "StartRecordingChapterTitle",
  };
}
