import { Particles, random } from "@storytime/shared/build/components";
import React from "react";
import { ScalarGenerator } from "../../utils";
import Visualizer from "../Visualizer";
import "./Recorder.css";

const { random: randomNumber } = Math;

const PARTICLE_COLORS = [
  "#5d5273",
  "#f8656a",
  "#92e8c9",
  "#ffc2bd",
  "#dcab7c",
  "#bd72f2",
  "#ff9e7c",
  "#badae4",
  "#f7eaa2",
];

interface Props {
  width: number;
  height: number;
  generator: ScalarGenerator;
  className?: string;
}

export function Recorder({ width, height, generator, className }: Props) {
  const fullHeight = height * 4;
  const mid = fullHeight / 2;

  return (
    <div
      className={`Recorder ${className || ""}`}
      style={{ width: width + "px", height: fullHeight + "px" }}
    >
      <Visualizer
        className="Recorder_visualizer"
        color="#bd72f2"
        generator={generator}
        width={width}
        height={height}
      />

      <Particles
        className="Recorder_particles"
        width={width}
        height={fullHeight}
        emitters={[
          {
            initialSpawn: 0,
            wander: 0.5,
            frequency: 0.15,
            initialVelocity: random.numberPair([300, 400], [-50, 50]),
            location: random.numberPair(
              [0, 0],
              [mid - mid * 0.05, mid + mid * 0.05],
            ),
            acceleration: () => [
              0,
              randomNumber() * 50 * (randomNumber() > 0.5 ? 1 : -1),
            ],
            colors: PARTICLE_COLORS,
            startDyingAt: 0.7,
            spawnUntil: random.number(0.4, 0.6),
            lifetime: random.number(2.0, 3.25),
            radius: random.number(3, 6),
            friction: 0.75,
          },
        ]}
      />
    </div>
  );
}
