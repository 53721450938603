import React from "react";
import useKeyPressEvent from "react-use/lib/useKeyPressEvent";
import ChapterTitleInfo from "../../components/ChapterTitleInfo";
import ContentFrame from "../../components/ContentFrame";
import { Recording, SavedStory } from "../../state";
import "./EditChapterTitle.css";

interface Props {
  chapterTitle: string;
  canSubmit: boolean;
  onChangeTitle: (title: string) => void;
  onSubmit: () => void;
  onStartRecording: () => void;
  onSetRecording: (recording: Recording) => void;
  onDeleteRecording: () => void;
  playAudio: (
    audio: string,
    playHead?: number,
    onPlayback?: () => void,
  ) => void;
  pauseAudio: () => void;
  onBack: () => void;
  isIOS: boolean;
  isSubmitting: boolean;
  currentRecording?: Recording;
  story?: SavedStory;
}

export function EditChapterTitle({
  chapterTitle,
  canSubmit,
  onChangeTitle,
  onSubmit,
  onStartRecording,
  onSetRecording,
  onDeleteRecording,
  playAudio,
  pauseAudio,
  onBack,
  isIOS,
  isSubmitting,
  currentRecording,
  story,
}: Props) {
  useKeyPressEvent("Enter", onSubmit);

  return (
    <div>
      <ContentFrame>
        <div className="EditChapterTitle">
          <ChapterTitleInfo
            id="edit_chapter_title"
            story={story}
            title={chapterTitle}
            onChange={onChangeTitle}
            onStartRecording={onStartRecording}
            onSetRecording={onSetRecording}
            onDeleteRecording={onDeleteRecording}
            canSubmit={canSubmit}
            onSubmit={onSubmit}
            playAudio={playAudio}
            pauseAudio={pauseAudio}
            onBack={onBack}
            isIOS={isIOS}
            isSubmitting={isSubmitting}
            currentRecording={currentRecording}
          />
        </div>
      </ContentFrame>
    </div>
  );
}
