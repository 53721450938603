import {
  Enter,
  eventually,
  noop,
  state,
  StateReturn,
  task,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import { generatorSingleton } from "../../../../utils";
import {
  cancelRecording,
  CancelRecording,
  ShowWarning,
  StartRecording,
} from "../actions";
import { Shared } from "../types";
import StartingRecording from "./StartingRecording";

function Empty(
  action: Enter | StartRecording | ShowWarning | CancelRecording,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { pauseAudio, audioContext } = shared;

  switch (action.type) {
    case "Enter":
      pauseAudio();

      return noop();

    case "StartRecording":
      return StartingRecording(shared, undefined, action.stream, audioContext);

    case "ShowWarning":
      const onAcknowledge = eventually(cancelRecording);
      const text =
        action.error && action.error.message === "NotAllowedError"
          ? t("audio_recording_not_allowed.text")
          : t("audio_recording_error.text");
      return [
        onAcknowledge,
        AppState.showAcknowledge({
          text,
          acknowledgeText: t("global.okay"),
          onAcknowledge,
        }),
      ];

    case "CancelRecording":
      return task(async () => {
        if (generatorSingleton.isRunning) {
          generatorSingleton.stop();
        }
        return [AppState.hideModal(), reenter(shared)];
      });
  }
}

const EmptyState = state("Empty", Empty);
const { reenter } = EmptyState;
export default EmptyState;
