import { Action } from "@druyan/druyan";

export interface ConvertFile extends Action<"ConvertFile"> {
  file: File;
}

export function convertFile(file: File): ConvertFile {
  return {
    type: "ConvertFile",
    file,
  };
}
