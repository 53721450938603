import { Action } from "@druyan/druyan";

export interface SetEmail extends Action<"SetEmail"> {
  email: string;
}

export function setEmail(email: string): SetEmail {
  return {
    type: "SetEmail",
    email,
  };
}
