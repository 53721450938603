import { RecordingsMap } from "./recording";

export type GreetingTypes =
  | "greeting"
  | "selectStory"
  | "listStories"
  | "continueOrStart"
  | "confirmation"
  | "exit"
  | "invalidStory"
  | "leftOff"
  | "lastRecording"
  | "error";

export const All_GREETING_TYPES: GreetingTypes[] = [
  "greeting",
  "selectStory",
  "listStories",
  "continueOrStart",
  "confirmation",
  "exit",
  "invalidStory",
  "leftOff",
  "lastRecording",
  "error",
];

// All greetings are required for now.
export type RequiredGreetingTypes = GreetingTypes;
export const REQUIRED_GREETING_TYPES = All_GREETING_TYPES;

export interface Greetings {
  recordings: RecordingsMap<GreetingTypes>;
}

export const emptyGreetingsRecordings = All_GREETING_TYPES.reduce(
  (sum, type) => {
    sum[type] = null;
    return sum;
  },
  {} as RecordingsMap<GreetingTypes>,
);

export function initialGreetings(): Greetings {
  return {
    recordings: {
      greeting: null,
      selectStory: null,
      listStories: null,
      continueOrStart: null,
      confirmation: null,
      exit: null,
      invalidStory: null,
      leftOff: null,
      lastRecording: null,
    } as RecordingsMap<GreetingTypes>,
  };
}
