import React from "react";
import { useTranslation } from "react-i18next";
import "./Spinner.css";

interface Props {
  className?: string;
}

export function Spinner({ className }: Props) {
  const { t } = useTranslation();
  return (
    <div className={className ? `${className} Spinner` : "Spinner"}>
      <img
        className="Spinner__image"
        alt={t("spinner.loading")}
        src="/assets/spinner.svg"
      />
    </div>
  );
}
