import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import { Confirm, confirm, Reject, reject } from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";

function ShowingConfirmCancelRecordingModal(
  action: Enter | Confirm | Reject | Exit,
  shared: Shared,
  stopRecording: () => Promise<void>,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          text: t("confirm_cancel_recording_modal.text"),
          confirmText: t("global.yes"),
          rejectText: t("global.no"),
          onConfirm,
          onReject,
        }),
      ];

    case "Confirm":
      stopRecording();

      return Entry(shared);

    case "Reject":
      return goBack();

    case "Exit":
      return AppState.hideModal();
  }
}

export default state(
  "ShowingConfirmCancelRecordingModal",
  ShowingConfirmCancelRecordingModal,
);
