export function objectURLToBlob(url: string): Promise<Blob> {
  return fetch(url).then(r => r.blob());
}

export function blobToBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Response(blob).arrayBuffer();
}

export function bufferToBlob(
  buffer: ArrayBuffer,
  type: string = "audio/mpeg",
): Blob {
  return new Blob([buffer], {
    type,
  });
}

export function bufferToObjectURL(buffer: ArrayBuffer) {
  return URL.createObjectURL(bufferToBlob(buffer));
}

export function fileToArrayBuffer(file: File): Promise<ArrayBuffer> {
  let resolve: (r: ArrayBuffer) => void;
  // tslint:disable-next-line:promise-must-complete
  const promise = new Promise<ArrayBuffer>(r => (resolve = r));

  const reader = new FileReader();

  reader.onload = e => {
    resolve((e.target as any).result);
  };

  reader.readAsArrayBuffer(file);

  return promise;
}
