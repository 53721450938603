import React from "react";
import { ChapterListIsEditing, ChapterListStatic, Props } from "./ChapterList";

type PropsWithIsEditing = Props & {
  isEditing: boolean;
};

export default function ChapterList(props: PropsWithIsEditing) {
  const { isEditing, ...remainingProps } = props;

  return isEditing && props.story.isChapterStory ? (
    <ChapterListIsEditing {...remainingProps} />
  ) : (
    <ChapterListStatic {...remainingProps} />
  );
}
