import React from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { acceptInvite } from "../../api";
import Spinner from "../../components/Spinner";
import { useActions, useAuth } from "../../hooks";
import { history, routeTo } from "../../utils";

export default ({ location }: RouteComponentProps) => {
  const user = useAuth();
  const { reloadDataAfterInvite } = useActions();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (!user || !token) {
      history.replace(routeTo.dashboard());
      return;
    }

    acceptInvite(token, user.email!, user.displayName!)
      .then(() => {
        reloadDataAfterInvite();
        history.replace(routeTo.dashboard());
      })
      .catch(e => {
        if (e.message === "Invite already accepted.") {
          history.replace(routeTo.dashboard());
        } else {
          history.replace(routeTo.invalidInvite());
        }
      });
  }, [location.search, user, reloadDataAfterInvite]);

  return <Spinner />;
};
