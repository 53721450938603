import {
  BackButton,
  Dashboard,
  Download,
  Plus,
  PrimaryButton,
  TertiaryButton,
  TrashSmall,
} from "@storytime/shared/build/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import ChapterList from "../../components/ChapterList";
import ContentFrame from "../../components/ContentFrame";
import Footer from "../../components/Footer";
import { SavedChapter, SavedStory } from "../../state";
import { routeTo } from "../../utils";
import "./StoryDetail.css";

interface Props {
  story: SavedStory;
  isEditing: boolean;
  user?: firebase.User;
  isWideScreen: boolean;
  onEdit?: () => void;
  onStopEditing?: () => void;
  onDelete?: () => void;
  onRemoveShared?: () => void;
  onDownload?: (story: SavedStory) => void;
  onReorder?: (chaptersInOrder: SavedChapter[]) => void;
}

// tslint:disable-next-line:max-func-body-length
export function StoryDetail({
  story,
  isEditing,
  onEdit,
  onStopEditing,
  onDelete,
  onRemoveShared,
  onDownload,
  onReorder,
  user,
  isWideScreen,
}: Props) {
  const { t } = useTranslation();

  let displayName;
  const userId = user && user.uid;

  if (!user) {
    displayName = "Unknown";
  } else if (story.owner === user.uid) {
    displayName = t("you");
  } else {
    displayName = story.ownerName;
  }

  const chapters = Object.values(story.chapters).sort(
    (b, a) => b.order - a.order,
  ) as SavedChapter[];

  return (
    <div>
      <ContentFrame>
        <div className="StoryDetail">
          <div className="StoryDetail_wrapper">
            <div className="StoryDetail_heading">
              <h2 className="StoryDetail_title">{story.title}</h2>

              {isEditing ? (
                <TertiaryButton onClick={onStopEditing} filled={true}>
                  {t("button.done")}
                </TertiaryButton>
              ) : (
                <TertiaryButton
                  ariaControls="story-detail-actions"
                  onClick={onEdit}
                >
                  {t("button.edit")}
                </TertiaryButton>
              )}
              <span className="StoryDetail_owner">
                Created by {displayName}
              </span>
            </div>

            {isEditing && story.owner === userId ? (
              <div className="StoryDetail_actions" id="story-detail-actions">
                <TertiaryButton
                  icon={<Download />}
                  // tslint:disable-next-line:react-this-binding-issue
                  onClick={() => {
                    if (onDownload) {
                      onDownload(story);
                    }
                  }}
                >
                  {t("button.download_story")}
                </TertiaryButton>

                <TertiaryButton icon={<TrashSmall />} onClick={onDelete}>
                  {t("button.delete_story")}
                </TertiaryButton>
              </div>
            ) : (
              undefined
            )}

            {isEditing && story.owner !== userId ? (
              <div className="StoryDetail_actions" id="story-detail-actions">
                <TertiaryButton icon={<TrashSmall />} onClick={onRemoveShared}>
                  {t("button.remove_shared_story")}
                </TertiaryButton>
              </div>
            ) : (
              undefined
            )}

            <ChapterList
              story={story}
              chapters={chapters}
              isEditing={isEditing}
              user={user}
              onReorder={onReorder}
            />

            {story.isChapterStory && !isEditing ? (
              <Link
                className="add-chapter-row"
                to={routeTo.createChapter(story.id)}
              >
                <div className="add-chapter-button">
                  <Plus />
                  <span className="add-chapter-label">
                    {t("action.add_chapter")}
                  </span>
                </div>
              </Link>
            ) : (
              undefined
            )}
          </div>
        </div>
      </ContentFrame>

      <Footer
        className="StoryDetail_footer"
        leftButton={
          <BackButton
            type={"secondary"}
            linkTo={routeTo.dashboard()}
            disabled={isEditing}
          >
            {t("button.back")}
          </BackButton>
        }
        rightButton={
          <PrimaryButton
            linkTo={routeTo.dashboard()}
            disabled={isEditing}
            icon={<Dashboard />}
            iconPosition="right"
          >
            {isWideScreen
              ? t("button.my_stories")
              : t("button.my_stories_short")}
          </PrimaryButton>
        }
      />
    </div>
  );
}
