export const routeTo = {
  dashboard: () => `/`,
  error: () => `/error`,
  notFound: () => `/not-found`,
  invalidInvite: () => `/invalid-invite`,
  setupInstructions: () => `/setup-instructions`,
  settings: () => `/account-settings`,
  invite: () => `/invite`,
  greetings: () => `/greetings`,
  acceptInvite: () => `/accept-invite`,
  createStory: () => `/stories/create`,
  editStory: (storyId: string) => `/stories/${storyId}/edit`,
  storyDetail: (storyId: string) => `/stories/${storyId}`,
  storyDetailEdit: (storyId: string, editMode: string) =>
    `/stories/${storyId}/${editMode}`,
  createChapter: (storyId: string) => `/stories/${storyId}/chapters/create`,
  chapterDetail: (storyId: string, chapterId: string) =>
    `/stories/${storyId}/chapters/${chapterId}`,
  editChapter: (storyId: string, chapterId: string) =>
    `/stories/${storyId}/chapters/${chapterId}/edit`,
  editChapterTitle: (storyId: string, chapterId: string) =>
    `/stories/${storyId}/chapters/${chapterId}/edit-title`,
};
