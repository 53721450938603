import {
  DeleteButtonUnfilled,
  PlayButtonUnfilled,
} from "@storytime/shared/build/components";
import React, { useEffect } from "react";
import { useWindowSize } from "react-use";
import { ScalarGenerator } from "../../utils";
import RecordButton from "../RecordButton";
import Spinner from "../Spinner";
import { Point } from "../Visualizer";
import "./RecordingInputIOS.css";

const { floor } = Math;

const DESKTOP_BP = 768;

function renderDuration(duration: number) {
  if (duration <= 0) {
    duration = 0;
  }

  const minutes = floor(duration / 60);

  let seconds = floor(duration % 60).toString();

  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

export interface EmptyProps {
  startRecording: () => void;
  customMobile?: boolean;
  isAwaitingUserStream: boolean;
}

export function DefaultEmptyRecordingInput({
  startRecording,
  isAwaitingUserStream,
}: EmptyProps) {
  return (
    <div className="RecordingInputIOS">
      <div className="RecordingInputIOS_buttons">
        {isAwaitingUserStream ? (
          <Spinner className="small" />
        ) : (
          <RecordButton onClick={startRecording} size="small" active={false} />
        )}
        <div className="RecordingInputIOS_duration">{renderDuration(0)}</div>
      </div>
    </div>
  );
}

export function CustomMobileEmptyRecordingInput({
  startRecording,
}: EmptyProps) {
  return (
    <div className="CustomRecordingInputIOS">
      <div className="RecordingInputIOS">
        <div className="RecordingInputIOS_duration">{renderDuration(0)}</div>
      </div>
      <RecordButton onClick={startRecording} size="large" active={false} />
    </div>
  );
}

export function EmptyRecordingInput({
  startRecording,
  customMobile,
  isAwaitingUserStream,
}: EmptyProps) {
  const { width } = useWindowSize();
  return width < DESKTOP_BP && customMobile ? (
    <CustomMobileEmptyRecordingInput
      isAwaitingUserStream={isAwaitingUserStream}
      startRecording={startRecording}
    />
  ) : (
    <DefaultEmptyRecordingInput
      isAwaitingUserStream={isAwaitingUserStream}
      startRecording={startRecording}
    />
  );
}

export interface RecordingProps {
  generator: ScalarGenerator;
  stopRecording: () => void;
  duration: number;
  customWidth?: number;
  customMobile?: boolean;
}

export function DefaultRecordingRecordingInput({
  generator,
  stopRecording,
  duration,
}: RecordingProps) {
  return (
    <div className="RecordingInputIOS">
      <div className="RecordingInputIOS_buttons">
        <RecordButton
          onClick={stopRecording}
          size="small"
          active={true}
          generator={generator}
        />

        <div className="RecordingInputIOS_duration">
          {renderDuration(duration)}
        </div>
      </div>
    </div>
  );
}

export function CustomMobileRecordingRecordingInput({
  generator,
  stopRecording,
  duration,
}: RecordingProps) {
  return (
    <div className="CustomRecordingInputIOS">
      <div className="RecordingInputIOS">
        <div className="RecordingInputIOS_duration">
          {renderDuration(duration)}
        </div>
      </div>
      <RecordButton
        onClick={stopRecording}
        size="large"
        active={true}
        generator={generator}
      />
    </div>
  );
}

export function RecordingRecordingInput({
  generator,
  stopRecording,
  duration,
  customMobile,
}: RecordingProps) {
  const { width } = useWindowSize();

  return width < DESKTOP_BP && customMobile ? (
    <CustomMobileRecordingRecordingInput
      generator={generator}
      stopRecording={stopRecording}
      duration={duration}
    />
  ) : (
    <DefaultRecordingRecordingInput
      generator={generator}
      stopRecording={stopRecording}
      duration={duration}
    />
  );
}

export interface PlayingProps {
  points: Point[];
  pause: () => void;
  trash: () => void;
  playPercentage: number;
  onUnmount?: () => void;
  duration: number | 0;
  customMobile?: boolean;
}

export function DefaultPlayingRecordingInput({
  pause,
  trash,
  onUnmount,
  duration,
  playPercentage,
}: PlayingProps) {
  const containerRef = React.createRef<HTMLDivElement>();

  // Pause on unmount (aka leaving the page).
  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  return (
    <div className="RecordingInputIOS" ref={containerRef}>
      <div className="RecordingInputIOS_buttons  RecordingInputIOS__paused">
        <PlayButtonUnfilled onClick={pause} size="small" active={true} />
        <DeleteButtonUnfilled onClick={trash} size="small" />
        <div className="RecordingInputIOS_duration">
          {renderDuration(playPercentage * duration)}
        </div>
      </div>
    </div>
  );
}

export function CustomMobilePlayingRecordingInput({
  pause,
  trash,
  onUnmount,
  duration,
  playPercentage,
}: PlayingProps) {
  const containerRef = React.createRef<HTMLDivElement>();

  // Pause on unmount (aka leaving the page).
  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  return (
    <div className="CustomRecordingInputIOS">
      <div className="RecordingInputIOS" ref={containerRef}>
        <div className="RecordingInputIOS_duration">
          {renderDuration(playPercentage * duration)}
        </div>
      </div>

      <div className="RecordingInputIOS_buttons">
        <DeleteButtonUnfilled onClick={trash} size="small" />
        <PlayButtonUnfilled onClick={pause} size="small" active={true} />
      </div>
    </div>
  );
}

export function PlayingRecordingInput({
  points,
  playPercentage,
  pause,
  trash,
  onUnmount,
  duration,
  customMobile,
}: PlayingProps) {
  const { width } = useWindowSize();

  // Pause on unmount (aka leaving the page).
  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  return width < DESKTOP_BP && customMobile ? (
    <CustomMobilePlayingRecordingInput
      points={points}
      pause={pause}
      trash={trash}
      playPercentage={playPercentage}
      duration={duration}
    />
  ) : (
    <DefaultPlayingRecordingInput
      points={points}
      pause={pause}
      trash={trash}
      playPercentage={playPercentage}
      duration={duration}
    />
  );
}

export interface PausedProps {
  points: Point[];
  playPercentage: number;
  play: () => void;
  trash: () => void;
  duration: number | 0;
  customMobile?: boolean;
}

export function DefaultPausedRecordingInput({
  play,
  trash,
  duration,
}: PausedProps) {
  const containerRef = React.createRef<HTMLDivElement>();

  return (
    <div className="RecordingInputIOS" ref={containerRef}>
      <div className="RecordingInputIOS_buttons RecordingInputIOS__paused">
        <PlayButtonUnfilled onClick={play} size="small" />
        <DeleteButtonUnfilled onClick={trash} size="small" />
        <div className="RecordingInputIOS_duration">
          {renderDuration(duration)}
        </div>
      </div>
    </div>
  );
}

export function CustomMobilePausedRecordingInput({
  play,
  trash,
  duration,
}: PausedProps) {
  const containerRef = React.createRef<HTMLDivElement>();

  return (
    <div className="CustomRecordingInputIOS">
      <div className="RecordingInputIOS" ref={containerRef}>
        <div className="RecordingInputIOS_duration">
          {renderDuration(duration)}
        </div>
      </div>
      <div className="RecordingInputIOS_buttons">
        <DeleteButtonUnfilled onClick={trash} size="small" />
        <PlayButtonUnfilled onClick={play} size="small" />
      </div>
    </div>
  );
}
export function PausedRecordingInput({
  points,
  playPercentage,
  play,
  trash,
  duration,
  customMobile,
}: PausedProps) {
  const { width } = useWindowSize();

  return width < DESKTOP_BP && customMobile ? (
    <CustomMobilePausedRecordingInput
      points={points}
      playPercentage={playPercentage}
      play={play}
      trash={trash}
      duration={duration}
    />
  ) : (
    <DefaultPausedRecordingInput
      points={points}
      playPercentage={playPercentage}
      play={play}
      trash={trash}
      duration={duration}
    />
  );
}
