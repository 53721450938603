import { useCallback, useState } from "react";
import { Recording, RecordingsMap } from "../state";

export function useRecorder(
  initialRecording: Recording | undefined,
  onRecorded?: (r: Recording) => void,
) {
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recording, setRecording] = useState<Recording | undefined>(
    initialRecording,
  );
  const isRecorded = !!recording;

  function startRecording() {
    setIsRecording(true);
  }

  function finishRecording(r: Recording) {
    setIsRecording(false);
    setRecording(r);

    if (onRecorded) {
      onRecorded(r);
    }
  }

  return {
    startRecording,
    finishRecording,
    isRecording,
    recording,
    isRecorded,
  };
}

export function useRecorders<T extends string>(
  initialRecordings: RecordingsMap<T>,
) {
  const [isRecording, setIsRecording] = useState<T | null>(null);
  const [recordings, setRecordings] = useState<RecordingsMap<T>>(
    initialRecordings,
  );

  const startRecording = useCallback((type: T) => {
    setIsRecording(type);
  }, []);

  const onStartRecording = useCallback(
    (type: T) => {
      return () => startRecording(type);
    },
    [startRecording],
  );

  const finishRecording = useCallback(
    (type: T, recording: Recording) => {
      setIsRecording(null);
      setRecordings({
        ...recordings,
        [type]: recording,
      });
    },
    [recordings],
  );

  const onFinishRecording = useCallback(
    (type: T) => {
      return (r: Recording) => finishRecording(type, r);
    },
    [finishRecording],
  );

  return {
    isRecording,
    recordings,
    startRecording,
    onStartRecording,
    finishRecording,
    onFinishRecording,
  };
}
