import { Action } from "@druyan/druyan";

export interface ReloadDataAfterInvite
  extends Action<"App/ReloadDataAfterInvite"> {}

export function reloadDataAfterInvite(): ReloadDataAfterInvite {
  return {
    type: "App/ReloadDataAfterInvite",
  };
}
