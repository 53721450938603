import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { StartRecording } from "../actions";
import { Shared } from "../types";
import StartingRecording from "./StartingRecording";

function Empty(action: Enter | StartRecording, shared: Shared): StateReturn {
  const { pauseAudio, audioContext } = shared;

  switch (action.type) {
    case "Enter":
      pauseAudio();

      return noop();

    case "StartRecording":
      return StartingRecording(shared, undefined, action.stream, audioContext);
  }
}

export default state("Empty", Empty);
