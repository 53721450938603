import React from "react";
import { t } from "../../i18n";
import { GoogleHomeIllustration } from "../Images";
import "./GoogleHomeTip.css";

export function GoogleHomeTip() {
  return (
    <div className="GoogleHomeTip">
      <h4 className="hide-text">{t("google_home_tip.headline")}</h4>
      <p className="tip-text">
        {t("google_home_tip.tip")}
        <span className="emphasized-text">
          {t("google_home_tip.emphasised")}
        </span>
      </p>
      <div className="illustration">
        <GoogleHomeIllustration />
      </div>
    </div>
  );
}
