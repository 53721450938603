import { createDruyanContext } from "@druyan/druyan-react";
import { StateContext as AppState } from "../../../state";
import * as Actions from "./actions";
import States from "./states";

export { States };

export const StateContext = createDruyanContext(States, Actions, {
  restartOnInitialStateChange: true,
  parent: AppState,
});
