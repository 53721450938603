import { Action } from "@druyan/druyan";

export interface SetDoesStoryHaveChapters
  extends Action<"SetDoesStoryHaveChapters"> {
  doesStoryHaveChapters: string;
}

export function setDoesStoryHaveChapters(
  doesStoryHaveChapters: string,
): SetDoesStoryHaveChapters {
  return {
    type: "SetDoesStoryHaveChapters",
    doesStoryHaveChapters,
  };
}
