import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Actions as AppState, SavedStory } from "../../../../state";
import { confirm, Confirm, reject, Reject } from "../actions";
import { Shared } from "../types";

function ConfirmingEditorFromStoryRemoval(
  action: Enter | Confirm | Reject | Exit,
  shared: Shared,
  userIdToRemove: string,
  story: SavedStory,
): StateReturn | StateReturn[] {
  const { editorNames } = shared;

  switch (action.type) {
    case "Enter":
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);
      const modalText = (story.isChapterStory) ? t("delete_editor_from_story_modal.text_chapter", {
        name: editorNames[userIdToRemove],
        title: story.title,
      }) : t("delete_editor_from_story_modal.text_nonchapter", {
        name: editorNames[userIdToRemove],
        title: story.title,
      });

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          text: modalText,
          confirmText: t("global.yes"),
          rejectText: t("global.no"),
          onConfirm,
          onReject,
        }),
      ];

    case "Reject":
      return goBack();

    case "Confirm":
      return [
        AppState.removeEditorFromStory(userIdToRemove, story.id),
        goBack(),
      ];

    case "Exit":
      return AppState.hideModal();
  }
}

export default state(
  "ConfirmingEditorFromStoryRemoval",
  ConfirmingEditorFromStoryRemoval,
);
