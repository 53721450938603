import { config } from "@storytime/shared/build/config";
import "firebase/analytics";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";

export const app = firebase.initializeApp(config.firebase);

firebase.performance();
export const analytics = firebase.analytics();

export function signOut() {
  firebase.auth().signOut();
}

export function getFirebaseToken() {
  const user = firebase.auth().currentUser;
  return user!.getIdToken(/* forceRefresh */ true);
}

export function authorize(): Promise<firebase.User | null> {
  return new Promise(resolve => firebase.auth().onAuthStateChanged(resolve));
}
