import Entry from "./Entry";
import Initializing from "./Initializing";
import Recording from "./Recording";
import RecordingChapterTitle from "./RecordingChapterTitle";
import RecordingGreetings from "./RecordingGreetings";
import Submitting from "./Submitting";

export default {
  Entry,
  Submitting,
  Recording,
  Initializing,
  RecordingGreetings,
  RecordingChapterTitle,
};
