import { RecordingsMap } from "./recording";

export type ChapterRecordingTypes = "audio" | "title";

export const All_CHAPTER_TYPES: ChapterRecordingTypes[] = ["audio", "title"];

export const emptyChapterRecordings = All_CHAPTER_TYPES.reduce((sum, type) => {
  sum[type] = null;
  return sum;
}, {} as RecordingsMap<ChapterRecordingTypes>);

interface BaseChapter {
  id: string;
  title: string;
  recordings: RecordingsMap<ChapterRecordingTypes>;
  order: number;
  owner: string;
}

export interface UnsavedRecordedChapter extends BaseChapter {
  id: "unsaved";
  isSaved: false;
  isRecorded: true;
}

export interface SavedRecordedChapter extends BaseChapter {
  isSaved: true;
  owner: string;
  ownerName: string;
  isRecorded: true;
}

export interface UnsavedUnrecordedChapter extends BaseChapter {
  id: "unsaved";
  isSaved: false;
  isRecorded: false;
}

export interface SavedUnrecordedChapter extends BaseChapter {
  isSaved: true;
  owner: string;
  ownerName: string;
  isRecorded: false;
}

export type SavedChapter = SavedUnrecordedChapter | SavedRecordedChapter;
export type UnsavedChapter = UnsavedUnrecordedChapter | UnsavedRecordedChapter;
export type Chapter = SavedChapter | UnsavedChapter;

export interface ChapterMap {
  [key: string]: Chapter;
}

export function emptyChapter(): UnsavedUnrecordedChapter {
  return {
    id: "unsaved",
    order: 0,
    isRecorded: false,
    isSaved: false,
    title: "",
    recordings: emptyChapterRecordings,
    owner: "userId",
  };
}
