function easeOutBack(pos: number) {
  const s = 1.70158;
  return (pos = pos - 1) * pos * ((s + 1) * pos + s) + 1;
}

export class Point {
  static pool: Point[] = [];

  static get(x: number, y: number): Point {
    let p = Point.pool.pop();
    p = p || new Point();
    return p.clear(x, y);
  }

  static return(p: Point) {
    Point.pool.push(p);
  }

  y = 0;
  yTime = 0;
  targetY = 0;
  x = 0;

  clear(x: number, y: number) {
    this.x = x;
    this.targetY = y;
    this.y = 0;
    this.yTime = 0;

    return this;
  }

  finish() {
    this.y = this.targetY;
    this.yTime = 1;

    return this;
  }

  tick(delta: number) {
    this.x += delta;

    if (this.yTime < 1) {
      this.yTime += 0.3 * delta;
      this.y = this.targetY * easeOutBack(this.yTime);
    }
  }
}
