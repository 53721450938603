import {
  CancelButton,
  DeleteButton,
  PlayButton,
  PrimaryButton,
  Save,
  UploadButton,
} from "@storytime/shared/build/components";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import useWindowSize from "react-use/lib/useWindowSize";
import ContentFrame from "../../components/ContentFrame";
import Footer from "../../components/Footer";
import RecordButton from "../../components/RecordButton";
import Recorder from "../../components/Recorder";
import Spinner from "../../components/Spinner";
import Visualizer, {
  Point,
  StaticVisualizer,
} from "../../components/Visualizer";
import { Chapter, Recording, SavedStory } from "../../state";
import { ScalarGenerator } from "../../utils";
import "./RecordChapter.css";

const { floor } = Math;
const VISUALIZER_HEIGHT = 100;

function renderDuration(duration: number) {
  if (duration <= 0) {
    duration = 0;
  }

  const minutes = floor(duration / 60);

  let seconds = floor(duration % 60).toString();

  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

interface HeadlineProps {
  story: SavedStory;
  chapter: Chapter;
}

function Headline({ story, chapter }: HeadlineProps) {
  return story.isChapterStory ? (
    <div className="RecordChapter_headline-container">
      <h2 className="RecordChapter_subheadline">{story.title}</h2>
      <h1 className="RecordChapter_headline">{chapter.title}</h1>
    </div>
  ) : (
    <div className="RecordChapter_headline-container">
      <h1 className="RecordChapter_headline">{story.title}</h1>
    </div>
  );
}

interface EmptyProps {
  story: SavedStory;
  chapter: Chapter;
  isWideScreen: boolean;
  startRecording: () => void;
  startSelectFile?: () => void;
  selectFile?: (file: File) => void;
  isIOS: boolean;
  isAwaitingUserStream: boolean;
  cancel: () => void;
}

export function EmptyRecordChapter({
  story,
  chapter,
  startRecording,
  selectFile,
  startSelectFile,
  isWideScreen,
  isIOS,
  isAwaitingUserStream,
  cancel,
}: EmptyProps) {
  const { t } = useTranslation();
  return (
    <div>
      <ContentFrame>
        <Headline story={story} chapter={chapter} />

        {!isIOS ? (
          <div className="RecordChapter">
            <div className="RecordChapter_midline" />
          </div>
        ) : (
          ""
        )}

        <div
          className={
            "RecordChapter_controls " + (isIOS ? "safari_controls" : "")
          }
        >
          <div className="RecordChapter_duration RecordChapter_unrecorded-duration">
            {t("record_chapter.start_recording")}
          </div>
          <div className="RecordChapter_buttons">
            <div className="RecordChapter_buttons_and_spinner">
              {isAwaitingUserStream ? (
                <Spinner className="medium" />
              ) : (
                <RecordButton
                  onClick={startRecording}
                  size="large"
                  active={false}
                />
              )}
            </div>
          </div>
        </div>
      </ContentFrame>

      <Footer
        className="RecordChapter_footer"
        leftButton={
          <CancelButton type={"secondary"} onClick={cancel}>
            {t("button.cancel")}
          </CancelButton>
        }
        rightButton={
          <UploadButton
            onClick={startSelectFile}
            onFile={selectFile}
            isWideScreen={isWideScreen}
          />
        }
      />
    </div>
  );
}

interface RecordingProps {
  story: SavedStory;
  chapter: Chapter;
  generator: ScalarGenerator;
  stopRecording: () => void;
  duration: number;
  cancel: () => void;
  isIOS: boolean;
}

export function RecordingRecordChapter({
  generator,
  stopRecording,
  duration,
  story,
  chapter,
  isIOS,
}: RecordingProps) {
  const { width } = useWindowSize();

  return (
    <div>
      <ContentFrame>
        <Headline story={story} chapter={chapter} />
        <div className="RecordChapter">
          {!isIOS ? <div className="RecordChapter_middleLine" /> : ""}

          {!isIOS ? (
            <Recorder
              className="RecordChapter_visualizer"
              width={width}
              height={VISUALIZER_HEIGHT}
              generator={generator}
            />
          ) : (
            ""
          )}
        </div>
        <div
          className={
            "RecordChapter_controls " + (isIOS ? "safari_controls" : "")
          }
        >
          <div className="RecordChapter_duration">
            {renderDuration(duration)}
          </div>
          <div className="RecordChapter_buttons">
            <RecordButton
              onClick={stopRecording}
              size="large"
              active={true}
              generator={generator}
            />
          </div>
        </div>
      </ContentFrame>

      <Footer className="RecordChapter_footer" />
    </div>
  );
}

interface PlayingProps {
  story: SavedStory;
  chapter: Chapter;
  points: Point[];
  pause: () => void;
  trash: () => void;
  playPercentage: number;
  duration: number;
  recording: Recording;
  startRecording: () => void;
  cancel: () => void;
  saveChapter: () => void;
  onUnmount?: () => void;
  isIOS: boolean;
}

export function PlayingRecordChapter({
  duration,
  points,
  playPercentage,
  pause,
  trash,
  story,
  chapter,
  recording,
  startRecording,
  cancel,
  saveChapter,
  onUnmount,
  isIOS,
}: PlayingProps) {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const unsavedRecording = recording.isUpdated;

  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  return (
    <div>
      <ContentFrame>
        <Headline story={story} chapter={chapter} />

        {!isIOS ? (
          <div className="RecordChapter">
            <StaticVisualizer
              className="RecordChapter_visualizer"
              pixelsPerSecond={Visualizer.PIXELS_PER_SECOND}
              width={width}
              height={VISUALIZER_HEIGHT}
              color="#bd72f2"
              points={points}
              maskColor="#f8656a"
              maskPercentage={playPercentage}
            />
          </div>
        ) : (
          ""
        )}
        <div
          className={
            "RecordChapter_controls " + (isIOS ? "safari_controls" : "")
          }
        >
          <div className="RecordChapter_duration">
            {renderDuration(playPercentage * duration)} /{" "}
            {renderDuration(duration)}
          </div>
          <div className="RecordChapter_buttons">
            <DeleteButton onClick={trash} size="medium" />
            <RecordButton
              onClick={startRecording}
              size="large"
              active={false}
            />
            <PlayButton onClick={pause} size="medium" active={true} />
          </div>
        </div>
      </ContentFrame>

      <Footer
        className="RecordChapter_footer"
        leftButton={
          <CancelButton type={"secondary"} onClick={cancel}>
            {t("button.cancel")}
          </CancelButton>
        }
        rightButton={
          unsavedRecording ? (
            <PrimaryButton
              onClick={saveChapter}
              icon={<Save />}
              iconPosition="right"
            >
              {t("button.save")}
            </PrimaryButton>
          ) : (
            undefined
          )
        }
      />
    </div>
  );
}

interface PausedProps {
  story: SavedStory;
  chapter: Chapter;
  points: Point[];
  playPercentage: number;
  duration: number;
  recording: Recording;
  play: () => void;
  trash: () => void;
  startRecording: () => void;
  cancel: () => void;
  saveChapter: () => void;
  isIOS: boolean;
}

export function PausedRecordChapter({
  points,
  playPercentage,
  play,
  trash,
  story,
  chapter,
  startRecording,
  recording,
  duration,
  cancel,
  saveChapter,
  isIOS,
}: PausedProps) {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const unsavedRecording = recording.isUpdated;

  return (
    <div>
      <ContentFrame>
        <Headline story={story} chapter={chapter} />

        {!isIOS ? (
          <div className="RecordChapter">
            <StaticVisualizer
              className="RecordChapter_visualizer"
              pixelsPerSecond={Visualizer.PIXELS_PER_SECOND}
              width={width}
              height={VISUALIZER_HEIGHT}
              color="#bd72f2"
              points={points}
              maskColor="#f8656a"
              maskPercentage={playPercentage}
            />
          </div>
        ) : (
          ""
        )}
        <div
          className={
            "RecordChapter_controls " + (isIOS ? "safari_controls" : "")
          }
        >
          <div className="RecordChapter_duration">
            {renderDuration(duration)}
          </div>
          <div className="RecordChapter_buttons">
            <DeleteButton onClick={trash} size="medium" />
            <RecordButton
              onClick={startRecording}
              size="large"
              active={false}
            />
            <PlayButton onClick={play} size="medium" />
          </div>
        </div>
      </ContentFrame>

      <Footer
        className="RecordChapter_footer"
        leftButton={
          <CancelButton type={"secondary"} onClick={cancel}>
            {t("button.cancel")}
          </CancelButton>
        }
        rightButton={
          unsavedRecording ? (
            <PrimaryButton
              onClick={saveChapter}
              icon={<Save />}
              iconPosition="right"
            >
              {t("button.save")}
            </PrimaryButton>
          ) : (
            undefined
          )
        }
      />
    </div>
  );
}
