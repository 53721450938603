import { Enter, state, StateReturn } from "@druyan/druyan";
import { Shared } from "../model";
import Authorizing from "./Authorizing";
import DeviceGate from "./DeviceGate";

function Initializing(
  action: Enter,
  shared: Shared,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      if (
        !navigator ||
        !navigator.mediaDevices ||
        !navigator.mediaDevices.getUserMedia
      ) {
        return DeviceGate(shared);
      }

      return Authorizing(shared);
  }
}

export default state("Initializing", Initializing);
