import { Action } from "@druyan/druyan";
import { ConfirmModal } from "../model";

export interface ShowRerecord extends Action<"App/ShowRerecord"> {
  modal: ConfirmModal;
}

export function showRerecord(modal: Omit<ConfirmModal, "type">): ShowRerecord {
  return {
    type: "App/ShowRerecord",
    modal: { ...modal, type: "confirm" },
  };
}
