import { Link, SecondaryButton } from "@storytime/shared/build/components";
import React from "react";
import { useCopyToClipboard } from "react-use";
import { t } from "../../i18n";

interface Props {
  copyTarget: string;
}

export function CopyToClipboard({ copyTarget }: Props) {
  const [state, copyTo] = useCopyToClipboard();
  const copyToClipboard = () => copyTo(copyTarget);
  return (
    <>
      <SecondaryButton icon={<Link />} onClick={copyToClipboard}>
        {t("device_gate.cta")}
      </SecondaryButton>
      <p>{state.value && t("device_gate.link_copied")}</p>
    </>
  );
}
