import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { Play } from "../actions";
import { Shared } from "../types";
import Playing from "./Playing";

function Paused(
  action: Enter | Play,
  shared: Shared,
  playPercentage: number,
): StateReturn {
  switch (action.type) {
    case "Enter":
      return noop();

    case "Play":
      return Playing(
        shared,
        playPercentage,
        performance.now() - playPercentage * shared.recording.duration * 1000,
      );
  }
}

export default state("Paused", Paused);
