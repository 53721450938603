import Authorizing from "./Authorizing";
import DeviceGate from "./DeviceGate";
import Initializing from "./Initializing";
import Loading from "./Loading";
import LoggedOut from "./LoggedOut";
import Ready from "./Ready";

export default {
  Initializing,
  LoggedOut,
  DeviceGate,
  Ready,
  Loading,
  Authorizing,
};
