export const headerAnimation = {
  v: "5.5.0",
  fr: 29.9700012207031,
  ip: 0,
  op: 239.00000973467,
  w: 1920,
  h: 180,
  nm: "___Starry BG",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "dot 1/Starry BG Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [960, 540, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] },
                  o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] },
                  t: 1,
                  s: [124, 124, 100],
                },
                { t: 3.00000012219251, s: [100, 100, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-3.42, 0], [3.42, 0]],
                      o: [[3.414, 0], [-3.414, 0]],
                      v: [[0, 2.656], [0, -2.656]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.525, 0.430999995213, 0.698000021542, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 4.00000016292334,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "dynamic/Starry BG Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [960, 540, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 0,
                  s: [20, 20, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 12,
                  s: [100, 100, 100],
                },
                {
                  i: { x: [0.667, 0.667, 0.667], y: [1, 1, 1] },
                  o: { x: [0.333, 0.333, 0.333], y: [0, 0, 0] },
                  t: 114,
                  s: [100, 100, 100],
                },
                { t: 129.000005254278, s: [20, 20, 100] },
              ],
              ix: 6,
            },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-2.394, 0], [2.393, 0]],
                      o: [[2.39, 0], [-2.39, 0]],
                      v: [[0, 1.859], [0, -1.859]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 1052.00004284884,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_2",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "twinkle A1/Starry BG Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [960, 200, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.399, -0.322],
                        [2.355, -2.259],
                        [-0.807, 0.431],
                        [-2.751, 1.788],
                        [0.077, 0.614],
                        [0.694, 0.339],
                        [0.281, 0],
                      ],
                      o: [
                        [-2.541, 2.047],
                        [-0.689, 0.658],
                        [2.895, -1.545],
                        [0.475, -0.308],
                        [-0.09, -0.712],
                        [-0.303, -0.148],
                        [-0.44, 0],
                      ],
                      v: [
                        [2.44, -3.82],
                        [-4.83, 2.596],
                        [-3.837, 3.884],
                        [4.571, -1.055],
                        [5.443, -2.575],
                        [4.571, -4.096],
                        [3.695, -4.314],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [959.814, 539.892], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.807, 0.431],
                        [-2.751, 1.787],
                        [0.077, 0.613],
                        [0.695, 0.338],
                        [0.655, -0.526],
                        [2.355, -2.258],
                      ],
                      o: [
                        [2.894, -1.545],
                        [0.476, -0.309],
                        [-0.09, -0.712],
                        [-0.777, -0.379],
                        [-2.54, 2.047],
                        [-0.688, 0.659],
                      ],
                      v: [
                        [-3.837, 3.964],
                        [4.57, -0.975],
                        [5.442, -2.495],
                        [4.57, -4.016],
                        [2.439, -3.741],
                        [-4.831, 2.675],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [959.814, 539.812], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.361, -0.322],
                        [-0.187, -0.252],
                        [-1.198, -1.064],
                        [-1.73, -0.14],
                        [0.219, 0.707],
                        [1.571, 1.021],
                        [1.17, 0],
                      ],
                      o: [
                        [-0.224, 0.2],
                        [0.953, 1.29],
                        [1.253, 1.109],
                        [0.773, 0.062],
                        [-0.585, -1.878],
                        [-0.94, -0.611],
                        [-0.39, 0],
                      ],
                      v: [
                        [-4.447, -3.354],
                        [-4.564, -2.447],
                        [-0.985, 1.081],
                        [3.369, 3.743],
                        [4.532, 2.214],
                        [0.035, -2.029],
                        [-3.315, -3.805],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [959.642, 540.304], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.187, -0.252],
                        [-1.2, -1.063],
                        [-1.73, -0.139],
                        [0.218, 0.706],
                        [1.571, 1.021],
                        [1.443, -1.289],
                      ],
                      o: [
                        [0.954, 1.289],
                        [1.252, 1.11],
                        [0.773, 0.063],
                        [-0.585, -1.879],
                        [-1.253, -0.814],
                        [-0.224, 0.199],
                      ],
                      v: [
                        [-4.564, -2.028],
                        [-0.984, 1.499],
                        [3.369, 4.161],
                        [4.533, 2.633],
                        [0.035, -1.611],
                        [-4.447, -2.935],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [959.642, 539.885], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 4",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.116, -0.261],
                        [-0.063, -2.384],
                        [-0.612, -2.475],
                        [-0.249, 1.013],
                        [-0.066, 2.542],
                        [0.978, 2.204],
                        [0.285, 0],
                      ],
                      o: [
                        [-0.979, 2.205],
                        [0.068, 2.548],
                        [0.25, 1.012],
                        [0.612, -2.473],
                        [0.062, -2.381],
                        [-0.116, -0.261],
                        [-0.286, 0],
                      ],
                      v: [
                        [-0.686, -7.689],
                        [-1.48, -0.753],
                        [-1.022, 7.067],
                        [1.023, 7.067],
                        [1.481, -0.753],
                        [0.688, -7.689],
                        [0.001, -8.08],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [959.382, 540.127], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 5",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.231, -0.522],
                        [-0.063, -2.384],
                        [-0.612, -2.475],
                        [-0.25, 1.014],
                        [-0.066, 2.543],
                        [0.978, 2.204],
                      ],
                      o: [
                        [-0.979, 2.205],
                        [0.067, 2.548],
                        [0.25, 1.013],
                        [0.612, -2.473],
                        [0.063, -2.38],
                        [-0.231, -0.522],
                      ],
                      v: [
                        [-0.687, -7.624],
                        [-1.48, -0.688],
                        [-1.023, 7.131],
                        [1.023, 7.131],
                        [1.48, -0.688],
                        [0.687, -7.624],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [959.382, 540.062], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 6",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 100.000004073084,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "twinkle A2/Starry BG Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [960, 200, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.2, -0.247],
                        [1.985, -1.496],
                        [-0.95, 0.526],
                        [-1.412, 2.323],
                        [0.734, 0],
                      ],
                      o: [
                        [-1.546, 1.903],
                        [-0.823, 0.62],
                        [2.398, -1.327],
                        [0.467, -0.767],
                        [-0.249, 0],
                      ],
                      v: [
                        [1.72, -3.602],
                        [-3.067, 2.011],
                        [-2.24, 3.428],
                        [3.423, -2.286],
                        [2.417, -3.953],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [959.717, 539.605], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.53, -0.513],
                        [-0.938, -0.674],
                        [-1.187, 0.118],
                        [0.227, 0.85],
                        [1.063, 0.544],
                        [1.234, 0.237],
                        [0.049, 0],
                      ],
                      o: [
                        [0.828, 0.802],
                        [0.898, 0.644],
                        [0.802, -0.08],
                        [-0.334, -1.251],
                        [-1.122, -0.572],
                        [-0.051, -0.01],
                        [-0.627, 0],
                      ],
                      v: [
                        [-3.236, -1.304],
                        [-0.686, 0.898],
                        [2.329, 2.451],
                        [3.539, 0.862],
                        [0.887, -1.43],
                        [-2.509, -2.555],
                        [-2.659, -2.568],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960.129, 540.385], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.041, -0.357],
                        [-0.537, -2.16],
                        [-0.229, 0.919],
                        [0.252, 2.179],
                        [0.257, 0],
                      ],
                      o: [
                        [-0.251, 2.178],
                        [0.229, 0.919],
                        [0.538, -2.166],
                        [-0.041, -0.357],
                        [-0.256, 0],
                      ],
                      v: [
                        [-0.556, -3.622],
                        [-0.931, 3.238],
                        [0.929, 3.238],
                        [0.553, -3.622],
                        [-0.002, -4.158],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [959.666, 540.421], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 100.000004073084,
          op: 103.000004195276,
          st: 100.000004073084,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "Dot",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1457.625, 60.25, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 138.000005620855,
      op: 1190.00004846969,
      st: 138.000005620855,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: "Dot",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1183.625, 104.75, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 203.000008268359,
      op: 1255.0000511172,
      st: 203.000008268359,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: "Dot",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1430.625, 41.25, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 24.00000097754,
      op: 1076.00004382638,
      st: 24.00000097754,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: "Dot",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [530.125, 92.25, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 6.00000024438501,
      op: 1058.00004309322,
      st: 6.00000024438501,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: "dynamic",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1480, 84, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: -85.000003462121,
      op: 51.0000020772726,
      st: -85.000003462121,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: "dynamic",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1480, 84, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 156.00000635401,
      op: 292.000011893404,
      st: 156.00000635401,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: "dynamic",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1570, 61, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: -69.0000028104276,
      op: 67.0000027289659,
      st: -69.0000028104276,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: "dynamic",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1570, 61, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 183.000007453743,
      op: 319.000012993136,
      st: 183.000007453743,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: "dynamic",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [762, 113, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 103.000004195276,
      op: 239.00000973467,
      st: 103.000004195276,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: "dynamic",
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [644, 44, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 1080,
      ip: 52.0000021180034,
      op: 188.000007657397,
      st: 52.0000021180034,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: "moon/Starry BG Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 22,
              s: [-6],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 31,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 52,
              s: [-24],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 89,
              s: [12],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 104,
              s: [0],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 162,
              s: [-43],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 181,
              s: [-5],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 213,
              s: [-17],
            },
            { t: 235.000009571746, s: [0] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [732, 71, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.42, -6.422],
                    [-3.296, 9.109],
                    [0.939, -0.686],
                    [3.707, 1.887],
                    [0.651, 0.847],
                    [0.254, 0.456],
                    [0.049, 0.112],
                    [-0.049, -0.129],
                    [-0.079, -0.184],
                    [-3.255, 3.45],
                    [1.014, 0.027],
                    [0.079, 0],
                  ],
                  o: [
                    [4.075, 7.652],
                    [0.385, -1.062],
                    [-2.948, 2.156],
                    [-0.955, -0.487],
                    [-0.316, -0.414],
                    [-0.048, -0.085],
                    [0.043, 0.128],
                    [-0.238, -0.623],
                    [-1.669, -4.944],
                    [0.695, -0.736],
                    [-0.08, -0.002],
                    [-8.094, 0],
                  ],
                  v: [
                    [-8.708, 3.996],
                    [11.744, 3.947],
                    [9.76, 2.42],
                    [-0.217, 4.904],
                    [-2.641, 2.826],
                    [-3.472, 1.502],
                    [-3.639, 1.144],
                    [-3.5, 1.529],
                    [-3.639, 1.144],
                    [0.997, -11.074],
                    [0.179, -13.053],
                    [-0.06, -13.056],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [959.065, 541.394], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 1052.00004284884,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 0,
      nm: "Twinkle A",
      refId: "comp_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1213.875, 45.25, 0], ix: 2 },
        a: { a: 0, k: [960, 200, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 1920,
      h: 400,
      ip: 53.0000021587343,
      op: 171.000006964973,
      st: 53.0000021587343,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 4,
      nm: "static/Starry BG Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 540, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.342, -0.371],
                    [0, -0.569],
                    [-0.379, -0.381],
                    [-0.679, 0.263],
                    [-0.156, 0.061],
                    [-0.269, 0.349],
                    [0.019, 0.418],
                    [0.158, 0.299],
                    [0.458, 0.187],
                    [0.157, 0.064],
                    [0.187, 0],
                  ],
                  o: [
                    [-0.376, 0.411],
                    [0, 0.539],
                    [0.489, 0.489],
                    [0.157, -0.061],
                    [0.4, -0.155],
                    [0.248, -0.321],
                    [-0.014, -0.338],
                    [-0.244, -0.462],
                    [-0.156, -0.064],
                    [-0.164, -0.066],
                    [-0.557, 0],
                  ],
                  v: [
                    [-1.657, -1.568],
                    [-2.271, -0.083],
                    [-1.657, 1.402],
                    [0.385, 1.943],
                    [0.853, 1.761],
                    [1.843, 1.077],
                    [2.252, -0.078],
                    [1.992, -1.04],
                    [0.853, -1.917],
                    [0.385, -2.109],
                    [-0.148, -2.206],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1808.871, 20.026], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.393, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1792.395, 43.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-4.004, 0], [4.002, 0]],
                  o: [[3.996, 0], [-3.997, 0]],
                  v: [[0.001, 3.109], [0.001, -3.109]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1903.394, 50.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0, 0], [-3.52, 0], [2.668, 1.161]],
                  o: [[-2.666, 1.161], [3.513, 0], [0, 0]],
                  v: [[-1.276, -2.984], [-0.001, 2.984], [1.273, -2.984]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [616.396, 2.984], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 4",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-4.004, 0], [4.003, 0]],
                  o: [[3.996, 0], [-3.997, 0]],
                  v: [[0, 3.109], [0, -3.109]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1826.394, 74.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 5",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.395, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0.001, 1.859], [0.001, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1704.394, 13.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 6",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-3.337, 0], [3.335, 0]],
                  o: [[3.33, 0], [-3.331, 0]],
                  v: [[0.001, 2.59], [0.001, -2.59]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1751.394, 48.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 7",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-3.336, 0], [3.336, 0]],
                  o: [[3.33, 0], [-3.331, 0]],
                  v: [[0, 2.591], [0, -2.591]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1751.395, 48.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 8",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [322.394, 42.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 9",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.391, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [322.394, 42.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 10",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [115.394, 54.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 11",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.391, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [115.395, 54.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 12",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.341, -0.371],
                    [0, -0.569],
                    [-0.38, -0.381],
                    [-0.679, 0.263],
                    [-0.156, 0.06],
                    [-0.27, 0.347],
                    [0.019, 0.418],
                    [0.157, 0.3],
                    [0.457, 0.187],
                    [0.157, 0.064],
                    [0.188, 0],
                  ],
                  o: [
                    [-0.377, 0.41],
                    [0, 0.539],
                    [0.488, 0.489],
                    [0.157, -0.06],
                    [0.4, -0.154],
                    [0.247, -0.321],
                    [-0.015, -0.338],
                    [-0.245, -0.461],
                    [-0.156, -0.064],
                    [-0.164, -0.067],
                    [-0.557, 0],
                  ],
                  v: [
                    [-1.656, -1.568],
                    [-2.271, -0.083],
                    [-1.656, 1.402],
                    [0.385, 1.943],
                    [0.853, 1.761],
                    [1.844, 1.078],
                    [2.252, -0.078],
                    [1.993, -1.041],
                    [0.853, -1.917],
                    [0.385, -2.108],
                    [-0.149, -2.206],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1514.871, 31.026], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 13",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.679, 0.263],
                    [-0.157, 0.061],
                    [-0.27, 0.348],
                    [0.019, 0.418],
                    [0.159, 0.299],
                    [0.457, 0.187],
                    [0.157, 0.064],
                    [0.456, -0.497],
                    [0, -0.569],
                    [-0.38, -0.38],
                  ],
                  o: [
                    [0.157, -0.061],
                    [0.399, -0.154],
                    [0.247, -0.322],
                    [-0.014, -0.338],
                    [-0.244, -0.462],
                    [-0.157, -0.064],
                    [-0.648, -0.265],
                    [-0.377, 0.411],
                    [0, 0.539],
                    [0.488, 0.489],
                  ],
                  v: [
                    [0.385, 2.026],
                    [0.854, 1.845],
                    [1.844, 1.161],
                    [2.251, 0.005],
                    [1.991, -0.956],
                    [0.854, -1.834],
                    [0.385, -2.024],
                    [-1.656, -1.485],
                    [-2.271, 0.001],
                    [-1.656, 1.486],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1514.871, 30.942], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 14",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-3.336, 0], [3.336, 0]],
                  o: [[3.33, 0], [-3.33, 0]],
                  v: [[0, 2.592], [0, -2.592]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [513.394, 46.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 15",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 15,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-3.336, 0], [3.336, 0]],
                  o: [[3.33, 0], [-3.331, 0]],
                  v: [[0, 2.591], [0, -2.591]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [513.394, 46.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 16",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 16,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.858], [0, -1.858]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [683.136, 84.087], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 17",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 17,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [683.136, 84.087], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 18",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 18,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1376.394, 108.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 19",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 19,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.395, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.391, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1376.394, 108.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 20",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 20,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-3.337, 0], [3.335, 0]],
                  o: [[3.33, 0], [-3.332, 0]],
                  v: [[0.001, 2.59], [0.001, -2.59]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1155.394, 39.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 21",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 21,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-3.336, 0], [3.336, 0]],
                  o: [[3.33, 0], [-3.331, 0]],
                  v: [[0, 2.591], [0, -2.591]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.525, 0.430999995213, 0.698000021542, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1155.395, 39.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 22",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 22,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.389, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1898.394, 28.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 23",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 23,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.341, -0.371],
                    [0, -0.569],
                    [-0.38, -0.381],
                    [-0.678, 0.263],
                    [-0.155, 0.06],
                    [-0.268, 0.347],
                    [0.018, 0.418],
                    [0.158, 0.3],
                    [0.458, 0.187],
                    [0.157, 0.064],
                    [0.188, 0],
                  ],
                  o: [
                    [-0.377, 0.41],
                    [0, 0.539],
                    [0.489, 0.489],
                    [0.157, -0.06],
                    [0.4, -0.154],
                    [0.249, -0.321],
                    [-0.015, -0.338],
                    [-0.244, -0.461],
                    [-0.155, -0.064],
                    [-0.164, -0.067],
                    [-0.557, 0],
                  ],
                  v: [
                    [-1.656, -1.568],
                    [-2.271, -0.083],
                    [-1.656, 1.402],
                    [0.385, 1.943],
                    [0.853, 1.761],
                    [1.843, 1.078],
                    [2.253, -0.078],
                    [1.992, -1.041],
                    [0.853, -1.917],
                    [0.385, -2.108],
                    [-0.149, -2.206],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [405.871, 31.026], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 24",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 24,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0.001, 1.859], [0.001, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [247.395, 40.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 25",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 25,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [389.395, 54.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 26",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 26,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-4.003, 0], [4.004, 0]],
                  o: [[3.997, 0], [-3.996, 0]],
                  v: [[0, 3.109], [0, -3.109]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [423.395, 85.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 27",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 27,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [579.395, 19.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 28",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 28,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0.001, 1.859], [0.001, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [201.395, 17.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 29",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 29,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-3.337, 0], [3.335, 0]],
                  o: [[3.33, 0], [-3.331, 0]],
                  v: [[0.001, 2.591], [0.001, -2.591]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [348.394, 59.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 30",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 30,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [296.394, 110.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 31",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 31,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.34, -0.371],
                    [0, -0.569],
                    [-0.38, -0.381],
                    [-0.679, 0.263],
                    [-0.157, 0.06],
                    [-0.269, 0.347],
                    [0.019, 0.418],
                    [0.159, 0.3],
                    [0.457, 0.187],
                    [0.156, 0.064],
                    [0.189, 0],
                  ],
                  o: [
                    [-0.377, 0.41],
                    [0, 0.539],
                    [0.488, 0.489],
                    [0.156, -0.06],
                    [0.399, -0.154],
                    [0.247, -0.321],
                    [-0.015, -0.338],
                    [-0.244, -0.461],
                    [-0.157, -0.064],
                    [-0.164, -0.067],
                    [-0.557, 0],
                  ],
                  v: [
                    [-1.656, -1.568],
                    [-2.27, -0.083],
                    [-1.656, 1.402],
                    [0.385, 1.943],
                    [0.854, 1.761],
                    [1.844, 1.078],
                    [2.252, -0.078],
                    [1.992, -1.041],
                    [0.854, -1.917],
                    [0.385, -2.108],
                    [-0.15, -2.206],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [131.871, 31.026], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 32",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 32,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0.001, 1.859], [0.001, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [149.395, 85.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 33",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 33,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.391, 0], [-2.389, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [235.394, 59.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 34",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 34,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.392, 0]],
                  v: [[0.001, 1.859], [0.001, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [48.395, 42.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 35",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 35,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-3.336, 0], [3.335, 0]],
                  o: [[3.33, 0], [-3.332, 0]],
                  v: [[0.001, 2.591], [0.001, -2.591]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [74.395, 59.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 36",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 36,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.393, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1774.395, 106.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 37",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 37,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1694.395, 48.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 38",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 38,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1498.395, 54.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 39",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 39,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.395, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.39, 0]],
                  v: [[0.001, 1.859], [0.001, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1532.394, 85.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 40",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 40,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.389, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1618.394, 59.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 41",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 41,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.341, -0.371],
                    [0, -0.569],
                    [-0.38, -0.381],
                    [-0.678, 0.263],
                    [-0.155, 0.06],
                    [-0.268, 0.347],
                    [0.018, 0.418],
                    [0.158, 0.3],
                    [0.458, 0.187],
                    [0.157, 0.064],
                    [0.188, 0],
                  ],
                  o: [
                    [-0.377, 0.41],
                    [0, 0.539],
                    [0.489, 0.489],
                    [0.157, -0.06],
                    [0.4, -0.154],
                    [0.249, -0.321],
                    [-0.015, -0.338],
                    [-0.244, -0.461],
                    [-0.155, -0.064],
                    [-0.164, -0.067],
                    [-0.557, 0],
                  ],
                  v: [
                    [-1.656, -1.568],
                    [-2.271, -0.083],
                    [-1.656, 1.402],
                    [0.385, 1.943],
                    [0.853, 1.761],
                    [1.843, 1.078],
                    [2.253, -0.078],
                    [1.992, -1.041],
                    [0.853, -1.917],
                    [0.385, -2.108],
                    [-0.149, -2.206],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [405.871, 94.026], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 42",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 42,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.2, -0.248],
                    [1.985, -1.495],
                    [-0.951, 0.524],
                    [-1.413, 2.324],
                    [0.734, 0],
                  ],
                  o: [
                    [-1.545, 1.903],
                    [-0.823, 0.619],
                    [2.398, -1.328],
                    [0.466, -0.767],
                    [-0.249, 0],
                  ],
                  v: [
                    [1.719, -3.602],
                    [-3.068, 2.011],
                    [-2.24, 3.429],
                    [3.424, -2.286],
                    [2.417, -3.953],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [841.031, 66.953], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 43",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 43,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.531, -0.513],
                    [-0.938, -0.674],
                    [-1.186, 0.119],
                    [0.227, 0.851],
                    [1.064, 0.544],
                    [1.234, 0.237],
                    [0.049, 0],
                  ],
                  o: [
                    [0.828, 0.803],
                    [0.898, 0.644],
                    [0.802, -0.079],
                    [-0.334, -1.251],
                    [-1.121, -0.572],
                    [-0.05, -0.01],
                    [-0.627, 0],
                  ],
                  v: [
                    [-3.235, -1.304],
                    [-0.685, 0.897],
                    [2.329, 2.45],
                    [3.539, 0.86],
                    [0.887, -1.43],
                    [-2.509, -2.555],
                    [-2.658, -2.569],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [841.443, 67.734], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 44",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 44,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.041, -0.357],
                    [-0.538, -2.159],
                    [-0.229, 0.921],
                    [0.253, 2.179],
                    [0.257, 0],
                  ],
                  o: [
                    [-0.251, 2.179],
                    [0.228, 0.921],
                    [0.539, -2.164],
                    [-0.04, -0.357],
                    [-0.257, 0],
                  ],
                  v: [
                    [-0.556, -3.622],
                    [-0.93, 3.236],
                    [0.93, 3.236],
                    [0.553, -3.622],
                    [-0.001, -4.158],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [840.98, 67.769], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 45",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 45,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.399, -0.322],
                    [2.355, -2.257],
                    [-0.807, 0.431],
                    [-2.752, 1.787],
                    [0.078, 0.614],
                    [0.693, 0.339],
                    [0.282, 0],
                  ],
                  o: [
                    [-2.54, 2.047],
                    [-0.689, 0.659],
                    [2.894, -1.544],
                    [0.475, -0.309],
                    [-0.091, -0.712],
                    [-0.303, -0.148],
                    [-0.44, 0],
                  ],
                  v: [
                    [2.439, -3.82],
                    [-4.831, 2.595],
                    [-3.837, 3.884],
                    [4.571, -1.054],
                    [5.442, -2.575],
                    [4.571, -4.096],
                    [3.694, -4.315],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1677.68, 81.845], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 46",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 46,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.361, -0.322],
                    [-0.186, -0.252],
                    [-1.2, -1.064],
                    [-1.731, -0.14],
                    [0.219, 0.707],
                    [1.57, 1.021],
                    [1.17, 0],
                  ],
                  o: [
                    [-0.223, 0.201],
                    [0.953, 1.29],
                    [1.252, 1.11],
                    [0.773, 0.063],
                    [-0.583, -1.878],
                    [-0.941, -0.611],
                    [-0.389, 0],
                  ],
                  v: [
                    [-4.448, -3.354],
                    [-4.565, -2.448],
                    [-0.984, 1.081],
                    [3.369, 3.742],
                    [4.531, 2.214],
                    [0.035, -2.029],
                    [-3.316, -3.805],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1677.508, 82.258], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 47",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 47,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.116, -0.261],
                    [-0.063, -2.384],
                    [-0.612, -2.475],
                    [-0.251, 1.013],
                    [-0.068, 2.543],
                    [0.977, 2.204],
                    [0.286, 0],
                  ],
                  o: [
                    [-0.977, 2.205],
                    [0.067, 2.548],
                    [0.25, 1.012],
                    [0.611, -2.473],
                    [0.063, -2.381],
                    [-0.116, -0.261],
                    [-0.286, 0],
                  ],
                  v: [
                    [-0.688, -7.689],
                    [-1.48, -0.753],
                    [-1.023, 7.067],
                    [1.023, 7.067],
                    [1.481, -0.753],
                    [0.688, -7.689],
                    [0, -8.08],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1677.248, 82.08], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 48",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 48,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-0.003, -0.044], [-0.043, -0.058], [0.062, 0.082]],
                  o: [[0.043, 0.051], [-0.031, -0.114], [0.004, 0.043]],
                  v: [[-0.058, -0.016], [0.07, 0.148], [-0.069, -0.148]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.957000014361, 0.980000035903, 0.987999949736, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1680.857, 85.258], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 49",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 49,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.399, -0.321],
                    [2.355, -2.258],
                    [-0.806, 0.43],
                    [-2.752, 1.786],
                    [0.079, 0.613],
                    [0.693, 0.338],
                    [0.281, 0],
                  ],
                  o: [
                    [-2.54, 2.047],
                    [-0.689, 0.659],
                    [2.896, -1.544],
                    [0.475, -0.309],
                    [-0.091, -0.713],
                    [-0.304, -0.148],
                    [-0.441, 0],
                  ],
                  v: [
                    [2.438, -3.82],
                    [-4.832, 2.595],
                    [-3.839, 3.884],
                    [4.57, -1.054],
                    [5.441, -2.574],
                    [4.57, -4.095],
                    [3.693, -4.314],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1327.681, 93.845], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 50",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 50,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.361, -0.322],
                    [-0.186, -0.253],
                    [-1.199, -1.064],
                    [-1.731, -0.139],
                    [0.22, 0.705],
                    [1.57, 1.021],
                    [1.17, 0],
                  ],
                  o: [
                    [-0.223, 0.199],
                    [0.953, 1.289],
                    [1.252, 1.11],
                    [0.774, 0.062],
                    [-0.583, -1.879],
                    [-0.941, -0.611],
                    [-0.389, 0],
                  ],
                  v: [
                    [-4.448, -3.353],
                    [-4.565, -2.446],
                    [-0.985, 1.081],
                    [3.369, 3.743],
                    [4.531, 2.215],
                    [0.035, -2.029],
                    [-3.316, -3.805],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1327.508, 94.257], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 51",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 51,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.116, -0.197],
                    [-0.062, -1.798],
                    [-0.611, -1.867],
                    [-0.251, 0.765],
                    [-0.068, 1.919],
                    [0.977, 1.664],
                    [0.286, 0],
                  ],
                  o: [
                    [-0.977, 1.664],
                    [0.068, 1.923],
                    [0.25, 0.764],
                    [0.611, -1.866],
                    [0.063, -1.795],
                    [-0.116, -0.197],
                    [-0.286, 0],
                  ],
                  v: [
                    [-0.688, -5.801],
                    [-1.481, -0.569],
                    [-1.023, 5.331],
                    [1.023, 5.331],
                    [1.481, -0.569],
                    [0.688, -5.801],
                    [0, -6.096],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1327.248, 94.096], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 52",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 52,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.458, -0.499],
                    [0, -0.754],
                    [-0.505, -0.505],
                    [-0.889, 0.362],
                    [-0.209, 0.085],
                    [-0.359, 0.465],
                    [0.025, 0.554],
                    [0.209, 0.397],
                    [0.603, 0.234],
                    [0.208, 0.08],
                    [0.25, 0],
                  ],
                  o: [
                    [-0.5, 0.545],
                    [0, 0.715],
                    [0.638, 0.639],
                    [0.208, -0.085],
                    [0.528, -0.215],
                    [0.33, -0.427],
                    [-0.02, -0.449],
                    [-0.319, -0.603],
                    [-0.209, -0.081],
                    [-0.22, -0.086],
                    [-0.738, 0],
                  ],
                  v: [
                    [-2.196, -2.088],
                    [-3.011, -0.119],
                    [-2.196, 1.852],
                    [0.51, 2.568],
                    [1.132, 2.315],
                    [2.444, 1.409],
                    [2.986, -0.124],
                    [2.642, -1.401],
                    [1.132, -2.564],
                    [0.51, -2.805],
                    [-0.202, -2.93],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1358.874, 22.237], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 53",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 53,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.345, -0.376],
                    [0, -0.568],
                    [-0.381, -0.381],
                    [-0.671, 0.273],
                    [-0.156, 0.063],
                    [-0.272, 0.351],
                    [0.019, 0.418],
                    [0.157, 0.3],
                    [0.456, 0.177],
                    [0.157, 0.061],
                    [0.188, 0],
                  ],
                  o: [
                    [-0.377, 0.411],
                    [0, 0.539],
                    [0.482, 0.482],
                    [0.157, -0.063],
                    [0.399, -0.164],
                    [0.248, -0.321],
                    [-0.015, -0.339],
                    [-0.241, -0.454],
                    [-0.156, -0.062],
                    [-0.166, -0.064],
                    [-0.557, 0],
                  ],
                  v: [
                    [-1.656, -1.575],
                    [-2.271, -0.09],
                    [-1.656, 1.396],
                    [0.385, 1.936],
                    [0.853, 1.746],
                    [1.844, 1.062],
                    [2.252, -0.094],
                    [1.993, -1.057],
                    [0.853, -1.933],
                    [0.385, -2.115],
                    [-0.152, -2.209],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1820.871, 62.209], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 54",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 54,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.346, -0.377],
                    [0, -0.569],
                    [-0.38, -0.381],
                    [-0.671, 0.273],
                    [-0.157, 0.063],
                    [-0.271, 0.351],
                    [0.018, 0.418],
                    [0.158, 0.3],
                    [0.455, 0.177],
                    [0.157, 0.061],
                    [0.188, 0],
                  ],
                  o: [
                    [-0.376, 0.41],
                    [0, 0.538],
                    [0.483, 0.482],
                    [0.157, -0.063],
                    [0.398, -0.163],
                    [0.248, -0.322],
                    [-0.014, -0.338],
                    [-0.24, -0.454],
                    [-0.157, -0.061],
                    [-0.166, -0.065],
                    [-0.556, 0],
                  ],
                  v: [
                    [-1.657, -1.574],
                    [-2.27, -0.089],
                    [-1.657, 1.396],
                    [0.385, 1.936],
                    [0.854, 1.746],
                    [1.844, 1.062],
                    [2.252, -0.094],
                    [1.992, -1.057],
                    [0.854, -1.933],
                    [0.385, -2.115],
                    [-0.152, -2.209],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1213.871, 46.209], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 55",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 55,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.46, -0.499],
                    [0, -0.753],
                    [-0.503, -0.505],
                    [-0.89, 0.361],
                    [-0.208, 0.084],
                    [-0.36, 0.467],
                    [0.025, 0.554],
                    [0.21, 0.397],
                    [0.603, 0.234],
                    [0.207, 0.081],
                    [0.25, 0],
                  ],
                  o: [
                    [-0.498, 0.544],
                    [0, 0.715],
                    [0.64, 0.639],
                    [0.207, -0.084],
                    [0.528, -0.215],
                    [0.328, -0.426],
                    [-0.02, -0.449],
                    [-0.319, -0.602],
                    [-0.208, -0.081],
                    [-0.221, -0.085],
                    [-0.738, 0],
                  ],
                  v: [
                    [-2.198, -2.088],
                    [-3.012, -0.119],
                    [-2.198, 1.852],
                    [0.511, 2.568],
                    [1.132, 2.315],
                    [2.445, 1.408],
                    [2.987, -0.124],
                    [2.642, -1.401],
                    [1.132, -2.563],
                    [0.511, -2.805],
                    [-0.202, -2.929],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1268.367, 76.141], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 56",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 56,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.443, -0.442],
                    [-0.027, -0.608],
                    [-1.365, 0],
                    [-0.442, 0.442],
                    [0.028, 0.607],
                    [1.367, 0],
                  ],
                  o: [
                    [-0.422, 0.422],
                    [0.059, 1.32],
                    [0.625, 0],
                    [0.423, -0.423],
                    [-0.059, -1.322],
                    [-0.624, 0],
                  ],
                  v: [
                    [-1.723, -1.723],
                    [-2.436, 0.001],
                    [-0.001, 2.439],
                    [1.722, 1.725],
                    [2.435, 0.001],
                    [-0.001, -2.439],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [607.038, 73.439], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 57",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 57,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1431.394, 42.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 58",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 58,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-3.335, 0], [3.337, 0]],
                  o: [[3.33, 0], [-3.331, 0]],
                  v: [[-0.001, 2.591], [-0.001, -2.591]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1457.395, 59.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 59",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 59,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.395, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0.001, 1.859], [0.001, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1284.394, 19.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 60",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 60,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1286.394, 71.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 61",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 61,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.391, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [441.394, 121.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 62",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 62,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.393, 0], [2.394, 0]],
                  o: [[2.391, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1184.262, 104.69], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 63",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 63,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [475.394, 153.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 64",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 64,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.395, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0.001, 1.859], [0.001, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1435.394, 121.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 65",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 65,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1133.394, 68.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 66",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 66,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.018, -0.503], [0, 0], [-0.649, 0], [1.334, 0]],
                  o: [[0, 0], [0.018, 0.503], [1.332, 0], [-0.649, 0]],
                  v: [
                    [-1.167, -0.043],
                    [-1.167, 0.043],
                    [-0.167, 1.036],
                    [-0.167, -1.036],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1727.98, 23.332], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 67",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 67,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.018, -0.503], [0, 0], [-0.649, 0], [1.333, 0]],
                  o: [[0, 0], [0.018, 0.503], [1.332, 0], [-0.649, 0]],
                  v: [
                    [-1.167, -0.042],
                    [-1.167, 0.044],
                    [-0.167, 1.036],
                    [-0.167, -1.036],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1593.979, 29.33], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 68",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 68,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.018, -0.503], [0, 0], [-0.649, 0], [1.334, 0]],
                  o: [[0, 0], [0.018, 0.503], [1.332, 0], [-0.649, 0]],
                  v: [
                    [-1.167, -0.042],
                    [-1.167, 0.044],
                    [-0.167, 1.036],
                    [-0.167, -1.036],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1340.98, 28.33], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 69",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 69,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-1.334, 0], [1.334, 0]],
                  o: [[1.332, 0], [-1.332, 0]],
                  v: [[0, 1.036], [0, -1.036]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [147.812, 94.331], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 70",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 70,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.004, -0.515],
                    [0, 0],
                    [-0.663, 0],
                    [-0.014, 0.509],
                    [0, 0],
                    [0.654, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.004, 0.514],
                    [0.653, 0],
                    [0, 0],
                    [-0.013, -0.508],
                    [-0.662, 0],
                  ],
                  v: [
                    [-1, -0.01],
                    [-1, 0.012],
                    [0, 1.036],
                    [1, 0.03],
                    [1, -0.031],
                    [0, -1.036],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [215.812, 25.331], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 71",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 71,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-1.334, 0], [1.334, 0]],
                  o: [[1.332, 0], [-1.332, 0]],
                  v: [[0, 1.036], [0, -1.036]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [660.812, 58.331], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 72",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 72,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.014, -0.508], [0, 0], [-0.653, 0], [1.334, 0]],
                  o: [[0, 0], [0.013, 0.507], [1.332, 0], [-0.653, 0]],
                  v: [
                    [-1.167, -0.031],
                    [-1.167, 0.032],
                    [-0.167, 1.036],
                    [-0.167, -1.036],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1039.979, 15.331], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 73",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 73,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.005, -0.515],
                    [0, 0],
                    [-0.662, 0],
                    [-0.004, 0.515],
                    [0, 0],
                    [0.663, 0],
                  ],
                  o: [
                    [0, 0],
                    [0.005, 0.514],
                    [0.662, 0],
                    [0, 0],
                    [-0.004, -0.515],
                    [-0.661, 0],
                  ],
                  v: [
                    [-1, -0.01],
                    [-1, 0.013],
                    [0, 1.036],
                    [1, 0.01],
                    [1, -0.011],
                    [0, -1.036],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [734.812, 12.33], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 74",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 74,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.014, -0.508], [0, 0], [-0.653, 0], [1.335, 0]],
                  o: [[0, 0], [0.014, 0.508], [1.332, 0], [-0.653, 0]],
                  v: [
                    [-1.167, -0.031],
                    [-1.167, 0.031],
                    [-0.167, 1.036],
                    [-0.167, -1.036],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1500.979, 5.332], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 75",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 75,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.014, -0.508], [0, 0], [-0.653, 0], [1.335, 0]],
                  o: [[0, 0], [0.014, 0.506], [1.332, 0], [-0.653, 0]],
                  v: [
                    [-1.167, -0.031],
                    [-1.167, 0.032],
                    [-0.167, 1.036],
                    [-0.167, -1.036],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1185.979, 73.331], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 76",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 76,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.019, -0.915], [0, 0], [-1.178, 0], [2.394, 0]],
                  o: [[0, 0], [0.018, 0.914], [2.391, 0], [-1.177, 0]],
                  v: [
                    [-2.094, -0.043],
                    [-2.094, 0.045],
                    [-0.3, 1.859],
                    [-0.3, -1.859],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1114.112, 28.331], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 77",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 77,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[0.009, -0.922], [0, 0], [-1.187, 0], [2.394, 0]],
                  o: [[0, 0], [0.009, 0.922], [2.39, 0], [-1.187, 0]],
                  v: [
                    [-2.094, -0.021],
                    [-2.094, 0.023],
                    [-0.3, 1.859],
                    [-0.3, -1.859],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [903.112, 21.331], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 78",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 78,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.395, 0], [-0.009, 0.922], [0, 0], [1.188, 0]],
                  o: [[1.187, 0], [0, 0], [-0.009, -0.922], [-2.39, 0]],
                  v: [
                    [0.301, 1.859],
                    [2.095, 0.021],
                    [2.095, -0.023],
                    [0.301, -1.859],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [795.511, 75.331], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 79",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 79,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.389, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [467.394, 87.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 80",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 80,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [780.931, 48.688], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 81",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 81,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.394, 0]],
                  o: [[2.39, 0], [-2.39, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1357.394, 72.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 82",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 82,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0.001, 1.859], [0.001, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [24.395, 110.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 83",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 83,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-2.394, 0], [2.393, 0]],
                  o: [[2.389, 0], [-2.391, 0]],
                  v: [[0, 1.859], [0, -1.859]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [687.395, 67.859], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 84",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 84,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.017, -0.001],
                    [0.164, -0.654],
                    [0, 0],
                    [-1.018, 0],
                    [0.261, 0.974],
                    [0.022, 0.077],
                    [0.625, 0],
                  ],
                  o: [
                    [-0.647, 0.029],
                    [0, 0],
                    [-0.244, 0.975],
                    [1.005, 0],
                    [-0.019, -0.077],
                    [-0.157, -0.592],
                    [-0.017, 0],
                  ],
                  v: [
                    [-0.009, -1.644],
                    [-1.443, -0.551],
                    [-1.502, -0.319],
                    [-0.008, 1.645],
                    [1.485, -0.319],
                    [1.423, -0.551],
                    [0.042, -1.645],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [530.15, 91.645], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 85",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 85,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.018, -0.001],
                    [0.163, -0.652],
                    [0, 0],
                    [-1.018, 0],
                    [0.26, 0.973],
                    [0.021, 0.078],
                    [0.625, 0],
                  ],
                  o: [
                    [-0.646, 0.029],
                    [0, 0],
                    [-0.245, 0.974],
                    [1.005, 0],
                    [-0.02, -0.078],
                    [-0.158, -0.59],
                    [-0.017, 0],
                  ],
                  v: [
                    [-0.009, -1.644],
                    [-1.442, -0.551],
                    [-1.501, -0.319],
                    [-0.007, 1.645],
                    [1.486, -0.319],
                    [1.425, -0.551],
                    [0.043, -1.645],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [594.149, 101.645], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 86",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 86,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-0.003, -0.044], [-0.044, -0.059], [0.062, 0.083]],
                  o: [[0.043, 0.052], [-0.032, -0.114], [0.004, 0.044]],
                  v: [[-0.059, -0.017], [0.07, 0.15], [-0.07, -0.15]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.957000014361, 0.980000035903, 0.987999949736, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1330.858, 97.257], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 87",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 87,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 1052.00004284884,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
