import { Action } from "@druyan/druyan";

export interface RemoveEditor extends Action<"RemoveEditor"> {
  id: string;
}

export function removeEditor(id: string): RemoveEditor {
  return {
    type: "RemoveEditor",
    id,
  };
}
