import { Enter, state, StateReturn, task } from "@druyan/druyan";
import { pathTo } from "@storytime/shared/build/pathTo";
import { modifyRecording } from "../../../../api";
import { t } from "../../../../i18n";
import { Recording } from "../../../../state";
import { Actions as AppState } from "../../../../state";
import { routeTo } from "../../../../utils";
import { redirectTo } from "../../../../utils";
import { saveRecording, SaveRecording } from "../actions";
import { Shared } from "../types";

function SavingChapterModal(
  action: Enter | SaveRecording,
  shared: Shared,
  recording: Recording,
): StateReturn | StateReturn[] {
  const { story, chapter } = shared;

  switch (action.type) {
    case "Enter":
      return [
        AppState.showProcessingIndicator([
          t("processing_indicator.sprinkling"),
          t("processing_indicator.mixing"),
          t("processing_indicator.saving"),
          t("processing_indicator.tucking"),
        ]),
        saveRecording(),
      ];
    case "SaveRecording":
      return task(async () => {
        const savedRecording = await modifyRecording(
          "audio",
          pathTo.db.chapterRecordingsCollection(story.id, chapter.id),
          pathTo.storage.chapterRecordingsCollection(story.id, chapter.id),
          recording,
        );

        if (!savedRecording) {
          throw new Error("Saving recording failed");
        }

        return [
          // TODO: Technically "didSave"
          AppState.saveChapterRecording(story, chapter, savedRecording),

          redirectTo(routeTo.storyDetail(story.id)),

          // Must manually hide, since redirect doesn't exit the state.
          AppState.hideModal(),
        ];
      });
  }
}

export default state("SavingChapterModal", SavingChapterModal);
