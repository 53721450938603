import {
  BackButton,
  DeleteEditorButton,
  MinimalButton,
} from "@storytime/shared/build/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ContentFrame from "../../components/ContentFrame";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Tabs from "../../components/Tabs";
import { Invite, SavedStory } from "../../state";
import { history } from "../../utils";
import "./AccountSettings.css";

interface Props {
  userEmail: string | null;
  editorUsers: Array<{ id: string; name: string; email: string }>;
  invitedUsers: Invite[];
  onRemoveEditor: (id: string) => void;
  onRemoveEditorFromStory: (id: string, story: SavedStory) => void;
  onRemoveEditorsFromStory: (story: SavedStory) => void;
  onDeleteAccount: () => void;
  onDownloadAllStories: () => void;
  onLogout: () => void;
  hasStories: boolean;
  myStories: SavedStory[];
}

interface InviteListItemProps {
  label: string;
}

interface EditorListItemProps {
  label: string;
  onRemove: () => void;
}

export function InviteListItem({ label }: InviteListItemProps) {
  const { t } = useTranslation();
  return (
    <li className="share-section-item --invite-item">
      <span className="share-section-label">{label}</span>
      <span className="share-section-status">
        <svg width="11" height="11" aria-hidden="true" role="presentation">
          <path
            d="M5.4945 0C2.4585 0 0 2.464 0 5.5S2.4585 11 5.4945 11C8.536 11 11 8.536 11 5.5S8.536 0 5.4945 0zM5.5 9.9c-2.431 0-4.4-1.969-4.4-4.4s1.969-4.4 4.4-4.4 4.4 1.969 4.4 4.4-1.969 4.4-4.4 4.4zm2.4695-2.002L4.95 6.0885V2.431h1.1v3.0305L8.5305 6.952l-.561.946z"
            fill="#85E0C0"
            fillRule="nonzero"
          />
        </svg>

        {t("account_settings.invite_sent")}
      </span>
    </li>
  );
}

export function EditorListItem({ label, onRemove }: EditorListItemProps) {
  const { t } = useTranslation();
  return (
    <li className="share-section-item --editor-item">
      <span className="share-section-label">
        <DeleteEditorButton
          size="small"
          // tslint:disable-next-line: react-this-binding-issue
          onClick={onRemove}
        />
        {label}
      </span>
      <span className="share-section-status">
        <svg width="12" height="10" aria-hidden="true" role="presentation">
          <path
            d="M4 7.574286L1.365464 4.811553.182131 6.020125 4 10l7.683048-7.907695-1.175-1.208571z"
            fill="#85E0C0"
            fillRule="nonzero"
          />
        </svg>

        {t("account_settings.shared")}
      </span>
    </li>
  );
}

// tslint:disable-next-line:max-func-body-length
export function AccountSettings({
  userEmail,
  editorUsers,
  invitedUsers,
  onRemoveEditor,
  onRemoveEditorFromStory,
  onRemoveEditorsFromStory,
  onDownloadAllStories,
  onDeleteAccount,
  onLogout,
  hasStories,
  myStories,
}: Props) {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  return (
    <div>
      <Header mode="active" />

      <ContentFrame>
        <div className="AccountSettings">
          <h1 className="account__headline">
            {t("account_settings.headline")}
          </h1>

          <div className="account__container">
            <h2 className="account__email">{userEmail}</h2>
            <ul className="account__links">
              <li className="account__link">
                <MinimalButton onClick={onLogout}>
                  {t("account.logout")}
                </MinimalButton>
              </li>
              {hasStories ? (
                <li className="account__link">
                  <MinimalButton onClick={onDownloadAllStories}>
                    {t("account_settings.download_all")}
                  </MinimalButton>
                </li>
              ) : (
                undefined
              )}

              <li className="account__link">
                <MinimalButton onClick={onDeleteAccount}>
                  {t("account_settings.delete_me")}
                </MinimalButton>
              </li>
            </ul>
            {editorUsers.length || invitedUsers.length ? (
              <>
                <hr className="account-hr" />
                <h2 className="shared_stories_headline">
                  {t("account_settings.headline_shared_story_settings")}
                </h2>
                <span className="shared_stories_subheadline">
                  {t("account_settings.access")}
                </span>

                <div className="tab-list">
                  <Tabs
                    labels={["By Person", "By Story"]}
                    index={tabIndex}
                    onChange={handleTabsChange}
                  />
                </div>
                <div
                  className={`tab-panel ${tabIndex === 0 ? "" : "--inactive"}`}
                >
                  {editorUsers.length > 0 ? (
                    <MinimalButton
                      size="small"
                      // tslint:disable-next-line: react-this-binding-issue
                      onClick={() => setIsEditing(!isEditing)}
                    >
                      {isEditing
                        ? t("button.done_editing")
                        : t("button.edit_sharing")}
                    </MinimalButton>
                  ) : (
                    <></>
                  )}

                  <ul
                    className={`share-section-list ${
                      isEditing ? "--editing" : ""
                    }`}
                  >
                    {editorUsers.length ? (
                      editorUsers.map(({ id, name, email }) => {
                        return (
                          <li key={id} className="share-section">
                            <span className="share-section-header">
                              <DeleteEditorButton
                                size="small"
                                // tslint:disable-next-line: react-this-binding-issue
                                onClick={() => onRemoveEditor(id)}
                              />
                              {name}
                            </span>
                            <ul className="share-section-list">
                              {myStories.map(story => {
                                if (
                                  story.editors &&
                                  story.editors.includes(id)
                                ) {
                                  return (
                                    <EditorListItem
                                      key={`${story.id}-${story.title}`}
                                      label={story.title}
                                      // tslint:disable-next-line: react-this-binding-issue
                                      onRemove={() => {
                                        onRemoveEditorFromStory(id, story);
                                      }}
                                    />
                                  );
                                }

                                if (
                                  story.invites &&
                                  story.invites.some(
                                    invite => invite.email === email,
                                  )
                                ) {
                                  return (
                                    <InviteListItem
                                      key={`${story.id}-${story.title}`}
                                      label={story.title}
                                    />
                                  );
                                }

                                return <></>;
                              })}
                            </ul>
                            <hr className="account-hr" />
                          </li>
                        );
                      })
                    ) : (
                      <></>
                    )}
                    {invitedUsers.length ? (
                      invitedUsers.map(invite => {
                        return (
                          <li key={invite.email} className="share-section">
                            <span className="share-section-header">
                              {invite.email}
                            </span>
                            <ul className="share-section-list">
                              {myStories.map(story => {
                                return story.invites &&
                                  story.invites.some(
                                    storyInvite =>
                                      storyInvite.email === invite.email,
                                  ) ? (
                                  <InviteListItem
                                    key={`${story.id}-${story.title}`}
                                    label={story.title}
                                  />
                                ) : (
                                  <></>
                                );
                              })}
                            </ul>
                            <hr className="account-hr" />
                          </li>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>

                <div
                  className={`tab-panel ${tabIndex === 1 ? "" : "--inactive"}`}
                >
                  {editorUsers.length > 0 ? (
                    <MinimalButton
                      size="small"
                      // tslint:disable-next-line: react-this-binding-issue
                      onClick={() => setIsEditing(!isEditing)}
                    >
                      {isEditing
                        ? t("button.done_editing")
                        : t("button.edit_sharing")}
                    </MinimalButton>
                  ) : (
                    <></>
                  )}

                  <ul
                    className={`share-section-list ${
                      isEditing ? "--editing" : ""
                    }`}
                  >
                    {/* tslint:disable-next-line: max-func-body-length */}
                    {myStories.map(story => {
                      if (
                        (story.editors && story.editors.length > 0) ||
                        (story.invites && story.invites.length > 0)
                      ) {
                        return (
                          <li key={story.id} className="share-section">
                            <span className="share-section-header">
                              {story.editors && story.editors.length > 0 ? (
                                <DeleteEditorButton
                                  size="small" // tslint:disable-next-line: react-this-binding-issue
                                  onClick={() =>
                                    onRemoveEditorsFromStory(story)
                                  }
                                />
                              ) : (
                                <></>
                              )}
                              {story.title}
                            </span>
                            <ul className="share-section-list">
                              {editorUsers.map(({ id, name, email }) => {
                                if (
                                  story.editors &&
                                  story.editors.includes(id)
                                ) {
                                  return (
                                    <EditorListItem
                                      key={`${story.id}-${name}`}
                                      label={name}
                                      // tslint:disable-next-line: react-this-binding-issue
                                      onRemove={() => {
                                        onRemoveEditorFromStory(id, story);
                                      }}
                                    />
                                  );
                                } else if (
                                  story.invites &&
                                  story.invites.some(
                                    storyInvite => storyInvite.email === email,
                                  )
                                ) {
                                  return (
                                    <InviteListItem
                                      key={`${story.id}-${name}`}
                                      label={name}
                                    />
                                  );
                                } else {
                                  return <></>;
                                }
                              })}

                              {invitedUsers.map(invite => {
                                return story.invites &&
                                  story.invites.some(
                                    storyInvite =>
                                      storyInvite.email === invite.email,
                                  ) ? (
                                  <InviteListItem
                                    key={`${story.id}-${invite.email}`}
                                    label={invite.email}
                                  />
                                ) : (
                                  <></>
                                );
                              })}
                            </ul>
                            <hr className="account-hr" />
                          </li>
                        );
                      }

                      return <></>;
                    })}
                  </ul>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </ContentFrame>
      <Footer
        leftButton={
          <BackButton type={"secondary"} onClick={history.goBack}>
            {t("button.back")}
          </BackButton>
        }
      />
    </div>
  );
}
