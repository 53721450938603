export function renderDuration(duration: number) {
  const minutes = Math.floor(duration / 60);

  let seconds = (Math.floor(duration) % 60).toString();
  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}
