import { pathTo } from "@storytime/shared/build/pathTo";
import firebase from "firebase/app";
import "firebase/functions";
import { app } from "../firebase";
import { SavedStory, Story, UnsavedStory } from "../state";
import { saveRecordings } from "./uploadAudio";

export function getStoryRef(
  storyId: string,
): firebase.firestore.DocumentReference {
  return app.firestore().doc(pathTo.db.story(storyId));
}

function storyToData({ title, author, isChapterStory }: Story) {
  return { title, author, isChapterStory };
}

export async function createStory(
  user: firebase.User,
  story: UnsavedStory,
): Promise<SavedStory> {
  const createdAt = +new Date();

  const ref = await app
    .firestore()
    .collection(pathTo.db.storiesCollection())
    .add({
      ...storyToData(story),
      owner: user.uid,
      createdAt,
    });

  return {
    ...story,
    id: ref.id,
    isSaved: true,
    createdAt,
    owner: user.uid,
    ownerName: user.displayName,
    recordings: await saveRecordings(
      story.recordings,
      pathTo.db.storyRecordingsCollection(ref.id),
      pathTo.storage.storyRecordingsCollection(ref.id),
    ),
    editors: [],
    invites: [],
  };
}

export async function deleteStoryFromDB(story: SavedStory): Promise<void> {
  const ref = getStoryRef(story.id);

  await ref.delete();
}

export const downloadStory = app.functions().httpsCallable("downloadStory");

export async function removeEditorFromStory(editorId: string, storyId: string) {
  const storyRef = getStoryRef(storyId);

  await storyRef.update({
    editors: firebase.firestore.FieldValue.arrayRemove(editorId),
  });
}

export async function removeEditorsFromStory(
  storyId: string,
): Promise<void> {
  const storyRef = getStoryRef(storyId);
  const storySnap = await storyRef.get();
  const storyData = storySnap.data();

  if (!storyData) {
    throw new Error(`Got empty snapshot data for document: ${storyId}.`);
  }

  await storyRef.update({
    editors: [],
  });
}

export async function deleteEditorChapter(storyId: string, chapterId: string) {
  const chapterRef = app.firestore().doc(pathTo.db.chapter(storyId, chapterId));
  chapterRef.delete();
}
