import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { Recording as RecordingType } from "../../../../state";
import { DeleteRecording, Play } from "../actions";
import { Shared } from "../types";
import Empty from "./Empty";
import RecordedPlaying from "./RecordedPlaying";

function RecordedPaused(
  action: Enter | Play | DeleteRecording,
  shared: Shared,
  recording: RecordingType,
  inProgressPlayback: number,
): StateReturn {
  const { onDelete, pauseAudio } = shared;

  switch (action.type) {
    case "Enter":
      pauseAudio();

      return noop();

    case "Play":
      return RecordedPlaying(
        shared,
        recording,
        performance.now() - inProgressPlayback / 1000,
        inProgressPlayback,
      );

    case "DeleteRecording":
      onDelete();

      return Empty(shared);
  }
}

export default state("RecordedPaused", RecordedPaused);
