import { Enter, noop, state, StateReturn, task } from "@druyan/druyan";
import { updateChapter } from "../../../../api";

import { Actions as AppState } from "../../../../state";
import { redirectTo, routeTo } from "../../../../utils";
import { Submit } from "../actions";
import { Shared } from "../types";

function Submitting(action: Enter | Submit, shared: Shared): StateReturn {
  const { story, chapter, user } = shared;

  switch (action.type) {
    case "Enter":
      return task(async () => {
        const chapterUpdates = {
          ...chapter,
          title: chapter.title,
          recordings: {
            ...chapter.recordings,
            title: chapter.recordings.title,
          },
        };

        const savedChapter = await updateChapter(user, story, chapterUpdates);

        return [
          // Send the new chapter to the global data store.
          AppState.updateStoryChapterTitle(story, savedChapter),
          redirectTo(routeTo.storyDetailEdit(story.id, "edit-story")),
        ];
      });

    case "Submit":
      return noop();
  }
}

export default state("Submitting", Submitting);
