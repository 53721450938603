import React, { createRef, useCallback, useEffect, useState } from "react";
import { useActions } from "../../hooks";
import "./TextInput.css";

interface Props {
  value?: string;
  labelledBy?: string;
  onChange?: (value: string) => void;
  needsImmediateFocus?: boolean;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
}

export function TextInput({
  value,
  onChange,
  labelledBy,
  needsImmediateFocus,
  placeholder,
  type,
  disabled,
}: Props) {
  const inputRef = createRef<HTMLInputElement>();
  const { inputFocused } = useActions();

  const [hasFocused, setHasFocused] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    if (needsImmediateFocus) {
      const element = inputRef.current as HTMLInputElement;
      if (element && !hasFocused) {
        element.focus();
        setHasFocused(true);
      }
    }
  }, [needsImmediateFocus, hasFocused, inputRef]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange) {
        setInternalValue(e.target.value);
        onChange(e.target.value);
      }
    },
    [onChange],
  );

  const handleFocus = () => {
    inputFocused(true);
  };

  const handleBlur = () => {
    inputFocused(false);
  };

  return (
    <input
      disabled={disabled}
      className="TextInput"
      value={internalValue}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      aria-labelledby={labelledBy}
      ref={inputRef}
      placeholder={placeholder}
      type={type}
    />
  );
}
