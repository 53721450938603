import ConfirmingAccountDeletion from "./ConfirmingAccountDeletion";
import ConfirmingEditorRemoval from "./ConfirmingEditorRemoval";
import Entry from "./Entry";
import GeneratingZipFile from "./GeneratingZipFile";

export default {
  Entry,
  ConfirmingAccountDeletion,
  ConfirmingEditorRemoval,
  GeneratingZipFile,
};
