import { Action } from "@druyan/druyan";

export interface OnNext extends Action<"OnNext"> {
  stories: string[];
}

export function onNext(stories: string[]): OnNext {
  return {
    type: "OnNext",
    stories,
  };
}
