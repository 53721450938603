import { StateReturn } from "@druyan/druyan";
import { pathTo } from "@storytime/shared/build/pathTo";
import { deleteAudio } from "../../../api";
import { DeleteGreeting, SaveGreeting } from "../../actions";
import { Modal, Shared } from "../../model";
import Ready from "../Ready";

export type GreetingsActions = SaveGreeting | DeleteGreeting;

export function greetingsActions(
  action: GreetingsActions,
  shared: Shared,
  modal: Modal,
  update: (shared: Shared, modal: Modal) => ReturnType<typeof Ready>,
): StateReturn | StateReturn[] {
  const { user, greetings } = shared;

  if (!user) {
    throw new Error("Must be logged in to handle greetings");
  }

  switch (action.type) {
    case "App/SaveGreeting":
      greetings.recordings[action.which] = action.recording;

      return update(shared, modal);

    case "App/DeleteGreeting":
      deleteAudio(pathTo.db.greeting(user.uid, action.which));

      delete greetings.recordings[action.which];

      return update(shared, modal);
  }
}
