import { Action } from "@druyan/druyan";
import { Recording } from "../../../../state";

export interface FinishRecording extends Action<"FinishRecording"> {
  recording: Recording;
}

export function finishRecording(recording: Recording): FinishRecording {
  return {
    type: "FinishRecording",
    recording,
  };
}
