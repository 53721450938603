import { Enter, state, StateReturn } from "@druyan/druyan";
import { Actions as AppState } from "../../../../state";
import { Recording as RecordingType } from "../../../../state";
import { routeTo } from "../../../../utils";
import { redirectTo } from "../../../../utils";
import {
  Cancel,
  DeleteRecording,
  Play,
  SaveChapter,
  StartRecording,
} from "../actions";
import { Shared } from "../types";
import RecordedPlaying from "./RecordedPlaying";
import SavingChapterModal from "./SavingChapterModal";
import ShowingConfirmStartOverModal from "./ShowingConfirmStartOverModal";
import ShowingSaveBeforeClosingModal from "./ShowingSaveBeforeClosingModal";
import StartingRecording from "./StartingRecording";

function RecordedPaused(
  action:
    | Enter
    | Play
    | DeleteRecording
    | SaveChapter
    | Cancel
    | StartRecording,
  shared: Shared,
  recording: RecordingType,
  inProgressPlayback: number,
): StateReturn | StateReturn[] {
  const { story, audioContext } = shared;

  switch (action.type) {
    case "Enter":
      return AppState.pauseAudio();

    case "Play":
      return RecordedPlaying(
        shared,
        recording,
        inProgressPlayback,
        performance.now() - inProgressPlayback / 1000,
      );

    case "DeleteRecording":
      return ShowingConfirmStartOverModal(shared);

    case "SaveChapter":
      return SavingChapterModal(shared, recording);

    case "Cancel":
      return recording.isUpdated
        ? ShowingSaveBeforeClosingModal(shared, recording)
        : redirectTo(routeTo.storyDetail(story.id));

    case "StartRecording":
      return StartingRecording(
        shared,
        recording,
        action.stream,
        action.stopAction,
        audioContext,
      );
  }
}

export default state("RecordedPaused", RecordedPaused);
