import { Action } from "@druyan/druyan";

export interface ConfirmedUploadModal
  extends Action<"App/ConfirmedUploadModal"> {}

export function confirmedUploadModal(): ConfirmedUploadModal {
  return {
    type: "App/ConfirmedUploadModal",
  };
}
