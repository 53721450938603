import { Action } from "@druyan/druyan";

export interface SetChapterTitle extends Action<"SetChapterTitle"> {
  chapterTitle: string;
}

export function setChapterTitle(chapterTitle: string): SetChapterTitle {
  return {
    type: "SetChapterTitle",
    chapterTitle,
  };
}
