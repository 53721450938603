import { Enter, state, StateReturn } from "@druyan/druyan";
import { Shared } from "../types";
import Instructions from "./Instructions";

function Entry(action: Enter, shared: Shared): StateReturn {
  switch (action.type) {
    case "Enter":
      return Instructions(shared);
  }
}

export default state("Entry", Entry);
