import { StateReturn } from "@druyan/druyan";
import { PauseAudio, PlayAudio, StopAudio } from "../../actions";
import { initialAudioPlayer, Modal, Shared } from "../../model";
import Ready from "../Ready";

export type AudioActions = PlayAudio | PauseAudio | StopAudio;

export function audioActions(
  action: AudioActions,
  shared: Shared,
  modal: Modal,
  update: (shared: Shared, modal: Modal) => ReturnType<typeof Ready>,
): StateReturn | StateReturn[] {
  const { audioPlayer } = shared;

  switch (action.type) {
    case "App/PlayAudio":
      shared.audioPlayer = {
        isPlaying: true,
        audio: action.audio,
        playHead: action.playHead,
        onPlayback: action.onPlayback,
      };

      return update(shared, modal);

    case "App/PauseAudio":
      audioPlayer.isPlaying = false;

      return update(shared, modal);

    case "App/StopAudio":
      shared.audioPlayer = initialAudioPlayer();

      return update(shared, modal);
  }
}
