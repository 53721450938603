import { Action } from "@druyan/druyan";

export interface StopRecording extends Action<"StopRecording"> {
  audioUrl: string;
  runAsync?: boolean;
}

export function stopRecording(
  audioUrl: string,
  runAsync?: boolean,
): StopRecording {
  return {
    type: "StopRecording",
    audioUrl,
    runAsync,
  };
}
