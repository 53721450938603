import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
  timeout,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import { redirectTo, routeTo } from "../../../../utils";
import { Confirm, confirm, Reject, reject } from "../actions";
import { Shared } from "../types";

function ShowConfirmDeleteModal(
  action: Enter | Confirm | Reject | Exit,
  shared: Shared,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          text: t("delete_story_modal.text"),
          confirmText: t("global.yes"),
          rejectText: t("global.no"),
          onConfirm,
          onReject,
        }),
      ];

    case "Confirm":
      return [
        AppState.hideModal(),

        redirectTo(routeTo.dashboard()),

        // Run later so transition succeeds before removing from DB.
        // Magic number, may vary by device, but I think it is tied
        // to the page transition timing.
        timeout(400, AppState.deleteStory(shared.story)),
      ];

    case "Reject":
      return goBack();

    case "Exit":
      return AppState.hideModal();
  }
}

export default state("ShowConfirmDeleteModal", ShowConfirmDeleteModal);
