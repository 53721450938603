import { Action } from "@druyan/druyan";
import { SavedChapter, SavedStory } from "../model";

export interface DeleteStoryChapter extends Action<"App/DeleteStoryChapter"> {
  story: SavedStory;
  chapter: SavedChapter;
}

export function deleteStoryChapter(
  story: SavedStory,
  chapter: SavedChapter,
): DeleteStoryChapter {
  return {
    type: "App/DeleteStoryChapter",
    story,
    chapter,
  };
}
