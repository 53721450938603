import {
  Enter,
  OnFrame,
  onFrame,
  state,
  StateReturn,
  task,
} from "@druyan/druyan";
import { Recording as RecordingType } from "../../../../state";
import { generatorSingleton } from "../../../../utils";
import { Cancel, StartRecording, StopRecording } from "../actions";
import { Shared } from "../types";
import ProcessingRecording from "./ProcessingRecording";
import ShowingConfirmCancelRecordingModal from "./ShowingConfirmCancelRecordingModal";

function Recording(
  action: Enter | StopRecording | OnFrame | Cancel | StartRecording,
  shared: Shared,
  previousRecording: RecordingType | undefined,
  stopRecording: () => Promise<void>,
  startTimestamp: number = performance.now(),
  _inProgressDuration: number = 0,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      return onFrame();

    case "OnFrame":
      return [
        update(
          shared,
          previousRecording,
          stopRecording,
          startTimestamp,
          (performance.now() - startTimestamp) / 1000,
        ),

        onFrame(),
      ];

    case "StopRecording":
      return task(async () => {
        if (generatorSingleton.isRunning) {
          generatorSingleton.stop();
        }

        // Convert in-browser blob URL to an actual binary Blob object.
        const recordedAudio = await fetch(action.audioUrl).then(r => r.blob());

        return ProcessingRecording(shared, previousRecording!, recordedAudio);
      });

    case "StartRecording":
      // If you've gotten here it's because you can click Record faster than a single frame
      return onFrame();

    case "Cancel":
      return ShowingConfirmCancelRecordingModal(shared, stopRecording!);
  }
}

const RecordingState = state("Recording", Recording);
const { update } = RecordingState;
export default RecordingState;
