import {
  ArrowRight,
  BackButton,
  Chat,
  MicWithCircle,
  NextButton,
  PrimaryButton,
} from "@storytime/shared/build/components";
import { config } from "@storytime/shared/build/config";
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
import ContentFrame from "../../components/ContentFrame";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import RecordingInput from "../../components/RecordingInput";
import RecordingInputIOS from "../../components/RecordingInputIOS";
import { useLottie } from "../../hooks";
import { Recording, RequiredGreetingTypes } from "../../state";
import "./Greetings.css";
import { stepFourAnimation } from "./stepFourAnimation";
import { stepOneAnimation } from "./stepOneAnimation";
import { stepThreeAnimation } from "./stepThreeAnimation";
import { stepTwoAnimation } from "./stepTwoAnimation";

interface Props {
  onCompleteWelcomeFlow: () => void;
}

export function GreetingsWelcome({ onCompleteWelcomeFlow }: Props) {
  const { t } = useTranslation();

  const stepOneRef = useLottie({
    animationData: stepOneAnimation,
  });

  const stepTwoRef = useLottie({
    animationData: stepTwoAnimation,
  });

  const stepThreeRef = useLottie({
    animationData: stepThreeAnimation,
  });

  const stepFourRef = useLottie({
    animationData: stepFourAnimation,
  });

  return (
    <div>
      <Header mode="static" />

      <ContentFrame center={true}>
        <div className="Greetings">
          <h1 className="greetings__headline">{t("greetings.headline")}</h1>
          <ol className="setup__steps">
            <li className="setup__step setup__step-one">
              <div className="setup__step-one-animation" ref={stepOneRef} />
              <h2 className="setup__step-heading">1</h2>
              <p className="setup__step-copy">
                {t("greetings.setup.step_one.copy")}
              </p>
            </li>

            <li className="setup__step setup__step-two">
              <div className="setup__step-two-animation" ref={stepTwoRef} />
              <h2 className="setup__step-heading">2</h2>
              <p className="setup__step-copy">
                {t("greetings.setup.step_two.copy")}
              </p>
            </li>

            <li className="setup__step setup__step-three">
              <div className="setup__step-three-animation" ref={stepThreeRef} />
              <h2 className="setup__step-heading">3</h2>
              <p className="setup__step-copy">
                {t("greetings.setup.step_three.copy")}
              </p>
            </li>

            <li className="setup__step setup__step-four">
              <div className="setup__step-four-animation" ref={stepFourRef} />
              <h2 className="setup__step-heading">4</h2>
              <p className="setup__step-copy">
                {t("greetings.setup.step_four.copy")}
              </p>
            </li>
          </ol>
        </div>
      </ContentFrame>
      <Footer
        className="greetings-footer__welcome"
        rightButton={
          <PrimaryButton
            onClick={onCompleteWelcomeFlow}
            icon={<ArrowRight />}
            iconPosition="right"
          >
            {t("button.got_it")}
          </PrimaryButton>
        }
      >
        <span className="help-text">
          {t("greetings.need_help")}
          <a
            href={`${config.urls.marketing}/faq`}
            target="_blank"
            rel="noopener noreferrer"
            className="help-link"
          >
            {t("greetings.faqs")}
          </a>
        </span>
      </Footer>
    </div>
  );
}

interface GreetingsInstructionsProps {
  onNext: () => void;
  onPrevious: () => void;
}

export function GreetingsInstructions({
  onPrevious,
  onNext,
}: GreetingsInstructionsProps) {
  const { t } = useTranslation();

  return (
    <div>
      <ContentFrame center={true}>
        <div className="Greetings">
          <span className="greetings__icon">
            <Chat />
          </span>
          <h1 className="greetings__headline">
            {t("greetings.instructions.title")}
          </h1>
          <p className="greetings__copy">{t("greetings.instructions.copy")}</p>
        </div>
      </ContentFrame>
      <Footer
        className="greetings-footer"
        leftButton={
          <BackButton type={"secondary"} onClick={onPrevious}>
            {t("button.back")}
          </BackButton>
        }
        rightButton={
          <NextButton onClick={onNext}>{t("button.next")}</NextButton>
        }
      />
    </div>
  );
}

interface GreetingsMicDisclaimerProps {
  onNext: () => void;
  onPrevious: () => void;
}

export function GreetingsMicDisclaimer({
  onPrevious,
  onNext,
}: GreetingsMicDisclaimerProps) {
  const { t } = useTranslation();

  return (
    <div>
      <ContentFrame center={true}>
        <div className="Greetings">
          <span className="greetings__icon greetings__icon__mic">
            <MicWithCircle />
          </span>
          <p className="greetings__disclaimer">
            {t("greetings.mic_disclaimer.copy")}
            {/* tslint:disable-next-line: react-a11y-anchors */}
            <a
              href={`${config.urls.marketing}/faq#who_can_access`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("greetings.mic_disclaimer.cta")}
            </a>
          </p>
        </div>
      </ContentFrame>
      <Footer
        className="greetings-footer"
        leftButton={
          <BackButton type={"secondary"} onClick={onPrevious}>
            {t("button.back")}
          </BackButton>
        }
        rightButton={
          <NextButton onClick={onNext}>{t("button.got_it")}</NextButton>
        }
      />
    </div>
  );
}

interface GreetingsRecordGreetingProps {
  current: RequiredGreetingTypes;
  currentNumber: number;
  total: number;
  canGoNext: boolean;
  currentRecording?: Recording;
  onPrevious: () => void;
  onNext: () => void;
  onStartRecording: () => void;
  onFinishRecording: (recording: Recording) => void;
  onDeleteRecording: () => void;
  playAudio: (
    audio: string,
    playHead?: number,
    onPlayback?: () => void,
  ) => void;
  pauseAudio: () => void;
  isSaving: boolean;
  isIOS: boolean;
}

export function GreetingsRecordGreeting({
  currentNumber,
  canGoNext,
  currentRecording,
  onPrevious,
  onNext,
  onStartRecording,
  onFinishRecording,
  onDeleteRecording,
  current,
  total,
  playAudio,
  pauseAudio,
  isSaving,
  isIOS,
}: GreetingsRecordGreetingProps) {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const isLast = currentNumber === total;

  return (
    <div className="Greetings">
      <ContentFrame center={true}>
        <div className="greetings_wrapper">
          <h1 className="greeting__label">{t(`greetings.${current}_label`)}</h1>
          <h2 className="greeting__prompt">
            {t(`greetings.${current}_prompt`)}
          </h2>

          {!isIOS ? (
            <RecordingInput
              key={currentNumber}
              initialRecording={currentRecording}
              onRecordStart={onStartRecording}
              onRecorded={onFinishRecording}
              onDelete={onDeleteRecording}
              playAudio={playAudio}
              pauseAudio={pauseAudio}
              customMobile={true}
            />
          ) : (
            <RecordingInputIOS
              key={currentNumber}
              initialRecording={currentRecording}
              onRecordStart={onStartRecording}
              onRecorded={onFinishRecording}
              onDelete={onDeleteRecording}
              playAudio={playAudio}
              pauseAudio={pauseAudio}
              customMobile={true}
            />
          )}
        </div>
      </ContentFrame>

      <Footer
        leftButton={
          <BackButton type={"secondary"} onClick={onPrevious}>
            {t("button.back")}
          </BackButton>
        }
        rightButton={
          <NextButton disabled={!canGoNext || isSaving} onClick={onNext}>
            {t(isLast ? "button.finish" : "button.next")}
          </NextButton>
        }
      >
        <p className="greetings_status">
          {width > 768
            ? t("greetings.completion", { current: currentNumber, total })
            : t("greetings.completion_mobile", {
                current: currentNumber,
                total,
              })}
        </p>
      </Footer>
    </div>
  );
}
