import { Action } from "@druyan/druyan";
import { SavedChapter } from "../../../../state";

export interface ReorderChapters extends Action<"ReorderChapters"> {
  chaptersInOrder: SavedChapter[];
}

export function reorderChapters(
  chaptersInOrder: SavedChapter[],
): ReorderChapters {
  return {
    type: "ReorderChapters",
    chaptersInOrder,
  };
}
