import React from "react";
import {
  useActions,
  useAudioContext,
  useAuth,
  useAwaitingUserStream,
  useRecordingController,
} from "../../hooks";
import { Recording } from "../../state";
import { generatorSingleton } from "../../utils";
import { StateContext, States } from "./machine";

import {
  EmptyRecordingInput,
  PausedRecordingInput,
  PlayingRecordingInput,
  RecordingRecordingInput,
} from "./RecordingInput";

export interface Props {
  initialRecording?: Recording;
  onRecordStart: () => void;
  onRecorded: (recording: Recording) => void;
  onDelete: () => void;
  playAudio: (
    audio: string,
    playHead?: number,
    onPlayback?: () => void,
  ) => void;
  pauseAudio: () => void;
  customMobile?: boolean | undefined;
}

// tslint:disable: max-func-body-length
export default function RecordingInput({
  initialRecording,
  onRecordStart,
  onRecorded,
  onDelete,
  playAudio,
  pauseAudio,
  customMobile,
}: Props) {
  const user = useAuth()!;
  const { awaitingStream, stopAudio } = useActions();
  const recordingController = useRecordingController()!;
  const audioContext = useAudioContext()!;
  const isAwaitingUserStream = useAwaitingUserStream();

  if (recordingController) {
    recordingController.useWav();
  }

  return (
    <StateContext.Create
      initialState={States.Entry(
        {
          user,
          onRecordStart,
          onRecorded,
          onDelete,
          playAudio,
          pauseAudio,
          recordingController,
          audioContext,
        },
        initialRecording,
      )}
    >
      {({ currentState, actions }) => {
        const startAction = async () => {
          try {
            awaitingStream(true);
            const stream = await recordingController!.start(audioContext);
            awaitingStream(false);
            actions.startRecording(stream!);
          } catch (error) {
            awaitingStream(false);
            actions.showWarning(error);
          }
        };

        const stopAction = async () => {
          try {
            const { audio } = await recordingController!.stop(audioContext);
            const runAsync = true;
            actions.stopRecording(audio, runAsync);
          } catch (error) {
            actions.showWarning();
          }
        };

        switch (currentState.name) {
          case "Entry":
            return null;

          case "Empty":
            return (
              <EmptyRecordingInput
                customMobile={customMobile}
                startRecording={startAction}
                isAwaitingUserStream={isAwaitingUserStream}
              />
            );

          case "Recording":
            const [, , , inProgressDuration] = currentState.data;

            return (
              <RecordingRecordingInput
                customMobile={customMobile}
                generator={generatorSingleton}
                stopRecording={stopAction}
                duration={inProgressDuration || 0}
              />
            );

          case "RecordedUnplayed":
            const [, unplayedRecording] = currentState.data;
            if (!unplayedRecording) {
              throw new Error("Expected recording to exist");
            }

            return (
              <PausedRecordingInput
                customMobile={customMobile}
                points={unplayedRecording.points}
                play={actions.play}
                trash={actions.deleteRecording}
                playPercentage={0}
                duration={unplayedRecording.duration}
              />
            );

          case "RecordedPaused":
            const [, pausedRecording, pausedPlayback] = currentState.data;

            if (!pausedRecording) {
              throw new Error("Expected recording to exist");
            }

            return (
              <PausedRecordingInput
                customMobile={customMobile}
                duration={pausedPlayback ? (pausedPlayback as number) : 0}
                points={pausedRecording.points}
                play={actions.play}
                trash={actions.deleteRecording}
                playPercentage={
                  pausedPlayback
                    ? (pausedPlayback as number) / pausedRecording.duration
                    : 0
                }
              />
            );

          case "RecordedPlaying":
            const [, recordedRecording, , playingPlayback] = currentState.data;

            if (!recordedRecording) {
              throw new Error("Expected recording to exist");
            }

            return (
              <PlayingRecordingInput
                customMobile={customMobile}
                points={recordedRecording.points}
                onUnmount={stopAudio}
                pause={actions.pause}
                trash={actions.deleteRecording}
                duration={recordedRecording.duration || 0}
                playPercentage={
                  playingPlayback
                    ? playingPlayback / recordedRecording.duration
                    : 0
                }
              />
            );
        }
      }}
    </StateContext.Create>
  );
}
