import "firebase/functions";
import { app } from "../firebase";

type Email = string;
type Token = string;

const sendInvitePromise = app.functions().httpsCallable("sendInvite");

export async function sendInvite(email: Email, stories: string[]) {
  await sendInvitePromise({ email, stories });
}

const acceptInvitePromise = app.functions().httpsCallable("acceptInvite");

export async function acceptInvite(token: Token, email: Email, name: string) {
  await acceptInvitePromise({ token, email, name });
}
