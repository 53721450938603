import {
  BackButton,
  NextButton,
  PrimaryButton,
} from "@storytime/shared/build/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Recording, SavedStory } from "../../state";
import { history, routeTo } from "../../utils";
import Footer from "../Footer";
import RecordingRow from "../RecordingRow";
import RecordingRowIOS from "../RecordingRowIOS";
import TextInput from "../TextInput";
import "./ChapterTitleInfo.css";

interface Props {
  id: string;
  title: string;
  onChange: (name: string) => void;
  onStartRecording: () => void;
  onSetRecording: (recording: Recording) => void;
  onDeleteRecording: () => void;
  canSubmit: boolean;
  onSubmit: () => void;
  playAudio: (
    audio: string,
    playHead?: number,
    onPlayback?: () => void,
  ) => void;
  pauseAudio: () => void;
  onBack: () => void;
  currentRecording?: Recording;
  isIOS: boolean;
  isSubmitting: boolean;
  story?: SavedStory;
}

// tslint:disable-next-line: max-func-body-length
export function ChapterTitleInfo({
  id,
  title,
  onChange,
  onStartRecording,
  onSetRecording,
  onDeleteRecording,
  canSubmit,
  onSubmit,
  playAudio,
  pauseAudio,
  onBack,
  currentRecording,
  isIOS,
  isSubmitting,
  story,
}: Props) {
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);

  const startRecording = () => {
    setIsRecording(true);
    onStartRecording();
  };

  const setRecording = (recording: Recording) => {
    setIsRecording(false);
    onSetRecording(recording);
  };

  const isEditMode = id === "edit_chapter_title" ? true : false;
  return (
    <div className="ChapterTitleInfo">
      <h3 className="section__headline">
        {!isEditMode
          ? t("create_chapter.chapter_headline_default")
          : t("create_chapter.chapter_headline_edit")}
      </h3>

      {!isEditMode ? (
        <h4>{t("create_chapter.chapter_title_helper")}</h4>
      ) : (
        <></>
      )}

      <div className="create_chapter_name_input_wrapper">
        <TextInput
          disabled={isRecording}
          labelledBy={id}
          value={title}
          onChange={onChange}
          placeholder={t("create_chapter.chapter_title_placeholder")}
        />
      </div>

      {!isIOS ? (
        <RecordingRow
          onRecordStart={startRecording}
          onRecorded={setRecording}
          onDelete={onDeleteRecording}
          playAudio={playAudio}
          pauseAudio={pauseAudio}
          initialRecording={currentRecording}
        >
          {!isEditMode ? (
            <h4>{t("create_chapter.chapter_title_recording_helper")}</h4>
          ) : (
            <></>
          )}
        </RecordingRow>
      ) : (
        <RecordingRowIOS
          onRecordStart={startRecording}
          onRecorded={setRecording}
          onDelete={onDeleteRecording}
          playAudio={playAudio}
          pauseAudio={pauseAudio}
          initialRecording={currentRecording}
        >
          {!isEditMode ? (
            <h4>{t("create_chapter.chapter_title_recording_helper")}</h4>
          ) : (
            <></>
          )}
        </RecordingRowIOS>
      )}

      <Footer
        className="CreateStory_footer"
        leftButton={
          !isEditMode ? (
            <BackButton
              type={"secondary"}
              onClick={onBack}
              disabled={isSubmitting}
            >
              {t("button.back")}
            </BackButton>
          ) : (
            <PrimaryButton
              // tslint:disable-next-line: react-this-binding-issue
              onClick={() => {
                history.replace(
                  routeTo.storyDetailEdit(story!.id, "edit-story"),
                );
              }}
            >
              {t("button.cancel")}
            </PrimaryButton>
          )
        }
        rightButton={
          !isEditMode ? (
            <NextButton onClick={onSubmit} disabled={!canSubmit}>
              {t("create_story.start_recording")}
            </NextButton>
          ) : (
            <PrimaryButton onClick={onSubmit} disabled={!canSubmit}>
              {t("button.done")}
            </PrimaryButton>
          )
        }
      />
    </div>
  );
}
