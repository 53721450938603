import { Action } from "@druyan/druyan";

export interface GeneratedZipFile extends Action<"GeneratedZipFile"> {
  url: string;
}

export function generatedZipFile(url: string): GeneratedZipFile {
  return {
    type: "GeneratedZipFile",
    url,
  };
}
