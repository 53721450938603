import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Actions as AppState, SavedStory } from "../../../../state";
import { confirm, Confirm, reject, Reject } from "../actions";

function ConfirmingEditorFromStoryRemoval(
  action: Enter | Confirm | Reject | Exit,
  story: SavedStory,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);
      const modalText = (story.isChapterStory) ? t("delete_editors_from_story_modal.text_chapter", {
        title: story.title,
      }) : t("delete_editors_from_story_modal.text_nonchapter", {
        title: story.title,
      });

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          text: modalText,
          confirmText: t("global.yes"),
          rejectText: t("global.no"),
          onConfirm,
          onReject,
        }),
      ];

    case "Reject":
      return goBack();

    case "Confirm":
      return [AppState.removeEditorsFromStory(story.id), goBack()];

    case "Exit":
      return AppState.hideModal();
  }
}

export default state(
  "ConfirmingEditorFromStoryRemoval",
  ConfirmingEditorFromStoryRemoval,
);
