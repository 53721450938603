import React from "react";
import { useInputFocusCheck } from "../../hooks";
import "./Footer.css";

interface Props {
  leftButton?: React.ReactChild;
  rightButton?: React.ReactChild;
  children?: React.ReactChild | React.ReactChild[];
  className?: string;
}

export function Footer({
  leftButton,
  rightButton,
  children,
  className,
}: Props) {
  const isInputFocused = useInputFocusCheck();
  return (
    <div className={`Footer ${className || ""} unfixed-${isInputFocused}`}>
      <div className="left-content">{leftButton ? leftButton : undefined}</div>

      <div className="center-content">{children}</div>

      <div className="right-content">
        {rightButton ? rightButton : undefined}
      </div>
    </div>
  );
}
