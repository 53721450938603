import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import { Confirm, confirm, Reject, reject } from "../actions";
import { Shared } from "../types";
import Submitting from "./Submitting";
function ShowingRerecordModal(
  action: Enter | Confirm | Reject | Exit,
  shared: Shared,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      shared.canSubmit = false;
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          text: t("rerecord_modal.text"),
          confirmText: t("rerecord_modal.edit_audio"),
          rejectText: t("rerecord_modal.save_info"),
          onConfirm,
          onReject,
        }),
      ];

    case "Confirm":
      return [AppState.hideModal(), goBack()];

    case "Reject":
      return [AppState.hideModal(), Submitting(shared)];

    case "Exit":
      return AppState.hideModal();
  }
}

export default state("ShowingRerecordModal", ShowingRerecordModal);
