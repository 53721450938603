import { useSharedState } from "./useSharedState";

export function useAwaitingUserStream() {
  const sharedState = useSharedState();
  if (!sharedState) {
    return false;
  } else {
    return sharedState.isAwaitingUserStream;
  }
}
