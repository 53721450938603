import { Action } from "@druyan/druyan";

export interface InputFocused extends Action<"App/InputFocused"> {
  isInputFocused: boolean;
}

export function inputFocused(isInputFocused: boolean): InputFocused {
  return {
    type: "App/InputFocused",
    isInputFocused,
  };
}
