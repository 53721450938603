import { Enter, noop, state, StateReturn, task } from "@druyan/druyan";
import { pathTo } from "@storytime/shared/build/pathTo";
import { modifyRecording } from "../../../../api";
import { Actions as AppState } from "../../../../state";
import * as ItemList from "../../../../utils/ItemList";
import {
  DeleteRecording,
  FinishRecording,
  Next,
  Previous,
  StartRecording,
} from "../actions";
import { Shared } from "../types";
import MicDisclaimer from "./MicDisclaimer";

// tslint:disable-next-line:max-func-body-length
function RecordGreeting(
  action:
    | Enter
    | Previous
    | Next
    | StartRecording
    | FinishRecording
    | DeleteRecording,
  shared: Shared,
): StateReturn | StateReturn[] {
  const {
    user,
    introGreetings,
    unsavedRecording,
    recordings,
    finishedGreetings,
  } = shared;

  const currentRecordingType = ItemList.getCurrent(introGreetings);
  const currentRecording = recordings[currentRecordingType] || unsavedRecording;

  switch (action.type) {
    case "Enter":
      shared.canGoNext = !!currentRecording;
      shared.isSaving = false;

      return update(shared);

    case "Previous":
      if (ItemList.isFirst(introGreetings)) {
        shared.unsavedRecording = undefined;

        return MicDisclaimer(shared);
      }

      shared.unsavedRecording = undefined;
      shared.introGreetings = ItemList.goToPrevious(introGreetings);

      return reenter(shared);

    case "StartRecording":
      shared.canGoNext = false;

      return update(shared);

    case "FinishRecording":
      shared.unsavedRecording = action.recording;

      return reenter(shared);

    case "DeleteRecording":
      const deleteEffects = [];

      if (currentRecording) {
        deleteEffects.push(AppState.deleteGreeting(currentRecordingType));

        delete shared.recordings[currentRecordingType];
      }

      shared.unsavedRecording = undefined;

      return [...deleteEffects, reenter(shared)];

    case "Next":
      if (!currentRecording || shared.isSaving) {
        return noop();
      }

      shared.isSaving = true;

      return task(async () => {
        const nextEffects = [];

        if (unsavedRecording) {
          const type = ItemList.getCurrent(introGreetings);

          const savedRecording = await modifyRecording(
            type,
            pathTo.db.greetingsCollection(user.uid),
            pathTo.storage.greetingsCollection(user.uid),
            unsavedRecording,
          );

          if (!savedRecording) {
            throw new Error("Saving recording failed");
          }

          nextEffects.push(AppState.saveGreeting(type, savedRecording));

          shared.recordings[type] = unsavedRecording;
        }

        if (ItemList.isLast(introGreetings)) {
          finishedGreetings();

          shared.unsavedRecording = undefined;

          return [...nextEffects, update(shared)];
        }

        shared.unsavedRecording = undefined;
        shared.introGreetings = ItemList.goToNext(introGreetings);

        return [...nextEffects, reenter(shared)];
      });
  }
}

// TODO: This state is doing a bit much. Not sure how we'd
// share the paging logic, but have more specific first/last
// and recording logic.
const RecordGreetingState = state("RecordGreeting", RecordGreeting);
const { update, reenter } = RecordGreetingState;
export default RecordGreetingState;
