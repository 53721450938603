import { Action } from "@druyan/druyan";
import { Recording } from "../../../../state";

export interface SetChapterTitleRecording
  extends Action<"SetChapterTitleRecording"> {
  recording: Recording;
}

export function setChapterTitleRecording(
  recording: Recording,
): SetChapterTitleRecording {
  return {
    type: "SetChapterTitleRecording",
    recording,
  };
}
