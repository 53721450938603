import React, { useMemo } from "react";
import { useTranslation } from 'react-i18next';
import { useAuth, useStories, useUserData } from "../../hooks";
import { OhnoFace } from '../Error/OhnoFace';
import { Invite, SelectStories } from "./Invite";
import { StateContext, States } from "./machine";

export default () => {
  const user = useAuth();
  const { editors, editorNames } = useUserData();
  const { myStories } = useStories();
  const { t } = useTranslation();

  if (!user || !user.email) {
    throw new Error("User with email must exist");
  }

  const listOfEditors = editors.map(id => ({
    id,
    name: editorNames[id],
  }));

  const listOfMyStories = useMemo(
    () => Object.values(myStories).sort((a, b) => a.createdAt - b.createdAt),
    [myStories],
  );

  return (listOfMyStories.length > 0) ? (
    <div>
      <StateContext.Create
        initialState={States.Entry({
          email: "",
          canSubmit: false,
          canShare: false,
          userEmail: user.email,
          storiesToShare: [],
        })}
      >
        {({ actions, currentState }) => {
          const [
            { email, canSubmit, canShare, storiesToShare },
          ] = currentState.data;

          switch (currentState.name) {
            case "Entry":
              return (
                <SelectStories
                  myStories={listOfMyStories}
                  onNext={actions.onNext}
                  storiesToShare={storiesToShare}
                  editorUsers={listOfEditors}
                  onSelectStories={actions.selectStories}
                  canShare={canShare}
                />
              );

            case "SelectedStories":
              return (
                <Invite
                  email={email}
                  canSubmit={canSubmit}
                  onChangeEmail={actions.setEmail}
                  onSubmit={actions.submit}
                />
              );
          }
        }}
      </StateContext.Create>
    </div>
  ) : (
      <div>
        <div className="ShareError --no-stories">
          <OhnoFace />
          <h2 className="header">{t("error.no_stories.header")}</h2>
          <p className="message">{t("error.no_stories.message")}</p>
        </div>
      </div>
    );
};
