export interface AudioPlayerPlaying {
  isPlaying: true;
  audio: string;
  playHead: number;
  onPlayback?: () => void;
}

export interface AudioPlayerEmpty {
  isPlaying: false;
  playHead: 0;
  onPlayback?: () => void;
}

export interface AudioPlayerPaused {
  isPlaying: false;
  playHead: number;
  onPlayback?: () => void;
}

export type AudioPlayer =
  | AudioPlayerPlaying
  | AudioPlayerEmpty
  | AudioPlayerPaused;

export function initialAudioPlayer(): AudioPlayer {
  return {
    isPlaying: false,
    playHead: 0,
  };
}
