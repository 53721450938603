import { Action } from "@druyan/druyan";

export interface ConfirmedFirstChapterModal
  extends Action<"App/ConfirmedFirstChapterModal"> {}

export function confirmedFirstChapterModal(): ConfirmedFirstChapterModal {
  return {
    type: "App/ConfirmedFirstChapterModal",
  };
}
