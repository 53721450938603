import { Action } from "@druyan/druyan";
import { Recording } from "../../../../state";

export interface SetRecording extends Action<"SetRecording"> {
  recording: Recording;
}

export function setRecording(recording: Recording): SetRecording {
  return {
    type: "SetRecording",
    recording,
  };
}
