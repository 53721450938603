import React from "react";
import { analytics } from "../../firebase";
import {
  useActions,
  useAuth,
  useGreetings,
  useIOSCheck,
  useRecordingController,
} from "../../hooks";
import { REQUIRED_GREETING_TYPES } from "../../state";
import { history, routeTo } from "../../utils";
import * as ItemList from "../../utils/ItemList";
import {
  GreetingsInstructions,
  GreetingsMicDisclaimer,
  GreetingsRecordGreeting,
} from "./Greetings";
import { StateContext, States } from "./machine";

interface Props {
  isFirstRun?: boolean;
  finishedGreetings?: () => void;
}

// tslint:disable-next-line: max-func-body-length
export default function Greetings({ finishedGreetings, isFirstRun }: Props) {
  const { playAudio, pauseAudio, completeGreetings } = useActions();
  const user = useAuth();
  const { recordings } = useGreetings();
  const isIOS = useIOSCheck();
  const recordingController = useRecordingController()!;

  if (recordingController) {
    recordingController.useWav();
  }

  if (!user) {
    throw new Error("Cannot record Greetings without a user");
  }

  const onFinishedGreetings =
    isFirstRun && finishedGreetings
      ? finishedGreetings
      : () => {
          completeGreetings();
          history.push(routeTo.dashboard());
        };

  return (
    <div>
      <StateContext.Create
        initialState={States.Entry({
          user,
          introGreetings: ItemList.create(REQUIRED_GREETING_TYPES),
          canGoNext: true,
          recordings,
          isFirstRun: isFirstRun || false,
          finishedGreetings: onFinishedGreetings,
          isSaving: false,
        })}
      >
        {({ currentState, actions: greetingsActions }) => {
          const startAction = async () => {
            try {
              greetingsActions.startRecording();
            } catch (error) {
              greetingsActions.showWarning(error);
            }
          };
          const [
            {
              introGreetings,
              canGoNext,
              unsavedRecording,
              recordings: updatedRecordings,
              isSaving,
            },
          ] = currentState.data;

          switch (currentState.name) {
            case "Instructions":
              analytics.logEvent("Greetings", {
                start_greetings_flow: true,
              });
              return (
                <GreetingsInstructions
                  onNext={greetingsActions.next}
                  // tslint:disable-next-line:react-this-binding-issue
                  onPrevious={() => history.goBack()}
                />
              );

            case "MicDisclaimer":
              return (
                <GreetingsMicDisclaimer
                  onNext={greetingsActions.next}
                  onPrevious={greetingsActions.previous}
                />
              );

            case "RecordGreeting":
              const current = ItemList.getCurrent(introGreetings);
              const total = ItemList.count(introGreetings);
              const currentRecording =
                updatedRecordings[current] || unsavedRecording || undefined;
              const currentItem = ItemList.currentIndex(introGreetings) + 1;

              analytics.logEvent("Record Greetings", {
                greeting_number: currentItem,
              });

              return (
                <GreetingsRecordGreeting
                  current={current}
                  key={currentItem}
                  currentNumber={currentItem}
                  total={total}
                  currentRecording={currentRecording}
                  canGoNext={canGoNext}
                  onPrevious={greetingsActions.previous}
                  onNext={greetingsActions.next}
                  onStartRecording={startAction}
                  onFinishRecording={greetingsActions.finishRecording}
                  onDeleteRecording={greetingsActions.deleteRecording}
                  playAudio={playAudio}
                  pauseAudio={pauseAudio}
                  isSaving={isSaving}
                  isIOS={isIOS}
                />
              );
          }
        }}
      </StateContext.Create>
    </div>
  );
}
