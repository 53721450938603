import { StateReturn } from "@druyan/druyan";
import { InputFocused } from "../../actions";
import { Shared } from "../../model";
import Ready from "./index";

export type InputActions = InputFocused;

export function inputActions(
  action: InputActions,
  shared: Shared,
  update: (shared: Shared) => ReturnType<typeof Ready>,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "App/InputFocused":
      shared.isInputFocused = action.isInputFocused;
      return update(shared);
  }
}
