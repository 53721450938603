import { Enter, Exit, noop, state, StateReturn, task } from "@druyan/druyan";
import { pathTo } from "@storytime/shared/build/pathTo";
import { convertAudioArbitrary, uploadAudio } from "../../../../api";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import {
  blobToBuffer,
  bufferToBlob,
  processAudioBuffer,
  redirectTo,
  routeTo,
} from "../../../../utils";
import {
  FinishedProcessing,
  finishedProcessing,
  StartedProcessing,
  startedProcessing,
} from "../actions";
import { Shared } from "../types";
import RecordedUnplayed from "./RecordedUnplayed";

function ProcessingRecording(
  action: Enter | StartedProcessing | FinishedProcessing | Exit,
  shared: Shared,
  recordedAudio: Blob,
  runAsync?: boolean,
): StateReturn | StateReturn[] {
  const { user, onRecorded, audioContext } = shared;

  switch (action.type) {
    case "Enter":
      if (runAsync) {
        return task(async () => {
          // Runs the convert, discards the returned buffer
          convertFileToMp3(user.uid, recordedAudio);

          const promisifiedBuffer = async () => {
            return await blobToBuffer(recordedAudio);
          };
          return finishedProcessing(await promisifiedBuffer());
        });
      }

      return [
        AppState.showProcessingIndicator([
          t("processing_indicator.sprinkling"),
          t("processing_indicator.mixing"),
          t("processing_indicator.saving"),
          t("processing_indicator.tucking"),
        ]),
        startedProcessing(),
      ];

    case "StartedProcessing":
      return task(async () => {
        return finishedProcessing(
          await convertFileToMp3(user.uid, recordedAudio),
        );
      });

    case "FinishedProcessing":
      return task(async () => {
        try {
          // Get the waveform and duration from the buffer.
          const { points, duration } = await processAudioBuffer(
            action.buffer,
            audioContext,
          );

          const recording = {
            // URL for the <audio> src.
            audio: URL.createObjectURL(bufferToBlob(action.buffer)),
            isUpdated: true,
            duration,
            points,
          };

          onRecorded(recording);

          // Update global state.
          return RecordedUnplayed(shared, recording);
        } catch (error) {
          // tslint:disable-next-line: no-console
          console.error(error);

          return redirectTo(routeTo.error());
        }
      });

    case "Exit":
      return runAsync ? noop() : AppState.hideModal();
  }
}

async function convertFileToMp3(
  userId: string,
  audio: Blob,
): Promise<ArrayBuffer> {
  // tslint:disable-next-line: no-console
  console.time("Uploading unconverted arbitrary recording");

  // Upload to storage
  await uploadAudio(
    pathTo.storage.unconvertedArbitraryRecording(userId),
    audio,
  );

  // tslint:disable-next-line: no-console
  console.timeEnd("Uploading unconverted arbitrary recording");

  // tslint:disable-next-line: no-console
  console.time("Remotely converting audio to mp3");

  const {
    data: { success, publicUrl },
  } = await convertAudioArbitrary();

  // tslint:disable-next-line: no-console
  console.timeEnd("Remotely converting audio to mp3");

  if (!success) {
    throw new Error("convertAudioArbitrary failed");
  }

  // tslint:disable-next-line: no-console
  console.time("Fetching converted file");

  // Then finally download new file and continue.
  const buffer = fetch(publicUrl).then(r => r.arrayBuffer());

  // tslint:disable-next-line: no-console
  console.timeEnd("Fetching converted file");

  return buffer;
}

export default state("ProcessingRecording", ProcessingRecording);
