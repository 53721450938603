import React from "react";
import { SavedStory } from "../../state";
import { ChapterRowIsEditing, Props } from "./ChapterRowIsEditing";
import { StateContext, States } from "./machine";

export default ({
  story,
  chapter: initialChapter,
  draggableHandleProps,
  title,
}: Omit<Props, "onDelete" | "onEdit"> & {
  story: SavedStory;
  user?: firebase.User;
  title: string;
}) => {
  return (
    <StateContext.Create
      initialState={States.Entry({
        story,
        chapter: initialChapter,
        canSubmit: true,
        title,
      })}
    >
      {({
        actions,
        currentState: {
          name,
          data: [{ chapter }],
        },
      }) => {
        return (
          <ChapterRowIsEditing
            chapter={chapter}
            draggableHandleProps={draggableHandleProps}
            isEditingTitle={name === "EditingTitle"}
            story={story}
            onDelete={actions.deleteChapter}
          />
        );
      }}
    </StateContext.Create>
  );
};
