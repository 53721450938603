import React from "react";
import { useTranslation } from "react-i18next";
import ContentFrame from "../../components/ContentFrame";
import CopyToClipboard from "../../components/CopyToClipboard";
import { DeviceIllustration } from "./DeviceIllustration";
import "./DeviceIllustration.css";

export function DeviceGate() {
  const { t } = useTranslation();

  return (
    <div className="DeviceGate">
      <ContentFrame>
        <DeviceIllustration className="illustration" />
        <div className="content">
          <h1 className="title">{t("device_gate.title")}</h1>
          <p className="body">{t("device_gate.body")}</p>
          <CopyToClipboard copyTarget="https://mystorytime.com" />
          <a href="https://mystorytime.com" className="link">
            mystorytime.com
          </a>
        </div>
      </ContentFrame>
    </div>
  );
}
