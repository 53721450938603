import { BackButton, NextButton } from "@storytime/shared/build/components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ChapterTitleInfo from "../../components/ChapterTitleInfo";
import ContentFrame from "../../components/ContentFrame";
import Footer from "../../components/Footer";
import RadioGroup from "../../components/RadioGroup";
import RecordingRow from "../../components/RecordingRow";
import RecordingRowIOS from "../../components/RecordingRowIOS";
import TextInput from "../../components/TextInput";
import { Recording, Story, UnsavedChapter } from "../../state";
import { history } from "../../utils";
import "./CreateStory.css";

interface Props {
  story: Story;
  chapter: UnsavedChapter;
  onSetTitle: (title: string) => void;
  onSetAuthor: (author: string) => void;
  onSetChapterTitle: (author: string) => void;
  onSetDoesStoryHaveChapters: (value: string) => void;
  onStartRecording: () => void;
  onStartRecordingChapterTitle: () => void;
  onSetRecording: (recording: Recording) => void;
  onSetChapterTitleRecording: (recording: Recording) => void;
  onDeleteRecording: () => void;
  onDeleteChapterTitleRecording: () => void;
  canSubmit: boolean;
  canSubmitChapterTitle: boolean;
  onSubmit: () => void;
  playAudio: (
    audio: string,
    playHead?: number,
    onPlayback?: () => void,
  ) => void;
  pauseAudio: () => void;
  onCreateChapter: () => void;
  isCreatingChapter?: boolean;
  isIOS: boolean;
  isSubmitting: boolean;
  onBack: () => void;
}

export function CreateStory({
  story,
  chapter,
  canSubmit,
  canSubmitChapterTitle,
  onSetTitle,
  onSetAuthor,
  onSetChapterTitle,
  onStartRecording,
  onStartRecordingChapterTitle,
  onSetRecording,
  onSetChapterTitleRecording,
  onDeleteRecording,
  onDeleteChapterTitleRecording,
  onSetDoesStoryHaveChapters,
  onSubmit,
  playAudio,
  pauseAudio,
  onCreateChapter,
  isCreatingChapter,
  isIOS,
  isSubmitting,
  onBack,
}: Props) {
  const { t } = useTranslation();
  const [isRecording, setIsRecording] = useState(false);

  const startRecording = () => {
    setIsRecording(true);
    onStartRecording();
  };

  const setRecording = (recording: Recording) => {
    setIsRecording(false);
    onSetRecording(recording);
  };

  const titleRecording =
    story.recordings && story.recordings.title
      ? story.recordings.title
      : undefined;

  // tslint:disable-next-line: max-func-body-length
  return (
    <div>
      <ContentFrame>
        <div className="CreateStory">
          {!isCreatingChapter ? (
            <div>
              <h3 className="section__headline">Story Info</h3>
              <div className="create_text_inputs_wrapper">
                <div className="create_text_input --first">
                  <h4 id="setup_title">{t("create_story.title")}</h4>
                  <TextInput
                    disabled={isRecording}
                    labelledBy="setup_title"
                    value={story.title}
                    onChange={onSetTitle}
                  />
                </div>

                <div className="create_text_input">
                  <h4 id="setup_author">
                    {t("create_story.author")}
                    <span className="input-optional">
                      ({t("global.optional")})
                    </span>
                  </h4>
                  <TextInput
                    disabled={isRecording}
                    labelledBy="setup_author"
                    value={story.author}
                    onChange={onSetAuthor}
                  />
                </div>
              </div>

              {!isIOS ? (
                <div className="create_recording_row">
                  <RecordingRow
                    onRecordStart={startRecording}
                    onRecorded={setRecording}
                    onDelete={onDeleteRecording}
                    initialRecording={titleRecording}
                    playAudio={playAudio}
                    pauseAudio={pauseAudio}
                  >
                    <h4>{t("create_story.record_title_and_author")}</h4>
                  </RecordingRow>
                </div>
              ) : (
                <div className="create_recording_row">
                  <RecordingRowIOS
                    onRecordStart={startRecording}
                    onRecorded={setRecording}
                    onDelete={onDeleteRecording}
                    initialRecording={titleRecording}
                    playAudio={playAudio}
                    pauseAudio={pauseAudio}
                  >
                    <h4>{t("create_story.record_title_and_author")}</h4>
                  </RecordingRowIOS>
                </div>
              )}

              <div className="create_toggle_chapters">
                <h4 id="toggle_chapters">
                  {t("create_story.toggle_chapters")}
                </h4>
                <RadioGroup
                  labelledBy="toggle_chapters"
                  name="doesStoryHaveChapters"
                  value={story.isChapterStory.toString()}
                  disabled={isRecording}
                  options={[
                    { value: "false", label: t("global.no") },
                    { value: "true", label: t("global.yes") },
                  ]}
                  onChange={onSetDoesStoryHaveChapters}
                />
              </div>

              <Footer
                className="CreateStory_footer"
                leftButton={
                  <BackButton
                    type={"secondary"}
                    onClick={history.goBack}
                    disabled={isSubmitting}
                  >
                    {t("button.back")}
                  </BackButton>
                }
                rightButton={
                  <NextButton
                    // tslint:disable-next-line: react-this-binding-issue
                    onClick={() => {
                      !story.isChapterStory ? onSubmit() : onCreateChapter();
                    }}
                    disabled={!canSubmit}
                  >
                    {t("create_story.start_recording")}
                  </NextButton>
                }
              />
            </div>
          ) : (
            <ChapterTitleInfo
              id="setup_chapter_name"
              title={chapter.title}
              onChange={onSetChapterTitle}
              onStartRecording={onStartRecordingChapterTitle}
              onSetRecording={onSetChapterTitleRecording}
              onDeleteRecording={onDeleteChapterTitleRecording}
              canSubmit={canSubmitChapterTitle}
              onSubmit={onSubmit}
              playAudio={playAudio}
              pauseAudio={pauseAudio}
              onBack={onBack}
              isIOS={isIOS}
              isSubmitting={isSubmitting}
            />
          )}
        </div>
      </ContentFrame>
    </div>
  );
}
