import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { SetEmail, setEmail, Submit } from "../actions";
import { Shared } from "../types";
import Submitting from "./Submitting";

function SelectedStories(
  action: Enter | SetEmail | Submit,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { canSubmit, userEmail } = shared;

  switch (action.type) {
    case "Enter":
      return setEmail("");

    case "SetEmail":
      shared.email = action.email;

      // Dumbest possible check: ANY_CHARS@ANY_CHARS.ANY_CHARS
      // Check that user isn't submitting own email
      shared.canSubmit =
        action.email.match(/^.+@.+\..+$/) !== null &&
        action.email !== userEmail;

      return update(shared);

    case "Submit":
      return canSubmit ? Submitting(shared) : noop();
  }
}

const SelectedStoriesState = state("SelectedStories", SelectedStories);
const { update } = SelectedStoriesState;
export default SelectedStoriesState;
