import {
  DeleteButtonUnfilled,
  PlayButtonUnfilled,
} from "@storytime/shared/build/components";
import React, { useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import { ScalarGenerator } from "../../utils";
import RecordButton from "../RecordButton";
import Spinner from "../Spinner";
import Visualizer, { Point, StaticVisualizer } from "../Visualizer";
import "./RecordingInput.css";

const { floor } = Math;

const DESKTOP_BP = 768;
const DESKTOP_PADDING = 70;
const DESKTOP_RECORDER_WIDTH = 700;
const RECORD_BUTTONS_WIDTH = 120;
const CUSTOM_MOBILE_DURATION_WIDTH = 64;

function renderDuration(duration: number) {
  if (duration <= 0) {
    duration = 0;
  }

  const minutes = floor(duration / 60);

  let seconds = floor(duration % 60).toString();

  if (seconds.length === 1) {
    seconds = `0${seconds}`;
  }

  return `${minutes}:${seconds}`;
}

export interface EmptyProps {
  startRecording: () => void;
  customMobile?: boolean;
  isAwaitingUserStream: boolean;
}

export function DefaultEmptyRecordingInput({
  startRecording,
  isAwaitingUserStream,
}: EmptyProps) {
  return (
    <div className="RecordingInput">
      <div className="RecordingInput_middleLine" />
      <div className="RecordingInput_buttons">
        <div className="RecordingInput_button_and_spinner">
          {isAwaitingUserStream ? (
            <Spinner className="small" />
          ) : (
            <RecordButton
              onClick={startRecording}
              size="small"
              active={false}
            />
          )}
        </div>
        <div className="RecordingInput_duration">{renderDuration(0)}</div>
      </div>
    </div>
  );
}

export function CustomMobileEmptyRecordingInput({
  startRecording,
}: EmptyProps) {
  return (
    <div className="CustomRecordingInput">
      <div className="RecordingInput">
        <div className="RecordingInput_middleLine" />
        <div className="RecordingInput_duration">{renderDuration(0)}</div>
      </div>
      <RecordButton onClick={startRecording} size="large" active={false} />
    </div>
  );
}

export function EmptyRecordingInput({
  startRecording,
  customMobile,
  isAwaitingUserStream,
}: EmptyProps) {
  const { width } = useWindowSize();
  return width < DESKTOP_BP && customMobile ? (
    <CustomMobileEmptyRecordingInput
      isAwaitingUserStream={isAwaitingUserStream}
      startRecording={startRecording}
    />
  ) : (
    <DefaultEmptyRecordingInput
      isAwaitingUserStream={isAwaitingUserStream}
      startRecording={startRecording}
    />
  );
}

export interface RecordingProps {
  generator: ScalarGenerator;
  stopRecording: () => void;
  duration: number;
  customWidth?: number;
  customMobile?: boolean;
}

export function DefaultRecordingRecordingInput({
  generator,
  stopRecording,
  duration,
}: RecordingProps) {
  const { width } = useWindowSize();
  const [recorderWidth, setRecorderWidth] = useState(
    width > DESKTOP_BP + DESKTOP_PADDING
      ? DESKTOP_RECORDER_WIDTH - RECORD_BUTTONS_WIDTH
      : width - RECORD_BUTTONS_WIDTH,
  );

  useEffect(() => {
    setRecorderWidth(
      width > DESKTOP_BP + DESKTOP_PADDING
        ? DESKTOP_RECORDER_WIDTH - RECORD_BUTTONS_WIDTH
        : width - RECORD_BUTTONS_WIDTH,
    );
  }, [width]);

  return (
    <div className="RecordingInput">
      <div className="RecordingInput_middleLine" />

      <div className="RecordingInput_buttons">
        <RecordButton
          onClick={stopRecording}
          size="small"
          active={true}
          generator={generator}
        />

        <div className="RecordingInput_duration">
          {renderDuration(duration)}
        </div>
      </div>

      <Visualizer
        className="RecordingInput_visualizer"
        width={recorderWidth}
        height={60}
        color="#bd72f2"
        generator={generator}
      />
    </div>
  );
}

export function CustomMobileRecordingRecordingInput({
  generator,
  stopRecording,
  duration,
}: RecordingProps) {
  const { width } = useWindowSize();

  const [recorderWidth, setRecorderWidth] = useState(
    width > DESKTOP_BP + DESKTOP_PADDING
      ? DESKTOP_RECORDER_WIDTH - CUSTOM_MOBILE_DURATION_WIDTH
      : width - CUSTOM_MOBILE_DURATION_WIDTH,
  );

  useEffect(() => {
    setRecorderWidth(
      width > DESKTOP_BP + DESKTOP_PADDING
        ? DESKTOP_RECORDER_WIDTH - CUSTOM_MOBILE_DURATION_WIDTH
        : width - CUSTOM_MOBILE_DURATION_WIDTH,
    );
  }, [width]);

  return (
    <div className="CustomRecordingInput">
      <div className="RecordingInput">
        <div className="RecordingInput_middleLine" />
        <div className="RecordingInput_duration">
          {renderDuration(duration)}
        </div>
        <Visualizer
          className="RecordingInput_visualizer"
          width={recorderWidth}
          height={60}
          color="#bd72f2"
          generator={generator}
        />
      </div>
      <RecordButton
        onClick={stopRecording}
        size="large"
        active={true}
        generator={generator}
      />
    </div>
  );
}

export function RecordingRecordingInput({
  generator,
  stopRecording,
  duration,
  customMobile,
}: RecordingProps) {
  const { width } = useWindowSize();

  return width < DESKTOP_BP && customMobile ? (
    <CustomMobileRecordingRecordingInput
      generator={generator}
      stopRecording={stopRecording}
      duration={duration}
    />
  ) : (
    <DefaultRecordingRecordingInput
      generator={generator}
      stopRecording={stopRecording}
      duration={duration}
    />
  );
}

export interface PlayingProps {
  points: Point[];
  pause: () => void;
  trash: () => void;
  playPercentage: number;
  onUnmount?: () => void;
  duration: number | 0;
  customMobile?: boolean;
}

export function DefaultPlayingRecordingInput({
  points,
  playPercentage,
  pause,
  trash,
  onUnmount,
}: PlayingProps) {
  const containerRef = React.createRef<HTMLDivElement>();
  const [recorderWidth, setRecorderWidth] = useState<number>(0);
  const { width } = useWindowSize();

  useEffect(() => {
    if (containerRef.current) {
      setRecorderWidth(containerRef.current.clientWidth - RECORD_BUTTONS_WIDTH);
    }
  }, [width, containerRef]);

  // Pause on unmount (aka leaving the page).
  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  return (
    <div className="RecordingInput" ref={containerRef}>
      <div className="RecordingInput_middleLine" />

      <div className="RecordingInput_buttons">
        <PlayButtonUnfilled onClick={pause} size="small" active={true} />
        <DeleteButtonUnfilled onClick={trash} size="small" />
      </div>

      <StaticVisualizer
        className="RecordingInput_visualizer"
        pixelsPerSecond={Visualizer.PIXELS_PER_SECOND}
        width={recorderWidth}
        height={60}
        color="#bd72f2"
        points={points}
        maskColor="#f8656a"
        maskPercentage={playPercentage}
      />
    </div>
  );
}

export function CustomMobilePlayingRecordingInput({
  points,
  playPercentage,
  pause,
  trash,
  onUnmount,
  duration,
}: PlayingProps) {
  const containerRef = React.createRef<HTMLDivElement>();
  const [recorderWidth, setRecorderWidth] = useState<number>(0);
  const { width } = useWindowSize();

  useEffect(() => {
    if (containerRef.current) {
      setRecorderWidth(
        containerRef.current.clientWidth - CUSTOM_MOBILE_DURATION_WIDTH,
      );
    }
  }, [width, containerRef]);

  // Pause on unmount (aka leaving the page).
  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  return (
    <div className="CustomRecordingInput">
      <div className="RecordingInput" ref={containerRef}>
        <div className="RecordingInput_middleLine" />
        <div className="RecordingInput_duration">
          {renderDuration(duration)}
        </div>
        <StaticVisualizer
          className="RecordingInput_visualizer"
          pixelsPerSecond={Visualizer.PIXELS_PER_SECOND}
          width={recorderWidth}
          height={60}
          color="#bd72f2"
          points={points}
          maskColor="#f8656a"
          maskPercentage={playPercentage}
        />
      </div>

      <div className="RecordingInput_buttons">
        <DeleteButtonUnfilled onClick={trash} size="small" />
        <PlayButtonUnfilled onClick={pause} size="small" active={true} />
      </div>
    </div>
  );
}

export function PlayingRecordingInput({
  points,
  playPercentage,
  pause,
  trash,
  onUnmount,
  duration,
  customMobile,
}: PlayingProps) {
  const { width } = useWindowSize();

  // Pause on unmount (aka leaving the page).
  useEffect(() => {
    return () => {
      if (onUnmount) {
        onUnmount();
      }
    };
  }, [onUnmount]);

  return width < DESKTOP_BP && customMobile ? (
    <CustomMobilePlayingRecordingInput
      points={points}
      pause={pause}
      trash={trash}
      playPercentage={playPercentage}
      duration={duration}
    />
  ) : (
    <DefaultPlayingRecordingInput
      points={points}
      pause={pause}
      trash={trash}
      playPercentage={playPercentage}
      duration={duration}
    />
  );
}

export interface PausedProps {
  points: Point[];
  playPercentage: number;
  play: () => void;
  trash: () => void;
  duration: number | 0;
  customMobile?: boolean;
}

export function DefaultPausedRecordingInput({
  points,
  playPercentage,
  play,
  trash,
}: PausedProps) {
  const containerRef = React.createRef<HTMLDivElement>();
  const [recorderWidth, setRecorderWidth] = useState<number>(0);
  const { width } = useWindowSize();

  useEffect(() => {
    if (containerRef.current) {
      setRecorderWidth(containerRef.current.clientWidth - RECORD_BUTTONS_WIDTH);
    }
  }, [width, containerRef]);

  return (
    <div className="RecordingInput" ref={containerRef}>
      <div className="RecordingInput_middleLine" />

      <div className="RecordingInput_buttons">
        <PlayButtonUnfilled onClick={play} size="small" />
        <DeleteButtonUnfilled onClick={trash} size="small" />
      </div>

      <StaticVisualizer
        className="RecordingInput_visualizer"
        pixelsPerSecond={Visualizer.PIXELS_PER_SECOND}
        width={recorderWidth}
        height={60}
        color="#bd72f2"
        points={points}
        maskColor="#f8656a"
        maskPercentage={playPercentage}
      />
    </div>
  );
}

export function CustomMobilePausedRecordingInput({
  points,
  playPercentage,
  play,
  trash,
  duration,
}: PausedProps) {
  const containerRef = React.createRef<HTMLDivElement>();
  const [recorderWidth, setRecorderWidth] = useState<number>(0);
  const { width } = useWindowSize();

  useEffect(() => {
    if (containerRef.current) {
      setRecorderWidth(
        containerRef.current.clientWidth - CUSTOM_MOBILE_DURATION_WIDTH,
      );
    }
  }, [width, containerRef]);

  return (
    <div className="CustomRecordingInput">
      <div className="RecordingInput" ref={containerRef}>
        <div className="RecordingInput_middleLine" />
        <div className="RecordingInput_duration">
          {renderDuration(duration)}
        </div>
        <StaticVisualizer
          className="RecordingInput_visualizer"
          pixelsPerSecond={Visualizer.PIXELS_PER_SECOND}
          width={recorderWidth}
          height={60}
          color="#bd72f2"
          points={points}
          maskColor="#f8656a"
          maskPercentage={playPercentage}
        />
      </div>
      <div className="RecordingInput_buttons">
        <DeleteButtonUnfilled onClick={trash} size="small" />
        <PlayButtonUnfilled onClick={play} size="small" />
      </div>
    </div>
  );
}
export function PausedRecordingInput({
  points,
  playPercentage,
  play,
  trash,
  duration,
  customMobile,
}: PausedProps) {
  const { width } = useWindowSize();

  return width < DESKTOP_BP && customMobile ? (
    <CustomMobilePausedRecordingInput
      points={points}
      playPercentage={playPercentage}
      play={play}
      trash={trash}
      duration={duration}
    />
  ) : (
    <DefaultPausedRecordingInput
      points={points}
      playPercentage={playPercentage}
      play={play}
      trash={trash}
      duration={duration}
    />
  );
}
