import { Action } from "@druyan/druyan";
import { SavedChapter, SavedStory } from "../model";

export interface ReorderChapters extends Action<"App/ReorderChapters"> {
  story: SavedStory;
  chaptersInOrder: SavedChapter[];
}

export function reorderChapters(
  story: SavedStory,
  chaptersInOrder: SavedChapter[],
): ReorderChapters {
  return {
    type: "App/ReorderChapters",
    story,
    chaptersInOrder,
  };
}
