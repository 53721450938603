import { Enter, noop, state, StateReturn, task } from "@druyan/druyan";
import { createChapter, createStory } from "../../../../api";
import { emptyChapter } from "../../../../state";
import { Actions as AppState } from "../../../../state";
import { redirectTo, routeTo } from "../../../../utils";
import { DeleteRecording, sendData, SendData, Submit } from "../actions";
import { Shared } from "../types";

function Submitting(
  action: Enter | Submit | SendData | DeleteRecording,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { user, story, chapter } = shared;

  switch (action.type) {
    case "Enter":
      shared.canSubmit = false;
      shared.isSubmitting = true;
      return [update(shared), sendData()];

    case "SendData":
      return [
        task(async () => {
          // Blank title for non-chapter stories.
          const chapterTitle = story.isChapterStory ? chapter.title : "";

          const chapterToBeSaved = {
            ...emptyChapter(),
            title: chapterTitle,
            recordings: chapter.recordings,
          };

          const savedStory = await createStory(user, story);
          const savedChapter = await createChapter(
            user,
            savedStory,
            chapterToBeSaved,
          );

          savedStory.chapters[savedChapter.id] = savedChapter;

          return [
            AppState.didCreateStory(savedStory),
            redirectTo(routeTo.editChapter(savedStory.id, savedChapter.id)),
          ];
        }),
      ];

    case "DeleteRecording":
      return noop();

    case "Submit":
      return noop();
  }
}

const SubmittingState = state("Submitting", Submitting);
const { update } = SubmittingState;
export default SubmittingState;
