import { Enter, state, StateReturn } from "@druyan/druyan";
import { Recording as RecordingType } from "../../../../state";
import { Shared } from "../types";
import Empty from "./Empty";
import RecordedUnplayed from "./RecordedUnplayed";

function Entry(
  action: Enter,
  shared: Shared,
  recording: RecordingType | undefined,
): StateReturn {
  switch (action.type) {
    case "Enter":
      shared.existingRecording = recording ? recording : undefined;
      return recording ? RecordedUnplayed(shared, recording) : Empty(shared);
  }
}

export default state("Entry", Entry);
