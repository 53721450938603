import { Point } from "../components/Visualizer";
import { fileToArrayBuffer } from "./binary";

// tslint:disable-next-line: no-var-requires
const WaveformData = require("waveform-data");

declare class WaveformChannel {
  max_sample(x: number): number;
  min_sample(x: number): number;
}

// tslint:disable-next-line: max-classes-per-file
declare class WaveformDataResult {
  length: number;
  channels: number;

  channel(which: number): WaveformChannel;
}

interface Response {
  points: Point[];
  duration: number;
}

export async function processAudioFile(
  file: File,
  audioContext: AudioContext,
): Promise<Response> {
  const arrayBuffer = await fileToArrayBuffer(file);

  return processAudioBuffer(arrayBuffer, audioContext);
}

// tslint:disable-next-line: max-func-body-length
export async function processAudioBuffer(
  originalBuffer: ArrayBuffer,
  audioContext: AudioContext,
): Promise<Response> {
  // Clone the buffer
  const arrayBuffer = await new Response(
    new Blob([originalBuffer], {
      type: "audio/mpeg",
    }),
  ).arrayBuffer();

  const context = audioContext;

  // tslint:disable-next-line: no-console
  console.time("Decode audio buffer");

  const buffer = await new Promise<AudioBuffer>((onDecoded, reject) => {
    context.decodeAudioData(arrayBuffer, onDecoded, (...error) => {
      // tslint:disable-next-line: no-console
      console.error("Audio decoding failed", ...error);
      reject();
    });
  });

  // tslint:disable-next-line: no-console
  console.timeEnd("Decode audio buffer");

  const options = {
    audio_context: context,
    audio_buffer: buffer,
    scale: 128,
  };

  // tslint:disable-next-line: no-console
  console.time("Generate waveform");

  const waveform = await new Promise<WaveformDataResult>((resolve, reject) => {
    WaveformData.createFromAudio(
      options,
      (err: any, result: WaveformDataResult) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      },
    );
  });

  // tslint:disable-next-line: no-console
  console.timeEnd("Generate waveform");

  // tslint:disable-next-line: no-console
  console.time("Confine waveform to range");

  const channel = waveform.channel(0);
  const totalPoints = Math.min(
    Math.max(Math.ceil(buffer.duration / 0.2), 100),
    200,
  );
  const step = waveform.length / totalPoints;

  const samples = Array(totalPoints)
    .fill(undefined)
    .map((_, i) => {
      return (
        Math.abs(channel.max_sample(i * step)) +
        Math.abs(channel.min_sample(i * step))
      );
    });

  const heighest = Math.max(...samples);

  const relative = samples.map(s => {
    return s / heighest;
  });

  // tslint:disable-next-line: no-console
  console.timeEnd("Confine waveform to range");

  return {
    duration: buffer.duration,
    points: relative.map((s, i) => Point.get(i * 0.2, s).finish()),
  };
}

export function volumeProcessorGraph(
  stream: MediaStream,
  context: OfflineAudioContext | AudioContext,
  input: AudioBufferSourceNode | MediaStreamAudioSourceNode,
  onFrame: (volume: number) => void,
) {
  let peak = 90;

  const analyser = context.createAnalyser();
  analyser.smoothingTimeConstant = 0.3;
  analyser.fftSize = 1024;

  const script = context.createScriptProcessor(2048, 1, 1);

  script.onaudioprocess = () => {
    // get the average, bincount is fftsize / 2
    const array = new Uint8Array(analyser!.frequencyBinCount);
    analyser!.getByteFrequencyData(array);

    let values = 0;

    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < array.length; i++) {
      values += array[i];
    }

    const average = values / array.length;

    if (average > peak) {
      peak = average;
    }

    onFrame(Math.max(0, Math.min(average / peak, 1)));
  };

  input.connect(analyser);
  analyser.connect(script);
  script.connect(context.destination);

  return () => {
    stream.getTracks().forEach(track => {
      track.stop();
    });
    input.disconnect();
    script.disconnect();
    analyser.disconnect();
  };
}
