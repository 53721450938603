import { Action } from "@druyan/druyan";

export interface RemoveSharedStory extends Action<"App/RemoveSharedStory"> {
  userId: string;
  storyId: string;
}

export function removeSharedStory(
  userId: string,
  storyId: string,
): RemoveSharedStory {
  return {
    type: "App/RemoveSharedStory",
    userId,
    storyId,
  };
}
