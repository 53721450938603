import i18next from "i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import enJson from "./en.json";
// import esJson from "./es.json";

i18next
  .use(i18nextBrowserLanguagedetector)
  .use(initReactI18next)
  .init({
    detection: {
      order: ["navigator"],
    },

    whitelist: ["en"], // "es"],

    lng: "en",
    fallbackLng: "en",

    debug: process.env.NODE_ENV === "development",

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      defaultTransParent: "div", // needed for preact
      wait: true,
    },

    resources: {
      en: enJson,
      // es: esJson,
    },
  });

export default i18next;
export const t = i18next.t.bind(i18next);
