import { Action } from "@druyan/druyan";
import { SavedStory } from "../../../../state";

export interface DownloadStory extends Action<"DownloadStory"> {
  story: SavedStory;
}

export function downloadStory(story: SavedStory): DownloadStory {
  return {
    type: "DownloadStory",
    story,
  };
}
