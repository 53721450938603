import { StateReturn } from "@druyan/druyan";
import { deleteStoryFromDB, removeEditorFromStory } from "../../../api";
import { DeleteStory, DidCreateStory, RemoveSharedStory } from "../../actions";
import { Modal, Shared } from "../../model";
import Ready from "../Ready";

export type StoryActions = DidCreateStory | DeleteStory | RemoveSharedStory;

export function storyActions(
  action: StoryActions,
  shared: Shared,
  modal: Modal,
  update: (shared: Shared, modal: Modal) => ReturnType<typeof Ready>,
): StateReturn | StateReturn[] {
  const { user, stories } = shared;

  if (!user) {
    throw new Error("Must be logged in to handle greetings");
  }

  switch (action.type) {
    case "App/DidCreateStory":
      stories.myStories[action.story.id] = action.story;

      return update(shared, modal);

    case "App/DeleteStory":
      deleteStoryFromDB(action.story);

      delete stories.myStories[action.story.id];

      return update(shared, modal);

    case "App/RemoveSharedStory":
      removeEditorFromStory(action.userId, action.storyId);

      if (stories.storiesSharedWithMe[action.storyId]) {
        delete stories.storiesSharedWithMe[action.storyId];
      }
      return update(shared, modal);
  }
}
