import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
  task,
} from "@druyan/druyan";
import { deleteUserData } from "../../../../api";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import { history, routeTo } from "../../../../utils";
import { confirm, Confirm, reject, Reject } from "../actions";
import { Shared } from "../types";

function ConfirmingAccountDeletion(
  action: Enter | Confirm | Reject | Exit,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { user } = shared;

  switch (action.type) {
    case "Enter":
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          text: t("delete_account_modal.text"),
          confirmText: t("global.yes"),
          rejectText: t("global.no"),
          onConfirm,
          onReject,
        }),
      ];

    case "Reject":
      return goBack();

    case "Confirm":
      history.push(routeTo.dashboard());
      // Await API Request to delete user data, then logout.
      return task(async () => {
        await deleteUserData(user);
        return AppState.logout();
      });

    case "Exit":
      return AppState.hideModal();
  }
}

export default state("ConfirmingAccountDeletion", ConfirmingAccountDeletion);
