import { RecordingController } from "../../utils";
import { AudioPlayer, initialAudioPlayer } from "./audioPlayer";
import { Greetings, initialGreetings } from "./greetings";
import {
  AccountModal,
  AcknowledgeModal,
  ConfirmModal,
  ProcessingIndicatorModal,
  SpinnerModal,
} from "./modal";
import { initialStories, Stories } from "./stories";
import { initialUserData, UserData } from "./userData";

export * from "./audioPlayer";
export * from "./chapters";
export * from "./greetings";
export * from "./modal";
export * from "./recording";
export * from "./stories";
export * from "./userData";

export interface Shared {
  user?: firebase.User;
  userData: UserData;
  audioPlayer: AudioPlayer;
  stories: Stories;
  greetings: Greetings;
  recordingController: RecordingController;
  audioContext: AudioContext;
  isIOS: boolean;
  isInputFocused: boolean;
  isAwaitingUserStream: boolean;
}

export function initialShared(): Shared {
  if (!("AudioContext" in window)) {
    window.AudioContext = (window as any).webkitAudioContext;
  }

  if (!("OfflineAudioContext" in window)) {
    window.OfflineAudioContext = (window as any).webkitOfflineAudioContext;
  }

  return {
    audioPlayer: initialAudioPlayer(),
    userData: initialUserData(),
    stories: initialStories(),
    greetings: initialGreetings(),
    recordingController: new RecordingController(),
    isIOS: iOSCheck(),
    isInputFocused: false,
    isAwaitingUserStream: false,
    audioContext: new AudioContext(),
  };
}

export function iOSCheck() {
  const ua = window.navigator.userAgent.toLowerCase();
  const isiPad =
    ua.indexOf("ipad") > -1 ||
    (ua.indexOf("macintosh") > -1 && "ontouchend" in document);
  if (isiPad || !!navigator.platform.match(/iPhone|iPod|iPad/)) {
    return true;
  } else {
    return false;
  }
}

export type Modal =
  | null
  | AcknowledgeModal
  | ConfirmModal
  | AccountModal
  | SpinnerModal
  | ProcessingIndicatorModal;
