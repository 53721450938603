import Empty from "./Empty";
import Entry from "./Entry";
import ProcessingRecording from "./ProcessingRecording";
import RecordedPaused from "./RecordedPaused";
import RecordedPlaying from "./RecordedPlaying";
import RecordedUnplayed from "./RecordedUnplayed";
import Recording from "./Recording";
import StartingRecording from "./StartingRecording";

export default {
  Entry,
  RecordedPaused,
  RecordedPlaying,
  RecordedUnplayed,
  Empty,
  Recording,
  StartingRecording,
  ProcessingRecording,
};
