import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { redirectBack } from "../../../../utils";
import { Next, Previous } from "../actions";
import { Shared } from "../types";
import MicDisclaimer from "./MicDisclaimer";
import Welcome from "./Welcome";

function Instructions(
  action: Enter | Next | Previous,
  shared: Shared,
): StateReturn {
  const { isFirstRun } = shared;

  switch (action.type) {
    case "Enter":
      return noop();

    case "Previous":
      return isFirstRun ? Welcome(shared) : redirectBack();

    case "Next":
      return MicDisclaimer(shared);
  }
}

export default state("Instructions", Instructions);
