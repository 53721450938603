import { Enter, state, StateReturn } from "@druyan/druyan";
import { SetChapterTitle, SetRecording } from "../actions";
import { Shared } from "../types";

function Recording(
  action: Enter | SetChapterTitle | SetRecording,
  shared: Shared,
): StateReturn {
  const { chapter } = shared;
  switch (action.type) {
    case "Enter":
      shared.canSubmit =
        !!chapter.title &&
        chapter.title.trim().length > 0 &&
        chapter.recordings.title !== undefined;

      return update(shared);

    case "SetChapterTitle":
      shared.chapter.title = action.title;

      return reenter(shared);

    case "SetRecording":
      shared.chapter.recordings.title = action.recording;
      shared.canSubmit = true;

      return reenter(shared);
  }
}

const RecordingState = state("Recording", Recording);
const { update, reenter } = RecordingState;
export default RecordingState;
