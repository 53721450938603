import {
  Enter,
  eventually,
  noop,
  state,
  StateReturn,
  task,
  // timeout,
} from "@druyan/druyan";
import { isStoryEmpty } from "../../../../api";
import { t } from "../../../../i18n";
import { Recording as RecordingType } from "../../../../state";
import { Actions as AppState } from "../../../../state";
import { generatorSingleton, routeTo } from "../../../../utils";
import { redirectTo } from "../../../../utils";
import {
  Cancel,
  CancelRecording,
  cancelRecording,
  DeleteRecording,
  Play,
  SaveChapter,
  ShowWarning,
  StartRecording,
} from "../actions";
import { Shared } from "../types";
import RecordedPlaying from "./RecordedPlaying";
import SavingChapterModal from "./SavingChapterModal";
import ShowingConfirmCancelEmptyStoryModal from "./ShowingConfirmCancelEmptyStoryModal";
import ShowingConfirmStartOverModal from "./ShowingConfirmStartOverModal";
import ShowingSaveBeforeClosingModal from "./ShowingSaveBeforeClosingModal";
import StartingRecording from "./StartingRecording";

function RecordedUnplayed(
  action:
    | Enter
    | Play
    | DeleteRecording
    | SaveChapter
    | Cancel
    | ShowWarning
    | CancelRecording
    | StartRecording,
  shared: Shared,
  recording: RecordingType,
): StateReturn | StateReturn[] {
  const { story, audioContext } = shared;

  switch (action.type) {
    case "Enter":
      return noop();

    case "Play":
      return RecordedPlaying(shared, recording, 0, performance.now());

    case "DeleteRecording":
      return ShowingConfirmStartOverModal(shared);

    case "SaveChapter":
      return SavingChapterModal(shared, recording);

    case "Cancel":
      return task(async () => {
        const isEmpty = await isStoryEmpty(story.id);

        return isEmpty
          ? ShowingConfirmCancelEmptyStoryModal(shared)
          : recording.isUpdated
          ? ShowingSaveBeforeClosingModal(shared, recording)
          : redirectTo(routeTo.storyDetail(story.id));
      });

    case "StartRecording":
      return StartingRecording(
        shared,
        recording,
        action.stream,
        action.stopAction,
        audioContext,
      );

    case "ShowWarning":
      const onAcknowledgeWarning = eventually(cancelRecording);
      const text =
        action.error && action.error.message === "NotAllowedError"
          ? t("audio_recording_not_allowed.text")
          : t("audio_recording_error.text");
      return [
        onAcknowledgeWarning,
        AppState.showAcknowledge({
          text,
          acknowledgeText: t("global.okay"),
          onAcknowledge: onAcknowledgeWarning,
        }),
      ];

    case "CancelRecording":
      return task(async () => {
        if (generatorSingleton.isRunning) {
          generatorSingleton.stop();
        }
        return [AppState.hideModal()];
      });
  }
}

export default state("RecordedUnplayed", RecordedUnplayed);
