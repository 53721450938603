import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
} from "@druyan/druyan";
import { downloadStories } from "../../../../api";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import { GeneratedZipFile, generatedZipFile } from "../actions";
import { Shared } from "../types";

function GeneratingZipFile(
  action: Enter | GeneratedZipFile | Exit,
  _shared: Shared,
): StateReturn | StateReturn[] {
  switch (action.type) {
    case "Enter":
      const onGeneratedZipFile = eventually(generatedZipFile);

      downloadStories()
        .then(({ data: { url } }) => {
          onGeneratedZipFile(url);
        })
        .catch(e => {
          // tslint:disable-next-line:no-console
          console.error("Download Story failed", e);

          throw e;
        });

      return [
        AppState.showSpinner(t("spinner.generating")),
        onGeneratedZipFile,
      ];

    case "GeneratedZipFile":
      // Point the browser at the download endpoint
      window.location.href = action.url;

      return goBack();

    case "Exit":
      return AppState.hideModal();
  }
}

export default state("GeneratingZipFile", GeneratingZipFile);
