import { Enter, noop, state, StateReturn, task } from "@druyan/druyan";
import { Actions as AppState } from "../../../../state";
import { SaveTitle } from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";

function Submitting(action: Enter | SaveTitle, shared: Shared): StateReturn {
  const { story, chapter } = shared;

  switch (action.type) {
    case "Enter":
      return task(async () => {
        const chapterUpdates = {
          ...chapter,
          title: chapter.title,
          recordings: {
            ...chapter.recordings,
            title: chapter.recordings.title,
          },
        };

        return [
          // Send the new chapter to the global data store.
          AppState.hideModal(),
          AppState.updateStoryChapterTitle(story, chapterUpdates),
          Entry(shared),
        ];
      });

    case "SaveTitle":
      return noop();
  }
}

export default state("Submitting", Submitting);
