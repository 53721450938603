import { Action } from "@druyan/druyan";

export interface RemoveEditorFromStory
  extends Action<"App/RemoveEditorFromStory"> {
  userId: string;
  storyId: string;
}

export function removeEditorFromStory(
  userId: string,
  storyId: string,
): RemoveEditorFromStory {
  return {
    type: "App/RemoveEditorFromStory",
    userId,
    storyId,
  };
}
