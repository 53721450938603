import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useMedia } from "react-use";
import { useAuth, useStories } from "../../hooks";
import { history, MEDIA_QUERY_SMALL, routeTo } from "../../utils";
import { StateContext, States } from "./machine";
import { StoryDetail } from "./StoryDetail";

type Props = RouteComponentProps<{ storyId?: string; editMode?: string }>;

export default ({
  match: {
    params: { storyId, editMode },
  },
}: Props) => {
  const user = useAuth();
  const { myStories, storiesSharedWithMe } = useStories();
  const isEditingFromURL = editMode === "edit-story";

  if (!user) {
    throw new Error("Cannot view story details unless logged in.");
  }

  const story = storyId
    ? myStories[storyId] || storiesSharedWithMe[storyId]
    : undefined;

  const isWideScreen = useMedia(MEDIA_QUERY_SMALL);

  if (!story) {
    // tslint:disable-next-line:no-console
    console.error(`Invalid story: ${storyId}`);
    if (history.location.pathname !== routeTo.notFound()) {
      history.replace(routeTo.notFound());
    }
    return null;
  }

  return (
    <StateContext.Create
      initialState={States.Entry({ user, story, isEditing: isEditingFromURL })}
    >
      {({
        actions,
        currentState: {
          data: [{ isEditing }],
        },
      }) => {
        return (
          <StoryDetail
            user={user}
            story={story}
            isEditing={isEditing}
            onEdit={actions.edit}
            onStopEditing={actions.stopEditing}
            onDelete={actions.deleteStory}
            onRemoveShared={actions.removeSharedStory}
            onDownload={actions.downloadStory}
            isWideScreen={isWideScreen}
            onReorder={actions.reorderChapters}
          />
        );
      }}
    </StateContext.Create>
  );
};
