import { Plus, PrimaryButton } from "@storytime/shared/build/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLottie } from "../../hooks";
import { routeTo } from "../../utils";
import { newStoryAnimation } from "./newStoryAnimation";
import "./NewStoryTile.css";

export function NewStoryTile() {
  const { t } = useTranslation();

  const bgRef = useLottie({
    animationData: newStoryAnimation,
  });

  return (
    <div className="NewStoryTile">
      <div className="bg-illustration" ref={bgRef} />
      <PrimaryButton
        icon={<Plus />}
        filled={true}
        linkTo={routeTo.createStory()}
      >
        {t("action.add_story")}
      </PrimaryButton>
    </div>
  );
}
