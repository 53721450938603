import { Action } from "@druyan/druyan";

export interface SetAuthor extends Action<"SetAuthor"> {
  author: string;
}

export function setAuthor(author: string): SetAuthor {
  return {
    type: "SetAuthor",
    author,
  };
}
