import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import { Confirm, confirm, Reject, reject } from "../actions";
import { Shared } from "../types";
import Empty from "./Empty";

function ShowingConfirmStartOverModal(
  action: Enter | Confirm | Reject | Exit,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { story, chapter } = shared;

  switch (action.type) {
    case "Enter":
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          text: t("confirm_start_over_modal.text"),
          confirmText: t("button.start_over"),
          rejectText: t("button.cancel"),
          onConfirm,
          onReject,
        }),
      ];

    case "Confirm":
      return [AppState.deleteChapterRecording(story, chapter), Empty(shared)];

    case "Reject":
      return goBack();

    case "Exit":
      return AppState.hideModal();
  }
}

export default state(
  "ShowingConfirmStartOverModal",
  ShowingConfirmStartOverModal,
);
