import { Action } from "@druyan/druyan";
import { SavedStory } from "../../../../state";

export interface RemoveEditorsFromStory
  extends Action<"RemoveEditorsFromStory"> {
  story: SavedStory;
}

export function removeEditorsFromStory(
  story: SavedStory,
): RemoveEditorsFromStory {
  return {
    type: "RemoveEditorsFromStory",
    story,
  };
}
