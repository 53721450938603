export const newStoryAnimation = {
  v: "5.5.0",
  fr: 29.9700012207031,
  ip: 0,
  op: 147.000005987433,
  w: 500,
  h: 550,
  nm: "___New Story",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: "Eyes",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [84, 58, 0], ix: 2 },
            a: { a: 0, k: [25, 25, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 50,
          h: 50,
          ip: 88.0000035843135,
          op: 177.000007209358,
          st: 88.0000035843135,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: "Eyes",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [84, 58, 0], ix: 2 },
            a: { a: 0, k: [25, 25, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 50,
          h: 50,
          ip: 0,
          op: 89.0000036250443,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 0,
          nm: "mouth 2",
          refId: "comp_2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [68, 59.25, 0], ix: 2 },
            a: { a: 0, k: [66.5, 83.5, 0], ix: 1 },
            s: { a: 0, k: [60, 60, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.160784319043, 0.105882361531, 0.266666680574, 1],
                    ix: 3,
                  },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          w: 133,
          h: 167,
          ip: 88.0000035843135,
          op: 175.000007127896,
          st: 88.0000035843135,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 0,
          nm: "mouth 2",
          refId: "comp_2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [68, 59.25, 0], ix: 2 },
            a: { a: 0, k: [66.5, 83.5, 0], ix: 1 },
            s: { a: 0, k: [60, 60, 100], ix: 6 },
          },
          ao: 0,
          ef: [
            {
              ty: 21,
              nm: "Fill",
              np: 9,
              mn: "ADBE Fill",
              ix: 1,
              en: 1,
              ef: [
                {
                  ty: 10,
                  nm: "Fill Mask",
                  mn: "ADBE Fill-0001",
                  ix: 1,
                  v: { a: 0, k: 0, ix: 1 },
                },
                {
                  ty: 7,
                  nm: "All Masks",
                  mn: "ADBE Fill-0007",
                  ix: 2,
                  v: { a: 0, k: 0, ix: 2 },
                },
                {
                  ty: 2,
                  nm: "Color",
                  mn: "ADBE Fill-0002",
                  ix: 3,
                  v: {
                    a: 0,
                    k: [0.160784319043, 0.105882361531, 0.266666680574, 1],
                    ix: 3,
                  },
                },
                {
                  ty: 7,
                  nm: "Invert",
                  mn: "ADBE Fill-0006",
                  ix: 4,
                  v: { a: 0, k: 0, ix: 4 },
                },
                {
                  ty: 0,
                  nm: "Horizontal Feather",
                  mn: "ADBE Fill-0003",
                  ix: 5,
                  v: { a: 0, k: 0, ix: 5 },
                },
                {
                  ty: 0,
                  nm: "Vertical Feather",
                  mn: "ADBE Fill-0004",
                  ix: 6,
                  v: { a: 0, k: 0, ix: 6 },
                },
                {
                  ty: 0,
                  nm: "Opacity",
                  mn: "ADBE Fill-0005",
                  ix: 7,
                  v: { a: 0, k: 1, ix: 7 },
                },
              ],
            },
          ],
          w: 133,
          h: 167,
          ip: 0,
          op: 89.0000036250443,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 1,
          nm: "Medium Red Solid 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [256, 225, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "a",
              pt: {
                a: 0,
                k: {
                  i: [[0, 0], [0, 0], [-16.421, -13.217], [-19, 8.25]],
                  o: [[0, 0], [0, 0], [10.25, 8.25], [26.41, -11.468]],
                  v: [
                    [826.25, 380],
                    [722.25, 381.75],
                    [744, 413.25],
                    [795.75, 421],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          sw: 1920,
          sh: 1080,
          sc: "#f8656a",
          ip: 0,
          op: 1052.00004284884,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "BA/New Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [70.21, 57.867, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.726, -0.986],
                        [1.243, -2.929],
                        [1.966, 3.015],
                        [-1.169, -3.729],
                        [3.564, -1.596],
                        [0.724, 3.957],
                        [0.386, -3.571],
                        [-8.292, 2.864],
                        [3.841, 6.799],
                        [1.902, -1.112],
                        [-4.027, 6.117],
                        [7.335, 1.034],
                      ],
                      o: [
                        [2.716, 1.553],
                        [-1.083, 2.553],
                        [-1.967, -3.015],
                        [1.023, 3.265],
                        [-4.762, 2.132],
                        [-0.759, -4.145],
                        [-0.967, 8.945],
                        [7.45, -2.572],
                        [-1.903, 1.112],
                        [3.971, 5.513],
                        [3.688, -5.604],
                        [-1.685, -0.238],
                      ],
                      v: [
                        [9.353, -14.144],
                        [12.32, -5.807],
                        [5.539, -4.454],
                        [-0.5, -1.908],
                        [-3.524, 7.158],
                        [-12.287, 0.337],
                        [-19.094, 1.259],
                        [-1.708, 14.468],
                        [5.539, -4.454],
                        [-0.167, -1.118],
                        [16.372, -0.881],
                        [10.596, -17.095],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960.87, 511.634], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.228, -3.238],
                        [-7.595, 0.177],
                        [6.312, 8.054],
                        [1.69, -0.786],
                        [4.468, -0.703],
                        [1.924, 2.824],
                      ],
                      o: [
                        [-3.212, 8.468],
                        [7.661, -0.178],
                        [-1.316, -1.679],
                        [-3.62, 1.685],
                        [-4.456, 0.702],
                        [-2.011, -2.955],
                      ],
                      v: [
                        [-15.591, -6.329],
                        [-0.72, 10.055],
                        [12.492, -7.156],
                        [6.958, -7.87],
                        [-0.864, -0.447],
                        [-8.338, -7.277],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [973.142, 560.066], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-18.46, -24.963],
                        [19.486, -18.938],
                        [20.831, 23.601],
                        [-22.341, 19.72],
                      ],
                      o: [
                        [15.898, 21.5],
                        [-21.807, 21.197],
                        [-19.961, -22.616],
                        [24.161, -21.326],
                      ],
                      v: [
                        [44.838, -31.507],
                        [39.036, 40.485],
                        [-40.775, 37.381],
                        [-36.079, -40.356],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.972999961703, 0.395999983245, 0.416000007181, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [959.512, 538.962], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 1052.00004284884,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "eyes closed/New Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [23.252, 24.815, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-1.109, -1.402],
                        [-2.585, 1.673],
                        [2.547, -0.773],
                        [1.991, 0.361],
                      ],
                      o: [
                        [2.037, 2.575],
                        [2.078, -1.346],
                        [-1.976, 0.599],
                        [-1.816, -0.33],
                      ],
                      v: [
                        [-4.943, 0.568],
                        [3.974, 1.615],
                        [2.233, -2.515],
                        [-3.221, -2.392],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [970.233, 539.962], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.173, -0.497],
                        [-1.778, -0.943],
                        [-2.185, 1.423],
                        [0.282, 1.026],
                        [0.548, 0.365],
                        [0.696, -0.388],
                        [1.208, 0.473],
                        [1.078, 0.324],
                      ],
                      o: [
                        [-0.742, 2.128],
                        [2.377, 1.26],
                        [0.867, -0.564],
                        [-0.18, -0.654],
                        [-0.69, -0.46],
                        [-1.084, 0.604],
                        [-1.031, -0.404],
                        [-0.538, -0.162],
                      ],
                      v: [
                        [-5.343, -2.71],
                        [-2.541, 2.305],
                        [4.737, 1.624],
                        [5.803, -0.997],
                        [4.737, -2.383],
                        [2.394, -2.383],
                        [-1.234, -1.932],
                        [-4.122, -3.403],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [950.945, 540.892], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 43.0000017514259,
          op: 47.0000019143492,
          st: 43.0000017514259,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "eyes open/New Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [23.252, 24.315, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-6.734, 0], [6.735, 0]],
                      o: [[6.724, 0], [-6.723, 0]],
                      v: [[0, 5.224], [0, -5.224]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [950.722, 540.087], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-6.734, 0], [6.735, 0]],
                      o: [[6.724, 0], [-6.724, 0]],
                      v: [[0, 5.224], [0, -5.224]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [969.279, 539.913], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 47.0000019143492,
          op: 90.0000036657751,
          st: 47.0000019143492,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "eyes open/New Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [23.252, 24.315, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-6.734, 0], [6.735, 0]],
                      o: [[6.724, 0], [-6.723, 0]],
                      v: [[0, 5.224], [0, -5.224]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [950.722, 540.087], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-6.734, 0], [6.735, 0]],
                      o: [[6.724, 0], [-6.724, 0]],
                      v: [[0, 5.224], [0, -5.224]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [969.279, 539.913], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 43.0000017514259,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_2",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "mount 4/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [97.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [82.963, 82.963, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-17.455, 0], [17.455, 0]],
                      o: [[17.426, 0], [-17.426, 0]],
                      v: [[0, 13.541], [0, -13.541]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 32.0000013033867,
          op: 35.0000014255792,
          st: 32.0000013033867,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "mount 4/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [97.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [82.963, 82.963, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-17.455, 0], [17.455, 0]],
                      o: [[17.426, 0], [-17.426, 0]],
                      v: [[0, 13.541], [0, -13.541]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 52.0000021180034,
          op: 55.0000022401959,
          st: 52.0000021180034,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "mount 4/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [97.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [82.963, 82.963, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-17.455, 0], [17.455, 0]],
                      o: [[17.426, 0], [-17.426, 0]],
                      v: [[0, 13.541], [0, -13.541]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 21.0000008553475,
          op: 24.00000097754,
          st: 21.0000008553475,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 4,
          ty: 4,
          nm: "mount 4/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-17.455, 0], [17.455, 0]],
                      o: [[17.426, 0], [-17.426, 0]],
                      v: [[0, 13.541], [0, -13.541]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 45.0000018328876,
          op: 48.0000019550801,
          st: 45.0000018328876,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 5,
          ty: 4,
          nm: "mount 4/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-17.455, 0], [17.455, 0]],
                      o: [[17.426, 0], [-17.426, 0]],
                      v: [[0, 13.541], [0, -13.541]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 14.0000005702317,
          op: 17.0000006924242,
          st: 14.0000005702317,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 6,
          ty: 4,
          nm: "mouth 1/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [95, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-8.674, 0], [8.674, 0]],
                      o: [[8.659, 0], [-8.66, 0]],
                      v: [[0, 6.729], [0, -6.729]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 60.0000024438501,
          op: 63.0000025660426,
          st: 60.0000024438501,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 7,
          ty: 4,
          nm: "mouth 1/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [95, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-8.674, 0], [8.674, 0]],
                      o: [[8.659, 0], [-8.66, 0]],
                      v: [[0, 6.729], [0, -6.729]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 26.0000010590017,
          op: 29.0000011811942,
          st: 26.0000010590017,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 8,
          ty: 4,
          nm: "mouth 1/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [102, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-8.674, 0], [8.674, 0]],
                      o: [[8.659, 0], [-8.66, 0]],
                      v: [[0, 6.729], [0, -6.729]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 29.0000011811942,
          op: 32.0000013033867,
          st: 29.0000011811942,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 9,
          ty: 4,
          nm: "mouth 1/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-8.674, 0], [8.674, 0]],
                      o: [[8.659, 0], [-8.66, 0]],
                      v: [[0, 6.729], [0, -6.729]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 34.0000013848484,
          op: 37.0000015070409,
          st: 34.0000013848484,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 10,
          ty: 4,
          nm: "mouth 1/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [[-8.674, 0], [8.674, 0]],
                      o: [[8.659, 0], [-8.66, 0]],
                      v: [[0, 6.729], [0, -6.729]],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960, 540], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 3.00000012219251,
          op: 6.00000024438501,
          st: 3.00000012219251,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 11,
          ty: 4,
          nm: "mouth 2/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.52, -3.469],
                        [-9.755, -2.943],
                        [-4.742, 9.478],
                        [3.283, 4.086],
                        [0.092, 0.115],
                        [2.456, -6.811],
                        [-0.619, -0.083],
                        [1.312, 0.959],
                        [2.698, 0.741],
                      ],
                      o: [
                        [-4.554, 10.394],
                        [9.995, 3.015],
                        [2.431, -4.857],
                        [-0.091, -0.115],
                        [-5.531, -6.886],
                        [0.369, -1.024],
                        [-1.157, -0.154],
                        [-2.938, -2.15],
                        [-3.327, -0.915],
                      ],
                      v: [
                        [-19.057, -8.864],
                        [-5.19, 14.229],
                        [21.179, 3.39],
                        [19.449, -10.013],
                        [19.173, -10.359],
                        [1.129, -5.594],
                        [1.882, -6.652],
                        [-1.586, -8.401],
                        [-8.98, -12.745],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960.706, 540.461], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 48.0000019550801,
          op: 51.0000020772726,
          st: 48.0000019550801,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 12,
          ty: 4,
          nm: "mouth 2/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.52, -3.469],
                        [-9.755, -2.943],
                        [-4.742, 9.478],
                        [3.283, 4.086],
                        [0.092, 0.115],
                        [2.456, -6.811],
                        [-0.619, -0.083],
                        [1.312, 0.959],
                        [2.698, 0.741],
                      ],
                      o: [
                        [-4.554, 10.394],
                        [9.995, 3.015],
                        [2.431, -4.857],
                        [-0.091, -0.115],
                        [-5.531, -6.886],
                        [0.369, -1.024],
                        [-1.157, -0.154],
                        [-2.938, -2.15],
                        [-3.327, -0.915],
                      ],
                      v: [
                        [-19.057, -8.864],
                        [-5.19, 14.229],
                        [21.179, 3.39],
                        [19.449, -10.013],
                        [19.173, -10.359],
                        [1.129, -5.594],
                        [1.882, -6.652],
                        [-1.586, -8.401],
                        [-8.98, -12.745],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960.706, 540.461], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 17.0000006924242,
          op: 20.0000008146167,
          st: 17.0000006924242,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 13,
          ty: 4,
          nm: "mouth 2/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 83.333, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.52, -3.469],
                        [-9.755, -2.943],
                        [-4.742, 9.478],
                        [3.283, 4.086],
                        [0.092, 0.115],
                        [2.456, -6.811],
                        [-0.619, -0.083],
                        [1.312, 0.959],
                        [2.698, 0.741],
                      ],
                      o: [
                        [-4.554, 10.394],
                        [9.995, 3.015],
                        [2.431, -4.857],
                        [-0.091, -0.115],
                        [-5.531, -6.886],
                        [0.369, -1.024],
                        [-1.157, -0.154],
                        [-2.938, -2.15],
                        [-3.327, -0.915],
                      ],
                      v: [
                        [-19.057, -8.864],
                        [-5.19, 14.229],
                        [21.179, 3.39],
                        [19.449, -10.013],
                        [19.173, -10.359],
                        [1.129, -5.594],
                        [1.882, -6.652],
                        [-1.586, -8.401],
                        [-8.98, -12.745],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960.706, 540.461], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 6.00000024438501,
          op: 9.00000036657752,
          st: 6.00000024438501,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 14,
          ty: 4,
          nm: "mouth 3/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.517, -5.527],
                        [-5.825, -1.34],
                        [-6.623, 3.54],
                        [0.345, 3.088],
                        [0.013, 0.114],
                        [6.251, -1.445],
                        [1.799, -0.542],
                        [2.322, 0.024],
                      ],
                      o: [
                        [-1.984, 7.23],
                        [7.305, 1.681],
                        [2.639, -1.411],
                        [-0.012, -0.114],
                        [-0.667, -5.972],
                        [-1.862, 0.43],
                        [-2.234, 0.674],
                        [-4.25, -0.044],
                      ],
                      v: [
                        [-17.285, -2.753],
                        [-5.941, 9.754],
                        [14.283, 7.219],
                        [18.924, -0.877],
                        [18.887, -1.221],
                        [7.375, -9.99],
                        [2.203, -7.686],
                        [-4.979, -7.226],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [961.746, 540.247], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 24.00000097754,
          op: 27.0000010997325,
          st: 24.00000097754,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 15,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: {
              a: 1,
              k: [
                { t: 0, s: [0], h: 1 },
                { t: 12, s: [100], h: 1 },
                { t: 14, s: [0], h: 1 },
                { t: 20, s: [100], h: 1 },
                { t: 21, s: [0], h: 1 },
                { t: 37, s: [100], h: 1 },
                { t: 45, s: [0], h: 1 },
                { t: 51, s: [100], h: 1 },
                { t: 52, s: [0], h: 1 },
                { t: 55, s: [100], h: 1 },
                { t: 60, s: [0], h: 1 },
                { t: 63.0000025660426, s: [100], h: 1 },
              ],
              ix: 11,
            },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [66.5, 83.5, 0], ix: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [6.25, -0.875],
                        [5.177, 1.122],
                        [-1.158, -2.732],
                        [-10.532, -0.214],
                        [-1, 3.75],
                      ],
                      o: [
                        [-6.037, 0.845],
                        [-7.5, -1.625],
                        [1.828, 4.311],
                        [8.973, 0.182],
                        [0.679, -2.548],
                      ],
                      v: [
                        [34.5, 29.75],
                        [17, 29.5],
                        [8.875, 36.75],
                        [25, 44.5],
                        [40.875, 38.25],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670588254929, 0.65098041296, 0.72549021244, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 1052.00004284884,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 16,
          ty: 4,
          nm: "mouth 3/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.517, -5.527],
                        [-5.825, -1.34],
                        [-6.623, 3.54],
                        [0.345, 3.088],
                        [0.013, 0.114],
                        [6.251, -1.445],
                        [1.799, -0.542],
                        [2.322, 0.024],
                      ],
                      o: [
                        [-1.984, 7.23],
                        [7.305, 1.681],
                        [2.639, -1.411],
                        [-0.012, -0.114],
                        [-0.667, -5.972],
                        [-1.862, 0.43],
                        [-2.234, 0.674],
                        [-4.25, -0.044],
                      ],
                      v: [
                        [-17.285, -2.753],
                        [-5.941, 9.754],
                        [14.283, 7.219],
                        [18.924, -0.877],
                        [18.887, -1.221],
                        [7.375, -9.99],
                        [2.203, -7.686],
                        [-4.979, -7.226],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [961.746, 540.247], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 3.00000012219251,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 17,
          ty: 4,
          nm: "mouth 3/Vignette3 Outlines",
          sr: 0.09677419354839,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [89.5, 117.5, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.517, -5.527],
                        [-5.825, -1.34],
                        [-6.623, 3.54],
                        [0.345, 3.088],
                        [0.013, 0.114],
                        [6.251, -1.445],
                        [1.799, -0.542],
                        [2.322, 0.024],
                      ],
                      o: [
                        [-1.984, 7.23],
                        [7.305, 1.681],
                        [2.639, -1.411],
                        [-0.012, -0.114],
                        [-0.667, -5.972],
                        [-1.862, 0.43],
                        [-2.234, 0.674],
                        [-4.25, -0.044],
                      ],
                      v: [
                        [-17.285, -2.753],
                        [-5.941, 9.754],
                        [14.283, 7.219],
                        [18.924, -0.877],
                        [18.887, -1.221],
                        [7.375, -9.99],
                        [2.203, -7.686],
                        [-4.979, -7.226],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.670999983245, 0.651000019148, 0.725, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [961.746, 540.247], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 9.00000036657752,
          op: 12.00000048877,
          st: 9.00000036657752,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_3",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 0,
          nm: "Eyes",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [47, 61.5, 0], ix: 2 },
            a: { a: 0, k: [25, 25, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 50,
          h: 50,
          ip: 57.0000023216576,
          op: 147.000005987433,
          st: 57.0000023216576,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 0,
          nm: "Eyes",
          refId: "comp_1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [47, 61.5, 0], ix: 2 },
            a: { a: 0, k: [25, 25, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          w: 50,
          h: 50,
          ip: -32.0000013033867,
          op: 58.0000023623884,
          st: -32.0000013033867,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 3,
          ty: 4,
          nm: "BB/New Outlines",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [60.727, 65.332, 0], ix: 2 },
            a: { a: 0, k: [960, 540, 0], ix: 1 },
            s: { a: 0, k: [100, 100, 100], ix: 6 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.714, 0.268],
                        [-1.847, -3.948],
                        [-4.76, 6.094],
                        [3.972, -2.604],
                        [-5.185, -2.935],
                        [-3.643, 5.275],
                        [2.429, -0.215],
                        [1.599, -0.408],
                        [1.236, -0.855],
                        [1.744, 1.745],
                        [0.402, 8.359],
                        [-1.779, 2.344],
                      ],
                      o: [
                        [-7.586, -2.845],
                        [4.677, 9.997],
                        [2.439, -3.123],
                        [-5.321, 3.489],
                        [5.305, 3.001],
                        [1.366, -1.979],
                        [-1.666, 0.148],
                        [-1.708, 0.436],
                        [-1.744, -1.744],
                        [-2.712, 3.661],
                        [-0.142, -2.945],
                        [0.571, -0.751],
                      ],
                      v: [
                        [-10.68, -11.613],
                        [-17.174, 4.461],
                        [3.817, 2.069],
                        [-0.819, -3.938],
                        [0.399, 9.031],
                        [17.655, 6.477],
                        [14.798, 1.498],
                        [10.256, 2.512],
                        [3.042, 2.667],
                        [-2.191, -2.566],
                        [-12.144, -2.286],
                        [-9.88, -9.646],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [954.556, 509.026], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 1",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.146, -3.126],
                        [4.719, 0.665],
                        [3.835, 1.745],
                        [1.417, -1.766],
                        [-8.048, -0.182],
                        [3.438, 8.961],
                      ],
                      o: [
                        [-2.065, 3.009],
                        [-4.711, -0.665],
                        [-1.8, -0.819],
                        [-6.807, 8.486],
                        [8.022, 0.182],
                        [-1.326, -3.456],
                      ],
                      v: [
                        [8.82, -7.707],
                        [0.964, -0.521],
                        [-7.282, -8.349],
                        [-13.169, -7.589],
                        [0.806, 10.651],
                        [16.537, -6.698],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.161000001197, 0.105999995213, 0.26699999641, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [944.282, 556.565], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 2",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [18.459, -24.963],
                        [-19.485, -18.938],
                        [-20.831, 23.601],
                        [22.341, 19.72],
                      ],
                      o: [
                        [-15.898, 21.5],
                        [21.808, 21.197],
                        [19.961, -22.616],
                        [-24.162, -21.326],
                      ],
                      v: [
                        [-44.838, -31.507],
                        [-39.037, 40.485],
                        [40.775, 37.381],
                        [36.079, -40.356],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [0.741000007181, 0.447000002394, 0.948999980852, 1],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [960.489, 538.962], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Group 3",
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 1052.00004284884,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "Blob A",
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.343], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.68], y: [0] },
              t: 11,
              s: [-31],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 17,
              s: [-22],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 54,
              s: [-22],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 61,
              s: [-9],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 71,
              s: [-22],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 94,
              s: [-22],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 101,
              s: [-9],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 111,
              s: [-22],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 132,
              s: [-22],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 140,
              s: [-11],
            },
            { t: 147.000005987433, s: [0] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [54, 68.187, 0],
              to: [0, -0.667, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 36.25,
              s: [54, 64.187, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 70.486,
              s: [54, 68.187, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 106.736,
              s: [54, 64.187, 0],
              to: [0, 0, 0],
              ti: [0, -0.667, 0],
            },
            { t: 145.000005905971, s: [54, 68.187, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [55.781, 43.303, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 140,
      h: 119,
      ip: 0,
      op: 1052.00004284884,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: "Blob B",
      refId: "comp_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.502], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 0,
              s: [-2],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 11,
              s: [-26],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 17,
              s: [-18],
            },
            {
              i: { x: [0.502], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 43,
              s: [-18],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 48,
              s: [-26],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 54,
              s: [-18],
            },
            {
              i: { x: [0.536], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 74,
              s: [-18],
            },
            {
              i: { x: [0.486], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 81,
              s: [-8],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 87,
              s: [-18],
            },
            {
              i: { x: [0.502], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 135,
              s: [-18],
            },
            {
              i: { x: [0.667], y: [1] },
              o: { x: [0.167], y: [0] },
              t: 140,
              s: [-26],
            },
            { t: 146.000005946702, s: [-2] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [438.356, 462.11, 0],
              to: [0, -0.333, 0],
              ti: [0, -0.167, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 24.2,
              s: [438.356, 460.11, 0],
              to: [0, 0.167, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 48,
              s: [438.356, 463.11, 0],
              to: [0, 0, 0],
              ti: [0, 0.167, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 72.6,
              s: [438.356, 460.11, 0],
              to: [0, -0.167, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.667, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 96.8,
              s: [438.356, 462.11, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 1 },
              o: { x: 0.167, y: 0 },
              t: 121,
              s: [438.356, 460.11, 0],
              to: [0, 0, 0],
              ti: [0, -0.333, 0],
            },
            { t: 147.000005987433, s: [438.356, 462.11, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [74.089, 87.314, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      w: 125,
      h: 129,
      ip: 0,
      op: 1052.00004284884,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Layer 1/New Outlines",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [250, 250, 0], ix: 2 },
        a: { a: 0, k: [960, 540, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.846, 1.02],
                    [-2.745, 4.511],
                    [1.539, -1.894],
                    [3.859, -2.905],
                  ],
                  o: [
                    [4.662, -2.576],
                    [1.214, -1.993],
                    [-3.002, 3.695],
                    [-1.599, 1.203],
                  ],
                  v: [
                    [-4.507, 7.262],
                    [6.5, -3.832],
                    [3.188, -6.388],
                    [-6.115, 4.511],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1118.6, 375.935], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 1",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.111, -1.074],
                    [-1.824, -1.308],
                    [-2.307, 0.228],
                    [0.441, 1.651],
                    [2.068, 1.055],
                    [2.398, 0.46],
                  ],
                  o: [
                    [1.611, 1.557],
                    [1.744, 1.251],
                    [1.559, -0.155],
                    [-0.649, -2.429],
                    [-2.179, -1.111],
                    [-1.351, -0.259],
                  ],
                  v: [
                    [-6.249, -2.415],
                    [-1.291, 1.858],
                    [4.568, 4.874],
                    [6.919, 1.788],
                    [1.764, -2.658],
                    [-4.835, -4.843],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1119.206, 377.94], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 2",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.16, -1.386],
                    [-1.044, -4.194],
                    [-0.446, 1.787],
                    [0.49, 4.232],
                  ],
                  o: [
                    [-0.488, 4.231],
                    [0.444, 1.786],
                    [1.046, -4.203],
                    [-0.159, -1.383],
                  ],
                  v: [
                    [-1.08, -6.86],
                    [-1.809, 6.459],
                    [1.807, 6.459],
                    [1.076, -6.86],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.33300000359, 0.289999988032, 0.416000007181, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1118.347, 377.951], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 3",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.322, 0.507],
                    [0.038, 0.05],
                    [0.232, 0.3],
                    [0.55, 0.179],
                    [0.983, -0.261],
                    [0.009, -0.002],
                    [0.39, -1.467],
                    [0.002, -0.009],
                    [0.053, -0.393],
                    [-0.278, -0.53],
                    [-0.276, -0.277],
                    [-0.518, -0.279],
                    [0, 0],
                    [0, 0],
                    [-0.586, 0.132],
                    [-0.344, 0.199],
                    [-0.401, 0.43],
                    [-0.236, 0.728],
                    [0.132, 0.587],
                  ],
                  o: [
                    [-0.038, -0.05],
                    [-0.232, -0.3],
                    [-0.402, -0.433],
                    [-0.919, -0.388],
                    [-0.009, 0.002],
                    [-1.467, 0.39],
                    [-0.003, 0.009],
                    [-0.053, 0.393],
                    [-0.022, 0.612],
                    [0.106, 0.376],
                    [0.313, 0.484],
                    [0, 0],
                    [0, 0],
                    [0.587, 0.184],
                    [0.399, -0.001],
                    [0.548, -0.179],
                    [0.517, -0.564],
                    [0.184, -0.587],
                    [-0.026, -0.588],
                  ],
                  v: [
                    [3.88, -2.124],
                    [3.767, -2.273],
                    [3.072, -3.172],
                    [1.643, -4.089],
                    [-1.25, -4.313],
                    [-1.275, -4.307],
                    [-4.346, -1.237],
                    [-4.352, -1.211],
                    [-4.512, -0.031],
                    [-4.129, 1.682],
                    [-3.557, 2.661],
                    [-2.311, 3.805],
                    [-2.162, 3.919],
                    [-1.107, 4.364],
                    [0.653, 4.443],
                    [1.768, 4.141],
                    [3.19, 3.229],
                    [4.325, 1.279],
                    [4.403, -0.482],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1165.649, 403.892], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 4",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.304, 0.53],
                    [-0.303, 0.123],
                    [-0.527, 0.682],
                    [0.036, 0.811],
                    [0.308, 0.581],
                    [0.885, 0.344],
                    [0.304, 0.117],
                    [0.898, -0.978],
                    [0, -1.105],
                    [-0.739, -0.74],
                  ],
                  o: [
                    [0.304, -0.124],
                    [0.775, -0.316],
                    [0.482, -0.625],
                    [-0.029, -0.658],
                    [-0.466, -0.883],
                    [-0.303, -0.117],
                    [-1.276, -0.495],
                    [-0.732, 0.797],
                    [0, 1.046],
                    [0.937, 0.936],
                  ],
                  v: [
                    [0.749, 3.915],
                    [1.659, 3.545],
                    [3.583, 2.218],
                    [4.378, -0.026],
                    [3.872, -1.895],
                    [1.659, -3.597],
                    [0.749, -3.95],
                    [-3.219, -2.901],
                    [-4.414, -0.018],
                    [-3.219, 2.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [939.16, 382.39], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 5",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.304, 0.53],
                    [-0.303, 0.123],
                    [-0.527, 0.681],
                    [0.036, 0.811],
                    [0.308, 0.582],
                    [0.885, 0.343],
                    [0.304, 0.118],
                    [0.898, -0.978],
                    [0, -1.104],
                    [-0.739, -0.739],
                  ],
                  o: [
                    [0.304, -0.123],
                    [0.775, -0.316],
                    [0.482, -0.625],
                    [-0.029, -0.658],
                    [-0.466, -0.882],
                    [-0.303, -0.118],
                    [-1.276, -0.495],
                    [-0.732, 0.797],
                    [0, 1.046],
                    [0.937, 0.937],
                  ],
                  v: [
                    [0.749, 3.915],
                    [1.659, 3.545],
                    [3.583, 2.218],
                    [4.378, -0.026],
                    [3.872, -1.896],
                    [1.659, -3.597],
                    [0.749, -3.95],
                    [-3.219, -2.901],
                    [-4.414, -0.018],
                    [-3.219, 2.866],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [830.16, 679.39], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 6",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-4.653, 0], [4.653, 0]],
                  o: [[4.645, 0], [-4.645, 0]],
                  v: [[0, 3.609], [0, -3.609]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.344999994016, 0.301999978458, 0.426999978458, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [804.294, 701.762], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 7",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [4.889, 0.142],
                    [51.231, 1.255],
                    [7.551, 0.725],
                    [0, 0],
                    [-2.205, -0.049],
                    [5.964, 0.134],
                    [15.154, 6.347],
                    [-4.079, 21.393],
                    [-17.01, 6.22],
                    [-16.955, -0.995],
                    [-36.711, 1.034],
                    [-33.52, -0.261],
                    [-9.769, 1.013],
                    [-15.128, 4.505],
                    [-12.104, 16.297],
                    [-2.39, 17.6],
                    [10.395, 23.184],
                    [18.706, 10.531],
                    [12.581, 2.983],
                    [10.072, 0.673],
                    [22.099, -0.323],
                    [22.285, 0.235],
                    [15.788, 13.881],
                    [0.086, 10.034],
                    [-0.049, -5.621],
                    [-6.546, -8.5],
                    [-9.188, -3.703],
                    [-22.268, 0.561],
                    [-42.443, -4.315],
                    [-18.374, -20.39],
                    [0.998, -28.71],
                    [36.479, -12.744],
                    [26.514, 0.23],
                    [33.83, -0.928],
                    [37.481, 1.91],
                    [16.291, -6.092],
                    [7.098, -16.527],
                    [-13.677, -15.368],
                    [-15.48, -1.796],
                    [-9.002, -0.206],
                    [0, 0],
                    [2.206, -0.084],
                    [-5.865, -0.565],
                    [-14.954, -0.247],
                    [-25.586, 0.127],
                    [-18.199, 0.379],
                  ],
                  o: [
                    [-51.228, -1.48],
                    [-7.579, -0.186],
                    [0, 0],
                    [2.205, -0.085],
                    [5.966, 0.135],
                    [-16.209, -0.367],
                    [-19.873, -8.323],
                    [3.591, -18.836],
                    [16.221, -5.932],
                    [36.631, 2.15],
                    [33.486, -0.943],
                    [9.803, 0.076],
                    [15.711, -1.629],
                    [19.34, -5.759],
                    [10.657, -14.347],
                    [3.41, -25.099],
                    [-8.76, -19.54],
                    [-11.258, -6.339],
                    [-9.848, -2.334],
                    [-22.063, -1.473],
                    [-22.286, 0.327],
                    [-20.022, -0.212],
                    [-7.538, -6.628],
                    [-0.047, -5.608],
                    [0.089, 10.476],
                    [6.132, 7.963],
                    [20.974, 8.452],
                    [42.551, -1.073],
                    [26.916, 2.737],
                    [19.605, 21.757],
                    [-1.286, 36.982],
                    [-25.277, 8.831],
                    [-33.863, -0.294],
                    [-37.551, 1.032],
                    [-17.102, -0.872],
                    [-16.263, 6.083],
                    [-8.129, 18.926],
                    [10.526, 11.826],
                    [8.959, 1.039],
                    [0, 0],
                    [-2.206, -0.051],
                    [-6.052, 0.23],
                    [14.887, 1.437],
                    [25.583, 0.419],
                    [18.203, -0.091],
                    [4.89, -0.103],
                  ],
                  v: [
                    [28.482, 184.664],
                    [-125.248, 183.26],
                    [-148.174, 182.667],
                    [-148.174, 191.936],
                    [-141.518, 192.074],
                    [-141.518, 182.808],
                    [-188.08, 176.064],
                    [-215.359, 125.578],
                    [-178.806, 89.305],
                    [-128.151, 85.255],
                    [-18.087, 88.049],
                    [82.307, 86.033],
                    [111.75, 85.436],
                    [158.698, 76.385],
                    [206.798, 43.618],
                    [225.842, -5.627],
                    [215.807, -80.066],
                    [173.583, -126.879],
                    [137.016, -140.815],
                    [106.84, -144.656],
                    [40.497, -145.853],
                    [-26.393, -144.394],
                    [-87.032, -160.611],
                    [-99.159, -187.815],
                    [-107.877, -187.815],
                    [-99.236, -159.194],
                    [-75.343, -142.601],
                    [-9.275, -134.499],
                    [118.852, -133.747],
                    [191.725, -100.218],
                    [217.707, -19.637],
                    [161.619, 65.569],
                    [83.337, 76.584],
                    [-18.087, 78.521],
                    [-130.648, 75.793],
                    [-181.785, 80.606],
                    [-221.122, 113.122],
                    [-211.636, 170.882],
                    [-168.585, 190.893],
                    [-141.518, 192.074],
                    [-141.518, 182.808],
                    [-148.174, 182.667],
                    [-148.174, 191.936],
                    [-102.938, 193.017],
                    [-26.122, 192.692],
                    [28.482, 192.258],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.365000017952, 0.322000002394, 0.451000019148, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [975.018, 557.616], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 8",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.469, -2.958],
                    [-0.269, -1.774],
                    [0.09, -1.911],
                    [-2.119, 2.849],
                    [4.608, 2.991],
                  ],
                  o: [
                    [0.767, 1.544],
                    [0.294, 1.944],
                    [-0.202, 4.276],
                    [3.502, -4.71],
                    [-3.167, -2.055],
                  ],
                  v: [
                    [-5.399, -4.018],
                    [-3.342, 0.499],
                    [-3.961, 6.076],
                    [3.367, 8.06],
                    [-0.563, -8.854],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.365000017952, 0.322000002394, 0.451000019148, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1024.643, 745.556], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 9",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.224, -4.586],
                    [5.361, -16.229],
                    [-1.952, 5.908],
                    [13.91, 15.101],
                  ],
                  o: [
                    [11.649, 12.648],
                    [-1.962, 5.94],
                    [6.454, -19.54],
                    [-4.236, -4.6],
                  ],
                  v: [
                    [-10.705, -22.07],
                    [-0.876, 25.04],
                    [8.475, 27.618],
                    [-3.849, -28.926],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.365000017952, 0.322000002394, 0.451000019148, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1051.447, 741.624], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 10",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-4.272, -4.548],
                    [3.701, -8.486],
                    [-2.491, 5.712],
                    [9.527, 10.139],
                  ],
                  o: [
                    [6.274, 6.679],
                    [-2.47, 5.663],
                    [5.509, -12.632],
                    [-4.281, -4.558],
                  ],
                  v: [
                    [-7.096, -12.265],
                    [-2.514, 13.073],
                    [5.859, 17.967],
                    [-0.241, -19.12],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.365000017952, 0.322000002394, 0.451000019148, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [1036.568, 743.995], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 11",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.576, 1.253],
                    [2.644, -2.024],
                    [0.084, -3.681],
                    [-10.267, -0.314],
                    [0.469, 6.882],
                    [3.421, 2.952],
                    [2.815, -3.59],
                    [-1.472, -2.526],
                    [-0.16, -0.273],
                    [-1.738, 4.216],
                    [-0.547, -2.746],
                    [-0.05, -0.83],
                    [-0.027, -1.094],
                    [-0.304, -0.374],
                    [-1.976, -0.065],
                    [0.039, 0.555],
                    [0.22, 2.408],
                    [-1.383, 1.471],
                  ],
                  o: [
                    [-2.557, -2.034],
                    [-3.153, 2.415],
                    [-0.161, 7.107],
                    [10.212, 0.313],
                    [-0.287, -4.209],
                    [-3.385, -2.92],
                    [-1.691, 2.157],
                    [0.159, 0.274],
                    [2.644, 4.538],
                    [-4.873, -1.064],
                    [0.086, 0.827],
                    [0.07, 1.092],
                    [0.013, 0.544],
                    [1.977, 0.065],
                    [-0.034, -0.555],
                    [0.364, -2.388],
                    [-0.166, -1.823],
                    [1.228, -1.307],
                  ],
                  v: [
                    [2.596, -14.05],
                    [-5.497, -14.117],
                    [-9.072, -3.8],
                    [0.027, 17.054],
                    [9.001, -2.596],
                    [4.961, -14.447],
                    [-6.44, -13.611],
                    [-7.387, -6.273],
                    [-6.909, -5.452],
                    [3.294, -6.785],
                    [-3.195, -4.263],
                    [-2.991, -1.778],
                    [-2.844, 1.502],
                    [-2.869, 6.424],
                    [3.061, 6.619],
                    [2.953, 4.955],
                    [2.381, -3.004],
                    [2.596, -8.916],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.365000017952, 0.322000002394, 0.451000019148, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [871.769, 337.508], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 12",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 12,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-0.151, -5.609],
                    [-16.58, 7.603],
                    [0.971, 11.848],
                    [-0.459, -5.589],
                    [6.052, -4.137],
                    [0.341, 12.684],
                  ],
                  o: [
                    [0.453, 16.843],
                    [10.783, -4.944],
                    [-0.455, -5.54],
                    [0.588, 7.173],
                    [-11.06, 7.562],
                    [-0.151, -5.586],
                  ],
                  v: [
                    [-24.748, -9.465],
                    [7.502, 13.136],
                    [23.928, -15.15],
                    [15.23, -15.15],
                    [6.987, 3.475],
                    [-16.049, -9.465],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.365000017952, 0.322000002394, 0.451000019148, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [873.12, 356.205], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 13",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 13,
          mn: "ADBE Vector Group",
          hd: false,
        },
        {
          ty: "gr",
          it: [
            {
              ind: 0,
              ty: "sh",
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [[-0.394, 0], [0.394, 0]],
                  o: [[0.393, 0], [-0.393, 0]],
                  v: [[0, 0.305], [0, -0.305]],
                  c: true,
                },
                ix: 2,
              },
              nm: "Path 1",
              mn: "ADBE Vector Shape - Group",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 0,
                k: [0.263000009574, 0.136999990426, 0.270999983245, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [881.742, 379.231], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Group 14",
          np: 2,
          cix: 2,
          bm: 0,
          ix: 14,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 1052.00004284884,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};
