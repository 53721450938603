import React, { Component } from "react";
import { ScalarGenerator } from "../../utils";
import { Point } from "./Point";
import { renderCurves } from "./rendering";

const { round } = Math;

const dpi = window !== undefined ? window.devicePixelRatio : 1;

interface Props {
  width: number;
  height: number;
  className?: string;
  color: string;
  generator?: ScalarGenerator;
  initialPoints?: Point[];
}

export class Visualizer extends Component<Props> {
  static PIXELS_PER_SECOND = 50;

  canvas: HTMLCanvasElement | null = null;
  ctx: CanvasRenderingContext2D | null = null;
  canvasWidth: number;
  canvasHeight: number;
  isRendering = false;
  lastTimestamp: number = 0;
  unsubscribeGenerator?: () => void;

  points: Point[] = this.props.initialPoints || [];

  constructor(props: Props) {
    super(props);

    this.canvasWidth = round(this.props.width * dpi);
    this.canvasHeight = round(this.props.height * dpi);

    this.renderFrame = this.renderFrame.bind(this);
    this.canvasRef = this.canvasRef.bind(this);
  }

  setupCanvas(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    canvas.width = this.canvasWidth;
    canvas.height = this.canvasHeight;
    canvas.style.width = `${this.props.width}px`;
    canvas.style.height = `${this.props.height}px`;

    this.ctx = this.canvas.getContext("2d")!;
    this.ctx.fillStyle = this.props.color;
  }

  start() {
    if (this.isRendering) {
      return;
    }

    if (this.props.generator) {
      this.connectGenerator(this.props.generator);
    }

    this.isRendering = true;

    this.lastTimestamp = performance.now();
    this.renderFrame(this.lastTimestamp);
  }

  stop() {
    this.isRendering = false;

    if (this.unsubscribeGenerator) {
      this.unsubscribeGenerator();
    }
  }

  connectGenerator(generator: ScalarGenerator) {
    this.unsubscribeGenerator = generator.subscribe(n => {
      this.points.unshift(Point.get(0, n));
    });
  }

  canvasRef(ref: HTMLCanvasElement) {
    if (ref === null) {
      this.stop();
      this.canvas = null;
      this.ctx = null;
      return;
    }

    this.setupCanvas(ref);
    this.start();
  }

  renderFrame(timestamp: DOMHighResTimeStamp) {
    if (!this.canvas) {
      return this.stop();
    }

    const ctx = this.ctx!;

    ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

    renderCurves(
      ctx,
      dpi,
      Visualizer.PIXELS_PER_SECOND,
      this.points,
      this.props.width,
      this.props.height,
    );

    let delta = (timestamp - this.lastTimestamp) / 1000;

    // Throw away long frames (if user tabs away)
    if (delta > 0.05) {
      delta = 0.016;
    }

    this.tickPhysics(delta);

    if (this.isRendering) {
      this.lastTimestamp = timestamp;
      requestAnimationFrame(this.renderFrame);
    }
  }

  tickPhysics(delta: number) {
    // Tick physics and cleanup offscreen points.
    for (let i = 0; i < this.points.length; i++) {
      const p = this.points[i];

      p.tick(delta);

      if (p.x * Visualizer.PIXELS_PER_SECOND > this.props.width) {
        const deadPoints = this.points.slice(i + 1, this.points.length);

        // Return old points to pool
        if (deadPoints.length) {
          for (const dp of deadPoints) {
            Point.return(dp);
          }
        }

        this.points = this.points.slice(0, i + 1);

        continue;
      }
    }
  }

  // Don't update on points change.
  shouldComponentUpdate(nextProps: Props) {
    if (
      nextProps.generator &&
      (!this.props.generator ||
        nextProps.generator.id !== this.props.generator.id)
    ) {
      if (this.unsubscribeGenerator) {
        this.unsubscribeGenerator();
      }

      this.connectGenerator(nextProps.generator);
    }

    return (
      nextProps.width !== this.props.width ||
      nextProps.height !== this.props.height
    );
  }

  render() {
    return (
      <div
        className={this.props.className}
        style={{
          width: this.props.width + "px",
          height: this.props.height + "px",
        }}
      >
        <canvas ref={this.canvasRef} />
      </div>
    );
  }
}
