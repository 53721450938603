import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import {
  Back,
  DeleteChapterTitleRecording,
  SetChapterTitle,
  SetChapterTitleRecording,
  StartRecordingChapterTitle,
  Submit,
} from "../actions";
import { Shared } from "../types";
import Entry from "./Entry";
import Submitting from "./Submitting";

function RecordingChapterTitle(
  action:
    | Enter
    | SetChapterTitle
    | SetChapterTitleRecording
    | StartRecordingChapterTitle
    | Submit
    | Back
    | DeleteChapterTitleRecording,
  shared: Shared,
): StateReturn {
  const { chapter } = shared;
  switch (action.type) {
    case "Enter":
      shared.canSubmitChapterTitle =
        !!chapter.title &&
        chapter.title.trim().length > 0 &&
        chapter.recordings.title !== null;

      return update(shared);

    case "SetChapterTitle":
      shared.chapter.title = action.chapterTitle;

      return reenter(shared);

    case "SetChapterTitleRecording":
      shared.chapter.recordings.title = action.recording;

      return reenter(shared);

    case "StartRecordingChapterTitle":
      return reenter(shared);

    case "DeleteChapterTitleRecording":
      shared.chapter.recordings.title = null;
      shared.canSubmitChapterTitle = false;

      return reenter(shared);

    case "Back":
      shared.isCreatingChapter = false;

      return Entry(shared);

    case "Submit":
      return shared.canSubmitChapterTitle ? Submitting(shared) : noop();
  }
}

const RecordingChapterTitleState = state(
  "RecordingChapterTitle",
  RecordingChapterTitle,
);
const { update, reenter } = RecordingChapterTitleState;
export default RecordingChapterTitleState;
