import { PointInterface } from "../../utils/PointInterface";
import { Point } from "./Point";

const startPoint = { x: 0, y: -0.1 };
const lastPoint = { x: 0, y: -0.1 };

function smooth(
  ctx: CanvasRenderingContext2D,
  points: PointInterface[],
  lastX: number,
  xMult: number = 1,
  yMult: number = 1,
) {
  ctx.moveTo(0, 0);

  smoothStep(ctx, startPoint, points[0], xMult, yMult);

  for (let i = 0; i < points.length - 1; i++) {
    smoothStep(ctx, points[i], points[i + 1], xMult, yMult);
  }

  lastPoint.x = lastX;
  smoothStep(ctx, points[points.length - 1], lastPoint, xMult, yMult);
}

function smoothStep(
  ctx: CanvasRenderingContext2D,
  p1: PointInterface,
  p2: PointInterface,
  xMult: number = 1,
  yMult: number = 1,
) {
  const x_mid = (p1.x * xMult + p2.x * xMult) / 2;
  const y_mid = (p1.y * yMult + p2.y * yMult) / 2;
  const cp_x1 = (x_mid + p1.x * xMult) / 2;
  const cp_x2 = (x_mid + p2.x * xMult) / 2;
  ctx.quadraticCurveTo(cp_x1, p1.y * yMult, x_mid, y_mid);
  ctx.quadraticCurveTo(cp_x2, p2.y * yMult, p2.x * xMult, p2.y * yMult);
}

export function renderCurves(
  ctx: CanvasRenderingContext2D,
  dpi: number,
  pixelsPerSecond: number,
  points: Point[],
  width: number,
  height: number,
) {
  ctx.save();

  // Retina
  ctx.scale(dpi, dpi);

  // Move to middle
  ctx.translate(0, height / 2 - 2);

  // Draw first curve.
  renderCurve(ctx, pixelsPerSecond, points, width, height);

  // Move down and flip.
  ctx.translate(0, 4);
  ctx.scale(1, -1);

  // Draw seconds curve.
  renderCurve(ctx, pixelsPerSecond, points, width, height);

  // Draw fill in space between curves.
  ctx.fillRect(0, 0, width, 4);

  ctx.restore();
}

function renderCurve(
  ctx: CanvasRenderingContext2D,
  pixelsPerSecond: number,
  points: Point[],
  _: number,
  height: number,
) {
  if (points.length < 1) {
    return;
  }

  ctx.beginPath();

  const lastX = points[points.length - 1].x + 2; // 2 seconds of ease out
  const multX = pixelsPerSecond;
  const multY = -(height / 2) * 0.9;

  smooth(ctx, points, lastX, multX, multY);

  ctx.closePath();

  ctx.fill();
}
