import React from "react";
import { useTranslation } from "react-i18next";
import ContentFrame from "../../components/ContentFrame";
import "./Error.css";
import { OhnoFace } from "./OhnoFace";

export function NotFound() {
  const { t } = useTranslation();

  return (
    <div>
      <ContentFrame>
        <div className="Error">
          <OhnoFace />
          <h2 className="message">{t("error.404")}</h2>
        </div>
      </ContentFrame>
    </div>
  );
}

export function UnrecoverableError() {
  const { t } = useTranslation();

  return (
    <div>
      <ContentFrame>
        <div className="Error">
          <OhnoFace />
          <h2 className="message">{t("error.500")}</h2>
        </div>
      </ContentFrame>
    </div>
  );
}

export function InvalidInvite() {
  const { t } = useTranslation();

  return (
    <div>
      <ContentFrame>
        <div className="Error --invalid-invite">
          <OhnoFace />
          <h2 className="header">{t("error.invalid_invite.header")}</h2>
          <p className="message">{t("error.invalid_invite.message")}</p>
        </div>
      </ContentFrame>
    </div>
  );
}
