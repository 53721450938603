import { Enter, noop, state, StateReturn } from "@druyan/druyan";
import { Modal, Shared } from "../../model";
import { AudioActions, audioActions } from "./Audio";
import { StreamActions, streamActions } from "./AwaitStream";
import { ChapterActions, chapterActions } from "./Chapter";
import { GreetingsActions, greetingsActions } from "./Greetings";
import { InputActions, inputActions } from "./Input";
import { inviteActions, InviteActions } from "./Invite";
import { ModalActions, modalActions } from "./Modal";
import { StoryActions, storyActions } from "./Story";
import { UserDataActions, userDataActions } from "./UserData";

function Ready(
  action:
    | Enter
    | InviteActions
    | ModalActions
    | UserDataActions
    | GreetingsActions
    | StoryActions
    | ChapterActions
    | AudioActions
    | InputActions
    | StreamActions,
  shared: Shared,
  modal: Modal = null,
): StateReturn | StateReturn[] {
  const { user } = shared;

  if (!user) {
    throw new Error("Must be logged in to ready Ready state");
  }

  switch (action.type) {
    case "Enter":
      return noop();

    case "App/RemoveEditorFromUser":
    case "App/RemoveEditorFromStory":
    case "App/RemoveEditorsFromStory":
    case "App/ReloadDataAfterInvite":
    case "App/SendInvite":
      return inviteActions(action, shared, modal, update);

    case "App/PlayAudio":
    case "App/PauseAudio":
    case "App/StopAudio":
      return audioActions(action, shared, modal, update);

    case "App/ShowGearMenu":
    case "App/ShowAcknowledge":
    case "App/ShowConfirm":
    case "App/ShowSpinner":
    case "App/ShowProcessingIndicator":
    case "App/HideModal":
    case "App/ShowRerecord":
      return modalActions(action, shared, update);

    case "App/DidCreateStory":
    case "App/DeleteStory":
    case "App/RemoveSharedStory":
      return storyActions(action, shared, modal, update);

    case "App/CompleteIntro":
    case "App/ConfirmedFirstChapterModal":
    case "App/ConfirmedUploadModal":
    case "App/CompleteGreetings":
      return userDataActions(action, shared, modal, update);

    case "App/SaveGreeting":
    case "App/DeleteGreeting":
      return greetingsActions(action, shared, modal, update);

    case "App/ReorderChapters":
    case "App/UpdateStoryChapterTitle":
    case "App/DeleteStoryChapter":
    case "App/DeleteChapterRecording":
    case "App/SaveChapterRecording":
    case "App/DidCreateChapter":
      return chapterActions(action, shared, modal, update);

    case "App/InputFocused":
      return inputActions(action, shared, update);

    case "App/AwaitingStream":
      return streamActions(action, shared, update);
  }
}

const ReadyState = state("Ready", Ready);
const { update } = ReadyState;
export default ReadyState;
