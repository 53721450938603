import { Recording } from "../state";
import { bufferToBlob } from "./binary";

// tslint:disable: no-var-requires
const Recorder: any = (window as any).Recorder;
const NoSleep = require("nosleep.js");
const noSleep = new NoSleep();

export class RecordingController {
  stream?: MediaStream;
  audioSourceNode?: MediaStreamAudioSourceNode;
  private recorder: any | null = null;
  private startTime = performance.now();
  private encoder: {
    path: string;
    type: string;
  } = this.useOpus();

  constructor(private onComplete?: (recording: Recording) => void) {
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
  }

  useWav() {
    return (this.encoder = {
      path: "/opus-recorder/waveWorker.min.js",
      type: "audio/wav",
    });
  }

  useOpus() {
    return (this.encoder = {
      path: "/opus-recorder/encoderWorker.min.js",
      type: "audio/ogg",
    });
  }

  async start(audioContext: AudioContext): Promise<MediaStream | undefined> {
    audioContext.resume();
    this.startTime = performance.now();
    try {
      this.recorder = new Recorder({
        encoderPath: this.encoder.path,
      });
    } catch (e) {
      // tslint:disable-next-line: no-console
      console.log(e);
      return;
    }

    try {
      this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (error) {
      throw new Error(error.name);
    }

    this.audioSourceNode = audioContext.createMediaStreamSource(this.stream!);

    await this.recorder.start(this.audioSourceNode);
    noSleep.enable();

    return (this.recorder as any).stream;
  }

  async stop(audioContext: AudioContext): Promise<Recording> {
    return new Promise((resolve, reject) => {
      this.recorder.ondataavailable = (buffer: ArrayBuffer) => {
        if (buffer.byteLength === 0) {
          reject();
          return;
        }

        const endTime = performance.now();
        const duration = Math.round((endTime - this.startTime) / 1000);

        const blob = bufferToBlob(buffer, this.encoder.type);

        const audio = URL.createObjectURL(blob);

        const recording = { points: [], audio, duration, isUpdated: true };

        if (this.onComplete) {
          this.onComplete(recording);
        }

        resolve(recording);
      };

      this.recorder!.stop();
      noSleep.disable();
      if (this.stream && this.audioSourceNode) {
        this.audioSourceNode.disconnect();
        this.stream.getTracks().forEach(track => {
          track.stop();
        });
        audioContext.suspend();
        this.audioSourceNode = undefined;
        this.stream = undefined;
      }
    });
  }
}
