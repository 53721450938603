import { Enter, noop, state, StateReturn } from "@druyan/druyan";

import { Edit, edit } from "../actions";
import { Shared } from "../types";
import Editing from "./Editing";

function Entry(
  action: Enter | Edit,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { isEditing } = shared;

  switch (action.type) {
    case "Enter":
      return isEditing ? edit() : noop();

    case "Edit":
      return Editing(shared);
  }
}

const EntryState = state("Entry", Entry);
export default EntryState;
