import {
  Enter,
  eventually,
  Exit,
  goBack,
  state,
  StateReturn,
  timeout,
} from "@druyan/druyan";
import { t } from "../../../../i18n";
import { Actions as AppState } from "../../../../state";
import { redirectTo } from "../../../../utils";
import { routeTo } from "../../../../utils";
import { Confirm, confirm, Reject, reject } from "../actions";
import { Shared } from "../types";

function ShowingConfirmCancelEmptyStoryModal(
  action: Enter | Confirm | Reject | Exit,
  shared: Shared,
): StateReturn | StateReturn[] {
  const { story } = shared;

  switch (action.type) {
    case "Enter":
      const onConfirm = eventually(confirm);
      const onReject = eventually(reject);

      return [
        onConfirm,
        onReject,
        AppState.showConfirm({
          text: t("confirm_cancel_empty_story.text"),
          confirmText: t("global.yes"),
          rejectText: t("global.no"),
          onConfirm,
          onReject,
        }),
      ];

    case "Confirm":
      return [
        AppState.hideModal(),
        redirectTo(routeTo.dashboard()),
        timeout(400, AppState.deleteStory(story)),
      ];

    case "Reject":
      return [
        // Must manually hide, since redirect doesn't exit the state.
        AppState.hideModal(),

        goBack(),
      ];
    case "Exit":
      return AppState.hideModal();
  }
}

export default state(
  "ShowingConfirmCancelEmptyStoryModal",
  ShowingConfirmCancelEmptyStoryModal,
);
