import { Action } from "@druyan/druyan";
import { ConfirmModal } from "../model";

export interface ShowConfirm extends Action<"App/ShowConfirm"> {
  modal: ConfirmModal;
}

export function showConfirm(modal: Omit<ConfirmModal, "type">): ShowConfirm {
  return {
    type: "App/ShowConfirm",
    modal: { ...modal, type: "confirm" },
  };
}
