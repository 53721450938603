import {
  Add,
  FilledRoundButton,
  Mic,
} from "@storytime/shared/build/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { SavedRecordedChapter, SavedStory } from "../../state";
import { renderDuration, routeTo } from "../../utils";
import "../ChapterRow/ChapterRow.css";
import PlayerButton from "../PlayerButton";

export interface Props {
  story: SavedStory;
  chapter: SavedRecordedChapter;
  user?: firebase.User;
}

export function ChapterRowUncompleted({ story, chapter, user }: Props) {
  const { t } = useTranslation();

  let displayName;

  if (!user) {
    displayName = "Unknown";
  } else if (chapter.owner === user.uid) {
    displayName = t("you");
  } else {
    displayName = chapter.ownerName;
  }

  const {
    recordings: { audio },
    title,
  } = chapter;

  return (
    <div className="chapter-row --uncompleted">
      <div
        className="chapter-row-item chapter-play"
        aria-label={t(`chapter_row.play_header`)}
      >
        {audio ? <PlayerButton recording={audio} /> : undefined}
      </div>
      <div
        className="chapter-row-item chapter-title-wrapper"
        aria-label={t(`chapter_row.title_header`)}
      >
        <div
          className="chapter-row-item chapter-duration"
          aria-label={t(`chapter_row.length_header`)}
        >
          {audio ? renderDuration(audio.duration) : "0:00"}
        </div>

        {title ? (
          <div className="chapter-title">
            {title}
            <div className="chapter-owner-wrapper">
              <span className="mic-icon">
                <Mic />
              </span>
              <span className="chapter-owner">{displayName}</span>
            </div>
          </div>
        ) : null}
      </div>
      <div
        className="chapter-row-item chapter-resume"
        aria-label={t(`chapter_row.resume_header`)}
      >
        <FilledRoundButton
          linkTo={routeTo.editChapter(story.id, chapter.id)}
          filled={true}
          icon={<Add />}
        >
          {t(`button.add`)}
        </FilledRoundButton>
      </div>
    </div>
  );
}
