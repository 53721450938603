import { StateReturn } from "@druyan/druyan";
import {
  deleteEditorChapter,
  removeEditor,
  removeEditorFromStory,
  removeEditorsFromStory,
  sendInvite,
} from "../../../api";
import {
  ReloadDataAfterInvite,
  RemoveEditorFromStory,
  RemoveEditorFromUser,
  RemoveEditorsFromStory,
  SendInvite,
} from "../../actions";
import { Modal, Shared } from "../../model";
import Loading from "../Loading";
import Ready from "../Ready";

export type InviteActions =
  | SendInvite
  | RemoveEditorFromUser
  | RemoveEditorFromStory
  | RemoveEditorsFromStory
  | ReloadDataAfterInvite;

export function inviteActions(
  action: InviteActions,
  shared: Shared,
  modal: Modal,
  update: (shared: Shared, modal: Modal) => ReturnType<typeof Ready>,
): StateReturn | StateReturn[] {
  const { user, userData, stories } = shared;

  if (!user) {
    throw new Error("Must be logged in to handle invites");
  }

  switch (action.type) {
    case "App/RemoveEditorFromUser":
      removeEditor(user.uid, action.userId);

      userData.editors = userData.editors.filter(id => id !== action.userId);

      Object.values(stories.myStories).forEach(story => {
        if (story.editors && story.editors.includes(action.userId)) {
          story.editors = story.editors.filter(id => id !== action.userId);
        }
      });

      return update(shared, modal);

    case "App/RemoveEditorFromStory":
      const myStory = stories.myStories[action.storyId];

      removeEditorFromStory(action.userId, action.storyId);

      if (myStory) {
        myStory.editors = stories.myStories[action.storyId].editors.filter(
          id => id !== action.userId,
        );
      }
      const stillHasSharedStories =
        Object.values(stories.myStories).filter(
          story => story.editors && story.editors.includes(action.userId),
        ).length > 0;

      if (!stillHasSharedStories) {
        removeEditor(user.uid, action.userId);
        userData.editors = userData.editors.filter(id => id !== action.userId);
      }

      Object.values(myStory.chapters).forEach(chapter => {
        if (chapter.owner === action.userId) {
          deleteEditorChapter(action.storyId, chapter.id);
        }
      });

      return update(shared, modal);

    case "App/RemoveEditorsFromStory":
      const storyToClearEditors = stories.myStories[action.storyId];
      const allEditorsForStory = storyToClearEditors.editors;
      
      storyToClearEditors.editors = [];

      allEditorsForStory.forEach(editorId => {
        Object.values(storyToClearEditors.chapters).forEach(chapter => {
          if (chapter.owner === editorId) {
            deleteEditorChapter(action.storyId, chapter.id);
          }
        });

        const stillAnEditor =
        Object.values(stories.myStories).filter(
          story => story.editors && story.editors.includes(editorId),
        ).length > 0;

        if (!stillAnEditor) {
          removeEditor(user.uid, editorId);
          userData.editors = userData.editors.filter(id => id !== editorId);
        }
      });
    
      removeEditorsFromStory(action.storyId);

    case "App/ReloadDataAfterInvite":
      return Loading(shared);

    case "App/SendInvite":
      const storiesToInvite = action.stories;

      sendInvite(action.email, action.stories);

      storiesToInvite.forEach(storyId => {
        stories.myStories[storyId].invites.push({
          email: action.email,
          time: "",
        });
      });

      userData.invites.push({ email: action.email, time: "" });

      return update(shared, modal);
  }
}
