import { volumeProcessorGraph } from "./processAudio";

// tslint:disable:max-classes-per-file

const { random: randomNumber } = Math;

const ua = window.navigator.userAgent.toLowerCase();
const isiPad =
  ua.indexOf("ipad") > -1 ||
  (ua.indexOf("macintosh") > -1 && "ontouchend" in document);

export interface ScalarGenerator {
  id: number;
  isRunning: boolean;
  scalars: number[];
  stop: () => number[];
  subscribe(fn: (y: number) => void): () => void;
}

let ids = 0;

export class IntervalScalarGenerator implements ScalarGenerator {
  id = ++ids;
  listeners: Array<(y: number) => void> = [];
  scalars: number[] = [];
  isRunning = true;
  interval?: NodeJS.Timer;

  constructor(public frequency = 500) {
    this.start();
  }

  start() {
    this.interval = setInterval(() => {
      const n = randomNumber();
      this.scalars.push(n);

      for (const l of this.listeners) {
        l(n);
      }
    }, this.frequency);
  }

  stop() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }

    return this.scalars;
  }

  subscribe(fn: (y: number) => void) {
    this.listeners.push(fn);

    return () => (this.listeners = this.listeners.filter(l => l !== fn));
  }
}

export class VolumeScalarGenerator implements ScalarGenerator {
  id = ++ids;

  listeners: Array<(y: number) => void> = [];

  volume = 0.0;

  scalars: number[] = [];

  mic?: MediaStreamAudioSourceNode;
  disconnectGraph?: () => void;
  interval?: NodeJS.Timer;
  isRunning: boolean = false;

  constructor(public frequency = 200) {
    this.saveScalar = this.saveScalar.bind(this);
    this.subscribe(this.saveScalar);
  }

  async start(audioContext: AudioContext, incomingStream?: MediaStream) {
    if (this.isRunning) {
      return;
    }

    if (isiPad || !!navigator.platform.match(/iPhone|iPod|iPad/)) {
      return;
    }

    const stream =
      incomingStream ||
      (await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      }));

    this.isRunning = true;
    this.scalars = [];

    const context = audioContext;

    this.mic = context.createMediaStreamSource(stream);

    this.disconnectGraph = volumeProcessorGraph(
      stream,
      context,
      this.mic,
      (volume: number) => (this.volume = volume),
    );

    this.interval = setInterval(() => {
      for (const l of this.listeners) {
        l(this.volume);
      }
    }, this.frequency);
  }

  saveScalar(volume: number) {
    this.scalars.push(volume);
  }

  stop() {
    if (isiPad || !!navigator.platform.match(/iPhone|iPod|iPad/)) {
      return this.scalars;
    }

    if (!this.isRunning) {
      throw new Error("Cannot stop unless running");
    }

    this.isRunning = false;

    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }

    if (this.disconnectGraph) {
      this.disconnectGraph();
      this.disconnectGraph = undefined;
    }

    return this.scalars;
  }

  subscribe(fn: (y: number) => void) {
    this.listeners.push(fn);

    return () => (this.listeners = this.listeners.filter(l => l !== fn));
  }
}
