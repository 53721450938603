import { Action } from "@druyan/druyan";
import { SavedStory } from "../model";

export interface DidCreateStory extends Action<"App/DidCreateStory"> {
  story: SavedStory;
}

export function didCreateStory(story: SavedStory): DidCreateStory {
  return {
    type: "App/DidCreateStory",
    story,
  };
}
