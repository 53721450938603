import React from "react";
import { RouteComponentProps } from "react-router";
import { analytics } from "../../firebase";
import { useActions, useAuth, useIOSCheck, useStories } from "../../hooks";
import { history, routeTo } from "../../utils";
import { CreateChapter } from "./CreateChapter";
import { StateContext, States } from "./machine";

type Props = RouteComponentProps<{ storyId?: string }>;

export default ({
  match: {
    params: { storyId },
  },
}: Props) => {
  const user = useAuth();
  const { playAudio, pauseAudio } = useActions();
  const { myStories, storiesSharedWithMe } = useStories();
  const isIOS = useIOSCheck();

  analytics.setCurrentScreen("Create Chapter");

  if (!user) {
    throw new Error("Cannot Create Chapter without user");
  }

  const story = storyId
    ? myStories[storyId] || storiesSharedWithMe[storyId]
    : undefined;

  if (!story) {
    // tslint:disable-next-line:no-console
    console.error(`Invalid story: ${storyId}`);
    if (history.location.pathname !== routeTo.notFound()) {
      history.replace(routeTo.notFound());
    }
    return null;
  }

  const originalChapterNumber = Object.keys(story.chapters).length + 1;

  return (
    <div>
      <StateContext.Create
        initialState={States.Entry({
          user,
          story,
          chapterTitleRecording: null,
          title: "",
          originalChapterNum: originalChapterNumber,
          canSubmit: false,
          isSaving: false,
          isSubmitting: false,
        })}
      >
        {({
          actions,
          currentState: {
            data: [{ title, canSubmit, isSubmitting }],
          },
        }) => {
          const startAction = async () => {
            try {
              actions.startRecording();
            } catch (error) {
              actions.showWarning(error);
            }
          };

          return (
            <CreateChapter
              chapterTitle={title}
              canSubmit={canSubmit}
              onChangeTitle={actions.setChapterTitle}
              onSubmit={actions.submit}
              onStartRecording={startAction}
              onSetRecording={actions.setRecording}
              onDeleteRecording={actions.deleteRecording}
              playAudio={playAudio}
              pauseAudio={pauseAudio}
              onBack={history.goBack}
              isIOS={isIOS}
              isSubmitting={isSubmitting}
            />
          );
        }}
      </StateContext.Create>
    </div>
  );
};
