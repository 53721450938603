import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { SavedChapter, SavedRecordedChapter, SavedStory } from "../../state";
import ChapterRowIsEditing from "../ChapterRowIsEditing";
import ChapterRowUncompleted from "../ChapterRowUncompleted";
import "./ChapterList.css";

export interface Props {
  story: SavedStory;
  chapters: SavedChapter[];
  user?: firebase.User;
  onReorder?: (chaptersInOrder: SavedChapter[]) => void;
}

const getListStyle = (_isDraggingOver: boolean) => ({});

const getItemStyle = (_isDragging: boolean, draggableStyle: any = {}) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    background: "var(--port-gore)",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function ChapterListIsEditing({ story, chapters, onReorder }: Props) {
  const { t } = useTranslation();

  function onDragEnd(result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const sortedChapters = reorder(
      chapters,
      result.source.index,
      result.destination.index,
    );

    if (onReorder) {
      onReorder(sortedChapters);
    }
  }

  return (
    <div className="chapter-list">
      <h3 className="hide-text">{t("chapter_list.aria_label")}</h3>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(droppableProvided, droppableSnapshot) => (
            <div
              className="chapter-list-list"
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
              style={getListStyle(droppableSnapshot.isDraggingOver)}
            >
              {chapters.map((chapter, index) => {
                return (
                  <Draggable
                    key={chapter.id}
                    draggableId={chapter.id}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        style={getItemStyle(
                          draggableSnapshot.isDragging,
                          draggableProvided.draggableProps.style,
                        )}
                      >
                        <ChapterRowIsEditing
                          story={story}
                          chapter={chapter}
                          title={chapter.title}
                          draggableHandleProps={
                            draggableProvided.dragHandleProps
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export function ChapterListStatic({ story, user, chapters }: Props) {
  const { t } = useTranslation();

  return (
    <div className="chapter-list">
      <h3 className="hide-text">{t("chapter_list.aria_label")}</h3>
      <div className="chapter-list-list">
        {chapters.map(chapter => {
          return (
            <ChapterRowUncompleted
              story={story}
              chapter={chapter as SavedRecordedChapter}
              key={chapter.id}
              user={user}
            />
          );
        })}
      </div>
    </div>
  );
}
