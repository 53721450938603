import { Action } from "@druyan/druyan";
import { SavedStory } from "../model";

export interface DeleteStory extends Action<"App/DeleteStory"> {
  story: SavedStory;
}

export function deleteStory(story: SavedStory): DeleteStory {
  return {
    type: "App/DeleteStory",
    story,
  };
}
