import { Particles, random, Share } from "@storytime/shared/build/components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Card.css";

export enum COLORS {
  carnation = "#f8656a",
  bermuda = "#85e0c0",
  your_pink = "#ffc2bd",
  tumbleweed = "#dcab7c",
  heliotrope = "#bd72f2",
  vivid_tangerine = "#ff9e7c",
  ziggurat = "#badae4",
  sandwisp = "#f7eaa2",
}

const ALL_PARTICLE_COLORS = [
  "#5d5273",
  "#f8656a",
  "#92e8c9",
  "#ffc2bd",
  "#dcab7c",
  "#bd72f2",
  "#ff9e7c",
  "#badae4",
  "#f7eaa2",
];

function colorName(hex: string) {
  return Object.keys(COLORS).find(k => (COLORS as any)[k] === hex);
}

export interface Props {
  title: string;
  color: COLORS;
  linkTo?: string;
  onClick?: () => void;
  isShared?: boolean;
}

export function StaticCard({
  title,
  color,
  onClick,
  particles,
  linkTo,
  isShared,
}: Props & { particles: boolean }) {
  const cardContents = (
    <>
      {isShared && (
        <span className="Card_share" aria-hidden="true">
          <Share />
        </span>
      )}
      <h2 className="Card_title">{title}</h2>

      {particles ? (
        <Particles
          className="Card_particles"
          width={236}
          height={236}
          emitters={[
            {
              initialSpawn: 20,
              wander: 0.2,
              frequency: 0.08,
              radius: random.number(1, 6),
              initialVelocity: random.numberPair([-12, 12], [-12, 12]),
              location: random.numberPair([8, 220], [8, 220]),
              acceleration: () => [0, 0],
              colors: ALL_PARTICLE_COLORS,
              startDyingAt: 0.75,
              spawnUntil: () => 0.15,
              friction: 0,
              lifetime: random.number(3, 5),
            },
          ]}
        />
      ) : (
        undefined
      )}
    </>
  );

  if (linkTo) {
    return (
      <Link to={linkTo} className={`Card --${colorName(color)}`}>
        {cardContents}
      </Link>
    );
  } else {
    return (
      <button
        type="button"
        className={`Card --${colorName(color)}`}
        onClick={onClick ? onClick : undefined}
      >
        {cardContents}
      </button>
    );
  }
}

export function Card(props: Props) {
  const [hover, setHover] = useState(false);
  return (
    // tslint:disable-next-line:react-a11y-event-has-role
    <div
      className="Card_wrapper"
      // tslint:disable-next-line:react-this-binding-issue
      onMouseOver={() => setHover(true)}
      // tslint:disable-next-line:react-this-binding-issue
      onMouseOut={() => setHover(false)}
    >
      <StaticCard {...props} particles={hover} />
    </div>
  );
}
